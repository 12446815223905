import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexAdapterKitModalComponent } from './index-adapter-kit-modal.component';
import {
  ButtonModule, LibPopoverModule, NgxSmartModalModule,
  RadioCheckboxModule, SelectModule
} from '@bssh/comp-lib';
import { AceModule } from 'ngx-ace-wrapper';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [IndexAdapterKitModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    SelectModule,
    RadioCheckboxModule,
    LibPopoverModule,
    NgxSmartModalModule,
    AceModule
  ],
  exports: [IndexAdapterKitModalComponent]
})
export class IndexAdapterKitModalModule { }

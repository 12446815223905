import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Constants } from '@app/core/utilities/constants';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { CodeFeaturesService } from '../../core/services/user/code-features.service';

@Injectable({
  providedIn: 'root'
})
export class MultiAnalysisCodeFeaturesGuard implements CanActivate {

  constructor(
    private codeFeaturesService: CodeFeaturesService,
    private router: Router,
    private userStore: CurrentUserStore
  ) {}

  /**
   * Checks if run-planning multi-analysis code feature is enabled.
   * If not, redirect to access forbidden page
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userStore.stateChanged.pipe(
      filter(e => e != null),
      map(e => {
        if (!this.codeFeaturesService.runPlanningMultiAnalysisEnabled) {
          this.router.navigate([Constants.ACCESS_FORBIDDEN_ROUTE]);
          return false;
        }
        return true;
      })
    );
  }
}

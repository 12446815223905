import { ChangeDetectorRef, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Copied from https://git.illumina.com/SEES/kratos/blob/develop/kratos-ui/src/app/directives/rad-form-field.directive.ts
// TODO: We really should have a shared library to implement these radiance directives. I straight up copy pasta this from the codebase above

@Directive({
  selector: 'rad-text-field, rad-textarea',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadTextFieldDirective,
      multi: true,
    },
  ],
})
export class RadTextFieldDirective implements ControlValueAccessor {

  constructor(private element: ElementRef, private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    this.onChange = () => {};
    this.onTouched = () => {};
  }

  onChange: (value: string) => void;
  onTouched: () => void;

  writeValue(value: string) {
    this.renderer.setProperty(this.element.nativeElement, 'value', value);
  }

  @HostListener('onInput', ['$event.detail'])
  _handleInput(event: any) {
    const value = event.target && event.target.value;

    if (typeof value === 'string' || !value) {

      // So we don't have to deal with emitted "null" values
      this.onChange(value ? value : '');
      this.cdr.detectChanges();
    }
  }

  @HostListener('change', ['$event.detail'])
  _handleChange(value: string) {
    // DS input field is emitting two change events, skipping InputEvent type change events.
    // Removing everything from rad-input emits value=null, so, we have to consider null value.
    if (typeof value === 'string' || !value) {
      // So we don't have to deal with emitted "null" values
      this.onChange(value ? value : '');
      this.cdr.detectChanges();
    }
  }

  registerOnChange(fn: (value: string) => void) {
    this.onChange = (event) => {
      fn(event);
    };
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
  }

}

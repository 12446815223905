<div #InitiateArchiveDiv>
    <ngx-smart-modal #InitiateArchiveModal
        [identifier]="modalType"
        [title]="ngxSmartModalTitle"
        closable="true"
        showCloseButton="true" 
        [closeButtonText]="'Cancel'"
        [disableConfirmButton]="disableConfirmButton" 
        [confirmButtonText]="confirmButtonText">

        <lib-progress #apiDataSpinnerLoader [id]="apiDataSpinnerLoader"  class="'spinner'" [loader]="false" [fixed]="true"
                [spinner]="true" [spinnerPosition]="'center'" [progressBarContainer]="'modal'" (started)="onStarted()" (completed)="onCompleted()"
                data-cy="archive-modal-progress-overlay-spinner">
        </lib-progress>

        <app-archive-or-restore-data
            [resourceType]="resourceType"
            [resourceList]="resourceList"
            [resourceIdentifierTitle]="resourceIdentifierTitle"
            [resourceSizeTitle]="resourceSizeTitle"
            [actionMsg]="actionMsg"
            [alertMsg]="alertMsg"
            [errorMsg]="errorMsg"
            [totalSizeOfResources]="totalSizeOfResources">
        </app-archive-or-restore-data>

    </ngx-smart-modal>
</div>
import * as gss from '@stratus/gss-ng-sdk';
import { assign } from 'lodash';
export class ImportPlannedRunWarning implements gss.ImportPlannedRunWarning {
    /**
     * Section name
     */
    public section?: string;
    /**
     * Field name
     */
     public field?: string;
    /**
     * error message
     */
     public message?: string;
    /**
     * Severity of the message
     */
     public severity?: string;

    constructor(obj?: gss.ImportPlannedRunWarning) {
        if (!obj) {
            return;
        }
        assign(this, obj);
    }

    public getRunWarningText(): string {
        let text = '';
        if (this.section) {
            text += `${this.section}<br>`;
        }
        text += this.formatRunWarningMessage(this.message);
        return text;
    }

    /**
     * Message can either be a normal line of text or a stringified JSON
     * If it is normal line of text, no parsing or formatting is needed
     * If it is JSON, the message will need to be parsed to extract the relevant information like ErrorMessage, SampleId, etc
     * @param message 
     * @returns formatted, user-readable message
     */
    private formatRunWarningMessage(message: string): string {
        const runWarningMessageJson = this.tryParseRunWarningMessageFromJson(message);
        if (runWarningMessageJson) {
            if (runWarningMessageJson.Details) {
                const texts = [];
                for (const detail of runWarningMessageJson.Details) {
                    const messageText = detail.ErrorMessage;
                    if (!messageText) {
                        return message;
                    }
                    const sampleText = detail.SampleId ? `Sample '${detail.SampleId}'` : null;
                    texts.push([sampleText, messageText].filter(text => !!text).join(': '));
                }
                return texts.join('<br>');
            } else {
                const messageText = runWarningMessageJson.ErrorMessage;
                if (messageText) {
                    const laneText = runWarningMessageJson.LaneNumber ? `Lane ${runWarningMessageJson.LaneNumber}` : null;
                    const sampleText = runWarningMessageJson.SampleName ? `Sample '${runWarningMessageJson.SampleName}'` : null;
                    const subjectText = [laneText, sampleText].filter(text => !!text).join(', ');
                    return [subjectText, messageText].filter(text => !!text).join(': ');
                }
            }
        }
        return message;
    }

    private tryParseRunWarningMessageFromJson(message: string): any {
        let runWarningMessageJson: any;
        try {
            runWarningMessageJson = JSON.parse(message);
        } catch (e) {
            return null;
        }
        if (typeof runWarningMessageJson !== "object") {
            return null;
        }
        return runWarningMessageJson;
    }
}
import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { ISearchResourceConfig } from '@app/core/services/resource-dictionary/search-resource-dictionary-types';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { BytesFilter } from '@app/core/utilities/filters/bytes.filter';
import { CustomColDef } from '@bssh/comp-lib/lib/components/data-table/custom-colDef';
import { OverrideColumnDefinition, OverrideColumnParameters, SearchGridOptionsFormatter } from './search-grid-options-formatter';
import { DataTableUtilities } from '@app/core/utilities/data-table-utilities';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';

export enum SampleSearchColumnFields {
    Name = 'Name',
    Created = 'Created',
    Owner = 'Owner',
    Project = 'Project',
    Size = 'Size',
    ReadLength = 'ReadLength',
    NumReadsPF = 'NumReadsPF',
    Genome = 'Genome'
}

export interface SampleOverrideColumnDefinition extends OverrideColumnDefinition<SampleSearchColumnFields> {}

export class SampleSearchGridOptionsFormatter extends SearchGridOptionsFormatter<SampleSearchColumnFields> {
    private sampleResourceConfig: ISearchResourceConfig;

    constructor(public resourceDictionaryService: SearchResourceDictionaryService, public bsshDatePipe: BsshDatePipe) {
        super();
        this.sampleResourceConfig = this.resourceDictionaryService.getResourceConfigByType(SearchResourceType.Sample);
    }

    protected getOverrideColumnParameters(): OverrideColumnParameters<SampleSearchColumnFields> {
        const inclusiveColumns: SampleSearchColumnFields[] = [
            SampleSearchColumnFields.Name,
            SampleSearchColumnFields.Project,
            SampleSearchColumnFields.Owner,
            SampleSearchColumnFields.Created
        ];

        const overrideColumnDefinitions: OverrideColumnDefinition<SampleSearchColumnFields>[] = [
            {
              colId: SampleSearchColumnFields.Name,
              checkboxSelection: false
            }
        ];

        return {
            inclusiveColumns,
            overrideColumnDefinitions
        };
    }

    protected getDefaultColumnDefinitions(): CustomColDef[] {
        const sampleConfig = this.sampleResourceConfig;
        return [
            {
                headerName: 'Name',
                field: 'Sample.Name',
                cellRendererSelector: (params) => {
                  if(params.data) {
                    return DataTableUtilities.getLinkRenderer(
                      params.data.Sample.Name,
                      sampleConfig.uiHrefAccessor(params.data)
                    );
                  } else {
                    return DataTableUtilities.getSpanRenderer();
                  }
              },
                flex: 3.5,
                filterActive: false,
                colId: SampleSearchColumnFields.Name,
                sortable: false
              },
              {
                headerName: 'Created',
                valueGetter: (params) => {
                    if (!params.data) { return; }
                    return this.bsshDatePipe.transform(params.data.Sample.DateCreated);
                  },
                flex: 1.5,
                filterActive: false,
                colId: SampleSearchColumnFields.Created,
                cellRenderer: 'span',
                sortable: false
              },
              {
                headerName: 'Owner',
                field: 'Sample.UserOwnedBy.Name',
                flex: 2,
                colId: SampleSearchColumnFields.Owner,
                cellRenderer: 'span',
                sortable: false
              },
              {
                headerName: 'Project',
                cellRendererSelector: (params) => {
                  if(params.data && params.data.Sample && params.data.Sample.Projects && params.data.Sample.Projects[0]) {
                    const project = params.data.Sample.Projects[0];
                    return DataTableUtilities.getLinkRenderer(
                        project.Name,
                        `/projects/${project.Id}`
                    );
                  } else {
                    return DataTableUtilities.getSpanRenderer();
                  }
                },
                flex: 3,
                colId: SampleSearchColumnFields.Project,
                sortable: false
              },
              {
                headerName: 'Size',
                valueGetter: (params) => {
                    if (!params.data) { return; }
                    return BytesFilter(params.data.Sample.TotalSize);
                },
                cellRenderer: 'span',
                suppressSizeToFit: true,
                minWidth: 105,
                maxWidth: 120,
                colId: SampleSearchColumnFields.Size,
                sortable: false
              },
              {
                headerName: 'Read Length',
                field: SampleSearchColumnFields.ReadLength,
                colId: SampleSearchColumnFields.ReadLength,
                valueGetter: params => {
                  if (params.data) {
                    const { Read1, Read2 } = params.data.Sample;
                    return `${Read1} | ${Read2}`;
                  }
                },
                cellRenderer: 'span',
                minWidth: 80,
                resizable: false,
                sortable: false
              },
              {
                headerName: 'Reads PF',
                field: 'Sample.NumReadsPF',
                colId: SampleSearchColumnFields.NumReadsPF,
                cellRenderer: 'span',
                minWidth: 100,
                resizable: true,
                sortable: false
              },
              {
                headerName: 'Genome',
                field: 'Sample.Genome.DisplayName',
                colId: SampleSearchColumnFields.Genome,
                cellRenderer: 'span',
                minWidth: 100,
                resizable: true,
                sortable: false
              }
        ];
    }
}
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

export interface ResourceInfo {
  name: string;
  value: string;
}

@Component({
  selector: 'app-requeue-fastq',
  templateUrl: './requeue-fastq.component.html',
  styleUrls: ['./requeue-fastq.component.scss'],
})
export class RequeueFastqComponent implements OnInit {

  @Input() public requeueDetailsList: ResourceInfo[];

  constructor() { }

  ngOnInit() {
  }

}

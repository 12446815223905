import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';
import { BiosampleSearchColumnFields, BiosampleSearchGridOptionsFormatter } from '@app/search/gridOptions/biosample-search-grid-options-formatter';
import { OverrideColumnDefinition, OverrideColumnParameters } from '@app/search/gridOptions/search-grid-options-formatter';


export class BiosampleChooserGridOptionsFormatter extends BiosampleSearchGridOptionsFormatter {
  constructor(
      public resourceDictionaryService: SearchResourceDictionaryService,
      public bsshDatePipe: BsshDatePipe
  ) {
      super(resourceDictionaryService, bsshDatePipe);
  }

  protected getOverrideColumnParameters(): OverrideColumnParameters<BiosampleSearchColumnFields> {
      const inclusiveColumns: BiosampleSearchColumnFields[] = [
          BiosampleSearchColumnFields.Name,
          BiosampleSearchColumnFields.DefaultProjectName,
          BiosampleSearchColumnFields.Updated,
          BiosampleSearchColumnFields.LabStatus,
          BiosampleSearchColumnFields.Status
        ];

      const overrideColumnDefinitions: OverrideColumnDefinition<BiosampleSearchColumnFields>[] = [];

      return {
          inclusiveColumns,
          overrideColumnDefinitions
      };
  }
}

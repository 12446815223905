import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { IApiHttpError} from '../model/v2-api/http-error';
import environment from '@environments/environment';
import { Constants } from '../utilities/constants';


@Injectable()
export class SkinnyBsInterceptor implements HttpInterceptor, OnDestroy {

    private subs = new SubSink();

    constructor(
        private router: Router,
        private httpClient: HttpClient) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => event, (err: any) => {
                // only interested in BSSH api
                if (_.startsWith(req.url, environment.apiUrl) && err instanceof HttpErrorResponse) {
                    if (err.status === 501) {
                        this.navigateToPageNotFound();
                    }
                }
            })
        );
    }

    private navigateToPageNotFound(apiError?: IApiHttpError): void {
        if (apiError == null) {
            apiError = {
                httpErrorStatus: 501,
                data: null
            };
        }
        // Navigate to the 404 page, skipLocationChange ?
        this.router.navigateByUrl(Constants.NOT_FOUND_ROUTE, {
            skipLocationChange: false,
            state: {
                ApiHttpError: apiError
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}


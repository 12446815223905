import { Component, OnInit, Input, AfterViewInit, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import {
  EditInfoEditableField, EditInfoComponentOutput, ResourceFieldToValueMap,
  EditInfoInputFieldType
} from '../../model/edit-info-editable-field';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditInfoComponent implements OnInit {

  @Input() public editableFields: EditInfoEditableField[] = []; // List of all fields which can be edited
  @Input() public errorMsg: string = null;

  @Output() confirm: EventEmitter<EditInfoComponentOutput> = new EventEmitter(); // use EditInfoOutput[] for emit

  updatedFields: BehaviorSubject<EditInfoEditableField[]> = new BehaviorSubject<EditInfoEditableField[]>([]);

  InputFieldType = null;

  constructor() { }

  ngOnInit() {
    this.InputFieldType = EditInfoInputFieldType;
    this.debounceUserInput();
  }

  private debounceUserInput() {
    this.updatedFields
      .pipe(debounceTime(200))
        .subscribe(updatedFields => {
          const editInfoOutput: EditInfoComponentOutput = {
            newValueMap: this.constructNewFieldToValueMap(),
          };
          this.confirm.emit(editInfoOutput);
        });
     }

  onChangeInputValues($event: EditInfoComponentOutput) {
    this.updatedFields.next(this.editableFields);
  }

  private constructNewFieldToValueMap() {
    const newFieldToValueMap: ResourceFieldToValueMap = {};
    for (const editableField of this.editableFields) {
      newFieldToValueMap[editableField.name] = editableField.currentValue;
    }
    return newFieldToValueMap;
  }

}

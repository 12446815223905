import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Params } from '@angular/router';

export interface ISearchRouteService {
    getSearchAreas(): Observable<SearchAreaItem[]>;
    setSearchQuery(action: any, input: string): Params;
  }

@Injectable({
    providedIn: 'root'
  })

  export class SearchRouteService implements ISearchRouteService {

    constructor() {
    }
    getSearchAreas(): Observable<SearchAreaItem[]> {
          return of([
            {
              id: 1,
              name: 'All of Sequence Hub',
              type: ''
            },
            {
              id: 2,
              name: 'Projects',
              type: 'project'
            },
            {
              id: 3,
              name: 'Runs',
              type: 'run'
            },
            {
              id: 4,
              name: 'Apps',
              type: 'app'
            },
            {
              id: 5,
              name: 'Biosamples',
              type: 'biosample'
            },
            {
              id: 6,
              name: 'Samples',
              type: 'sample'
            },
            {
              id: 7,
              name: 'Files',
              type: 'datasetfile'
            }
          ]);
    }
    setSearchQuery(input: string, type: any) {

      let queryParams: Params = {};
      if (!!type) {
        queryParams = {
          ['query']: input,
          ['type']: type
        };
      } else {
        queryParams = {
          ['query']: input
        };
      }
      return queryParams;
    }
}

export class SearchAreaItem {
  id: number;
  name: string;
  type: string;
}




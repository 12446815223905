import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Constants } from '@app/core/utilities/constants';
import { filter, map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  constructor(private titleService: Title, private router: Router,
              private activatedRoute: ActivatedRoute) { }

  public initialize(): void {
    const pageTitle = this.formatTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          let pageTitlePrefix = child.snapshot.data.pageTitlePrefix;
          // If the route data specifies that the title is set dynamically in the component, skip setting here
          if (child.snapshot.data.isTitleDynamic === true) {
            return null;
          }
          while (child.firstChild) {
            child = child.firstChild;
            if (!isNullOrUndefined(child.snapshot.data.pageTitlePrefix)) {
              pageTitlePrefix = child.snapshot.data.pageTitlePrefix;
            }
          }
          if (pageTitlePrefix) {
            return this.formatTitle(pageTitlePrefix);
          }
          return pageTitle;
        })
      ).subscribe((title: string) => {
        if (title != null) {
          this.titleService.setTitle(title);
        }
      });
  }


  /**
   * Appends the given prefix to the app level title suffix.
   * @param titlePrefix The title prefix
   * E.g: <Title_prefix> - Basespace Sequence Hub
   */
  public setPageTitlePrefix(titlePrefix: string): void {
    const title = this.formatTitle(titlePrefix);
    this.titleService.setTitle(title);
  }

  /**
   * Formats the page title by appending with app level suffix
   * @param titlePrefix title Prefix
   */
  private formatTitle(titlePrefix?: string) {
    return !isNullOrUndefined(titlePrefix) ? `${titlePrefix} - ${Constants.PageTitleSuffix}` : Constants.PageTitleSuffix;
  }
}

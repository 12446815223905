import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { OverrideColumnDefinition, OverrideColumnParameters } from '@app/search/gridOptions/search-grid-options-formatter';
import { SampleSearchColumnFields, SampleSearchGridOptionsFormatter } from '@app/search/gridOptions/sample-search-grid-options-formatter';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';

export class SampleChooserGridOptionsFormatter extends SampleSearchGridOptionsFormatter {
  constructor(
      public resourceDictionaryService: SearchResourceDictionaryService,
      public bsshDatePipe: BsshDatePipe
  ) {
      super(resourceDictionaryService, bsshDatePipe);
  }

  protected getOverrideColumnParameters(): OverrideColumnParameters<SampleSearchColumnFields> {
      const inclusiveColumns: SampleSearchColumnFields[] = [
          SampleSearchColumnFields.Name,
          SampleSearchColumnFields.Created,
          SampleSearchColumnFields.Owner,
          SampleSearchColumnFields.Project,
          SampleSearchColumnFields.Size,
          SampleSearchColumnFields.ReadLength,
          SampleSearchColumnFields.NumReadsPF,
          SampleSearchColumnFields.Genome
        ];

      const overrideColumnDefinitions: OverrideColumnDefinition<SampleSearchColumnFields>[] = [];

      return {
          inclusiveColumns,
          overrideColumnDefinitions
      };
  }
}
<div #CommentsDiv>
    <ngx-smart-modal #CommentsModal
                     [identifier]="modalType"
                     title="Comments"
                     [closable]="true"
                     [showCloseButton]="false"
                     [showConfirmButton]="false">
        <div class="comment"
             *ngIf="comments && comments.length>0">
            <div *ngFor="let comment of comments">
                <div class="comment__event">{{comment.Action}} from {{comment.PriorValue}} to {{comment.NewValue}}</div>
                <hr>
                <div class="comment__user-meta"> {{comment.User.Name}} - {{comment.DateCreated | date:'yyyy-MM-dd' }}
                </div>
                <div class="comment__body"> {{comment.Body}} </div>
                <hr>
            </div>
        </div>
        <lib-error-alert-message [errorMsg]="errorMsg">
        </lib-error-alert-message>
    </ngx-smart-modal>

</div>
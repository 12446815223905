<div #DownloadDiv>
    <ngx-smart-modal #DownloadModal
        [identifier]="modalType"
        [title]="headers.modalHeader"
        closable="true"
        [showCloseButton]="showCloseButton" 
        closeButtonText="Close"
        [disableConfirmButton]="disableConfirmButton"
        confirmButtonText="Download">

        <div>
            <div #wrapperLoader class="wrapper active" *ngIf="activeWrapper" data-cy="download-modal-progress-overlay-wrapper">
            </div>
            <lib-progress #spinnerLoader class="'spinner'" [id]="'app-download-modal'" [loader]="false" [fixed]="true"
                [spinner]="true" [spinnerPosition]="'center'" [progressBarContainer]="'modal'" (started)="onStarted()" (completed)="onCompleted()"
                data-cy="download-modal-progress-overlay-spinner">
            </lib-progress>
        </div>
        
        <div *ngIf="!showError">
            <lib-download-data [runName]='headers.resourceTitleHeader' [runSize]='headers.resourcePropertyHeader'
                [runData]='itemsToDownload' [runAlert]="downloadAlertMessage" [fileListHeader]="headers.downloadOptionHeader"
                [fileListTypes]="downloadOptionTypes" [downloadMessage]="downloadMessage" [downloadLinkText]="downloadLinkText"
                [downloadLink]="downloadLink" (outputDataChange)="dataChanged($event)">
            </lib-download-data>
        </div>
        <div *ngIf="showError">
            <div *ngIf="showOauthErrorBody" [innerHtml]="authErrorBodyToDisplay">
            </div>
            <lib-error-alert-message *ngIf="!showOauthErrorBody" [errorMsg]="errorMsg">
            </lib-error-alert-message>
        
        </div>
    </ngx-smart-modal>
</div>

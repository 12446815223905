<h1 style="margin-top: 2%;" data-cy="access-forbidden">Resource Deleted</h1>
<br/>
<span><b>The page you are trying to access <i>{{ attemptedRequest }}</i>.</b></span>
<br/>
<span>This may be a result of a merge, transfer or deletion of this data.</span>
<br/>
<nav>
    <ul>
        <li>Try <a class="data-list__link" routerLink="/dashboard" routerLinkActive="active">going back to dashbord.</a></li>
        <li>Or, go back to <a href="https://www.illumina.com" target="_blank" class="data-list__link">illumina.com</a></li>
        <li>Or, try <a href="https://my.illumina.com/order-management" target="_blank" class="data-list__link">My Account</a></li>
    </ul>
</nav>




<div class="get-link">
    <div class="get-link__wrapper">
        <div class="get-link__wrapper--inner">
            <div class="get-link__container">
                <div class="get-link__content">
                    <div class="get-link__result">
                        <h5 class="get-link__result__heading">
                            <span>{{ title }}</span>
                        </h5>
                    </div>

                    <div class="input-field">
                        <div class="input-field__label get-link__text-message" *ngIf="!shareUrl && canDisplayActivationText">
                            <div class="input-field__label">
                                {{activateText}}
                            </div>
                        </div>
                        <div class="input-field__label get-link__text-message" *ngIf="shareUrl">
                            <div class="input-field__label">
                                {{copyUrlText}}
                            </div>
                        </div>

                        <div *ngIf="shareUrl">
                            <input class="input-field__text-input get-link__share-url" type="text" value="{{shareUrl}}" #myInput />
                            <span class="get-link__copy" (click)="copyShareUrl(myInput)"> 
                                <svg 
                                    class="get-link__alert-box-icon"
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox='0 0 512 512'
                                >
                                    <path d="M 433.941 65.941 l -51.882 -51.882 A 48 48 0 0 0 348.118 0 H 176 c -26.51 0 -48 21.49 -48 48 v 48 H 48 c -26.51 0 -48 21.49 -48 48 v 320 c 0 26.51 21.49 48 48 48 h 224 c 26.51 0 48 -21.49 48 -48 v -48 h 80 c 26.51 0 48 -21.49 48 -48 V 99.882 a 48 48 0 0 0 -14.059 -33.941 Z M 266 464 H 54 a 6 6 0 0 1 -6 -6 V 150 a 6 6 0 0 1 6 -6 h 74 v 224 c 0 26.51 21.49 48 48 48 h 96 v 42 a 6 6 0 0 1 -6 6 Z m 128 -96 H 182 a 6 6 0 0 1 -6 -6 V 54 a 6 6 0 0 1 6 -6 h 106 v 88 c 0 13.255 10.745 24 24 24 h 88 v 202 a 6 6 0 0 1 -6 6 Z m 6 -256 h -64 V 48 h 9.632 c 1.591 0 3.117 0.632 4.243 1.757 l 48.368 48.368 a 6 6 0 0 1 1.757 4.243 V 112 Z" />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
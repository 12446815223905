
import { Injectable, OnDestroy } from '@angular/core';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { CurrentUserWorkgroupsStore } from '@app/user/store/current-user-workgroups/current-user-workgroups.store';
import { CurrentUserGlobalAppsStore } from '@app/user/store/current-user-global-apps/current-user-global-apps.store';
import { BsKeepAliveService } from '../services/bs-api/keep-alive/bs-keep-alive.service';
import { AuthorizationService } from '../services/authorization/authorization.service';
import { HttpUtilityService } from '../services/http-utility/http-utility.service';
import { UserSettingsStore } from '@app/core/store/usersettings/user-settings.store';
import { SubSink } from 'subsink';
import { UserSessionType } from '../model/user/session-type';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppInitializationService implements OnDestroy {
    private subs = new SubSink();
    constructor(private authorizationService: AuthorizationService,
                private currentUserStore: CurrentUserStore,
                private userWorkgroupsStore: CurrentUserWorkgroupsStore,
                private userGlobalAppsStore: CurrentUserGlobalAppsStore,
                private userSettingsStore: UserSettingsStore) { }

    init(): void {

        this.subs.sink =  this.authorizationService.validateUserSession().subscribe({
            next: (isSessionAlive) => {

                if (isSessionAlive) {
                    // this list can be added to...but may slow down the app-init process, so use caution.
                    this.currentUserStore.loadCurrentUser();
                    // these are here for now, theyll likely move when we have the navbar ready
                    this.userWorkgroupsStore.loadCurrentUserWorkgroups(undefined, 100);
                    // Since platform apps call is dependent on the user's domain, need the domain id from the users api to load.
                    this.subs.sink = this.currentUserStore.stateChanged.
                        pipe(filter(state => state != null && state.currentUser != null)).subscribe({
                            next: state => {
                                // tslint:disable-next-line: max-line-length
                                this.userGlobalAppsStore.loadCurrentUserGlobalApps(false, state.currentUser.LoggedInUser.IsPublicDomainUser);
                            }
                        });
                    this.userSettingsStore.loadUserSettings(UserSessionType.LoggedInUser);
                    this.userSettingsStore.loadUserSettings(UserSessionType.ActingUser);
                } else {
                    // Session is not active for the psToken, redirect to login page on platform.
                    this.authorizationService.logon();
                }
            }
        });
    }
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}

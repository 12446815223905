import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-metrics-tooltip-renderer',
  templateUrl: './metrics-tooltip-renderer.component.html',
  styleUrls: ['./metrics-tooltip-renderer.component.scss']
})
export class MetricsTooltipRendererComponent implements AgRendererComponent {

  public params: ICellRendererParams = null;

  constructor() { }

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: ICellRendererParams): void {
    if (!params || !params.data) {
      return;
    }
    
    this.params = params;
  }
}

import { Injectable } from '@angular/core';
import { IServerSideRequestOptions } from '@bssh/comp-lib';
import { V2BiologicalSampleCompactList } from "@bssh/ng-sdk";
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { cloneDeep, forEach, isEmpty } from 'lodash';
import environment from '../../../environments/environment';
import { FilterMethod, ITableDatasource } from '../../core/data-table/table-datasource';
import { StringUtilities } from '../../core/utilities/string-utilities';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BsApiEndPoints } from '../../core/services/bs-api/endpoints';
import { ISearchFilterItem } from '../../core/model/search-filter';
import { IApiListResponseWrapper } from '../../core/model/v2-api/v2-api-wrappers';
import { ResourceType } from '@app/core/model/resource-type';

type V2BiosamplesListResponse = V2BiologicalSampleCompactList;

@Injectable({
    providedIn: 'root'
})
export class BiosamplesDatasource implements ITableDatasource<any, V2BiosamplesListResponse> {
    origin = StringUtilities.trimTrailingSlash(environment.apiUrl);
    path = 'v2/biosamples';
    defaultPageSize = 25;

    private PROJECT_FILTER_URL = `${BsApiEndPoints.V1PRE3_URL}/search`;

    // TODO: this should really be "private" or "protected" at least
    defaultParams: GetV2BiosamplesParams = {
        limit: 100,
        offset: 0,
        sortBy: 'DateModified',
        sortDir: 'desc'
    };
    // Used on details tabs tables (e.g. project analyses tab) when a resource prefilter is already applied to table
    private resourceDetailsFilter: { resourceType: ResourceType; resourceId: string; };

    rowData: any[] = null;

    filterMethods: { [field: string]: FilterMethod<GetV2BiosamplesParams>} = {
        DefaultProjectName: (params, filterObj) => params.projectId = [filterObj.value],
        LabStatus: (params, filterObj) => params.labStatus = filterObj.value,
        Status: (params, filterObj) => params.status = filterObj.value
    };

    constructor(private httpClient: HttpClient) {}

    public setResourceDetailsFilter(resourceType: ResourceType, resourceId: string): void {
        this.resourceDetailsFilter = { resourceType, resourceId };
    }

    get requestOptions(): IServerSideRequestOptions {
        return {
          origin: this.origin,
          path: this.path,
          params: this.defaultParams,
          preRequestParamsProcessing: (params, rowsRequest) => this.getUpdatedParams(params, rowsRequest),
          postSuccessRequestCallback: (params, rowsRequest, response) => this.getRowsRequest(params, rowsRequest, response),
          postErrorRequestCallback: (params, rowsRequest, err) => console.error(err)
        };
    }

    applyPaginationParams(params: GetV2BiosamplesParams, startRow: number) {
        const updatedParams = cloneDeep(params);
        updatedParams.offset = startRow;
        return updatedParams;
    }

    applySortParams(params: GetV2BiosamplesParams, sortModel: {colId: string, sort: string}[]) {
        const updatedParams = cloneDeep(params);
        // SortModel will only contain at most 1 container, since we can only sort one column at a time
        const sortRequest = sortModel[0];
        updatedParams.sortBy = sortRequest.colId;
        updatedParams.sortDir = sortRequest.sort;
        return updatedParams;
    }

    getRowsRequest(params: GetV2BiosamplesParams, rowsRequest: IServerSideGetRowsRequest, response: V2BiosamplesListResponse) {
        return {
          ...rowsRequest,
          rowData: response.Items,
          startRow: response.Paging.Offset,
          lastRow: response.Paging.TotalCount,
        };
    }

    applyFilterParams(params: GetV2BiosamplesParams, filterModel: any) {
        const updatedParams = cloneDeep(params);
        forEach(filterModel, (filterObj, field) => this.filterMethods[field](updatedParams, filterObj));
        return updatedParams;
    }

    getUpdatedParamsWithResourceId(params: GetV2BiosamplesParams) {
        if(this.resourceDetailsFilter) {
            const { resourceType, resourceId } = this.resourceDetailsFilter;
            switch (resourceType) {
                case ResourceType.PROJECT:
                case ResourceType.PROJECTS:
                    params.projectId = [resourceId];
                    break;
            }
        }

        return params;
    }

    getUpdatedParams(params: GetV2BiosamplesParams, rowsRequest: IServerSideGetRowsRequest) {
        const { startRow, sortModel, filterModel } = rowsRequest;
        let updatedParams = cloneDeep(params);
        updatedParams = this.applyPaginationParams(updatedParams, startRow);

        if (!isEmpty(rowsRequest.sortModel)) {
          updatedParams = this.applySortParams(updatedParams, sortModel);
        }

        // Apply filter params
        if (!isEmpty(filterModel)) {
          updatedParams = this.applyFilterParams(updatedParams, filterModel);
        }

        return this.getUpdatedParamsWithResourceId(updatedParams);
    }

    getProjectFilterOptions(arg?: string): Observable<IApiListResponseWrapper<ISearchFilterItem>> {
        return this.httpClient.get<IApiListResponseWrapper<ISearchFilterItem>>(this.PROJECT_FILTER_URL, {
            params: {
                limit: '50',
                query: arg ? `Project.Name:"${arg}"` : '',
                scope: 'projects',
                sortBy: arg ? 'score' : 'ModifiedOn', // if text query is there results are sorted by 'score' else 'ModifiedOn'
                sortDir: 'desc'
            },
            withCredentials: true
        });
    }

    resetDatasourceParams(): void {
        this.resourceDetailsFilter = null;
        this.rowData = null;
    }
}

export interface GetV2BiosamplesParams {
    BioSampleName?: string[];
    Include?: string[];
    PropertyNameStartsWith?: string[];
    status?: string[];
    labStatus?: string[];
    projectId?: string[];

    // sort
    sortBy?: string;
    sortDir?: string;

    // pagination
    offset?: number;
    limit?: number;
}

export enum BiosamplesSortFields {
    BioSampleName = 'BioSampleName',
    DefaultProjectName = 'DefaultProjectName',
    DateModified = 'DateModified',
    UserOwnedBy = 'UserOwnedBy',
    LabStatus = 'LabStatus',
    Status = 'Status'
}

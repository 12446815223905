import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { BaseModalComponent } from '@root/src/app/shared/modals/base-modal/base-modal.component';
import { IAppAgreementModalInput, IAppEulaModalOutput } from '@root/src/app/shared/modals/model/action-modal';
import { AgreementCategory, AgreementStatus } from '@root/src/app/core/model/user-agreements/user-agreements';

@Component({
  selector: 'app-agreement-modal',
  templateUrl: './app-agreement-modal.component.html',
  styleUrls: ['./app-agreement-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppAgreementModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {

  modalType = 'AppAgreementModal';
  modalTitle = 'GENERIC APP';
  modalContent = 'GENERIC CONTENT';
  modalCloseButtonText = 'CLOSE';
  modalConfirmButtonText = 'ACCEPT';

  agreementStatus: AgreementStatus;
  agreementCategory: AgreementCategory;

  showConfirmButton: boolean = false;
  isAccepted: boolean = false;


  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private element: ElementRef
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    // Assign modal text content according to input
    this.subs.sink = this.data.subscribe((modalInput: IAppAgreementModalInput) => {
      if (modalInput && modalInput.content && modalInput.title && modalInput.version) {
        this.modalTitle = `${modalInput.title} ${modalInput.version} Terms of Use`;
        this.modalContent = modalInput.content;

        this.agreementStatus = modalInput.status;
        this.agreementCategory = modalInput.category;

        if(this.agreementCategory == AgreementCategory.APP_EULA && this.agreementStatus === AgreementStatus.PENDING) {
          this.modalCloseButtonText = 'CANCEL';
          this.showConfirmButton = true;
        }
      }
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if(this.agreementCategory == AgreementCategory.APP_EULA && this.agreementStatus === AgreementStatus.SIGNED) {
      this.appendAcceptedAgreementText();
    }
  }

  private appendAcceptedAgreementText(): void {
    const element = this.element.nativeElement;
    const closeButtonElement = element.querySelector('.modal-dialog__footer');

    const acceptedDiv = document.createElement('div');
    acceptedDiv.classList.add('modal-dialog__footer--item');
    acceptedDiv.innerHTML = `
      <div>You have already accepted this license agreement</div>
    `;

    closeButtonElement.prepend(acceptedDiv);
  }

  /**
   * To be called whenever the alert modal is closed.
   * Will emit the isProceed outcome to the consumer.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  close(modal: NgxSmartModalComponent) {
    const modalOutput: IAppEulaModalOutput = { isAccepted: this.isAccepted };
    this.confirm.emit(modalOutput);
    this.confirm.complete();
  }

  /**
   * Override BaseModalComponent's accept(). Called when the confirm
   * button on modal is clicked to proceed with the action.
   * Sets isProceed to true to be emitted on close.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  accept(modal: NgxSmartModalComponent) {
    this.isAccepted = true;
    modal.close();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

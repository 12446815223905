import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddContentTypeInterceptor } from './add-content-type-interceptor';
import { HttpRequestInterceptor } from '@bssh/ng-sdk';
import { BsApiUnauthorizedInterceptor } from './bs-api-unauthorized-interceptor';
import { IpsHeaderInterceptor } from './ips.header.interceptor';
import { GssHeaderInterceptor } from './gss.header.interceptor';
import { BsApiSuccessInterceptor } from './bs-api-success-interceptor';
import { BsOutageInterceptor } from './bs-api-outage-interceptor';
import { SkinnyBsInterceptor } from './skinny-bs.interceptor';

// Interceptors should be added in order that we need the request to flow through
// add more as required.
// https://angular.io/guide/http#http-interceptors
export const httpInterceptorProviders = [
    // Sets request.withCredentials to true
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    // Sets Content-Type header on POST/PUT requests.
    { provide: HTTP_INTERCEPTORS, useClass: AddContentTypeInterceptor, multi: true },
    // Detects if BSSH is experiencing a planned/unplanned outage, and routes to a outage page
    { provide: HTTP_INTERCEPTORS, useClass: BsOutageInterceptor, multi: true },
    // handles api redirect based on response status
    { provide: HTTP_INTERCEPTORS, useClass: BsApiSuccessInterceptor, multi: true },
    // handles api unauthorized response errors
    { provide: HTTP_INTERCEPTORS, useClass: BsApiUnauthorizedInterceptor, multi: true },
    // add Authorization header to IPS requests
    { provide: HTTP_INTERCEPTORS, useClass: IpsHeaderInterceptor, multi: true },
    // add Authorization header to GSS requests
    { provide: HTTP_INTERCEPTORS, useClass: GssHeaderInterceptor, multi: true },
    // add Skinny BSSH interceptor
    { provide: HTTP_INTERCEPTORS, useClass: SkinnyBsInterceptor, multi: true }
];

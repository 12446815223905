import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IArchiveOrRestoreResource } from '../../model/archive-or-restore-resource';
import { ResourceType } from '../../../../core/model/resource-type';
import _ from 'lodash';
import { StringUtilities } from '@app/core/utilities/string-utilities';

@Component({
  selector: 'app-archive-or-restore-data',
  templateUrl: './archive-or-restore-data.component.html',
  styleUrls: ['./archive-or-restore-data.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArchiveOrRestoreDataComponent implements OnInit, OnChanges {

  public SIZE = 'Size';
  public NAME = 'Name';

  @Input() public resourceType: ResourceType = null; // Runs and Datasets can be archived
  @Input() public resourceList: IArchiveOrRestoreResource[] = [] ; // Multiple items can be archived at once.
  // Each resource must have name and size 
  @Input() public resourceIdentifierTitle: string = null; // Custom title in place of Resource Name
  @Input() public resourceSizeTitle: string = null; // Custom title in place of Size
  @Input() public actionMsg: string = ''; // Any message specifying the archive action
  @Input() public alertMsg: string = null; // Any alert message
  @Input() public errorMsg: string | string[] = null; // Any error msg
  @Input() public totalSizeOfResources: string = null; // total size in GB/MB

  constructor() { }

  ngOnInit() {
    this.setResourceIdentifierTitle();
    this.setResourceSizeTitle();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setResourceIdentifierTitle();
    this.setResourceSizeTitle();
  }

  private setResourceIdentifierTitle() {
     if (!this.resourceIdentifierTitle && this.resourceType ) {
      const formattedResourceTypeColumnName = StringUtilities.trimTrailingCharacter(this.capitalizeFirstLetter(this.resourceType.toString()), 's');
      this.resourceIdentifierTitle = `${formattedResourceTypeColumnName} ${this.NAME}`;
    }
  }

  private setResourceSizeTitle() {
    if (!this.resourceSizeTitle) {
      this.resourceSizeTitle = this.SIZE;
    }
  }

  private capitalizeFirstLetter(lowerCaseString: string) {
    return _.startCase(_.camelCase(lowerCaseString));
  }

}

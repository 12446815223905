import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { NgxSmartModalService, IRunData, NgxSmartModalComponent } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ITrashPlannedRunsModalInput, ITrashPlannedRunsModalOutput } from '../model/action-modal';
import { ModalTexts } from '../modal-texts';
import { PlannedRunsTrashService } from '@app/core/services/toolbar/planned-runs/planned-runs-trash.service';
import { IRun } from '@app/core/model/runs/run';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-trash-planned-runs-modal',
  templateUrl: './trash-planned-runs-modal.component.html',
  styleUrls: ['./trash-planned-runs-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrashPlannedRunsModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  modalType = 'trashPlannedRuns';

  selectedRuns: IRunData[];
  runsToTrash: IRun[];
  title: string;
  closeButtonText = ModalTexts.TRASH_PLANNED_RUNS.CANCEL_BUTTON_TEXT;
  confirmButtonText: string;
  runNameHeaderText = ModalTexts.TRASH_PLANNED_RUNS.LIST_NAME_COLUMN_HEADER;
  runStatusHeaderText = ModalTexts.TRASH_PLANNED_RUNS.LIST_VALUE_COLUMN_HEADER;
  infoText: string;
  modalOutput: ITrashPlannedRunsModalOutput;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private trashService: PlannedRunsTrashService
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subs.sink = this.data.subscribe((modalInput: ITrashPlannedRunsModalInput) => {
      if (modalInput) {
        // Convert selected-runs to IRunData for lib-download-data component input
        this.selectedRuns = modalInput.runsToTrash.map(run => {
          return { name: run.Name, value: run.Status, status: run.Status };
        });
        this.runsToTrash = modalInput.runsToTrash;
        // Pluralize Planned Runs if multiple runs selected
        this.updateModalText();

        if (modalInput.errorMsg) {
          this.error.next(modalInput.errorMsg);
        }
      }
    });

    this.subscribeApiDataLoader();
  }

  accept(modal: NgxSmartModalComponent) {
    this.isLoadingApiData$.next(true);
    this.subs.sink = this.trashService.trashRuns(this.runsToTrash).pipe(
      first()
    ).subscribe({
      next: (batchTrashResult) => {
        this.modalOutput = { trashResult: batchTrashResult };
        modal.close();
        this.confirm.emit(this.modalOutput);
      },
      complete: () => this.isLoadingApiData$.next(false)
    });
  }

  private updateModalText() {
    const isMultipleRunsToTrash = this.selectedRuns.length > 1;
    const plannedRunsText = isMultipleRunsToTrash ? 'Planned Runs' : 'Planned Run';
    this.title = ModalTexts.TRASH_PLANNED_RUNS.MODAL_TITLE(plannedRunsText);
    this.confirmButtonText = ModalTexts.TRASH_PLANNED_RUNS.CONFIRMATION_BUTTON_TEXT(isMultipleRunsToTrash);
    this.infoText = ModalTexts.TRASH_PLANNED_RUNS.MODAL_INFO_TEXT(plannedRunsText);
  }
}

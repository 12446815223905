import { Component } from '@angular/core';
import { IApplication } from '@app/core/model/applications/applications';
import { BsApiEndPoints } from '@app/core/services/bs-api/endpoints';
import { SiteNavigationLinksService } from '@app/core/services/site-navigation/site-navigation-links.service';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-app-info-renderer',
  templateUrl: './app-info-renderer.component.html',
  styleUrls: ['./app-info-renderer.component.scss']
})
export class AppInfoRendererComponent implements AgRendererComponent {
  public params: IApplication;
  public appLogoUrl: string;
  public href: string;

  constructor(private siteNavigationLinksService: SiteNavigationLinksService) { }

  public agInit(params: any): void {
    this.params = params.data.Application;
    this.href = this.siteNavigationLinksService.appSummaryUrl(this.params.Id);
    this.appLogoUrl = BsApiEndPoints.getApplicationLogoUrl(this.params.Id);
  }

  public refresh(params: any): boolean {
      return true;
  }
}
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { INotificationTime } from '@bssh/comp-lib/lib/components/notification-card/notification.interface';
import { MomentWrapperService } from '../../services/moment-wrapper-service';


@Pipe({
    name: 'elapsedTng imePipe',
})
@Injectable({
    providedIn: 'root'
})
export class ElapsedTimePipe  implements PipeTransform {

    constructor(private momentWrapperService: MomentWrapperService) { }

    transform(timeInMilliseconds: number): INotificationTime {

        if (timeInMilliseconds == null) {
            return { hours: '00', minutes: '00', seconds: '00'};
        }

        const momentWithLocale = this.momentWrapperService.getMomentWithLocale();
        const duration = momentWithLocale.duration(timeInMilliseconds);

        const time = {
            year: duration.asYears(),
            month: duration.asMonths(),
            day: duration.asDays(),
            hour: duration.asHours(),
            minute: duration.asMinutes(),
            second: duration.asSeconds()
        };

        let hours = Math.floor(time.hour);
        let minutes  = Math.floor(time.minute) - hours * 60;
        let seconds   = Math.floor(time.second) - hours * 60 * 60 - minutes * 60;

        hours = (hours < 10) ? 0 + hours : hours;
        minutes = (minutes < 10) ? 0 + minutes : minutes;
        seconds = (seconds < 10) ? 0 + seconds : seconds;

        return { hours: hours.toString(), minutes: minutes.toString(), seconds: seconds.toString()};

    }
}


import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  public getCurrentTimeInSec(): number {
    return Math.floor(Date.now() / 1000);
  }
}

<ngx-smart-modal #switchWorkgroupModal 
[identifier]="modalType" 
[title]="modalTitle"
[closable]="showCloseButton" 
[showCloseButton]="showCloseButton" 
closeButtonText="Cancel"
[showConfirmButton]="showConfirmButton" 
[escapable]="false" 
[dismissible]="false" 
[confirmButtonText]= "confirmButtonText">
    <div class="modal-body">
        <div *ngIf="switchWorkgroupModal.hasData()">
            <div *ngIf="!invalidRouteData;else invalidRouteDataContent">
                <p>We need to change the workgroup view in order to show you the {{resourceDisplayNameSingular}}
                    you requested. This might cancel any incomplete processes in the current workgroup (background
                    processes such as <b>analyses and run uploads will not be affected</b>).</p>
            </div>
            <ng-template #invalidRouteDataContent>
                <p> Use your browser Back button to try again. If the problem
                    persists,
                    contact <a
                        href="mailto:basespace-support@illumina.com?Subject=Basespace%20Sequence%20Hub%20Support">Technical
                        Support</a>
                    or return to the <a href="/dashboard">Dashboard</a></p>
            </ng-template>

        </div>
    </div>
</ngx-smart-modal>
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IApiHttpError, IBsOutageStatus, IHttpErrorMessages } from '@app/core/model/v2-api/http-error';
import { Constants } from '@app/core/utilities/constants';
import { Router } from '@angular/router';
import { HttpErrorMessagesService } from './service/http-error-messages.service';
import { PageTitleService } from '@app/core/services/title/page-title.service';
import _ from 'lodash';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HttpErrorComponent implements OnInit {

  public errorMessage: IHttpErrorMessages = null;

  constructor(
    private router: Router,
    private httpErrorMessagesService: HttpErrorMessagesService,
    private pageTitleService: PageTitleService) {

    const currentNavigationExtras = this.router.getCurrentNavigation().extras;
    if (currentNavigationExtras != null && currentNavigationExtras.state != null) {
      const apiError = currentNavigationExtras.state[Constants.ApiHttpErrorField];
      // Use http error messages service to get user friendly error message texts
      this.errorMessage = this.httpErrorMessagesService.getErrorMessagesForApiError(apiError);
      if (this.errorMessage && !_.isEmpty(this.errorMessage.pageTitle)) {
        this.pageTitleService.setPageTitlePrefix(this.errorMessage.pageTitle);
      }

    }
  }


  ngOnInit(): void {

  }


}

import { isNullOrUndefined } from 'util';

export class ArrayUtilities {
    /**
     * Sort an array of objects by the given property.  Defaults to ascending order.  Returns the sorted array
     */
    static sortArrayAlphabetically(arr: Array<any>, property: string, orderDesc: boolean = false): Array<any> {
        const order = orderDesc ? -1 : 1;
        return arr.sort((a, b) =>
          order * a[property].toLowerCase().localeCompare(b[property].toLowerCase()));
    }

    static isNullOrEmpty(arr: Array<any>): boolean {
        return !arr || arr.length === 0;
    }

    static isArrayOfSize(arr: Array<any>, size: number): boolean {
        if (isNullOrUndefined(arr) || arr.length !== size) {
            return false;
        }
        return true;
    }
}

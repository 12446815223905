import { Injectable } from '@angular/core';
import { IBsMetaData } from '@app/core/model/meta/meta-data';
import { ResourceBaseService } from '../../resource-base.service';
import { HttpClient } from '@angular/common/http';
import { BsApiEndPoints } from '../endpoints';
import { Observable, throwError } from 'rxjs';
import { IApiResponseWrapper, IApiErrorResponseStatus } from '@app/core/model/v2-api/v2-api-wrappers';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BsMetaDataService extends ResourceBaseService<IApiResponseWrapper<IBsMetaData>>  {

  constructor(httpClient: HttpClient) {
    super(httpClient, BsApiEndPoints.v1Meta);
  }

  /**
   * Gets Basespace application meta data.
   */
  getBasespaceAppMetadata(): Observable<IApiResponseWrapper<IBsMetaData>> {
    return this.get(null).pipe(
      catchError((apiError) => {
        let apiResponseError: IApiErrorResponseStatus;
        if (apiError != null && apiError.error != null && apiError.error.ResponseStatus != null) {
          apiResponseError = apiError.error.ResponseStatus as IApiErrorResponseStatus;
        }
        return throwError(apiResponseError);
      })
    );
  }


}

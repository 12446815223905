import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { BehaviorSubject } from 'rxjs';
import { IGetLinkModalInput } from '../model/action-modal';
import { ResourceType } from '../../../core/model/resource-type';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';

@Component({
  selector: 'app-get-link-modal',
  templateUrl: './get-link-modal.component.html',
  styleUrls: ['./get-link-modal.component.scss']
})
export class GetLinkModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  modalTitle = ModalTexts.SHARE.GET_LINK_TITLE;
  modalType = 'getLink';

  public confirmButtonText = '';
  public alertMessage = '';
  public getLinkMode: GetLinkMode;
  public resourceType: ResourceType;
  public title: string;
  public shareUrl: string;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subs.sink = this.data.subscribe((response: IGetLinkModalInput) => {
      this.resourceType = response.resourceType;
      this.title = response.title;
      this.shareUrl = response.shareUrl;
      this.getLinkMode = response.getLinkMode;
      // Set the alert message supplied
      if (response.alertMsg != null) {
        this.alertMessage = response.alertMsg;
      }

      if (response.errorMsg) {
        this.error.next(response.errorMsg);
      }
    });

    this.confirmButtonText = this.getLinkMode === GetLinkMode.ACTIVATE ?
    GetLinkMode.ACTIVATE.toString() : GetLinkMode.DEACTIVATE.toString();
  }

  accept(modal: NgxSmartModalComponent) {
    if (this.getLinkMode === GetLinkMode.ACTIVATE) {
      this.confirm.emit(this.getLinkMode);
      this.getLinkMode = GetLinkMode.DEACTIVATE;
      this.confirmButtonText = GetLinkMode.DEACTIVATE.toString();
    } else if (this.getLinkMode === GetLinkMode.DEACTIVATE) {
      this.getLinkMode = GetLinkMode.CONFIRM_DEACTIVATE;
      this.confirmButtonText = GetLinkMode.CONFIRM_DEACTIVATE.toString();
      this.alertMessage = ModalTexts.SHARE.ALERT_MESSAGE_DEACTIVATION(this.resourceType);
    } else if (this.getLinkMode === GetLinkMode.CONFIRM_DEACTIVATE) {
        this.confirm.emit(this.getLinkMode);
    }
  }

  /**
   * Get Link Modal when in Activation mode, should change to Deactivation on clicking the confirm button without
   * closing the modal.
   */
  canCloseOnConfirm(): boolean {
    return this.getLinkMode === GetLinkMode.CONFIRM_DEACTIVATE;
  }

  constructError(error) {
    if (this.getLinkMode === GetLinkMode.CONFIRM_DEACTIVATE) {
      return ModalTexts.SHARE.DEACTIVATE_ERROR_MESSAGE;
    }
    return error.error.ErrorMessage ? error.error.ErrorMessage : ModalTexts.SHARE.GENERIC_LINK_ERROR_MESSAGE;
  }

}

export enum GetLinkMode {
  ACTIVATE = 'Activate',
  DEACTIVATE = 'Deactivate',
  CONFIRM_DEACTIVATE = 'Yes-Deactivate'
}

import { Component, Input } from '@angular/core';
import { INavItem } from '@bssh/comp-lib';

@Component({
  selector: 'app-tabs-page',
  templateUrl: './tabs-page-layout.component.html',
  styleUrls: ['./tabs-page-layout.component.scss']
})
export class TabsPageLayoutComponent {
  @Input() pageHeader: string = "";
  @Input() menuItems: INavItem[] = [];
  @Input() activateFn: (component: any) => void = () => {};

  public isTabsLoaded = false

  constructor() { }

  ngAfterViewInit() {
    this.isTabsLoaded = true;
  }

  onActivate(component: any) {
    this.activateFn(component);
  }
  
}

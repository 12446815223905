export enum AnalysisDeliveryStatus {
    NONE = 'None',
    READY_FOR_DELIVERY = 'ReadyForDelivery',
    DELIVERY_IN_PROGRESS = 'DeliveryInProgress',
    DELIVERED = 'Delivered',
    ON_HOLD = 'OnHold',
    DO_NOT_DELIVER = 'DoNotDeliver',
    DELIVERY_FAILED = 'DeliveryFailed'
}

export const ReverseAnalysisDeliveryStatus: Map<string, AnalysisDeliveryStatus> = (() => {
    const reverseMap = new Map<string, AnalysisDeliveryStatus>();
    reverseMap.set('None', AnalysisDeliveryStatus.NONE);
    reverseMap.set('ReadyForDelivery', AnalysisDeliveryStatus.READY_FOR_DELIVERY);
    reverseMap.set('DeliveryInProgress', AnalysisDeliveryStatus.DELIVERY_IN_PROGRESS);
    reverseMap.set('Delivered', AnalysisDeliveryStatus.DELIVERED);
    reverseMap.set('OnHold', AnalysisDeliveryStatus.ON_HOLD);
    reverseMap.set('DoNotDeliver', AnalysisDeliveryStatus.DO_NOT_DELIVER);
    reverseMap.set('DeliveryFailed', AnalysisDeliveryStatus.DELIVERY_FAILED);
    return reverseMap;
})();


export const ALL_POSSIBLE_ANALYSIS_DELIVERY_STATUSES = [AnalysisDeliveryStatus.NONE, AnalysisDeliveryStatus.READY_FOR_DELIVERY,
AnalysisDeliveryStatus.DELIVERY_IN_PROGRESS, AnalysisDeliveryStatus.DELIVERED, AnalysisDeliveryStatus.ON_HOLD,
AnalysisDeliveryStatus.DO_NOT_DELIVER, AnalysisDeliveryStatus.DELIVERY_FAILED];

export const NON_SUCCESS_ANALYSIS_DELIVERY_STATUSES = [AnalysisDeliveryStatus.NONE.toString(),
AnalysisDeliveryStatus.DELIVERY_FAILED.toString(), AnalysisDeliveryStatus.DO_NOT_DELIVER.toString()];

// This is display status and should not be used to compare against api execution status.
// If comparison against execution status is needed then refer to AppSessionExecutionStatus
export enum AnalysisStatus {
    // The analysis has been stopped.
    Aborted = 'Aborted',

    // The analysis is in the process of being stopped.
    Aborting = 'Aborting',

    // The analysis has been created but has not been accepted.
    AwaitingAuthorization = 'Awaiting Authorization',

    // A user manually canceled the analysis before its dependencies were met.
    Canceled = 'Canceled',

    // The analysis has finished and is ready for use.
    Complete = 'Complete',

    // The app has launched and is awaiting execution.
    Initializing = 'Initializing',

    // There is a problem with the analysis.
    NeedsAttention = 'Needs Attention',

    //  The analysis app purchase was canceled.
    PaymentAborted = 'Payment Aborted',

    // The analysis app purchase is complete and the analysis can begin.
    PaymentComplete = 'Payment Complete',

    // The analysis is waiting for dependencies (eg, yield or completion of another analysis) to be met.
    Pending = 'Pending',

    // The analysis workflow has met its dependencies (eg, yield or completion of another analysis) and is looking for a machine to run on.
    PendingExecution = 'Queued For Analysis',

    // The analysis app purchase is in progress.
    PendingPayment = 'Pending Payment',

    // The analysis is running.
    Running = 'Running',

    // The analysis did not complete within 48 hours and was terminated.
    TimedOut = 'Timed Out'

}

export enum AnalysisQCStatus {

    Undefined = 'Undefined',

    // One or more analysis metrics did not meet established thresholds or were reviewed and manually failed.
    QcFailed = 'QcFailed',

    // All analysis metrics met established thresholds or were reviewed and manually passed.
    QcPassed = 'QcPassed',

    // The QC Failed analysis has been reviewed and can be ignored.
    Discarded = 'Discarded',

}

export const ALL_POSSIBLE_ANALYSIS_QC_STATUSES = [AnalysisQCStatus.QcPassed.toString(), AnalysisQCStatus.QcFailed.toString(), AnalysisQCStatus.Discarded.toString()];

export const NON_SUCCESS_ANALYSIS_QC_STATUSES = [AnalysisQCStatus.Discarded.toString(), AnalysisQCStatus.QcFailed.toString()];

export const ANALYSIS_NON_SUCCESS_STATUS = [AnalysisStatus.Aborted.toString(), AnalysisStatus.NeedsAttention.toString()];
export const TRASHABLE_APPSESSION_STATES: string[] = [AnalysisStatus.Aborted, AnalysisStatus.AwaitingAuthorization,
AnalysisStatus.Canceled, AnalysisStatus.Complete, AnalysisQCStatus.Discarded,
AnalysisStatus.NeedsAttention, AnalysisStatus.PaymentAborted,
AnalysisStatus.Pending, AnalysisStatus.PendingPayment, AnalysisQCStatus.QcFailed,
AnalysisQCStatus.QcPassed, AnalysisStatus.TimedOut];

export const AnalysisNonSuccessStatus = [AnalysisStatus.Aborted, AnalysisStatus.NeedsAttention];

// TODO: DESTROY THIS and replace in bssh-ui uses as soon as radiance team implements their own readme pattern https://jira.illumina.com/browse/RAD-9638


import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[readMore]',
})
export class ReadMoreDirective {
  @Input('maxLength') maxLength: number = 100; // Default max length

  constructor(private element: ElementRef) { }

  ngOnInit() {
    this.applyDirective(this.maxLength);
  }

  private applyDirective(limit: number) {
    const element = this.element.nativeElement;

    // Only apply the read more template if text is long enough to need it
    if (limit && element.textContent.trim().length > 0 && element.textContent.trim().length > limit) {
        const detachedElements = [];
        const children = Array.from(element.children);

        children.forEach(child => {
          detachedElements.push(child);
          element.removeChild(child);
        });

        const truncationContainer = document.createElement('div');
        truncationContainer.classList.add('bs-read-more');
        element.prepend(truncationContainer);

        detachedElements.forEach((detachedElement) => {
          truncationContainer.appendChild(detachedElement);
        });

        const divElement = document.createElement('div');
        divElement.className = 'bs-read-more__action';

        const anchorElement = document.createElement('a');
        anchorElement.className = 'bs-read-more--action';
        anchorElement.textContent = 'Read More';
        divElement.appendChild(anchorElement);

        truncationContainer.append(divElement);

        truncationContainer.querySelector(".bs-read-more__action a").addEventListener('click', () => {
            const $this = truncationContainer.querySelector(".bs-read-more__action a")

            truncationContainer.style.maxHeight = this.getMaxHeight(truncationContainer) + 'px';
            truncationContainer.classList.toggle('bs-read-more--expanded');

            if(truncationContainer.classList.contains('bs-read-more--expanded')) {
              $this.textContent = 'Read Less';
            } else {
              truncationContainer.removeAttribute('style');
              $this.textContent = 'Read More';
            }
        });
    }
  }

    private getMaxHeight(element: HTMLDivElement) {
      // Add extra spacing to prevent action link from overlapping the content
      return element.scrollHeight + 32;
  }
}


import { Injectable } from "@angular/core";
import { Router, RouterStateSnapshot } from "@angular/router";
import environment from "@environments/environment";
import { StringUtilities } from "../utilities/string-utilities";
import { HttpUtilityService } from "./http-utility/http-utility.service";

/**
 * Reloading the entire page interrupts in-app routing, effectively exiting the single-page application (SPA) environment.
 * When users navigate to a route within the application that doesn't exist, reloading the entire page loads the actual URL from the browser,
 * bypassing the SPA and loading the server-rendered content associated with that URL.
 */
@Injectable({
  providedIn: 'root'
})
export class ReloadPageService {
  
  private currentUrl: string;

  constructor(private router: Router,
    private httpUtilityService: HttpUtilityService) { }

  reloadPage(routerStateUrl: string) {
    this.currentUrl = this.router.url;

    this.httpUtilityService.redirectToUrl(`${StringUtilities.trimTrailingSlash(environment.siteUrl)}${routerStateUrl}`);
    // Reset history for back button in search
    if (this.isSearchRoute()) {
      // can be removed after old site is decommissioned
      // without this code, hitting the back button would go back to search url and redirects the user again
      if (this.router.getCurrentNavigation().extras) {
        const initialUrl = this.router.getCurrentNavigation().extras['initialUrl'];
        this.router.navigated = false;
        window.history.replaceState(null, '', initialUrl);
      }
    }
  }

  private isSearchRoute(): boolean {
    return (this.currentUrl.indexOf('/search') >= 0);
  }
}
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Constants } from '@app/core/utilities/constants';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { isNullOrUndefined } from 'util';
import { IRequeueParams } from '../../../core/bsshapi/api-wrappers';
import { ILabWorkflow } from '../../../core/model/labWorkflow';
import { ResourceType } from '../../../core/model/resource-type';
import { BasePairsPipe } from '../../../core/utilities/pipes/base-pairs-format-pipe';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ILabRequeueComponentOutput } from '../components/lab-requeue/lab-requeue.component';
import { ModalTexts } from '../modal-texts';
import { ILibraryRequeueModalInput, ILibraryRequeueModalOutput, INewLibraryRequeueOutput } from '../model/action-modal';
import { IRequeueLibrary } from '../model/requeue-library.interface';

@Component({
  selector: 'app-requeue-library-modal',
  templateUrl: './requeue-library-modal.component.html',
  styleUrls: ['./requeue-library-modal.component.scss']
})
export class RequeueLibraryModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  /* Library can be requeued from biosamples and pools context only */

  modalType = 'InitiateRequeueLibraryModal';
  ngxSmartModalTitle: string = ModalTexts.REQUEUE_LIBRARY.MODAL_TITLE;
  confirmButtonText: string = ModalTexts.REQUEUE_LIBRARY.CONFIRM_BUTTON_TEXT;
  closeButtonText: string = ModalTexts.REQUEUE_LIBRARY.CANCEL_BUTTON_TEXT;

  infoList = []; // information to display in lab-requeue component
  selectedLabWorkflow: ILabWorkflow = null; // selectedLabWorkFlow for current biosample

  modalOutput: ILibraryRequeueModalOutput = null;

  constructor(public ngxSmartModalService: NgxSmartModalService, private basePairPipe: BasePairsPipe) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.disableConfirmButton = true;
    this.setUpInputSubscriber();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // had to use this approach as lab requeue component is sending confirm event with data even before modal 
    // is initialized
    this.setModalData(this.modalOutput);
  }

  protected constructError(error: any): string {
    if (error && error.error && error.error.ErrorMessage) {
      return error.error.ErrorMessage;
    }
    return 'An unexpected error occured while requeuing library.';
  }

  private setUpInputSubscriber() {
    this.subs.sink = this.data.subscribe((response: ILibraryRequeueModalInput) => {
      if (response) {
        this.updateInfoList(response.associatedParentResourceType, response.library);
        if (!response.errorMsg) {
          this.disableConfirmButton = false;
        } else {
          this.errorMsg = response.errorMsg;
        }
      }
    });
  }

  dataChanged(labRequeueComponentOutput: ILabRequeueComponentOutput) {
    if (labRequeueComponentOutput) {
      const requeueParams: IRequeueParams = {
        requestedAdditionalYield: labRequeueComponentOutput.RequestedAdditionalYield,
        yieldInputMax: labRequeueComponentOutput.yieldInputMax,
        yieldInputMin: labRequeueComponentOutput.yieldInputMin,
        isValid: labRequeueComponentOutput.errorMsg == null,
        // currentBasePairUnit?: BasePairUnit;
      };
      const data: ILibraryRequeueModalOutput = {
        requeueParams
      };

      if (!isNullOrUndefined(this.modal)) {
        this.updateDisableConfirmButton(labRequeueComponentOutput);
      }

      // had to use this approach as lab requeue component is sending confirm event with data even before modal 
      // is initialized
      this.modalOutput = data;
      this.setModalData(this.modalOutput);
    }
  }

  private updateDisableConfirmButton(output: ILabRequeueComponentOutput) {

    if (!isNullOrUndefined(output.errorMsg) ||
      isNullOrUndefined(output.RequestedAdditionalYield) ||
      !output.isInputValid ||
      isNullOrUndefined(output.PrepRequestId)) {
      this.disableConfirmButton = true;
    } else {
      this.disableConfirmButton = false;
    }
  }

  private setModalData(data: ILibraryRequeueModalOutput) {
    if (!this.modal) {
      return;
    }
    this.modal.setData(data, true);
  }

  private handleBiosampleListInfo(libraryToRequeue: IRequeueLibrary): void {
    this.selectedLabWorkflow = libraryToRequeue.selectedLabWorkflow;
      this.infoList.push({
        resourceName: 'Biosample Status',
        resourceValue: libraryToRequeue.biosampleStatus
      });

      if (!libraryToRequeue.hasIssues) {
        if (this.selectedLabWorkflow && this.selectedLabWorkflow.prepRequest && this.selectedLabWorkflow.prepRequest.RequiredYield) {

          const requiredYield = this.basePairPipe.transform(this.selectedLabWorkflow.prepRequest.RequiredYield, 2, 'N/A');
          this.infoList.push({
            resourceName: 'Required Yield',
            resourceValue: requiredYield
          });
        }
        if (this.selectedLabWorkflow && this.selectedLabWorkflow.missingYield) {
          const missingYield = Math.max(this.selectedLabWorkflow.missingYield, 0);
          const misingYieldTransformedResult = this.basePairPipe.transform(missingYield, 2, 'N/A');
          this.infoList.push({
            resourceName: 'Missing Yield',
            resourceValue: misingYieldTransformedResult
          });
        }
      }
  }

  private updateInfoList(resourceType: ResourceType, libraryToRequeue: IRequeueLibrary) {
    if (!resourceType || !libraryToRequeue) {
      return;
    }
    this.infoList.push({
      resourceName: 'Library Name',
      resourceValue: libraryToRequeue.libraryName
    });
    this.infoList.push({
      resourceName: 'Library Status',
      resourceValue: libraryToRequeue.libraryStatus
    });

    switch(resourceType) {
      case ResourceType.BIO_SAMPLE:
      case ResourceType.BIO_SAMPLES:
        this.handleBiosampleListInfo(libraryToRequeue);
        break;
      // TODO: add for pool ---> else if (resourceType === Pool)
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { IEmptyTrashModalInput } from '../model/action-modal';

@Component({
  selector: 'app-empty-trash-modal',
  templateUrl: './empty-trash-modal.component.html',
  styleUrls: ['./empty-trash-modal.component.scss']
})
export class EmptyTrashModalComponent extends BaseModalComponent implements OnInit {
  modalTitle: string = 'Permanently Empty Trash?';
  modalType: string = 'EmptyTrashModal';
  alertMessages: string[] = [`You can't undo this action!`, `Items with a \'Failed\' status or \'In Progress\' will not be purged.  Empty trash again after they are marked \'Complete\'.`];
  totalSize: number;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
   }

  ngOnInit() {
    super.ngOnInit();

    this.subs.sink = this.data.subscribe((modalInput: IEmptyTrashModalInput) => {
      this.totalSize = modalInput.totalSize;
    });
  }

}

import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef, ElementRef, Input } from '@angular/core';
import { CurrentUserStore } from '../../user/store/current-user/current-user.store';
import { CurrentUserWorkgroupsStore } from '../../user/store/current-user-workgroups/current-user-workgroups.store';
import { UniversalNavbarComponent as ExternalUniversalNavbarComponent,
  NgxSmartModalComponent, ToastrService, ISearchInput, ICurrentAlertNotificationState, IAlertCard
} from '@bssh/comp-lib';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, catchError } from 'rxjs/operators';
import { UniversalNavLinksService } from '../../core/services/universal-nav-links/universal-nav-links.service';
import { SubSink } from 'subsink';
import { IUserOrWorkgroup } from '../../core/model/workgroup';
import { Constants } from '../../core/utilities/constants';
import { AuthorizationService } from '../../core/services/authorization/authorization.service';
import { HttpUtilityService } from '../../core/services/http-utility/http-utility.service';
import { SearchRouteService } from '../../core/services/search/search-route.service';
import { merge, Observable, of, EmptyError } from 'rxjs';
import { CodeFeaturesService } from '@app/core/services/user/code-features.service';
import { UserAlertNotificationService } from '@app/core/store/usernotifications/alert-notifications/user-alert-notification.service';
import { SkinnyBsshService } from '@app/core/services/skinny-bssh/skinny-bssh.service';
import { TrashItemsStore } from '@app/core/store/trash-items/trash-items.store';

/**
 * Represents the universal nav bar adapted to BSSH
 */
@Component({
  selector: 'app-bs-univ-nav-bar',
  templateUrl: './bs-univ-nav-bar.component.html',
  styleUrls: ['./bs-univ-nav-bar.component.scss'],
  providers: [ SearchRouteService]
})

export class BsUnivNavBarComponent implements OnInit, OnDestroy, AfterViewInit {
  public logoLinkNewWindow = true;
  public titleLinkNewWindow = false;
  public menuOptions: any;
  public searchRoute: string;
  public searchRouteService = new SearchRouteService();
  public searchResults: any;
  public archiveEnabled = false;
  public runZippingEnabled = false;
  private isBioSampleRegistryEnabled  = true;
  public trashBadge: string;
  public userHasActiveIcaSubscription = false;

  public searchWidgetParameters = {
    overlayTop: '52px',
    closeOnSelect: 'true',
    requester: (inputValue: string) => {
      return this.searchRouteService.getSearchAreas();
    },
    resultsMapper: (value, stringData) => {
      return {
        label: value.name,
        action: value.type,
        value: value.value || stringData
      };
    },
    onSelectAction: (data: ISearchInput) => {
      this.setSearchRoute(data);
    }
  };

  onSelectAction(data: ISearchInput) {
    this.setSearchRoute(data);
  }

  private subs: SubSink = new SubSink();
  @ViewChild(ExternalUniversalNavbarComponent, { static: true }) public navBar: ExternalUniversalNavbarComponent;
  // Todo: Make the invocation of the modal more imperative and dynamic.
  @ViewChild('subscriptionErrorModal', { static: true }) private subscriptionErrorModal: NgxSmartModalComponent;


  @Input()
  get transparent(): boolean { return this._transparent; }
  set transparent(value: boolean) {
    this._transparent = value;
  }
  private _transparent = null;
  private isSkinnyBssh: boolean = false;


  constructor(
    private userStore: CurrentUserStore,
    private userWorkgroupsStore: CurrentUserWorkgroupsStore,
    private router: Router,
    private route: ActivatedRoute,
    private universalNavLinksService: UniversalNavLinksService,
    private toastService: ToastrService,
    private authService: AuthorizationService,
    private httpUtilityService: HttpUtilityService,
    private changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private codeFeaturesService: CodeFeaturesService,
    private userAlertNotificationService: UserAlertNotificationService,
    private skinnyBsshService: SkinnyBsshService,
    private trashItemsStore: TrashItemsStore
    ) {
  }

  /**
   * OnInit Life cycle hook
   */
  ngOnInit(): void {
    this.navBar.userService = this.userStore;
    this.navBar.userWorkgroupService = this.userWorkgroupsStore;
    this.navBar.userApplicationsService = {
      stateChanged : this.universalNavLinksService.getGlobalAppItemsState(),
      loading$: this.universalNavLinksService.userGlobalAppsStore.loading$
    };

    // load user alert notifications
    this.userAlertNotificationService.loadUserAlertNotificationList('Invite', 0, 20, 'DateModified', 'Desc');
    this.navBar.userAlertNotificationService =  {
      stateChanged :  this.userAlertNotificationService.getUserAlertNotificationStoreState(),
      loading$: this.userAlertNotificationService.userAlertNotificationStore.loading$
    }

    //Check if Skinny BSSH, hide analyses notifications section in DOM
    this.isSkinnyBssh = this.skinnyBsshService.isSkinnyBssh();

    this.subs.sink = this.userStore.contextChanged$.subscribe({
      next: () => {
        this.userAlertNotificationService.loadUserAlertNotificationList('Invite', 0, 20, 'DateModified', 'Desc', true);
      }
    });

    this.subs.sink = this.userStore.stateChanged.pipe(filter(state => state != null && state.currentUser != null))
    .subscribe({
      next: state => {
        this.navBar.profileLinks = this.universalNavLinksService.getUserProfileLinkItems(state.currentUser);
      }
    });

    this.subs.sink = this.trashItemsStore.stateChanged.pipe(
      filter((state) => !!state && !isNaN(state.trashItemsCount))
    ).subscribe((state) => {
      this.trashBadge = this.formatTrashBadgeCount(state.trashItemsCount);
    });

    this.subs.sink = this.route.queryParams.subscribe((data) => {
      this.navBar.searchWidget.search.setValue(data.query ? data.query : '');
    });
  }

  public deleteUserAlertNotification($event: IAlertCard){
    this.userAlertNotificationService.deleteUserAlertNotification($event);
  }

  private formatTrashBadgeCount(count: number): string | null {
    if(count === 0) {
      return null;
    } else if(count < 100) {
      return `${count}`;
    } else {
      return `99+`;
    }
  }

  ngAfterViewInit() {
    // configure nav bar
    this.configureNavBar();

    // Needed to set the current active Link in the navbar in case of direct loads
    // Clicks are already handled in the component.
    this.setCurrentlyActiveNavBarMenuItem();

    this.subscribeToCurrentUserStore();

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Switches the context to another user/workgroup
   * @param userOrWorkgroup The new workgroup we're switching to
   */
  switchUserContext(userOrWorkgroup: IUserOrWorkgroup): void {
    this.userStore.changeUserContext(userOrWorkgroup.Id);   
  }


  /**
   * Method to invoke when the 'help' action icon is clicked.
   * @param data The $event
   */
  helpAction(event) {
    /**
     * To do: Christopher has to update walkme to overlay a transparent 
     * element on top of the help icon and that is controlling the walking interaction
    */
    // navigating help to an fallback help page when the script isn't present
    // when script is present then walkme will take over
    window.open(this.universalNavLinksService.bsHelpUrl, "_blank");

  }

  /**
   * Method to invoke when the 'trash' action icon is clicked.
   * @param data The $event
   */
  trashAction(event) {
    // To do: plugin logic here, when trash is ready to be implemented
    this.router.navigate([this.universalNavLinksService.trashUrl]);
  }

  /**
   * Method to invoke when the 'trash' action ico is clicked.
   * @param data The $event
   */
  searchAction(event) {
    // To do: plugin logic here, when search is ready to be implemented
  }

  /**
   * Method to invoke when the 'notifications' action ico is clicked.
   * @param data The $event
   */
  notificationsAction(event) {
    // To do: plugin logic here, when notifications is ready to be implemented
  }

  /**
   * A temporary log method for actions to log
   * Todo: Remove when all actions have been implemented.
   * @param data The $event
   */
  log(data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        console.log(`Callback for ${key} has status ${element}`);
      }
    }
  }

  /**
   * Logs out user
   * @param data The $event
   */
  logOut() {
    this.authService.logout(true);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private setSearchRoute(data: ISearchInput) {
    const queryParameters = this.searchRouteService.setSearchQuery(data.value, data.action);
    this.router.navigate(['search'],
    {
      queryParams: queryParameters,
      replaceUrl: true,
      // can be removed after old site is decommissioned
      state:
      {
        ['initialUrl']: this.router.url
      }
    });
  }

  /**
   * Configures the universal nav bar
   */
  private configureNavBar() {
    this.navBar.links = this.universalNavLinksService.getNavItems(!this.isSkinnyBssh);
    this.navBar.profileLinks = this.universalNavLinksService.getUserProfileLinkItems();
    // this.navBar.actions = this.universalNavLinksService.getNavActions();

    this.navBar.logoUrl = this.universalNavLinksService.logoUrl;
    this.navBar.title = this.universalNavLinksService.navBartitle;
    this.navBar.titleLink = this.universalNavLinksService.titleLink;
    this.navBar.logoLinkNewWindow = this.universalNavLinksService.logoLinkNewWindow;
    this.navBar.titleLinkNewWindow = this.universalNavLinksService.titleLinkNewWindow;
    this.navBar.applicationLinkBottom = this.universalNavLinksService.getWaffleMenuApplicationsFooterLink();
  }

  /**
   * Sets the currently active menu option, when directly navigated to the url
   */
  private setCurrentlyActiveNavBarMenuItem() {
    const currentUrl = this.httpUtilityService.getPathFromCurrentRequestUrl();
    this.navBar.setActive(currentUrl);
  }

  /**
   * subscribe To CurrentUserStore.
   */
  private subscribeToCurrentUserStore() {
    // this.errorModal.setData
    this.subs.sink = this.userStore.stateChanged.pipe(
      filter(state => state != null)
    ).subscribe({
      next: (state) => {
         // Listens to errors from the user store and shows a modal/toast accordingly.
        if (state.currentUserStateError != null) {
          // If the error is because of an invalid subscription problem, show a modal with helpful instructions.
          if (state.currentUserStateError.error.ErrorCode === Constants.inactiveSubscriptionErrorCode) {
            this.subscriptionErrorModal.open(true);
          }
        }

        if (state.currentUser != null && state.currentUserCodeFeatures != null) {

          // walk-me still needs this
          this.isBioSampleRegistryEnabled = this.codeFeaturesService.bioSampleRegistryEnabled;

          // we're no longer disabling the Biosamples menu link based on code feature, but skinnyBssh may limit what's visible
          const navbarLinks = this.universalNavLinksService.getNavItems(!this.isSkinnyBssh);
          this.navBar.links = navbarLinks;

          // used for walkme only, always hidden: adds hidden element to the DOM when the archive code feature is enabled.
          this.archiveEnabled = this.codeFeaturesService.archivingIsEnabled;

          // used for walkme only, always hidden: adds hidden element to the DOM when the run zipping code feature is enabled.
          this.runZippingEnabled = this.codeFeaturesService.runZippingIsEnabled;

          // used for walkme only, always hidden: adds hidden element to the DOM when the user has an active ICA subscription
          this.userHasActiveIcaSubscription = state.currentUser.Subscription != null
                                              && state.currentUser.Subscription.HasActiveIcaSubscription === true;
        }
      }
    });
  }
}

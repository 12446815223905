import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IApiFile, IDirectoryItem, ITreeData } from '@bssh/comp-lib';
import { IApiFile as ApiFile } from '../modals/model/file-preview.interface';

@Component({
  selector: 'app-file-browser',
  templateUrl: './file-browser-wrapper.component.html',
  styleUrls: ['./file-browser-wrapper.component.scss']
})
export class FileBrowserWrapperComponent {

  @Input() public directoryData: ITreeData = null;  // data for current view/folder
  @Input() public columnDefs = null;
  @Output() public directorySelected: EventEmitter<IDirectoryItem> = new EventEmitter(); // folder to be updated
  @Output() public fileSelected: EventEmitter<ApiFile> = new EventEmitter(); // file selected

  constructor() { }

  updateDirectory(folder: IDirectoryItem) {
    this.directorySelected .emit(folder);
  }

  onFileSelection(file: IApiFile) {
    const selectedFile: ApiFile = file as ApiFile;
    this.fileSelected.emit(selectedFile);
  }

}

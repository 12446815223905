import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '@app/shared/modals/base-modal/base-modal.component';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { IImportRunErrorsModalInput } from '@app/shared/modals/model/action-modal';
import { ImportPlannedRunWarning } from '../model/import-planned-run-warning';

@Component({
  selector: 'app-import-run-errors-modal',
  templateUrl: './import-run-errors-modal.component.html',
  styleUrls: ['./import-run-errors-modal.component.scss']
})
export class ImportRunErrorsModalComponent extends BaseModalComponent implements OnInit {
  modalType = 'importRunErrors';
  title='Import Run Failed';
  subTitle = 'Please fix the sample sheet and try again.';
  warnings: string[] = [];
  errors: string[] = [];
  showConfirmButton = false;
  showCloseButton = false;
  isProceed = false;

  constructor(
    public ngxSmartModalService: NgxSmartModalService
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subs.sink = this.data.subscribe((modalInput: IImportRunErrorsModalInput) => {
      if (modalInput) {
        this.title = modalInput.title || this.title;
        this.subTitle = modalInput.subTitle || this.subTitle;
        const warnings = modalInput.warnings.map(x => new ImportPlannedRunWarning(x));
        this.errors = this.getErrors(warnings);
        this.warnings = this.getWarnings(warnings);
        this.showConfirmButton = modalInput.showConfirmButton || this.showConfirmButton;
        this.showCloseButton = modalInput.showCloseButton || this.showCloseButton;
      }
    });
  }

  close(modal: NgxSmartModalComponent): void {   
    this.confirm.emit(this.isProceed);
    this.confirm.complete();
    super.close(modal);
  }

  /**
   * Override BaseModalComponent's accept(). Called when the confirm
   * button on modal is clicked to proceed with the action.
   * Sets isProceed to true to be emitted on close.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  accept(modal: NgxSmartModalComponent) {
    this.isProceed = true;
    modal.close();
  }

  public getWarnings(inputWarnings: ImportPlannedRunWarning[]): string[] {
    const warnings = inputWarnings.filter(x => x.severity.toLowerCase() === 'informational')
      .map(msg => msg.getRunWarningText());
    return [...new Set(warnings)]
  }

  public getErrors(inputWarnings: ImportPlannedRunWarning[]): string[] {
    const errors = inputWarnings.filter(x => x.severity.toLowerCase() !== 'informational')
      .map(msg => msg.getRunWarningText());
    return [...new Set(errors)]
  }
}

<div #CancelOwnershipTransferModalDiv>
    <ngx-smart-modal #CancelOwnershipTransferModal
        [identifier]="modalType"
        [title]="modalTitle"
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButton" 
        [confirmButtonText]="confirmButtonText">
        <div> 
            <span class=cancel-transfer__resource__name>{{ resourceName }}</span>
            <p>{{ pendingTransferMessage }}</p>
        </div>
        <br>
        <lib-error-alert-message
            [errorMsg]="errorMsg">
        </lib-error-alert-message>
        
    </ngx-smart-modal>
</div>
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { IAlertModalInput, IAlertModalOutput } from '../model/action-modal';
import { alertModalTexts, IAlertModalText } from './alert-modal-texts';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {
  modalType = 'alertModal';
  modalText: IAlertModalText = {
    title: 'Generic Warning Modal',
    content: 'Pass alert type via modalInput',
    confirmButtonText: 'Proceed',
    closeButtonText: 'Dismiss Warning'
  };
  isProceed = false;
  showCloseButton: boolean = true;
  showConfirmButton: boolean = true;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    // Assign modal text content according to input
    this.subs.sink = this.data.subscribe((modalInput: IAlertModalInput) => {
      if (modalInput && modalInput.type) {
        // Only necessary for dynamic error messages
        if(modalInput.type === 'CUSTOM') {
          this.modalText = modalInput.alertModalText;
          // For custom messages, close and confirm button are displayed by default unless showCloseButton or showConfirmButton is explicitly set to false
          this.showCloseButton = typeof modalInput.showCloseButton === 'boolean' ? modalInput.showCloseButton : this.showCloseButton;
          this.showConfirmButton = typeof modalInput.showConfirmButton === 'boolean' ? modalInput.showConfirmButton : this.showConfirmButton;
        } else {
          this.modalText = alertModalTexts[modalInput.type];
          // For non-custom messages, close and confirm button are displayed based on whether the button text is provided
          this.showCloseButton = !!this.modalText.closeButtonText;
          this.showConfirmButton = !!this.modalText.confirmButtonText;
          this.modalText.closable = (typeof this.modalText.closable === 'undefined') ? true : this.modalText.closable;
        }
      }
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  /**
   * To be called whenever the alert modal is closed.
   * Will emit the isProceed outcome to the consumer.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  close(modal: NgxSmartModalComponent) {
    const modalOutput: IAlertModalOutput = { isProceed: this.isProceed };
    this.confirm.emit(modalOutput);
    this.confirm.complete();
    super.close(modal);
  }

  /**
   * Override BaseModalComponent's accept(). Called when the confirm
   * button on modal is clicked to proceed with the action.
   * Sets isProceed to true to be emitted on close.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  accept(modal: NgxSmartModalComponent) {
    this.isProceed = true;
    modal.close();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { IApplication, IApplicationCategory } from '@app/core/model/applications/applications';
import { AppsStore } from '@app/core/store/apps/apps.store';
import { MetadataStore } from '@app/core/store/metadata/metadata.store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root'
})
export class AppLauncherService implements OnDestroy {
  private subs = new SubSink();

  constructor(
    public appsStore: AppsStore,
    public metadataStore: MetadataStore
  ) {}

  getApps(): Observable<IApplication[]> {
    this.appsStore.loadAppsList();
    return this.appsStore.stateChanged.pipe(
      filter((stateChanged: any) => stateChanged && stateChanged.appsList),
      map((stateChanged: any) => stateChanged.appsList)
    );
  }

  getBookmarkedApps(): Observable<IApplication[]> {
    return this.appsStore.stateChanged.pipe(
      filter((stateChanged: any) => stateChanged && stateChanged.bookmarkedAppsList),
      map((stateChanged) => stateChanged.bookmarkedAppsList)
    );
  }

  getAppClassifications(): Observable<IApplicationCategory[]> {
    this.metadataStore.loadApplicationMetadata();
    return this.metadataStore.stateChanged.pipe(
      filter((stateChanged: any) => stateChanged && stateChanged.applicationMetadata),
      map((stateChanged: any) => {
        return stateChanged.applicationMetadata.Classifications as IApplicationCategory[]
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

<ng-container *ngIf="params?.data">

    <ng-template #customTrigger>
        <div class="metrics-tooltip-name__custom-trigger">{{ params.data.Name }}</div>
    </ng-template>

    <lib-popover popoverSize="extra-large" [popoverType]="customTrigger" popoverPlacement="top"
        [popoverAsyncContent]="popover">

        <ng-template #popover>
            <div class="metrics-tooltip__title">
                {{ params.data.Name }} <span>({{ params.data.AttributeName }})</span>
            </div>
            <div class="metrics-tooltip__description">{{ params.data.Description }}</div>
        </ng-template>
    </lib-popover>

</ng-container>
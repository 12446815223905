import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

interface DateFormatOptions {
    L: string;
    LL: string;
    LLL: string;
}

@Pipe({
    name: 'bsshdate',
})
@Injectable({
    providedIn: 'root'
  })
export class BsshDatePipe implements PipeTransform {

    constructor(private datePipe: DatePipe) { }

    // transforms to standardized iso format
    transform(value: any, format?: keyof DateFormatOptions): string {
        const formatOptions: DateFormatOptions = {
            L: 'HH:mm',
            LL: 'yyyy-MM-dd',
            LLL: 'yyyy-MM-dd HH:mm'
        };
        const dateFormat = format ? formatOptions[format] : formatOptions['LLL'];

        return this.datePipe.transform(value, dateFormat);
    }
}
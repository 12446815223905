import { Injectable } from '@angular/core';
import { ComboBoxComponent } from '../combobox.component';

@Injectable()
export class ComboBoxDropdownService {

  private ComboBox: ComboBoxComponent;

  public register(ComboBox: ComboBoxComponent | any) {
    this.ComboBox = ComboBox;
  }

  public getComboBox(): ComboBoxComponent {
    return this.ComboBox;
  }
}

/**
 * A model representing an API error
 */
export interface IApiHttpError {
    // Http Error status code i.e. 500, 503 etc
    httpErrorStatus: number;
    // Additional information about the error
    data: any;
}

export interface IHttpErrorMessages {
    status: number;
    pageTitle: string;
    heroMessage: string;
    summary: string;
    detail: string;
}

// 503 outage models
export interface IBsOutageStatus {
    type: OutageType;
    productName?: string;
    start?: string;
    end?: string;
}


export enum OutageType {
    Planned = 'planned',
    Unplanned = 'unplanned'
}


import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
@Pipe({ name: 'decodeHtml' })
export class DecodeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): any {
    const div = document.createElement('div');
    div.innerHTML = value;

    return this.sanitizer.bypassSecurityTrustHtml(div.innerText);
  }
}
import { Injectable } from '@angular/core';
import { Observable, forkJoin, EMPTY, of } from 'rxjs';
import { IUser } from '../../model/user';
import { ResourceType } from '../../model/resource-type';
import { V1pre3TrashItem, BasespaceService, V2AppSession, V2AppSessionCompactList } from '@bssh/ng-sdk';
import { DeleteOption } from '../../model/delete-options';
import IResource from '../../model/resource';
import { IApiResponseWrapper, IBulkUpdateResponse, IBulkUpdateFailure } from '../../model/v2-api/v2-api-wrappers';
import { CodeFeaturesService } from '../user/code-features.service';
import { BsApiService } from '../bs-api/bs-api-service';
import { catchError, map, retryWhen, delay, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';

export interface ITrashService {
  canTrash(resources: IResource[], currentUserId: string): boolean;
  trashResources(resourceIds: string[], resourceType: ResourceType,
                 deleteOption?: DeleteOption): Observable<IBulkUpdateResponse<IApiResponseWrapper<V1pre3TrashItem>>>;
  getAppSessionsForRun(resourceIds: string[]): Observable<V2AppSessionCompactList>;
  trashAppSessions(appSessionIds: string[]): Observable<IBulkUpdateResponse<IApiResponseWrapper<V1pre3TrashItem>>>;
  trashResourcesWithV2(
    resourceIds: string[],
    resourceType: ResourceType,
    deleteOption?: DeleteOption
  ): Observable<IBulkUpdateResponse<V1pre3TrashItem>>;
  trashSamples(sampleIds: string[]): Observable<IBulkUpdateResponse<IApiResponseWrapper<V1pre3TrashItem>>>;

}

@Injectable({
  providedIn: 'root'
})
export class TrashService implements ITrashService {
  constructor(private bsApiService: BsApiService, private codeFeaturesService: CodeFeaturesService,
              private basespaceApi: BasespaceService) { }

  /**
   * Checks whether the currentUserId is same as the resource's owner Id
   * @param currentUserId Id of the current user
   * @param owner Contains the Owner information of the Resource to Trash
   * @returns A boolean value saying whether current user is allowed to Trash Resource
   */
  private isResourceOwner(currentUserId: string, owner: IUser): boolean {
    return owner.Id === currentUserId;
  }

  /**
   * Checks whether the User with the available codeFeatures is allowed to trash resource or not
   * @param resource Resource to trash
   * @param currentUserId Id of the current user
   */
  canTrash(resources: IResource[], currentUserId: string): boolean {
    if (isNullOrUndefined(resources) || resources.length === 0) {
      return false;
    }

    return resources.every(resource => !((!resource) || (resource.IsArchived && !this.codeFeaturesService.gdsDeleteIsEnabled)));
  }

  /**
   * Calls the API to Trash the Resource with given Id
   * @param resourceId Id of the Resource to Trash
   * @param resourceType Type of the Resource to Trash
   * @param deleteOption Option to trash the Data Files only
   */
  trashResources(resourceIds: string[], resourceType: ResourceType,
                 deleteOption?: DeleteOption): Observable<IBulkUpdateResponse<IApiResponseWrapper<V1pre3TrashItem>>> {

    const resouceResponse: IBulkUpdateResponse<IApiResponseWrapper<V1pre3TrashItem>> = {
      SuccessItems: [],
      TotalSuccessCount: 0,
      FailureItems: [],
      TotalFailureCount: 0
    };

    return forkJoin(
      resourceIds.map((resourceId: string) => {
        return this.bsApiService.trashResource(resourceType, resourceId, deleteOption).
          pipe(
            catchError(error => {
              const failureObject: IBulkUpdateFailure<IApiResponseWrapper<V1pre3TrashItem>> = {
                Id: resourceId,
                ErrorMessage: error.error.ResponseStatus.Message,
                ErrorCode: error.error.ResponseStatus.ErrorCode,
              };
              resouceResponse.FailureItems.push(failureObject);
              resouceResponse.TotalFailureCount++;
              return of(undefined);
            }),
          ).pipe(
            map((response) => {
              if (response) {
                resouceResponse.SuccessItems.push(response);
                resouceResponse.TotalSuccessCount++;
              }
              return EMPTY; // as nothing needs to be returned from this observable, but resouceResponse needs to returned
            })
          );
      }),
    ).pipe(map(() => {
      return resouceResponse;
    }));
  }

  getAppSessionsForRun(resourceIds: string[]): Observable<V2AppSessionCompactList> {
    const ids = [];
    resourceIds.forEach(resourceId => {
      ids.push(Number(resourceId));
    });

    const requestParams: BasespaceService.GetV2AppsessionsParams = {
      inputRuns: ids,
    };

    return this.basespaceApi.GetV2Appsessions(requestParams).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  trashSamples(sampleIds: string[]): Observable<IBulkUpdateResponse<IApiResponseWrapper<V1pre3TrashItem>>> {
    const resourceResponse: IBulkUpdateResponse<IApiResponseWrapper<any>> = {
      SuccessItems: [],
      TotalSuccessCount: 0,
      FailureItems: [],
      TotalFailureCount: 0,
    };

    return this.bsApiService.trashSamples(sampleIds).pipe(
      map((response: IApiResponseWrapper<V1pre3TrashItem[]>) => {
        response.Response.forEach((trashItem: V1pre3TrashItem) => {
          resourceResponse.SuccessItems.push({
            Response: trashItem,
            ResponseStatus: response.ResponseStatus,
            Notifications: response.Notifications
          });
          resourceResponse.TotalSuccessCount++;
        });

        return resourceResponse;
      }),
      catchError((error) => {
        resourceResponse.FailureItems.push({
          Id: sampleIds[0],
          ErrorMessage: error.error.ResponseStatus.Message,
          ErrorCode: error.error.ResponseStatus.ErrorCode,
        });
        resourceResponse.TotalFailureCount++;

        return of(resourceResponse);
      })
    );
  }

  trashAppSessions(appSessionIds: string[]): Observable<IBulkUpdateResponse<IApiResponseWrapper<V1pre3TrashItem>>> {
    const resouceResponse: IBulkUpdateResponse<IApiResponseWrapper<V2AppSession>> = {
      SuccessItems: [],
      TotalSuccessCount: 0,
      FailureItems: [],
      TotalFailureCount: 0,
    };

    return forkJoin(
      appSessionIds.map((appSessionId: string) => {
        return this.bsApiService.trashAppSession(appSessionId).
          pipe(
            catchError(error => {
              const failureObject: IBulkUpdateFailure<IApiResponseWrapper<V2AppSession>> = {
                Id: appSessionId,
                ErrorMessage: error.error.ResponseStatus.Message,
                ErrorCode: error.error.ResponseStatus.ErrorCode,
              };
              resouceResponse.FailureItems.push(failureObject);
              resouceResponse.TotalFailureCount++;
              return of(undefined);
            }),
          ).pipe(
            map((response) => {
              if (response) {
                resouceResponse.SuccessItems.push(response);
                resouceResponse.TotalSuccessCount++;
              }
              return EMPTY; // as nothing needs to be returned from this observable, but resouceResponse needs to returned
            })
          );
      })
    ).pipe(map(() => {
      return resouceResponse;
    }));
  }

  /**
   * Calls the API to Trash the Resource with given Id
   * @param resourceId Id of the Resource to Trash
   * @param resourceType Type of the Resource to Trash
   * @param deleteOption Option to trash the Data Files only
   */
  trashResourcesWithV2(resourceIds: string[], resourceType: ResourceType,
                       deleteOption?: DeleteOption): Observable<IBulkUpdateResponse<V1pre3TrashItem>> {

    const resouceResponse: IBulkUpdateResponse<V1pre3TrashItem> = {
      SuccessItems: [],
      TotalSuccessCount: 0,
      FailureItems: [],
      TotalFailureCount: 0
    };

    return forkJoin(
      resourceIds.map((resourceId: string) => {
        return this.bsApiService.trashResourceWithV2(resourceType, resourceId, deleteOption).
          pipe(
            catchError(error => {
              const failureObject: IBulkUpdateFailure<V1pre3TrashItem> = {
                Id: resourceId,
                ErrorMessage: error.error.ErrorMessage,
                ErrorCode: error.error.ErrorCode,
              };
              resouceResponse.FailureItems.push(failureObject);
              resouceResponse.TotalFailureCount++;
              return of(undefined);
            }),
          ).pipe(
            map((response) => {
              if (response) {
                resouceResponse.SuccessItems.push(response);
                resouceResponse.TotalSuccessCount++;
              }
              return EMPTY; // as nothing needs to be returned from this observable, but resouceResponse needs to returned
            })
          );
      }),
    ).pipe(map(() => {
      return resouceResponse;
    }));
  }
}

import { Injectable } from '@angular/core';

export class ValidIndexingStrategies {
  public static DualIndex = "DualIndex";
  public static SingleIndex = "SingleIndex";
  public static None = "None";
  public static values = [ValidIndexingStrategies.None, ValidIndexingStrategies.SingleIndex, ValidIndexingStrategies.DualIndex]
}

export class ValidReadTypes {
  public static SingleRead = "SingleRead";
  public static PairedEnd = "PairedEnd";
  public static values = [ValidReadTypes.SingleRead, ValidReadTypes.PairedEnd]
}

@Injectable({
  providedIn: 'root'
})
export class PrepTabLibraryPrepKitsMapperService {

  constructor() { }

  mapReadTypes(input: string[]): any[] {
    if (!input) {
      return [];
    }
    return input
      .filter(t => t)
      .map(type => {
        return {
          text: this.getPrepTabReadTypeDisplayName(type),
          value: type
        };
      });
  }

  mapPrepTabIndexReads(input: string[]): any[] {
    if (!input) {
      return [];
    }
    return input
      .filter(t => t)
      .map(type => {
        return {
          text: this.getPrepTabIndexReadDisplayName(type),
          value: type
        };
      });
  }

  private getPrepTabReadTypeDisplayName = (readType: string): string => {
    switch (readType) {
      case ValidReadTypes.SingleRead:
        return 'Single Read';
      case ValidReadTypes.PairedEnd:
        return 'Paired End';
      default:
        return readType;

    }
  }

  private getPrepTabIndexReadDisplayName(indexRead: string): string {
    switch (indexRead) {
      case ValidIndexingStrategies.None:
        return 'No Index';
      case ValidIndexingStrategies.SingleIndex:
        return 'Single Index';
      case ValidIndexingStrategies.DualIndex:
        return 'Dual Indexes';
      default:
        return indexRead;

    }
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

export const PREFIX = '--';

@Component({
    selector: 'app-sass-helper',
    template: '<ng-container></ng-container>',
    styleUrls: ['./sass-helper.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SassHelperComponent implements OnInit {

    ngOnInit(): void {
  
    }
   //Read the custom property of body section with given name
    readProperty(name: string): string {
      const bodyStyles = window.getComputedStyle(document.body);
      return bodyStyles.getPropertyValue(PREFIX + name);
    }
  }
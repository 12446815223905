import { Injectable } from '@angular/core';
import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { ISearchResultItem } from '@app/core/model/search/search-result-item';
import { BsApiEndPoints } from '../bs-api/endpoints';
import { SiteNavigationLinksService } from '../site-navigation/site-navigation-links.service';
import { ISearchResourceConfig } from './search-resource-dictionary-types';

export interface ISearchResourceDictionaryService {
  getResourceConfigByType(searchType: string): ISearchResourceConfig;
}
/**
 * Manages the search configuration for resource types
 */
@Injectable({
  providedIn: 'root'
})
export class SearchResourceDictionaryService {

  public project: ISearchResourceConfig;
  public run: ISearchResourceConfig;
  public app: ISearchResourceConfig;
  public biosample: ISearchResourceConfig;
  public sample: ISearchResourceConfig;
  public file: ISearchResourceConfig;
  public appSession: ISearchResourceConfig;
  public appResult: ISearchResourceConfig;
  public dataset: ISearchResourceConfig;

  constructor(private siteNavigationLinksService: SiteNavigationLinksService) {
    this.setResourceDictionaries();
  }

  /**
   * Gets the resource config by resource type
   * @param resourceType The Resource Type
   */
  public getResourceConfigByType(resourceType: SearchResourceType): ISearchResourceConfig {
    switch (resourceType) {
      case SearchResourceType.Project:
        return this.project;
      case SearchResourceType.Run:
        return this.run;
      case SearchResourceType.App:
        return this.app;
      case SearchResourceType.Biosample:
        return this.biosample;
      case SearchResourceType.Sample:
        return this.sample;
      case SearchResourceType.File:
        return this.file;
      case SearchResourceType.AppSession:
        return this.appSession;
      case SearchResourceType.AppResult:
        return this.appResult;
      case SearchResourceType.Dataset:
        return this.dataset;
      default:
        throw new Error('Unknown resource type ' + resourceType);
    }
  }


  private setResourceDictionaries(): void {
    this.project = {
      key: 'project',
      displayNameSingular: 'Project',
      displayNamePlural: 'Projects',
      searchObjectName: 'Project',
      searchProperties: ['Name'],
      chooserMessage: 'Select Project',
      idPropertyName: 'Id',
      namePropertyName: 'Name',
      hrefPropertyName: 'Href',
      searchScope: 'projects',
      apiBaseHrefFragment: 'projects',
      searchFilters: null,
      isCreatableInChooser: true,
      uiHrefAccessor: (item: ISearchResultItem) =>
        this.siteNavigationLinksService.projectSummaryUrl(item.Project.Id),
      convertContentObject: (content: any) => {
        return { Project: content };
      },
      apiHrefAccessor: (item: ISearchResultItem) =>
        // TODO: Should this be prefixed with the 'v2'? Old basespace UI calls don't do this but they also seem to be broken...
         BsApiEndPoints.getProjectsPath(item.Project.Id),
      baseQuery: null
    };

    this.biosample = {
      key: 'biosample',
      displayNameSingular: 'Biosample',
      displayNamePlural: 'Biosamples',
      searchObjectName: 'Biosample',
      searchProperties: ['BioSampleName'],
      chooserMessage: 'Select Biosample',
      idPropertyName: 'Id',
      namePropertyName: 'BioSampleName',
      hrefPropertyName: 'Href',
      searchScope: 'biosamples',
      apiBaseHrefFragment: 'biosamples',
      searchFilters: null,
      isCreatableInChooser: true,
      uiHrefAccessor: (item: ISearchResultItem) =>
        this.siteNavigationLinksService.bioSampleSummaryUrl(item.BioSample.Id),
      convertContentObject: (content: any) => {
        return { BioSample: content };
      },
      apiHrefAccessor: (item: ISearchResultItem) =>
         `v2/${BsApiEndPoints.getBiosamplesPath(item.BioSample.Id)}`,
      baseQuery: null
    };

    this.run = {
      key: 'run',
      displayNameSingular: 'Run',
      displayNamePlural: 'Runs',
      searchObjectName: 'Run',
      searchProperties: ['ExperimentName', 'FlowcellBarcode'],
      chooserMessage: 'Select Run',
      idPropertyName: 'Id',
      namePropertyName: 'Name',
      hrefPropertyName: 'Href',
      searchScope: 'runs',
      apiBaseHrefFragment: 'runs',
      searchFilters: null,
      isCreatableInChooser: true,
      uiHrefAccessor: (item: ISearchResultItem) =>
        this.siteNavigationLinksService.runSummaryUrl(item.Run.Id),
    };

    this.app = {
      key: 'app',
      displayNameSingular: 'App',
      displayNamePlural: 'Apps',
      searchObjectName: 'Application',
      searchProperties: ['Name'],
      chooserMessage: 'Select App',
      idPropertyName: 'Id',
      namePropertyName: 'Name',
      hrefPropertyName: 'Href',
      searchScope: 'apps',
      apiBaseHrefFragment: 'apps',
      searchFilters: null,
      isCreatableInChooser: true,
      uiHrefAccessor: (item: ISearchResultItem) =>
        this.siteNavigationLinksService.appSummaryUrl(item.Application.Id),
    };

    this.biosample = {
      key: 'biosample',
      displayNameSingular: 'Biosample',
      displayNamePlural: 'Biosamples',
      searchObjectName: 'Biosample',
      searchProperties: ['BioSampleName'],
      chooserMessage: 'Select Biosample',
      idPropertyName: 'Id',
      namePropertyName: 'BioSampleName',
      hrefPropertyName: 'Href',
      searchScope: 'biosamples',
      apiBaseHrefFragment: 'biosamples',
      searchFilters: null,
      isCreatableInChooser: true,
      uiHrefAccessor: (item: ISearchResultItem) =>
        this.siteNavigationLinksService.bioSampleSummaryUrl(item.BioSample.Id),
      convertContentObject: (content: any) => {
        return { BioSample: content };
      },
      apiHrefAccessor: (item: ISearchResultItem) =>
         `v2/${BsApiEndPoints.getBiosamplesPath(item.BioSample.Id)}`,
      baseQuery: null
    };

    this.sample = {
      key: 'sample',
      displayNameSingular: 'Sample',
      displayNamePlural: 'Samples',
      searchObjectName: 'Sample',
      searchProperties: ['SampleId'],
      chooserMessage: 'Select Sample',
      idPropertyName: 'Id',
      namePropertyName: 'Name',
      hrefPropertyName: 'Href',
      searchScope: 'samples',
      apiBaseHrefFragment: 'samples',
      searchFilters: null,
      isCreatableInChooser: true,
      uiHrefAccessor: (item: ISearchResultItem) =>
        this.siteNavigationLinksService.sampleSummaryUrl(item.Sample.Id, item.Sample.Name),
      convertContentObject: (content: any) => {
        return { Sample: content };
      },
      apiHrefAccessor: (item: ISearchResultItem) => BsApiEndPoints.getSamplesPath(item.Sample.Id),
      baseQuery: null
    };

    this.file = {
      key: 'file',
      displayNameSingular: 'File',
      displayNamePlural: 'Files',
      searchObjectName: 'File',
      searchProperties: ['Name'],
      chooserMessage: 'Select File',
      idPropertyName: 'Id',
      namePropertyName: 'Name',
      hrefPropertyName: 'Href',
      searchScope: 'datasetfiles',
      apiBaseHrefFragment: 'files',
      searchFilters: null,
      isCreatableInChooser: true,
      uiHrefAccessor: (item: ISearchResultItem) =>
        this.siteNavigationLinksService.fileSummaryUrl(item.DatasetFile.ParentDataset.AppSession.Id, item.DatasetFile.ParentDataset.Id),
    };

    this.appSession = {
      key: 'appsession',
      displayNameSingular: 'Analysis',
      displayNamePlural: 'Analyses',
      searchObjectName: 'AppSession',
      searchProperties: ['Name'],
      chooserMessage: 'Select Analysis:',
      idPropertyName: 'Id',
      namePropertyName: 'Name',
      searchScope: 'appsessions',
      apiBaseHrefFragment: 'appsessions',
      searchFilters: null,
      isCreatableInChooser: true,
      uiHrefAccessor: (item: ISearchResultItem) =>
        this.siteNavigationLinksService.fileSummaryUrl(item.DatasetFile.ParentDataset.AppSession.Id, item.DatasetFile.Id),
    };

    this.appResult = {
      key: 'appresult',
      displayNameSingular: 'App Result',
      displayNamePlural: 'App Results',
      searchObjectName: 'AppResult',
      searchProperties: ['Name'],
      chooserMessage: 'Select App Result(s):',
      idPropertyName: 'Id',
      namePropertyName: 'Name',
      searchScope: 'appresults',
      apiBaseHrefFragment: 'appresults',
    };

    this.dataset = {
      key: 'dataset',
      displayNameSingular: 'dataset',
      displayNamePlural: 'datasets',
      searchObjectName: 'Dataset',
      searchProperties: ['Name'],
      chooserMessage: 'Select Datasets:',
      idPropertyName: 'Id'
    };

  }
}

import { Component } from '@angular/core';
import { ModalTexts } from '../../modal-texts';
import { ALL_POSSIBLE_FASTQ_QC_STATUSES, FastqQcStatus } from '../../../../core/model/datasets/fastq-qc-status';
import { IDataset } from '../../../../core/model/datasets/dataset';
import { NgxSmartModalService, StatusChangeResource } from '@bssh/comp-lib';
import { StatusToDisplayName } from '../../../../core/utilities/pipes/status-to-display-name.pipe';
import { ChangeStatusModalComponent } from '../change-status-modal.component';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-change-fastq-qc-modal',
  templateUrl: './change-fastq-qc-modal.component.html',
  styleUrls: ['./change-fastq-qc-modal.component.scss']
})
export class ChangeFastqQcModalComponent extends ChangeStatusModalComponent<IDataset, FastqQcStatus> {
  statusTitle = 'QC Status';
  datasetIdentifierTitle = 'Dataset Name';
  modalType = 'InitiateChangeFastqQcStatusModal';
  ngxSmartModalTitle: string = ModalTexts.CHANGE_STATUS.MODAL_TITLE.QC.FASTQ_DATASETS;
  allPossibleStatuses = ALL_POSSIBLE_FASTQ_QC_STATUSES;

  constructor(public ngxSmartModalService: NgxSmartModalService, public statusToDisplayName: StatusToDisplayName) {
    super(ngxSmartModalService, statusToDisplayName);
  }


  protected getStatusChangeResources(resources: IDataset[]): StatusChangeResource[] {
    const datasetsForChange: StatusChangeResource[] = [];

    for (const dataset of resources) {
      const qcStatus = dataset.QcStatus;
      // TODO: Extend StatusChangeResource interface in BSSH UI 
      // resourceId is mapped to filter duplicate resource by Id while constructing fastQ dataset error messages
      const statusChangeResource: StatusChangeResource = {
        resourceName: dataset.Name,
        resourceId: dataset.Id, // Mapping dataset Id of the resource to filter duplicate resource 
        currentStatus: (qcStatus !== 'Undefined') ?  this.statusToDisplayName.transform(qcStatus) :'---',
        statusClass: qcStatus === FastqQcStatus.QC_FAILED ? 'status-change__result__value--bold' : null
      };

      datasetsForChange.push(statusChangeResource);
    }
    return datasetsForChange;
  }

  constructError(error: any): string | string[] {

    if (error.data && Array.isArray(error.data)) {
      // check if resource Id is in errors list
      this.resources = this.resources.filter(resource => {
        return error.data.find(e => (e.Id === resource.resourceId));
      });
      this.resources = this.setHasIssuesFlag(this.resources);

      // check for api error messages 
      let apiErrors: string[] = [];
      error.data.forEach(element => {
        if (element.ErrorMessage) {
          apiErrors.push(element.ErrorMessage)
        }
      });
      if (apiErrors && apiErrors.length > 0) {
        return this.removeDuplicateErrors(apiErrors);
      }
    }

    if (!isNullOrUndefined(error.errorMessage)) {
      return error.errorMessage;
    }

    if (isNullOrUndefined(error.errorCode)) {
      return ModalTexts.CHANGE_STATUS.ERROR;
    }
    return '';
  }

  // set hasIssues flag to all resources having errors
  private setHasIssuesFlag(resourcesWithError: StatusChangeResource[]) {
    resourcesWithError.forEach(resourceWithError => {
      resourceWithError.hasIssues = true;
    });
    return resourcesWithError;
  }

  private removeDuplicateErrors(messages: string[]): string[] {
    let uniqueMessages = null;
    // filter duplicate error messages
    if (messages.length > 0) {
      uniqueMessages = messages.filter((msg, idx) => messages.indexOf(msg) === idx);
    } 
    return uniqueMessages;
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { ILaneCommentsModalInput } from '../model/action-modal';
import { BsApiService } from '@app/core/services/bs-api/bs-api-service';
import { V2CommentList, V2Comment } from '@bssh/ng-sdk';

@Component({
  selector: 'app-lane-comments-modal',
  templateUrl: './lane-comments-modal.component.html',
  styleUrls: ['./lane-comments-modal.component.scss']
})
export class LaneCommentsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  constructor(public ngxSmartModalService: NgxSmartModalService,
              private bsApi: BsApiService) {
    super(ngxSmartModalService);
  }

  modalType = 'LaneCommentsModal';
  laneNumber: number = null ;
  laneComments: V2Comment[] = [];
  url: string = '';
  Title: string = '';

  ngOnInit() {
    super.ngOnInit();
    this.setUpInputSubscriber();
  }

  private setUpInputSubscriber() {

    this.subs.sink = this.data.subscribe(res => {
      if (res) {
        const response: ILaneCommentsModalInput = res as ILaneCommentsModalInput;
        this.url = response.url;
        this.laneNumber = response.lane;
      }
    });

    this.subs.sink = this.bsApi.getLaneComment(this.url).subscribe((data: V2CommentList ) => {
      this.laneComments = data.Items;
      this.Title = this.laneComments.length === 1 ? 'Lane ' + this.laneNumber + ' comment' : 'Lane ' + this.laneNumber + ' comments';
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ComboBoxFilterPipe } from "./comboBoxFilterPipe.pipe";
import { ComboBoxComponent } from './combobox.component';
import { ComboBoxOptionComponent } from './option/option.component';
import { ComboBoxDropdownComponent } from './dropdown/dropdown.component';
import { ComboBoxDropdownService } from './dropdown/dropdown.service';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    OverlayModule,
    PortalModule,
    OverlayscrollbarsModule
  ],
  providers: [ComboBoxDropdownService],
  declarations: [ComboBoxComponent, ComboBoxOptionComponent, ComboBoxFilterPipe, ComboBoxDropdownComponent],
  exports: [ComboBoxComponent, ComboBoxOptionComponent, ComboBoxFilterPipe, ComboBoxDropdownComponent]
})
export class ComboBoxModule { }

import { Injectable } from '@angular/core';
import {ScriptStore} from "./script.store";

declare var document: any;

/*
  https://medium.com/better-programming/angular-load-external-javascript-file-dynamically-3d14dde815cb
*/
@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  public scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        type: script.type,
        innerText: script.innerText
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  remove(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.removeScript(script)));
    return Promise.all(promises);
  }

  loadNoScript(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadNoScriptTag(script)));
    return Promise.all(promises);
  }
  /*
  * It's my understanding, that "removing" a script from the DOM, 
  * as we attempted to do, won't remove it from the user's memory.
  */
  removeScript(name: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {

        var head = document.getElementsByTagName('head')[0];

        var scriptTag = head.querySelectorAll(`script[src*=${this.scripts[name].type}]`);
        scriptTag.forEach(e => {  e.parentNode.removeChild(e)});

        var linkTag = head.querySelectorAll(`link[href*=${this.scripts[name].type}]`);
        linkTag.forEach(e => { e.parentNode.removeChild(e)});

        var classTag = head.querySelectorAll(`style[class*=${this.scripts[name].type}]`);
        classTag.forEach(e => { e.parentNode.removeChild(e) });
       
        this.scripts[name].loaded = false;
        resolve({script: name, loaded: false, status: 'Removed'});
      } else {
        resolve({ script: name, loaded: false, status: 'Not loaded' });
      }
    });
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = this.scripts[name].type;
        if(typeof this.scripts[name].src != 'undefined' ){
          script.src = this.scripts[name].src;
        } 
        if(typeof this.scripts[name].innerText != 'undefined' ){
          script.textContent = this.scripts[name].innerText;
        } 
        script.async = this.scripts[name].loadAsync;
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(script, s); 
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }


  loadNoScriptTag(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement ("noscript");

        var iframe = document.createElement('iframe');
        iframe.style.display = "none";
        iframe.style.visibility = "hidden";
        iframe.style.width = "0";
        iframe.style.height = "0";
        iframe.src = this.scripts[name].src;
        iframe.id = this.scripts[name].type;

        script.appendChild(iframe);        
        script.async = this.scripts[name].loadAsync;
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        var body = document.getElementsByTagName('body')[0];
        body.insertBefore(script, body.firstChild); 
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }


}

// The status of the individual lane.
// Lane status can be changed by Automatic Lane QC, manually, or via API.
export enum LaneStatus {

    // The lane data passed QC.
    QcPassed = 'QcPassed',

    // The lane data failed QC.
    QcFailed = 'QcFailed',
}

export const ALL_POSSIBLE_LANE_STATUSES = [LaneStatus.QcFailed.toString(), LaneStatus.QcPassed.toString()];
export const NON_SUCCESS_LANE_STATUSES = [LaneStatus.QcFailed.toString()];


export const ReverseLaneStatus = (() => {
    const reverseMap = new Map<string, LaneStatus>();
    Object.keys(LaneStatus).forEach((laneStatus: LaneStatus) => {
        reverseMap.set(LaneStatus[laneStatus], laneStatus);
    });
    return reverseMap;
})();

// The overall status of the lanes on a run.
export enum LaneQcStatus {

    // The default status for a lane until QC has been performed or is manually overwritten.
    INITIAL = 'Initial',

    // Automatic lane QC is being processed against the run thresholds
    LANE_PENDING_QC = 'Lane Pending QC',

    // The lane has failed the automatic QC thresholds set by the user (via an API) or has been manually overwritten.
    LANE_QC_FAILED = 'Lane QC Failed',

    // All lanes passed QC.
    QC_PASSED = 'QC Passed',
    
    QC_FAILED = 'QC Failed',

    // The run has been stopped because of a problem with the flow cell.
    // After the flow cell is rehybed, it is used in a new run.
    REHYBED = 'Rehybed'
}


export const RUN_LANE_AND_QC_STATUS_DIC = {
    Initial: LaneQcStatus.INITIAL,
    QcPassed: LaneQcStatus.QC_PASSED,
    QcFailed: LaneQcStatus.QC_FAILED,
    LanePendingQc: LaneQcStatus.LANE_PENDING_QC,
    LaneQcFailed: LaneQcStatus.LANE_QC_FAILED,
    Rehybed: LaneQcStatus.REHYBED
}

import { Injectable } from '@angular/core';
import { IApiHttpError, IBsOutageStatus, IHttpErrorMessages, OutageType } from '@app/core/model/v2-api/http-error';
import { ErrorMessages } from '@app/core/utilities/error-messages';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorMessagesService {

  constructor() { }


  /**
   * Gets the error message for a given api error
   * @param apiError The api error
   * @returns Http Error messages to be used on the page
   */
  getErrorMessagesForApiError(apiError: IApiHttpError) {
    let erroMessage: IHttpErrorMessages = null;

    if (apiError && apiError.httpErrorStatus) {
      switch (apiError.httpErrorStatus) {
        case 403:
          erroMessage = this.constructBadRequestMessage(apiError);
          break;
        case 503:
          erroMessage = this.constructErrorMessageForOutage(apiError);
          break;
      }
    }
    return erroMessage;
  }


  /**
   * Gives the error messages to be used to display in case of an planned/unplanned outage
   * @param apiError The api error
   * @returns Http Error messages for outage
   */
  private constructErrorMessageForOutage(apiError: IApiHttpError): IHttpErrorMessages {
    const outageStatus = apiError.data as IBsOutageStatus;
    const errorMessage: IHttpErrorMessages = { status: 503, heroMessage: '', detail: '', pageTitle: '', summary: '' };
    if (outageStatus.type === OutageType.Planned && outageStatus.end != null) {
      errorMessage.heroMessage = ErrorMessages.HTTP_ERROR_MESSAGES.PLANNED_503.MAIN;
      errorMessage.summary = ErrorMessages.HTTP_ERROR_MESSAGES.PLANNED_503.SUMMARY(outageStatus.start, outageStatus.end);
      errorMessage.detail = ErrorMessages.HTTP_ERROR_MESSAGES.PLANNED_503.DETAIL(outageStatus.start, outageStatus.end);
      errorMessage.pageTitle = ErrorMessages.HTTP_ERROR_MESSAGES.PLANNED_503.PAGE_TITLE;
    } else {
      errorMessage.heroMessage = ErrorMessages.HTTP_ERROR_MESSAGES.UNPLANNED_503.MAIN;
      errorMessage.summary = ErrorMessages.HTTP_ERROR_MESSAGES.UNPLANNED_503.SUMMARY;
      errorMessage.detail = ErrorMessages.HTTP_ERROR_MESSAGES.UNPLANNED_503.DETAIL;
      errorMessage.pageTitle = ErrorMessages.HTTP_ERROR_MESSAGES.UNPLANNED_503.PAGE_TITLE;
    }
    return errorMessage;
  }

  private constructBadRequestMessage(apiError: IApiHttpError): IHttpErrorMessages {
    return {
      heroMessage: ErrorMessages.HTTP_ERROR_MESSAGES.RESOURCE_403.MAIN,
      summary: ErrorMessages.HTTP_ERROR_MESSAGES.RESOURCE_403.SUMMARY,
      detail: ErrorMessages.HTTP_ERROR_MESSAGES.RESOURCE_403.DETAIL,
      pageTitle: ErrorMessages.HTTP_ERROR_MESSAGES.RESOURCE_403.PAGE_TITLE,
      status: 403
    };
  }
}






<ngx-smart-modal
    #ConfirmModal
    class="confirm-modal"
    identifier="ConfirmModal"
    [title]="modalText.title"
    showCloseButton="true"
    [disableConfirmButton]="disableConfirmButton"
    [closeButtonText]="modalText.closeButtonText"
    [confirmButtonText]="modalText.confirmButtonText">
  <!-- Confirm message content -->
  <div [innerHTML]="modalText.content"></div>

  <div>
    <lib-error-alert-message
        [errorMsg]="errorMsg">
    </lib-error-alert-message>
  </div>

</ngx-smart-modal>
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StringUtilities } from '../utilities/string-utilities';
import _ from 'lodash';

/**
 * Intercepts HTTP calls to add 'Content-Type' And 'Accept' Headers for POST/PUT requests
 */
@Injectable()
export class AddContentTypeInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!StringUtilities.isBlank(req.body) && StringUtilities.IsJsonString(req.body)) {
            if (!req.headers.has('Content-Type')) {
                req = req.clone({ headers: req.headers.set('Content-Type', 'application/json'), withCredentials: true });
            }
            return next.handle(req);
        }
        return next.handle(req);
    }
}




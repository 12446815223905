import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { BasespaceService, V2CommentList, V2Comment } from '@bssh/ng-sdk';
import { ICommentsModalInput } from '../model/action-modal';
import { ResourceType } from '../../../core/model/resource-type';
import { Observable, EMPTY } from 'rxjs';

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent extends BaseModalComponent implements OnInit {
  public modalType = 'CommentsModal';
  public resourceId = null;
  public resourceType = null;
  public comments: Array<V2Comment> = [];

  constructor(public ngxSmartModalService: NgxSmartModalService,
    private basespaceApi: BasespaceService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subs.sink = this.data.subscribe((input: ICommentsModalInput) => {
      if (!input || !input.id || !input.resourceType) {
        return;
      }

      this.resourceId = input.id;
      this.resourceType = input.resourceType;

      this.subs.sink = this.fetchComments().subscribe({
        next: (response: V2CommentList) => {
          this.comments = response.Items;
        },
        error: error => {
          this.error.next(error.error.Message);
        }
      });
    });
  }

  private fetchComments(): Observable<V2CommentList> {
    switch (this.resourceType) {
      case ResourceType.DATASET:
        return this.fetchDatasetComments();
      case ResourceType.ANALYSIS:
        return this.fetchAnalysisComments();

      default:
        return EMPTY;
    }
  }

  private fetchAnalysisComments(): Observable<V2CommentList> {
    const params: BasespaceService.GetV2AppsessionsIdCommentsParams = {
      id: this.resourceId
    }

    return this.basespaceApi.GetV2AppsessionsIdComments(params);
  }

  private fetchDatasetComments(): Observable<V2CommentList> {
    const params: BasespaceService.GetV2DatasetsIdCommentsParams = {
      id: this.resourceId,
      limit: 500
    };

    return this.basespaceApi.GetV2DatasetsIdComments(params);
  }

}

import { Injectable, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import marked from 'marked';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): any {
    if(value) {
      return this.sanitizer.sanitize(SecurityContext.HTML, marked(value, { pedantic: true }));
    } else {
      return null;
    }
  }
}

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { merge } from 'lodash';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';
import { IDeleteConfigModalInput } from '../model/action-modal';


@Component({
  selector: 'app-delete-config-modal',
  templateUrl: './delete-config-modal.component.html',
  styleUrls: ['./delete-config-modal.component.scss']
})
export class DeleteConfigModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {

  private modalText: IDeleteConfigModalInput = { configTitle: 'Configuration'};
  private isProceed = false;

  public modalType = 'DeleteConfigModal';
  public modalTitle = ModalTexts.DELETE_CONFIG.MODAL_TITLE;
  public closeButtonText = ModalTexts.DELETE_CONFIG.CLOSE_BUTTON_TEXT;
  public confirmButtonText = ModalTexts.DELETE_CONFIG.CONFIRM_BUTTON_TEXT;
  public get modalContent() {
    return `${this.modalText.configTitle} and all the related samples will be removed from this run.`;
  }

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    // Assign modal text content according to input
    this.subs.sink = this.data.subscribe((modalInput: IDeleteConfigModalInput) => {
      if (modalInput) {
        merge(this.modalText, modalInput);
      }
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  /**
   * To be called whenever the alert modal is closed.
   * Will emit the isProceed outcome to the consumer.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  close(modal: NgxSmartModalComponent) {
    this.confirm.emit(this.isProceed);
    this.confirm.complete();
  }

  /**
   * Override BaseModalComponent's accept(). Called when the confirm
   * button on modal is clicked to proceed with the action.
   * Sets isProceed to true to be emitted on close.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  accept(modal: NgxSmartModalComponent) {
    this.isProceed = true;
    modal.close();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

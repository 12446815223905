import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import environment from '../../../environments/environment';
import { AuthorizationService } from '../services/authorization/authorization.service';
import { Constants } from '../utilities/constants';
import { isNullOrUndefined } from 'util';
import { PageContextService } from '../services/page-context.service';
import { ErrorRedirectService } from '../services/error-redirect/error-redirect.service';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { SubSink } from 'subsink';
import { IApiHttpError, IBsOutageStatus, OutageType } from '../model/v2-api/http-error';

@Injectable()
export class BsApiUnauthorizedInterceptor implements HttpInterceptor, OnDestroy {

    private subs = new SubSink();

    constructor(private authService: AuthorizationService, private router: Router, private pageContextService: PageContextService,
                private errorRedirectService: ErrorRedirectService, private currentUserStore: CurrentUserStore) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => event,  (err: any) => {
                // If there are errors in bs api
                if (_.startsWith(req.url, environment.apiUrl) && err instanceof HttpErrorResponse) {
                    // would it make sense to include 403 ?
                    // Some of the actions like workgroup switch result in 403,
                    // and user need not be logged out for that

                    // we don't want to navigate if the 403 is a workgroup context switch,
                    // because it is not a forbidden response for the current resource/page
                    // & we want to stay on the current page

                    let errorCode = err.error.ErrorCode;
                    if (err.error != null && err.error.ResponseStatus != null && err.error.ResponseStatus.ErrorCode) {
                        errorCode = err.error.ResponseStatus.ErrorCode;
                    }
                    const preventRedirect = this.errorRedirectService.shouldPreventErrorRedirect(req, err.status, errorCode);

                    if (preventRedirect) {
                        return;
                    } else if (err.status === 403 && err.error.ErrorCode !== Constants.inactiveSubscriptionErrorCode) {
                        // Only if 'RelatedUserWithAccess' was requested, and the api suggests that there is a related user.
                        if (req.urlWithParams.indexOf(Constants.RelatedUserWithAccessParam) > 0
                            && !isNullOrUndefined(err.error.AdditionalData)) {
                            const relatedUserIdWithAccess = err.error.AdditionalData[Constants.RelatedUserIdWithAccessField];
                            if (relatedUserIdWithAccess != null) {
                                this.navigateToSwitchWorkgroupPage(relatedUserIdWithAccess);
                                return;
                            }
                        }
                        // If there was no related user suggested by api, navigate to forbidden page as usual
                        this.navigateToAccessForbiddenPage(req.urlWithParams);
                    }

                    if (err.status === 410) {
                        this.navigateToPage(req.urlWithParams, Constants.RESOURCE_DELETED);
                    }

                    if (err.status === 404) {
                        const apisExcludedFor404Redirect = this.errorRedirectService.getExcludedApiPathsFor404Redirect();
                       if (!apisExcludedFor404Redirect.some(api => req.method === api.method && 
                            ((req.urlWithParams.includes(api.path) || (api.pathRegex != null ? api.pathRegex.test(req.urlWithParams) : false)))))
                        {
                            this.navigateToPageNotFound(req.urlWithParams);
                        }
                    }

                    if (err.status === 401) {
                        // Logout the user.
                        this.authService.logout();
                    }

                }
            })
        );
    }

    // TODO: move to authService
    private navigateToPage(attemptedRequest: string, pageRoute: string) {
        // not working with angular Location, thats why used window.
        const currentUrl = window.location.href;
        const newRoute = pageRoute + '?url=' + currentUrl;
        // pass object of type InterceptedRequest in state
        this.router.navigateByUrl(newRoute, { state: { attemptedRequest } });
    }

    private navigateToPageNotFound(attemptedRequest: string) {
        const currentUrl = window.location.href;
        const newRoute = Constants.NOT_FOUND_ROUTE + '?url=' + currentUrl;
        this.router.navigateByUrl(newRoute, { queryParams: { invalidId: true }, state: { attemptedRequest }, skipLocationChange: true });
    }

    // TODO: move to authService
    private navigateToAccessForbiddenPage(attemptedRequest: string) {
        // If we are already on the switch workgroup route, do not navigate.
        if (this.router.url.indexOf(Constants.SWITCH_WORKGROUP_ROUTE) > 0) {
            return;
        }

        const apiError: IApiHttpError = {
            httpErrorStatus: 403,
            data: {}
        };
        // New: Navigate to the http-error page silently, without changing the url in the address bar.
        // The route state will have info that the page can use to display content
        this.router.navigateByUrl(Constants.HTTP_ERROR_ROUTE, {
            skipLocationChange: true,
            state: {
                ApiHttpError: apiError
            }
        });
    }

    /**
     * navigates to 'switch workgroup' page with the route data added.
     * @param relatedUserIdWithAccess The related user which has access to the resource
     */
    private navigateToSwitchWorkgroupPage(relatedUserIdWithAccess: string) {
        const newRoute = Constants.SWITCH_WORKGROUP_ROUTE + '?returnUrl=' + this.router.url;

        // If the current context user id is already on the context which has access to the resource,
        // we need not redirect or show the Switch Workgroup modal.
        let relatedUserIdWithAccessClone = _.clone(relatedUserIdWithAccess); // do not modify param, so clone.
        this.subs.sink = this.currentUserStore.stateChanged.
            pipe(
                filter(state => state != null && state.currentUser != null),
                map(state => state.currentUser)
            ).subscribe({
                next: (currentUser) => {
                    if (relatedUserIdWithAccessClone != null && currentUser.Id !== relatedUserIdWithAccessClone) {
                        this.router.navigateByUrl(newRoute, {
                            state: {
                                RelatedUserIdWithAccess: relatedUserIdWithAccess,
                                ResourceType: this.pageContextService.getCurrentContextResourceType()
                            }
                        });
                    }
                    relatedUserIdWithAccessClone = null;
                }
            });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}

<div class="edit-info">
    <div class="edit-info__wrapper">
      <div class="edit-info__wrapper--inner">
        <div class="edit-info__container">
          <div class="edit-info__content">

            <div class="edit-info__edit-list" *ngIf="editableFields.length > 0">
              <div class="edit-info__result__item" *ngFor="let item of editableFields; let i = index">

                <div class="input-field" *ngIf="item.type === InputFieldType.INPUT">
                  <label for="{{item.name + i}}" class="input-field__label">
                    {{item.name}}
                  </label>
                  <input
                  class="input-field__text-input"
                  type="text"
                  [id]="item.name + i"
                  [(ngModel)]="item.currentValue"
                  [placeholder]="item.placeholder"
                  (input)="onChangeInputValues($event)"
                  [disabled]="item.disabled"
                />
                  <span class="input-field__helper--error" [id]="'For_mandatory' + i"
                    *ngIf="!item.isOptional && item.currentValue?.length === 0">
                    {{item.mandatoryFieldErrorMessage}}
                  </span>
                  <span class="input-field__helper--warning" [id]="'For_max_length' + i"
                    *ngIf="item.maxLength && item.currentValue?.length > item.maxLength">
                    {{item.maxLengthWarningMessage}}
                  </span>
                </div>

                <div class="input-field input-field--block" *ngIf="item.type === InputFieldType.TEXTAREA">
                  <label for="{{item.name + i}}" class="input-field__label">
                  {{ item.name }}
                  </label>
                  <textarea
                  class="input-field__textarea"
                  [id]="item.name + i"
                  [(ngModel)]="item.currentValue"
                  [placeholder]="item.placeholder"
                  (input)="onChangeInputValues($event)"
                ></textarea>
                <span class="input-field__helper--error" [id]="'For_mandatory' + i" 
                  *ngIf="!item.isOptional && item.currentValue?.length === 0">
                  {{ item.mandatoryFieldErrorMessage }}
                </span>
                <span class="input-field__helper--warning" [id]="'For_max_length' + i" 
                  *ngIf="item.maxLength && item.currentValue?.length > item.maxLength">
                  {{ item.maxLengthWarningMessage }}
                </span>
                </div>
              </div>
            </div>
            <div>
              <lib-error-alert-message 
                [errorMsg]="errorMsg">
              </lib-error-alert-message>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
  
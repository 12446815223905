<ngx-smart-modal #prepTabLibraryPrepKit [identifier]="modalType" [closable]="false" [dismissible]="false"
    [escapable]="false" [closeButtonText]="closeButtonText" [confirmButtonText]="confirmButtonText"
    [disableConfirmButton]="!lpkFormGroup.valid" [title]="title">
    <div class="error-message error-message-response" [hidden]="!errorMessage">
        <span>{{ errorMessage }}</span>
    </div>

    <form [formGroup]="lpkFormGroup" novalidate ng-submit="onCreateNewKitClick()" autocomplete="off">
        <div class="form-group">
            <h4 class="form-group-title">Name of your new kit</h4>
            <lib-form-field [label]="" [attr.for]="lpkName" hint="(alphanumeric, underscore allowed)" [errorMessage]="getLPKNameErrorMessage()" >
                <input lib-input type="text" formControlName="lpkName" placeholder="Type Library Prep Kit name here..." data-cy="lpkName">
            </lib-form-field>
        </div>

        <div class="form-group">
            <div class="d-flex">
                <h4 class="form-group-title">Supported Read Type</h4>
            </div>
            <!-- Read Type radio -->
            <lib-radio-checkbox-list type="checkbox" [options]="readTypeOptions" name="readType"
                formControlName="readType" valueProperty="value" labelProperty="text">
            </lib-radio-checkbox-list>
            <div class="error-message" *ngIf="readType.value.length == 0">Must select at least one.</div>
        </div>
        <div class="form-group">
            <div class="d-flex">
                <h4 class="form-group-title">Supporting Indexing Strategy</h4>
            </div>
            <!-- Read Type radio -->
            <lib-radio-checkbox-list type="checkbox" [options]="indexStrategies" name="indexStrategy"
                formControlName="indexStrategy" valueProperty="value" labelProperty="text">
            </lib-radio-checkbox-list>
            <div class="error-message" *ngIf="indexStrategy.value.length == 0">Must select at least one.</div>
            <div class="error-message" *ngIf="onlyNoIndexReadTypeSelected">Kit must support more indexing strategies
                than just 'None'.</div>
        </div>
        <div class="d-flex">
            <h4 class="form-group-title">Default Read Cycles</h4>
        </div>
        <div class="form-group">
            <!-- Read 1 -->
            <h4>Read 1 Cycles</h4>
            <input lib-input type="text" name="defaultReadCycle1" formControlName="defaultReadCycle1"
                data-cy="defaultReadCycle1">
            <div class="error-message"
                *ngIf="defaultReadCycle1.touched && defaultReadCycle1.errors && defaultReadCycle1.errors.required">Read
                1 Cycles is required.</div>
            <div class="error-message"
                *ngIf="defaultReadCycle1.touched && defaultReadCycle1.errors && (defaultReadCycle1.errors.pattern || defaultReadCycle1.errors.min || defaultReadCycle1.errors.max)">
                Read 1 Cycles must be {{ValidationRule.minReadLengths}} - {{ ValidationRule.maxReadLengths }}</div>
        </div>
        <div class="form-group">
            <!-- Read 2 -->
            <h4>Read 2 Cycles</h4>
            <input lib-input type="text" name="defaultReadCycle2" formControlName="defaultReadCycle2"
                data-cy="defaultReadCycle2">
            <div class="error-message"
                *ngIf="defaultReadCycle2.touched && defaultReadCycle2.errors && defaultReadCycle2.errors.required">Read
                2 Cycles is required.</div>
            <div class="error-message"
                *ngIf="defaultReadCycle2.touched && defaultReadCycle2.errors && (defaultReadCycle2.errors.pattern || defaultReadCycle2.errors.min || defaultReadCycle2.errors.max)">
                Read 2 Cycles must be {{ValidationRule.minReadLengths}} - {{ ValidationRule.maxReadLengths }}</div>
        </div>
        <div class="form-group import-csv-section">
            <div class="d-flex">
                <!-- Choose csv File -->
                <button lib-btn primary small type="button" class="import-csv-section__button" (click)="csvFileInput.click()">
                    Choose .csv File
                </button>
                Download this
                <a class="download-link" download href="./Resources/Import_LibraryPrep_Template-v1.csv">template</a>
            </div>
            <input #csvFileInput hidden type="file" accept=".csv" (change)="onCSVFileChange($event)"
                (click)="csvFileInput.value = null">
            <h4 class="form-group-title import-csv-section__file-name">{{selectedCSVFile}}</h4>
        </div>

    </form>
</ngx-smart-modal>

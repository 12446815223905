import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operatorToSymbol'
})
@Injectable({
  providedIn: 'root'
})
export class OperatorToSymbolPipe implements PipeTransform {

  transform(input: string): string {
    const operatorSymbols = {
      Equal: '=',
      LessThan: '<',
      GreaterThan: '>',
      LessThanOrEqual: '<=',
      GreaterThanOrEqual: '>=',
      NotEqual: '!=',
      Between: '[ ]',
      Outside: ') ('
  };
  return (operatorSymbols[input] || '');
  }

}

import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { SubscriptionPlan } from '@app/core/model/user/subscription-plan';
import { ISubscription } from '@app/core/model/subscription';
import { CodeFeaturesService } from './code-features.service';
import { BaseService } from '../base.service';
import { IUser } from '@app/core/model/user';
import { SubscriptionType } from '@app/run-planning/interface';

// Interface for the service.
export interface IUserSubscriptionService {
  isBasicUser(): boolean;
  isFreeTrialUser(): boolean;
  isNewUserWithoutFreeTrial(): boolean;
  hasCommercialSubscription(): boolean;
  hasExpiredCommercialSubscription(): boolean;
  isFreeTrialOrSubscriber(): boolean;
  isNewUserWithNoFreeTrialOrSubscription(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionService extends BaseService implements IUserSubscriptionService {

  private readonly COMMERCIAL_PLANS = [SubscriptionPlan.PROFESSIONAL,
                                       SubscriptionPlan.ENTERPRISE];

  private subscription: ISubscription;
  private currentUser: IUser;

  constructor(private userStore: CurrentUserStore, private codeFeaturesService: CodeFeaturesService) {
    super();
    this.init();
  }

  private init(): void {
    this.subs.sink = this.userStore.stateChanged.pipe(filter(state => state != null && state.currentUser != null))
    .subscribe({
      next: state => {
        this.subscription = state.currentUser.Subscription;
        this.currentUser = state.currentUser;
      }
    });
  }

  private isActiveSubscription(): boolean {
    return this.subscription && this.subscription.IsActive;
  }

  public isBasicUser(): boolean {
    return !this.isActiveSubscription() &&
           this.codeFeaturesService.freeTrialIsEnabled;
  }

  public isFreeTrialUser(): boolean {
    return this.isActiveSubscription() &&
           this.subscription.Plan === SubscriptionPlan.FREE_TRIAL &&
           this.codeFeaturesService.freeTrialIsEnabled;
  }

  // TODO: How do we know this? Checking for inactive subscription is sufficient?
  // The implementation for this in the old code is exaclty same as isFreeTrialUser
  // https://git.illumina.com/BaseSpace/basespace/blob/5712d5c40188f1a071add5374afd86fa5c00fc22/src/Tang.Modules/User/subscriptionPlanService.ts#L26
  public isNewUserWithoutFreeTrial(): boolean {
    return this.subscription && !this.subscription.IsActive &&
           this.subscription.Plan === SubscriptionPlan.FREE_TRIAL &&
           this.codeFeaturesService.freeTrialIsEnabled;
  }

  public hasCommercialSubscription(): boolean {
    return this.isActiveSubscription() &&
           this.codeFeaturesService.freeTrialIsEnabled &&
           this.COMMERCIAL_PLANS.includes(this.subscription.Plan);
  }

  public hasExpiredCommercialSubscription(): boolean {
    return this.subscription && !this.subscription.IsActive &&
           this.codeFeaturesService.freeTrialIsEnabled &&
           this.COMMERCIAL_PLANS.includes(this.subscription.Plan);
  }

  public isFreeTrialOrSubscriber(): boolean {
    return this.isFreeTrialUser() || this.hasCommercialSubscription();
  }

  public isNewUserWithNoFreeTrialOrSubscription(): boolean {
    return this.codeFeaturesService.freeTrialIsEnabled &&
         this.codeFeaturesService.freeTrialTemporarilyDisabled &&
         this.subscription && this.subscription.Plan === SubscriptionPlan.FREE_TRIAL &&
         !this.subscription.IsActive && !this.subscription.StartDate;
  }

  public hasActiveIcaSubscription(): boolean {
    return this.isActiveSubscription() && this.subscription.HasActiveIcaSubscription;
  }
  public isWorkgroup(): boolean {
    return this.currentUser.IsWorkgroup;
  }
}

import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Navigation } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Constants } from '@app/core/utilities/constants';
import { Location } from '@angular/common';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NgxSmartModalService, NgxSmartModalComponent } from '@bssh/comp-lib';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { ResourceType } from '@app/core/model/resource-type';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-switch-workgroup-modal',
  templateUrl: './switch-workgroup-modal.component.html',
  styleUrls: ['./switch-workgroup-modal.component.scss']
})
export class SwitchWorkgroupModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {

  public modalType = 'switchWorkgroupModal';
  public modalTitle = '';
  public confirmButtonText = 'Switch Workgroup';
  public showCloseButton = true;
  public showConfirmButton = true;
  public invalidRouteData = false;
  public resourceDisplayNameSingular: string
  private switchWorkgroupOnClose: boolean = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public ngxSmartModalService: NgxSmartModalService,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private currentUserStore: CurrentUserStore
  ) {
    super(ngxSmartModalService);
    this.modalType = 'switchWorkgroupModal';
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    const location = this.location.getState();
    let userId = null;
    if (location) {
      userId = this.location.getState()[Constants.RelatedUserIdWithAccessField];
    }
    const returnUrl: string = this.route.snapshot.queryParams.returnUrl;
    if (!isNullOrUndefined(userId) &&
      !isNullOrUndefined(this.route.snapshot.queryParams.returnUrl)) {
      const resourceTypeParam = this.location.getState()[Constants.ResourceTypeParam];

      switch(resourceTypeParam) {
        case ResourceType.RUN:
        case ResourceType.RUNS:
          this.resourceDisplayNameSingular = "run";
          break;
        case ResourceType.PROJECT:
        case ResourceType.PROJECTS:
          this.resourceDisplayNameSingular = "project";
          break;
        case ResourceType.ANALYSIS:
            this.resourceDisplayNameSingular = "analysis";
            break;
        case ResourceType.BIO_SAMPLE:
        case ResourceType.BIO_SAMPLES:
          this.resourceDisplayNameSingular = "biosample";
          break;
        default:
          throw new Error(`switch workgroup modal not implemented for resource type param: ${resourceTypeParam}`);
      }
      this.modalTitle = `Switch workgroup to view ${this.resourceDisplayNameSingular}?`;

    } else {
      // Either the route state was not set correctly, or the return url was blank
      // Probably as a result of direct access. Usually this route is invoked by the unauthorized interceptor.
      this.invalidRouteData = true;
      this.showCloseButton = false;
      this.showConfirmButton = false;
      this.modalTitle = 'Your request could not be processed.';
    }
    // Open the modal.
    this.ngxSmartModalService.setModalData({ userId }, this.modalType);
    this.ngxSmartModalService.open(this.modalType);
    this.cdr.detectChanges();

    // On confirm subscription
    this.subs.sink = this.ngxSmartModalService.getModal(this.modalType)
      .onConfirm.pipe(
        switchMap((modal: NgxSmartModalComponent) => {
          this.switchWorkgroupOnClose = true;
          return this.onSwitchWorkgroupClick(modal.getData().userId, returnUrl);
        })
      ).subscribe(() => {
        this.ngxSmartModalService.closeLatestModal();
      });

    this.subs.sink = this.ngxSmartModalService.getModal(this.modalType)
      .onClose.subscribe((modal: NgxSmartModalComponent) => {
        if(!this.switchWorkgroupOnClose) {
          this.router.navigate(['dashboard']);
        }
      });
  }

  /**
   * Switches user context to the specified user and navigates to the new route.
   * @param newUserId The new user id to switch to
   * @param returnUrl The Url which was originally requested
   */
  private onSwitchWorkgroupClick(newUserId: string, returnUrl: string): Observable<any> {
    this.currentUserStore.changeUserContext(newUserId);
    // When a successful context switch occurs, navigate to the originally requested url.
    return this.currentUserStore.contextChanged$.pipe(
      tap(() => {
        this.router.navigateByUrl(returnUrl);
      })
    );

  }

}

import { Component, OnInit, Input, Output, AfterViewInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent, IFileListTypes, IRunData } from '@bssh/comp-lib';
import { TrashableRunStatuses, NON_SUCCESS_RUN_STATES } from '../../../core/model/runs/run-status';
import { ErrorMessages } from '../../../core/utilities/error-messages';
import { ResourceType } from '../../../core/model/resource-type';
import { BehaviorSubject } from 'rxjs';
import { DeleteOption } from '../../../core/model/delete-options';
import { ITrashModalInput, ITrashModalOutput } from '../model/action-modal';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';
import { isNullOrUndefined } from 'util';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-trash-modal',
  templateUrl: './trash-modal.component.html',
  styleUrls: ['./trash-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrashModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  @Input() data = new BehaviorSubject<ITrashModalInput>(undefined);
  modalType = 'TrashModal';
  confirmButtonText = ModalTexts.TRASH.CONFIRM_BUTTON_TEXT;

  public resourceType: ResourceType = null; // type of the resource to trash
  public trashAlertMessage = [];
  public availableAppSessions: any[] = [];
  public infoMessage: boolean = null;
  public msg: string = null;

  // Contains header field values for different section of modal
  public headers = {
    modalHeader: '',
    resourceTitleHeader: '',
    resourcePropertyHeader: '',
    deleteOptionHeader: '',
  };

  public itemsToTrash: IItemToTrash[] = [];
  public deleteOptionTypes: IFileListTypes[] = [];
  public StatusError = false; // to check if the any run passed to the modal have status that is not trashable
  public isAppSessionsAvailable = false; // to check the availability of appSessions in case of runs (will enable the 3rd option if is true)
  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.sink = this.data.subscribe((input: ITrashModalInput) => {
      if (!input) {
        return;
      }
      this.resourceType = input.resourceType;
      this.isAppSessionsAvailable = input.availableAppSessions ? input.availableAppSessions.length > 0 : false;
      this.availableAppSessions = input.availableAppSessions;
      this.disableConfirmButton = true; // initially action will be disabled, will get enabled only after one of the options is selected
      this.infoMessage = input.infoMessage;
      this.itemsToTrash = input.itemToTrash;
      this.StatusError = !isEmpty(input.errorMsg);
      this.trashAlertMessage = input.alertMessages;

      if (this.StatusError) {
        this.error.next(input.errorMsg);
      }
      this.initModalProperties();
    });
  }

  private initModalProperties() {
    switch (this.resourceType) {
      case ResourceType.RUN:
      case ResourceType.RUNS:
        this.initTrashRuns();
        break;
      case ResourceType.PROJECT:
      case ResourceType.PROJECTS:
        this.initTrashProject();
        break;
      case ResourceType.DATASET:
      case ResourceType.DATASETS:
        this.initTrashDataSets();
        break;
      case ResourceType.ANALYSIS:
        this.initTrashAnalyses();
        break;
      case ResourceType.SAMPLE:
      case ResourceType.SAMPLES:
        this.initTrashSamples();
        break;
      default:
        break;
    }
  }

  private initTrashProject() {
    this.headers.modalHeader = `Move Project to Trash`;

    if (!this.itemsToTrash || this.itemsToTrash.length === 0 || this.StatusError) {
      return;
    }

    this.headers.resourceTitleHeader = 'Project Name';
    this.headers.resourcePropertyHeader = 'Size';
    this.msg = this.msg = ModalTexts.TRASH.ALERT_MESSAGE;

    this.disableConfirmButton = false;
  }

  private initTrashAnalyses() {
    this.headers.resourceTitleHeader = 'Analysis Name';
    this.headers.resourceTitleHeader = 'Status';
    this.headers.modalHeader = this.itemsToTrash.length > 1 ? `Move Analyses to Trash` : `Move Analysis to Trash`;

    if (!this.itemsToTrash || this.itemsToTrash.length === 0 || this.StatusError) {
      return;
    }

    this.msg = ModalTexts.TRASH.ALERT_MESSAGE;
    this.disableConfirmButton = false;
  }

  private initTrashSamples() {
    this.headers.resourceTitleHeader = 'Sample Name';
    this.headers.resourcePropertyHeader = 'Size';
    this.headers.modalHeader = this.itemsToTrash.length > 1 ? `Move Samples to Trash` : `Move Sample to Trash`;

    if (!this.itemsToTrash || this.itemsToTrash.length === 0 || this.StatusError) {
      return;
    }

    this.msg = ModalTexts.TRASH.ALERT_MESSAGE;
    this.disableConfirmButton = false;
  }

  private initTrashDataSets() {
    this.headers.resourceTitleHeader = 'DATASET NAME';
    this.headers.resourcePropertyHeader = 'Size';
    this.headers.modalHeader = this.itemsToTrash.length > 1 ? `Move Datasets to Trash` : `Move Dataset to Trash`;

    if (!this.itemsToTrash || this.itemsToTrash.length === 0 || this.StatusError) {
      return;
    }

    // initialise the file type selections if itemsToTrash are initialised
    if (this.itemsToTrash.length > 0) {
      this.headers.deleteOptionHeader = ModalTexts.TRASH.DELETE_OPTION_HEADER;
      this.deleteOptionTypes = this.getDataSetDeleteOptions();
    }
  }

  private initTrashRuns() {
    this.headers.resourceTitleHeader = 'Run Name';
    this.headers.resourcePropertyHeader = 'Status';
    this.headers.modalHeader = `Move Runs to Trash`;

    if (!this.itemsToTrash || this.itemsToTrash.length === 0 || this.StatusError) {
      return;
    }

    // initialise the file type selections if itemsToTrash are initialised
    if (this.itemsToTrash.length > 0) {
      this.headers.deleteOptionHeader = ModalTexts.TRASH.RUN_DELETE_OPTION_HEADER;

      this.itemsToTrash.forEach(itemToTrash => {
        itemToTrash.valueClass = NON_SUCCESS_RUN_STATES.includes(itemToTrash.value) ? 'download-data__result__value--bold' : null;
      });

      // TODO: check for enabling the option of 'all run related files' if appSessions is available for atleast one run
      // and update 'isAppSessionsAvailable' parameter
      // disabling that option by default
      this.deleteOptionTypes = this.getRunDeleteOptions();
      this.deleteOptionTypes[2].disabled = !this.isAppSessionsAvailable;
    }
  }

  private getDataSetDeleteOptions() {
    const deleteOptionTypes = [
      {
        label: ModalTexts.TRASH.DATA_FILES_ONLY.
          concat(` (This moves dataset files to reduce your data \n storage but \r\n keeps record\ of the dataset.)`),
        value: DeleteOption.PRESERVE_METADATA.toString(),
        type: 'radio',
        checked: false,
        disabled: false
      },
      {
        label: ModalTexts.TRASH.ALL_RELATED_FILES.
          concat(' (This moves everything associated with a dataset including record of it.)'),
        value: DeleteOption.ALL_FILES.toString(),
        type: 'radio',
        checked: false,
        disabled: false
      }
    ];
    return deleteOptionTypes;
  }

  private getRunDeleteOptions() {
    const deleteOptionTypes = [
      {
        label: ModalTexts.TRASH.DATA_FILES_ONLY.concat(' (Keep run record)'),
        value: DeleteOption.PRESERVE_METADATA.toString(),
        type: 'radio',
        checked: false,
        disabled: false
      },
      {
        label: ModalTexts.TRASH.DATA_FILES_AND_RUN_RECORD,
        value: DeleteOption.RUN_COMPLETE.toString(),
        type: 'radio',
        checked: false,
        disabled: false
      },
      {
        label: ModalTexts.TRASH.ALL_RUN_RELATED_FILES,
        value: DeleteOption.ASSOC_APPSESSIONS.toString(),
        type: 'radio',
        checked: false,
        disabled: false
      }
    ];

    return deleteOptionTypes;
  }

  dataChanged($event) {
    const deleteOptionTypes = $event.fileTypes;

    // only one option can be selected for any resource type at a time
    // filter the selected option
    const selectedOption = deleteOptionTypes.filter((option) => {// returns an array with selected option
      if (option.checked) {
        return option.value;
      } else {
        return;
      }
    });

    const modalData: ITrashModalOutput = {
      selectedDeleteOption: null
    };

    // extract the selected value from selectedOption array and set in modal data
    if (selectedOption.length === 1) {
      modalData.selectedDeleteOption = selectedOption[0].value;
    }

    if (this.modal) {
      this.modal.setData(modalData, true);
      if (this.disableConfirmButton) {
        this.disableConfirmButton = false;
      }
    }
  }

  private updateViewOnError(error: any) {
    this.headers.deleteOptionHeader = null;
    this.deleteOptionTypes = null;

    // TODO: need to add a check if the error is runStateError
    error.forEach(element => {
      const index = this.itemsToTrash.findIndex((item) => element.data.Id === item.id);
      if (index >= 0) {
        this.itemsToTrash[index].status = 'failed';
      }
    });

  }

  private removeDuplicateErrors(messages: string[]): string[] {
    let uniqueMessages = null;

    if (messages.length > 0) {
      uniqueMessages = messages.filter((msg, idx) => messages.indexOf(msg) === idx);
    } else {
      uniqueMessages = [ModalTexts.TRASH.ERROR];
    }
    return uniqueMessages;
  }

  private constructErrorMessages(errors: any): string[] {
    const messages = [];

    errors.forEach(e => {
      const itemToTrash = this.itemsToTrash.find(item => item.id === e.data.Id);
      const name = itemToTrash ? itemToTrash.name : null;

      // if item to trash is not run, then check if the app session option if selected has errors
      if (!isNullOrUndefined(name)) {
        messages.push(name + ': ' + e.errorMessage);
      } else if (this.modal && this.modal.getData() && this.modal.getData().selectedDeleteOption === DeleteOption.ASSOC_APPSESSIONS) {

        const appSessionToTrash = this.availableAppSessions.find(item => item.Id === e.data.Id);
        const appSessionName = appSessionToTrash ? appSessionToTrash.Name : null;

        if (!isNullOrUndefined(appSessionName)) {
          messages.push(appSessionName + ': ' + e.errorMessage);
        }
      }
    });

    return this.removeDuplicateErrors(messages);
  }

  protected constructError(errors: any): string[] {
    const messages = this.constructErrorMessages(errors);
    this.updateViewOnError(errors);

    return messages;
  }
}

export interface IItemToTrash extends IRunData {
  id: string;
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { IDeleteCustomKitModalInput } from '../model/action-modal';

@Component({
  selector: 'app-delete-prep-tab-library-prep-kit-modal',
  templateUrl: './delete-kit-modal.component.html',
  styleUrls: ['./delete-kit-modal.component.scss']
})
export class DeleteKitModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  public modalType = 'DeleteKitModal';
  public confirmButtonText:string;
  public modalTitle:string;
  public warningMessage:string;
  public closeButtonText:string;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.sink = this.data.subscribe((input: IDeleteCustomKitModalInput) => {
      if (!input) {
        return;
      }
      this.confirmButtonText = input.modalText.CONFIRM_BUTTON_TEXT;
      this.modalTitle = input.modalText.MODAL_TITLE;
      this.warningMessage = input.modalText.ALERT_MESSAGE;
      this.closeButtonText = input.modalText.CLOSE_BUTTON_TEXT;
    });
  }

  ngAfterViewInit() {
    // calling the parent class method to open modal and to subscribe to confirm & close events
    super.ngAfterViewInit();
  }

  /**
   * Upon confirmation, delete kit and handle return result
   */
  accept() {
    this.confirm.emit();
  }
}

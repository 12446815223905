<ngx-smart-modal
    #alertModal
    class="alert-modal"
    identifier="alertModal"
    [closable]="modalText.closable"
    [title]="modalText.title"
    [showCloseButton]="showCloseButton"
    [showConfirmButton]="showConfirmButton"
    [closeButtonText]="modalText.closeButtonText"
    [confirmButtonText]="modalText.confirmButtonText">
  <!-- Alert message content -->
  <div [innerHTML]="modalText.content"></div>
</ngx-smart-modal>

/**
 * Represents the various streams in the user notifications.
 */
export enum UserNotificationStream {
    Activity = 'Activity',
    Storage = 'Storage',
    DataDeletion = 'DataDeletion',
    Subscription = 'Subscription',
    Cms = 'Cms',
    RestrictedActivity = 'RestrictedActivity'
}

export enum SubscriptionNotificationType {
    FreeTrial = 'FreeTrial',
}

/**
 * Represents the notification Info object returned by the API
 */
export interface IUserNotificationInfo {
    Type?: string;
    Stream?: string;
    HrefContent?: string;
    TitleHtml?: string;
    Name?: string;
    Description?: string;
    NotificationSeverity?: string;
    IsDismissable?: boolean;
    AppSessionElapsedTime?: number;
    PercentPf?: number;
    PercentGtQ30?: number;
    InviteType?: string;
    InviteToken?: string;
}

export interface IUserNotification {
    Id: string;
    Href?: string;
    DateCreated?: string;
    DateModified?: string;
    RunId?: string;
    AppSessionId?: string;
    InviteId?: string;
    NotificationInfo?: IUserNotificationInfo;
    AttentionRequired?: boolean;
}

export interface IUserNotificationList {
    userNotifications : IUserNotification[];
    offset?: number;
    limit?: number;
    sortby?: string;
    sortdir?: string;
}

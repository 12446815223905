import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, InputModule, RadioCheckboxModule, FormFieldsModule, RadioCheckboxListModule, ProgressModule, LibPopoverModule, SelectModule, NgxSmartModalModule } from '@bssh/comp-lib';
import { ReactiveFormsModule } from '@angular/forms';
import { ComboBoxModule } from '@app/cloud-run-prep/directives/combobox/combobox.module';
import { PrepTabLibraryPrepKitModalComponent } from './prep-tab-library-prep-kit-modal.component';

@NgModule({
  declarations: [PrepTabLibraryPrepKitModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    InputModule,
    RadioCheckboxModule, 
    ButtonModule,
    FormFieldsModule, 
    RadioCheckboxListModule, 
    ProgressModule, 
    LibPopoverModule,
    ReactiveFormsModule,
    ComboBoxModule,
    SelectModule,
    NgxSmartModalModule
  ],
  exports: [PrepTabLibraryPrepKitModalComponent]
})
export class PrepTabLibraryPrepKitModalModule { }

import { Injectable } from "@angular/core";
import { CurrentUserStore } from "@app/user/store/current-user/current-user.store";
import { CodeFeaturesService } from "./user/code-features.service";
import { filter } from "rxjs/operators";

/**
 * This service allows internal users to preview hidden A8 pages that aren't available for public view.
 * At the time of this writing we have a few hidden analyses details pages. We will use that as an example.
 * This service sets the state of the application in the beta preview mode until the page is refreshed.
 * 
 * 
 * Proxy Notes:
 *  - We do not need to enable the proxy routes as long as we stay inside the app like a true SPA.
 *  - More info: https://git.illumina.com/BaseSpace/illumina-bssh-ecs/blob/f7d3e7ee21be61ac1a60d4bae1e67054472ede11/environments/illumina-basespace-dev/us-east-1/bespin-bssh-cluster/taskdefinitions/shwebproxy.json#L40
 * 
 * 
 * Usage example:
 *  - In analysis-details-routing.module we set resolve: { betaPreview: BetaPreviewResolverService }
 *    on paths that we want to boot public users out of.
 * 
 *  - Ensure that at least one route is public inside the analysis details module. Which would be the
 *    files path.
 * 
 * - Navigate to the files path with ?activate-beta-preview=true: 
 *   https://local-basespace.illumina.com/analyses/3853850/files?activate-beta-preview=true
 * 
 * - This will set the state of the application to have beta preview enabled. Click on the "Summary"
 *   link. This should use Angular's route to load the new Summary page and not boot the user out.
 * 
 * 
 * To turn off this feature:
 *  - Do a full page refresh without the activate-beta-preview=true param. 
 */

@Injectable({
  providedIn: 'root'
})
export class BetaPreviewService {
  private betaPreviewEnabled = false;

  constructor() { }

  enableBetaPreviewIfUserHasAccess(isCodeFeatureEnabled: boolean) {
    if (this.canEnableBetaPreview(isCodeFeatureEnabled, window.location.search)) {
      this.enableBetaPreview();
    }
  }

  isBetaPreviewEnabled() {
    return this.betaPreviewEnabled;
  }

  private canEnableBetaPreview(isCodeFeatureEnabled: boolean, searchParams: string): boolean {
    if (!isCodeFeatureEnabled) {
      return false;
    }

    const queryParams = new URLSearchParams(searchParams);
    return queryParams.get('activate-beta-preview') === 'true';
  }

  private enableBetaPreview() {
    this.betaPreviewEnabled = true;
  }
}

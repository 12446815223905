import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, CanActivateChild } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { AuthorizationService } from '../services/authorization/authorization.service';
import { HttpUtilityService } from '../services/http-utility/http-utility.service';
import { map, catchError, take } from 'rxjs/operators';
import { StringUtilities } from '../utilities/string-utilities';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private authService: AuthorizationService) {

  }

  /**
   * Auth guard for 'eagerly' loaded routes
   * @param next route snap shot
   * @param state route state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.checkIfAuthorizedToLoad();
  }

  /**
   * Auth guard for child routes
   * @param route route snap shot
   * @param state route state
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.checkIfAuthorizedToLoad();
  }

  /**
   * Auth guard for 'lazily' loaded routes
   * @param route route
   */
  canLoad(route: Route): Observable<boolean> {
    return this.checkIfAuthorizedToLoad();
  }


  /**
   * Checks if the requested route/module can be loaded. Redirects to login page if not
   */
  private checkIfAuthorizedToLoad(): Observable<boolean> {
    // Currently relies on 'AuthorizationService.isAuthorized$' observable to make a decision
    // does not call to platform on each guard check
    return this.authService.isAuthorized$.pipe(
      take(1),
      map((isAuthorized) => {
        if (!isAuthorized) {

          this.authService.logon();
        } else {
          return isAuthorized;
        }

      }),
      catchError(error => {
        return of(false);
      })
    );
  }

}

import { Injectable } from "@angular/core";
import { TitleCasePipe } from "@angular/common";
import { isNullOrUndefined } from 'util';

@Injectable({
    providedIn: 'root'
})
export class StringHumanizer {

    constructor(private titleCasePipe: TitleCasePipe) { }

    /**
     * Splits the input string at the upper case letters and joins them
     * with a space and returns a title cased string.
     * Eg : 1. 'Run FailedUpload' => 'Run Failed Upload'
     *      2. 'run failedUpload' => 'Run Failed Upload'
     * 
     * @param inputString 
     */
    humanizeString(inputString: string): string {
        if (isNullOrUndefined(inputString)) {
            return inputString;
        }
        
        var splits = inputString.split(/(?=[A-Z])/);
        splits.forEach(function (value, index, splits) {
            splits[index] = value.trim();
        });
        return this.titleCasePipe.transform(splits.join(' '));
    }

}
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { isArray } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { IAlertModalOutput, IDynamicAlertModalInput } from '../model/action-modal';

@Component({
  selector: 'app-dynamic-alert-modal',
  templateUrl: './dynamic-alert-modal.component.html',
  styleUrls: ['./dynamic-alert-modal.component.scss'],
})
export class DynamicAlertModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {
  modalType = 'dynamicAlertModal';
  private _isProceed = false;
  private _modalInput$ = new BehaviorSubject<IDynamicAlertModalInput>({
    title: 'Warning',
    content: 'There was an issue.',
    confirmButtonText: 'Proceed',
    closable: true,
  });
  modalInput$ = this._modalInput$.asObservable();

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    this.subs.sink = this.data.subscribe((newModalInput: IDynamicAlertModalInput) => {
      this._modalInput$.next({...this._modalInput$.getValue(), ...newModalInput});
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    const CtrlAPressed = event.ctrlKey && (event.key.toUpperCase() === 'A');
    const CmdAPressed = event.metaKey && (event.key.toUpperCase() === 'A');
    if (CtrlAPressed || CmdAPressed) {
      event.preventDefault();
      this.selectModalContent();
    }
  }

  private selectModalContent() {
    const myDiv = document.querySelector('#modalContent') as HTMLDivElement;
      if (myDiv) {
        const range = document.createRange();
        range.selectNodeContents(myDiv);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      }
  }

  /**
   * To be called whenever the alert modal is closed.
   * Will emit the isProceed outcome to the consumer.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  close(modal: NgxSmartModalComponent) {
    const modalOutput: IAlertModalOutput = { isProceed: this._isProceed };
    this.confirm.emit(modalOutput);
    this.confirm.complete();
    super.close(modal);
  }

  /**
   * Override BaseModalComponent's accept(). Called when the confirm
   * button on modal is clicked to proceed with the action.
   * Sets isProceed to true to be emitted on close.
   * @param modal modal instance emitted as $event from eventEmitter
   */
  accept(modal: NgxSmartModalComponent) {
    this._isProceed = true;
    modal.close();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  isContentAnArray = isArray;

  isLineAListItem(line: string): boolean {
    return line.startsWith('- ');
  }

  trimListItemPrefix(line: string): string {
    // Only called if `isLineAListItem` returns true, guarantees to replace only prefixing '- ', and not within the string.
    return line.replace('- ', '');
  }
}

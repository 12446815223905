import { Injectable } from '@angular/core';
import { IRun } from '@app/core/model/runs/run';
import { StratusApiService } from '../../stratus-api/stratus-api.service';
import { ToastrService } from '@bssh/comp-lib';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { ErrorResponse } from '@stratus/gss-ng-sdk';
import { map, catchError, concatMap, reduce, flatMap } from 'rxjs/operators';

export interface ITrashResult {
  isTrashSuccessful: boolean;
  numTrashSuccess: number;
  numTrashFailed: number;
}

export interface IPlannedRunsTrashService {
  trashRuns(runs: IRun[]): Observable<ITrashResult>;
}

@Injectable({
  providedIn: 'root'
})
export class PlannedRunsTrashService implements IPlannedRunsTrashService {
  private readonly initialTrashOperationResult: ITrashResult = {
    isTrashSuccessful: true,
    numTrashFailed: 0,
    numTrashSuccess: 0
  };

  constructor(
    private stratusApiService: StratusApiService,
    private toastrService: ToastrService
  ) { }

  /**
   * Handles a successful trash operation by returning a trashResult to be
   * reduced into a batch operation summary result.
   */
  private handleTrashSuccess(): Observable<ITrashResult> {
    return of({
      isTrashSuccessful: true,
      numTrashSuccess: 1,
      numTrashFailed: 0
    });
  }

  /**
   * Handles the error caught from GSS disableSequencingRun api. Returns the current
   * trash operation result.
   * @param errorResponse GSS error response object
   */
  private handleTrashError(errorResponse: HttpErrorResponse): Observable<ITrashResult> {
    const gssErrorResponse: ErrorResponse = errorResponse.error;
    this.toastrService.error(gssErrorResponse.message, 'Failed to delete run', {timeOut: 6000});
    return of({
      isTrashSuccessful: false,
      numTrashSuccess: 0,
      numTrashFailed: 1
    });
  }

  /**
   * Calls the GSS api to soft-delete (disable) a single run. Also transforms the api response
   * into a boolean to indicate success/failure.
   * @param run single run object to trash
   */
  private processTrashOperation(run: IRun): Observable<ITrashResult> {
    return this.stratusApiService.disableSequencingRun(run.Id).pipe(
      // transform trash success to current TrashResult
      flatMap(successResponse => this.handleTrashSuccess()),
      // catch api deletion errors
      catchError(error => this.handleTrashError(error))
    );
  }

  /**
   * Reduce the current trash operation results into the accumulated trash results.
   * @param accTrashResult trash results so far
   * @param currentResult current trash operation result
   */
  private reduceTrashResults(accTrashResult: ITrashResult, currentResult: ITrashResult): ITrashResult {
    const isEveryTrashSuccessful = accTrashResult.isTrashSuccessful ? currentResult.isTrashSuccessful : false;
    return {
      isTrashSuccessful: isEveryTrashSuccessful,
      numTrashSuccess: accTrashResult.numTrashSuccess + currentResult.numTrashSuccess,
      numTrashFailed: accTrashResult.numTrashFailed + currentResult.numTrashFailed
    };
  }

  /**
   * Trash (soft-delete) planned runs. Performs batch trash operation
   * by calling the GSS api to trash one run at a time, and handles any
   * error thrown by the api.
   * Emits isEveryTrashSuccessful, which is false when one or more
   * trash operation failed.
   * @param runs list of runs to be trashed
   */
  trashRuns(runs: IRun[]): Observable<ITrashResult> {
    // Proceed to delete selected runs (api only allow one deletion at a time)
    return from(runs).pipe(
      // attempt to trash each run and emit the outcome as trashResult
      concatMap(run => {
        return this.processTrashOperation(run);
      }),
      // update accumulative trash result on each operation and emit result when done
      reduce((accTrashResult, currentTrashResult) => {
        return this.reduceTrashResults(accTrashResult, currentTrashResult);
      }, this.initialTrashOperationResult)
    );
  }

}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchPathResolverService implements Resolve<SearchRoute | null>  {
constructor(private router: Router) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SearchRoute> | null {
    if (state.url.match('/search')) {
      const adjustedPath = this.adjustSearchUrl(state.url);
      const initialData = this.router.getCurrentNavigation().extras.state;
      const searchPathData: SearchRoute = {
        newUrl: adjustedPath,
        initialState: initialData
      };
      return new Observable<SearchRoute>((observer: Subscriber<SearchRoute>) => {
        observer.next(searchPathData);
        observer.complete();
      });
    }
    return null;
  }

  private adjustSearchUrl(input: string) {
    return input.replace('?', '/?');
  }
}

export class SearchRoute {
  newUrl: string;
  initialState?: any;
}

<div #BiosampleUnlockModalDiv>
    <ngx-smart-modal #BiosampleUnlockModal
        [identifier]="modalType"
        [title]="modalTitle"
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButton" 
        [confirmButtonText]="confirmButtonText"
        [showCloseButton]="showCloseButton"
        [showConfirmButton]="showConfirmButton"
        [closable]="closable">

        <lib-download-data
            runName='Biosample Name'
            runSize='Biosample Status'
            [runData]='biosampleItems'
            [runError]="errorMsg"
            [runAlert]="alertMessage">
        </lib-download-data>

        
        <section class="unlock-biosamples-modal__progress-wrapper" *ngIf="isLoadingApiData$ | async">
            <div>Unlocking biosamples...</div>
            <lib-progress 
                class="progress--project-importer"
                #apiDataSpinnerLoader
                [id]="apiDataSpinnerLoader"
                [loader]="true" 
                [spinner]="false"
                [min]="1">
            </lib-progress>
        </section>
        
    </ngx-smart-modal>
</div>
import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { MomentWrapperService } from '../../services/moment-wrapper-service';

@Pipe({
    name: 'isoDateTime',
})
@Injectable({
    providedIn: 'root'
})
export class IsoDateTime implements PipeTransform {

    constructor(private momentWrapperService: MomentWrapperService) { }

    transform( dateTimeStr: string, format?: string): any {
        
        //updated regex to make date work on different browsers (BASE-70657: safari and firefox)
        const datePipeStr = new Date(Date.parse(dateTimeStr.replace(/\s+/g, 'T')));
        if (!typeof (datePipeStr)) { return undefined; } 

        const momentWithLocale = this.momentWrapperService.getMomentWithLocale();
        // api times stored in UTC, use moment utc
        const dateTime = momentWithLocale.utc(datePipeStr);
        const formatOptions = {
            L: 'HH:mm',
            LL: 'YYYY-MM-DD',
            LLL: 'YYYY-MM-DD HH:mm'
        };
       const defaultFormat = formatOptions.LL;

        // Check for format and check if it's a valid option or set it to the default format
       format = formatOptions.hasOwnProperty(format) ? formatOptions[format] : defaultFormat;
        // Display local date format if is China, else use ISO format
        // return this.momentWrapperService.isLocaleChina() ? dateTime.local().format("LL") : dateTime.local().format(format);
        return dateTime.local().format(format);
    }
}

// Template contents (in yaml)
const nonFixedLayoutPlateTemplateName = 'Non-fixed Layout Plate';
const nonFixedLayoutPlateTemplateContent =
`---
Name: "<Enter the name of the non-fixed-layout index adapter kit>"
DisplayName: "<Enter the preferred name to be displayed>"
Organization: "<Enter the organization>" #Admin right is required
AllowedIndexStrategies: #In a List format and the options are NoIndex, Single, Dual
  - "Dual" #Indentation is denoted by two spaces
  - "Single"
  - "NoIndex"
AdapterSequenceRead1: "<Enter the adapter sequence for Read 1>"
AdapterSequenceRead2: "<Enter the adapter sequence for Read 2>"
IndexSequences: #Enter index sequences for both Index1 and Index2
  i7Index1: #Index1
    D701: "ATTACTCG" #<IndexName>: <IndexSequence>
    D702: "TCCGGAGA"
    D703: "CGCTCATT"
  i5Index2: #Index2
    D501: "TATAGCCT" #<IndexName>: <IndexSequence>
    D502: "ATAGAGGC"
    D503: "CCTATCCT"
Settings:
  FixedLayout: false
  Multiplate: false
  DefaultIndexStrategy: "<Enter one of the AllowedIndexStrategies>"
`;

const fixedLayoutSinglePlateTemplateName = 'Fixed Layout - Single Plate';
const fixedLayoutSinglePlateTemplateContent =
`---
Name: "<Enter the name of the fixed layout - single plate index adapter kit>"
DisplayName: "<Enter the preferred name to be displayed>"
Organization: "<Enter the organization>" #Admin right is required
AllowedIndexStrategies: #In a List format and the options are NoIndex, Single, Dual
  - "Dual" #Indentation is denoted by two spaces
  - "Single"
  - "NoIndex"
AdapterSequenceRead1: "<Enter the adapter sequence for Read 1>"
AdapterSequenceRead2: "<Enter the adapter sequence for Read 2>"
IndexSequences: #Enter index sequences for both Index1 and Index2
  i7Index1: #Index1
    D701: "ATTACTCG" #<IndexName>: <IndexSequence>
    D702: "TCCGGAGA"
    D703: "CGCTCATT"
  i5Index2: #Index2
    D501: "TATAGCCT" #<IndexName>: <IndexSequence>
    D502: "ATAGAGGC"
    D503: "CCTATCCT"
Settings:
  FixedLayout: true
  Multiplate: false
  FixedIndexPositions: #Format: '- "WellPosition/Index1Name-Index2Name"'
    - "A01/D701-D501"
    - "B01/D702-D502"
  DefaultIndexStrategy: "<Enter one of the AllowedIndexStrategies>"
`;

const fixedLayoutMultiPlateTemplateName = 'Fixed Layout - Multi Plate';
const fixedLayoutMultiPlateTemplateContent =
`---
Name: "<Enter the name of the fixed layout - multiplate index adapter kit>"
DisplayName: "<Enter the preferred name to be displayed>"
Organization: "<Enter the organization>" #Admin right is required
AllowedIndexStrategies: #In a List format and the options are NoIndex, Single, Dual
  - "Dual" #Indentation is denoted by two spaces
  - "Single"
  - "NoIndex"
AdapterSequenceRead1: "<Enter the adapter sequence for Read 1>"
AdapterSequenceRead2: "<Enter the adapter sequence for Read 2>"
IndexSequences: #Enter index sequences for both Index1 and Index2
  i7Index1: #Index1
    D701: "ATTACTCG" #<IndexName>: <IndexSequence>
    D702: "TCCGGAGA"
    D703: "CGCTCATT"
  i5Index2: #Index2
    D501: "TATAGCCT" #<IndexName>: <IndexSequence>
    D502: "ATAGAGGC"
    D503: "CCTATCCT"
Settings:
  FixedLayout: true
  Multiplate: true
  FixedIndexPositions: #Format: '- "PlateName-WellPosition/Index1Name-Index2Name"'
    - "B-G05/D701-D501"
    - "C-G05/D702-D502"
  DefaultIndexStrategy: "<Enter one of the AllowedIndexStrategies>"
`;

export interface ITemplate {
    name: string;
    content: string;
}

// Template options
export const TEMPLATE_OPTIONS: ITemplate[] = [
    {name: nonFixedLayoutPlateTemplateName, content: nonFixedLayoutPlateTemplateContent},
    {name: fixedLayoutSinglePlateTemplateName, content: fixedLayoutSinglePlateTemplateContent},
    {name: fixedLayoutMultiPlateTemplateName, content: fixedLayoutMultiPlateTemplateContent}
];

export const iakTsvTemplate =
`<remove anything inside the chevrons prior to upload>

[IndexKit]
Name\t<Enter the name of the fixed layout - single plate index adapter kit (Required - Recommended to be PascalCase without spaces)>
DisplayName\t<Enter the preferred name to be displayed (Optional)>
Description\t<Enter the preferred description (Optional)>
IndexStrategy\t<Enter the preferred index strategy (Required - Options are: NoIndex, SingleOnly, DualOnly, NoAndSingle, NoAndDual, SingleAndDual, and All)>

[Resources]
Name\tType\tFormat\tValue
Adapter\tAdapter\tstring\t<Enter the adapter sequence for Read 1 if applicable, otherwise remove this line (Optional)>
AdapterRead2\tAdapterRead2\tstring\t<Enter the adapter sequence for Read 2 if applicable, otherwise remove this line (Optional)>
FixedLayout\tFixedLayout\tbool\ttrue
Multiplate\tMultiplate\tbool\tFALSE
A01\tFixedIndexPosition\tstring\tD701-D501
B01\tFixedIndexPosition\tstring\tD702-D502

[Indices]
Name\tSequence\tIndexReadNumber
D701\tATTACTCG\t1
D702\tTCCGGAGA\t1
D703\tCGCTCATT\t1

D501\tTATAGCCT\t2
D502\tATAGAGGC\t2
D503\tCCTATCCT\t2`;

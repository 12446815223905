<section class="layout__spotlight layout__spotlight--large-color">
    <div class="layout__spotlight__priority-info layout__spotlight--center">
        <div class="http-error-status__container">
            <div class="http-error-status__hero-text">{{errorMessage.heroMessage}}</div>
            <div class="http-error-status__status-text">{{errorMessage.status}}</div>
        </div>
    </div>
    <div class="layout__spotlight__dashboard-background"></div>
</section>

<section class="layout__page-container" #layoutContainer>
    <div class="layout__page-container__content">
        <div class="http-error-message">
            <div class="http-error-message__summary" [innerHTML]="errorMessage.summary">
            </div>
            <div class="http-error-message__detail" [innerHTML]="errorMessage.detail">
            </div>
        </div>
    </div>
</section>
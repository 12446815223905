import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferenceFileModalComponent } from "./reference-file-modal.component";
import {
  ButtonModule,
  FormFieldsModule,
  InputModule,
  LibPopoverModule,
  NgxSmartModalModule,
  ProgressModule,
  RadioCheckboxListModule,
  RadioCheckboxModule,
  SelectModule,
} from "@bssh/comp-lib";
import { ReactiveFormsModule } from "@angular/forms";
import { ComboBoxModule } from "@app/cloud-run-prep/directives/combobox/combobox.module";

@NgModule({
  declarations: [ReferenceFileModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    InputModule,
    RadioCheckboxModule,
    ButtonModule,
    FormFieldsModule,
    RadioCheckboxListModule,
    ProgressModule,
    LibPopoverModule,
    ReactiveFormsModule,
    ComboBoxModule,
    SelectModule,
    NgxSmartModalModule,
  ],
  exports: [ReferenceFileModalComponent],
})
export class ReferenceFileModalModule {
}

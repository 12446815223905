<ngx-smart-modal
    #trashPlannedRuns
    identifier="trashPlannedRuns"
    [closable]="true"
    [escapable]="false"
    [dismissible]="false"
    [disableConfirmButton]="false"
    [closeButtonText]="closeButtonText"
    [confirmButtonText]="confirmButtonText"
    [title]="title">

    <!-- Loading overlay and spinner -->
    <section #apiDataWrapperLoader
        class="wrapper"
        [ngClass]="{ active: (isLoadingApiData$ | async) }"
        *ngIf="(isLoadingApiData$ | async)"
        data-cy="progress-overlay-wrapper">
    </section>
    <lib-progress #apiDataSpinnerLoader
        class="spinner"
        [loader]="false"
        [fixed]="true"
        [spinner]="true"
        [spinnerPosition]="'center'"
        (started)="onStarted()"
        (completed)="onCompleted()"
        data-cy="trash-planned-runs-progress-overlay-spinner">
    </lib-progress>

    <!-- Scrollable list of selected-runs to be deleted -->
    <lib-download-data
        class="modal-runs-list"
        [runName]="runNameHeaderText"
        [runSize]="runStatusHeaderText"
        [runData]="selectedRuns"
        [runAlert]="infoText"
        [runError]="errorMsg">
    </lib-download-data>

</ngx-smart-modal>

import { IResourceStore } from './resource.store';
import { IResourceState } from './resource.state';
import { BaseObservableStore } from '../infrastructure/base-observable.store';
import { Observable } from 'rxjs';
import { Invitation } from '@app/core/model/v2-api/invitation';
import { ICollaborator } from '@app/core/model/collaborator';
import { DownloadUrl } from '@app/core/model/download-url';
import IResource from '@app/core/model/resource';
import { IApiResponseWrapper } from '@app/core/model/v2-api/v2-api-wrappers';
import { V2AppSessionCompactList } from '@bssh/ng-sdk';
import { FastqQcStatus } from '../../model/datasets/fastq-qc-status';
import { ResourceType } from '@app/core/model/resource-type';

export class AbstractResourceStore<T extends IResourceState> extends BaseObservableStore<T> implements IResourceStore<T> {

    canCopy(): boolean {
        return false;
    }

    copy(destinationResource: IResource, originResource?: IResource): void {
        throw new Error('Method not implemented.');
    }

    isResourceOwner(resource: IResource): boolean {
        const { currentUserId } = this.getState();
        if (resource && resource.UserOwnedBy && resource.UserOwnedBy.Id) {
            return resource.UserOwnedBy.Id === currentUserId;
        }

        return false;
    }

    edit(newName: string, newDescription?: string): void {
        throw new Error('Method not implemented.');
    }

    canEdit(): boolean {
        return false;
    }

    getAvailableAppSessions(): Observable<V2AppSessionCompactList> {
        throw new Error('Method not implemented.');
    }

    getResourceType(): ResourceType {
        throw new Error('Method not implemented.');
    }

    canTransferOwnership(): boolean {
        return false;
    }

    transferOwnership(email: string, comment?: string): void {
        throw new Error('Method not implemented.');
    }

    canCancelOwnershipTransfer(): boolean {
        return false;
    }

    getPendingTransfers(): Observable<Invitation[]> {
        throw new Error('Method not implemented.');
    }

    cancelInvitation(InvitationId: string): void {
        throw new Error('Method not implemented.');
    }

    canArchive(): boolean {
        return false;
    }

    archive(): void {
        throw new Error('Method not implemented.');
    }

    canTrash(): boolean {
        return false;
    }

    trash(deleteOption?: string): void {
        throw new Error('Method not implemented.');
    }

    sendInvitations(collaborators: ICollaborator[], includeAssociatedResources: boolean): void {
        throw new Error('Method not implemented.');
    }

    getCollaborators(): Observable<ICollaborator[]> {
        throw new Error('Method not implemented.');
    }

    canShare(): boolean {
        return false;
    }

    getShareLink(): Observable<string> {
        throw new Error('Method not implemented.');
    }

    activateLink(): Observable<string> {
        throw new Error('Method not implemented.');
    }

    deactivateLink(): Observable<string> {
        throw new Error('Method not implemented.');
    }

    canDownload(): boolean {
        return false;
    }

    getDownloadUri(): Observable<DownloadUrl> {
        throw new Error('Method not implemented.');
    }

    getDownloadInfo(runId: string): Observable<IApiResponseWrapper<IResource>> {
        throw new Error('Method not implemented.');
    }

    canRestore(): boolean {
        return false;
    }

    restore(): void {
        throw new Error('Method not implemented.');
    }

    getActiveList(): IResource[] {
        return null;
    }

    unlock(): void {
        return;
    }

    canUnlock(): boolean {
        return false;
    }

    canChangeFastqQc(): boolean {
        return false;
    }

    changeFastqQc(newStatus: FastqQcStatus, comments?: string): void {
        return;
    }

    canCancelAnalysis() {
        return false;
    }

    cancelAnalysis(): void {
        return;
    }
}

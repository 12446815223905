import { IAppSession } from '@app/core/model/appSessions/appSession';
import { IDataset } from '@app/core/model/datasets/dataset';
import { IProject } from '@app/core/model/projects/project';
import { IRun } from '@app/core/model/runs/run';
import { ISample } from '@app/core/model/samples/sample';
import { IAppResult } from '@app/core/services/importer/IAppResult';
import { ISearchResourceConfig } from '../services/resource-dictionary/search-resource-dictionary-types';
import { SearchResourceDictionaryService } from '../services/resource-dictionary/search-resource-dictionary.service';
import { SearchResourceType } from './search/search-resource-type';

export interface ITrash {
    Id: string;
    Name: string;
    Href: string;
    DeletedOn: string;
    TotalSize: number;
    DeletedAppSession: IAppSession;
    DeletedRun: IRun;
    DeletedProject: IProject;
    DeletedSample: ISample;
    DeletedAppResult: IAppResult;
    DeletedDataset: IDataset;
    IsVisibleInTrash: boolean;
    ChildCount: number;
    HrefPostRestoreFromTrash: string;
    Type: string;
    Description: string;
    Details: string;
    DetailsDescription: string;
    TrashStatus: string;
    CompletedChildEvents: number;
    Includes: string[];
}

export enum TrashStatus {
  Undefined = 'UNDEFINED',
  Pending = 'PENDING',
  InProgress = 'INPROGRESS',
  Complete = 'COMPLETE',
  Error = 'ERROR'
}

export class TrashItemUtilities {

    public static getTrashItemResourceName(
        trashItem: ITrash,
        resourceDictionary: SearchResourceDictionaryService
    ): string {
        let searchResourceType: SearchResourceType;

        if (trashItem.DeletedAppSession) {
            searchResourceType = SearchResourceType.AppSession;
        } else if (trashItem.DeletedRun) {
            searchResourceType = SearchResourceType.Run;
        } else if (trashItem.DeletedProject) {
            searchResourceType = SearchResourceType.Project;
        } else if (trashItem.DeletedSample) {
            searchResourceType = SearchResourceType.Sample;
        } else if (trashItem.DeletedAppResult) {
            searchResourceType = SearchResourceType.AppResult;
        } else if (trashItem.DeletedDataset) {
            searchResourceType = SearchResourceType.Dataset;
        } else {
            throw new Error('The item was restored, but the resource type is unknown.');
        }

        return resourceDictionary.getResourceConfigByType(searchResourceType).displayNameSingular;
    }

    static computeType(trashModel: ITrash): string {
        if(trashModel.DeletedRun && trashModel.DeletedRun.ExperimentName) {
            return 'Run';
        } else if(trashModel.DeletedAppSession && trashModel.DeletedAppSession.Name) {
            return 'Analysis';
        } else if(trashModel.DeletedProject && trashModel.DeletedProject.Name) {
            return 'Project';
        } else if(trashModel.DeletedDataset && trashModel.DeletedDataset.DatasetType.ConformsToIds) {
            if(trashModel.DeletedDataset.DatasetType.ConformsToIds.indexOf('common.fastq') !== -1) {
                return 'FASTQ Dataset';
            } else {
                return 'Dataset';
            }
        } else {
            return '--';
        }
    }

    static computeDetails(trashModel: ITrash): string {
        if(trashModel.Type === 'Run') {
            if(trashModel.Includes.indexOf('METADATA') === -1) {
                return 'Run files only';
            } else {
                return 'Run record and files';
            }
        } else if(trashModel.Type === 'Dataset' || trashModel.Type === 'FASTQ Dataset') {
            if(trashModel.Includes.indexOf('METADATA') === -1) {
                return 'Dataset files only';
            } else {
                return 'Dataset record and files';
            }
        } else {
            return '--';
        }
    }

    static getTrashStatusText(trashModel: ITrash): string {
      if(trashModel.TrashStatus === TrashStatus.Complete) {
          return `Complete`
      } else if(trashModel.TrashStatus === TrashStatus.Error) {
          return `Failed`
      } else if(trashModel.TrashStatus === TrashStatus.InProgress) {
        // Display % complete as a whole number
        let percentComplete = 0;
        if (trashModel.ChildCount !== 0) {
          // To avoid "NaN % Complete" from being displayed when ChildCount hasn't been determined yet,
          // we will only calculate percent complete once it is available
          percentComplete = Math.trunc((trashModel.CompletedChildEvents/trashModel.ChildCount) * 100);
        }

        return percentComplete + `% Complete`;
      } else {
        return '--'
      }
  }

}

import { Component, OnInit, AfterViewInit, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Validators, FormControl, FormGroup, AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import { INITIAL_INDEX_STRATEGY_OPTIONS } from '@app/cloud-run-prep/constants';
import {
  IndexAdapterKitsService,
  IndexAdapterKit,
  UpdateIndexAdapterKitRequest,
  IndexSequence,
  UpdateIndexAdapterKitByDefinitionRequest,
  IndexStrategy,
} from '@stratus/gss-ng-sdk';
import { Observable } from 'rxjs';
import { BaseModalComponent } from '@app/shared/modals/base-modal/base-modal.component';
import { IEditIndexAdapterKitModalInput } from '../../model/action-modal';
import { get, set, partition, isEmpty } from 'lodash';
import { CloudRunMapperService } from '@app/core/services/mapper/cloud-run-mapper.service';
import { SharedSpinnerService } from '@app/run-planning/services/shared-spinner/shared-spinner.service';
import { AceComponent, AceConfigInterface } from 'ngx-ace-wrapper';
import ace from 'brace';
import { SimpleOption } from '@app/run-planning/model/option';
import { REGEX_VALIDATIONS } from '@app/run-planning/constants';
import { NgxSmartModalComponent, NgxSmartModalService, ToastrService } from '@bssh/comp-lib';
import { CodeFeaturesService } from '@app/core/services/user/code-features.service';

@Component({
  selector: 'app-edit-index-adapter-kit-modal',
  templateUrl: './edit-index-adapter-kit-modal.component.html',
  styleUrls: ['./edit-index-adapter-kit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditIndexAdapterKitModalComponent
  extends BaseModalComponent
  implements OnInit, AfterViewInit
{
  @ViewChildren(AceComponent)
  aceEditors: AceComponent[];
  aceInstance: ace.Editor;
  config: AceConfigInterface = {
    mode: 'yaml',
    theme: 'chrome'
  };

  isUiEditor: boolean = true;
  public menuItems = [
    { label: 'UI editor', selected: true },
    { label: 'Text editor', selected: false  },
  ];

  public modalType = 'UpdateIndexAdapterKit';
  // Modal Text
  public title = 'Edit Index Adapter Kit';
  public closeButtonText = 'Cancel';
  public confirmButtonText = 'Confirm changes';

  // Error message
  public errorMessage: string;

  // Compatible IAK list
  public compatibleIAKs$: Observable<any>;

  // IAK Form validation rules
  public ValidationRule = {
    name: {
      pattern: REGEX_VALIDATIONS.freeText,
      maxLength: 255,
    },
    integer: {
      pattern: REGEX_VALIDATIONS.number,
    },
    adapterSequenceRead: {
      pattern: REGEX_VALIDATIONS.adapterSequenceRegex
    }
  };
  index1SequencesValue = '';
  index2SequencesValue = '';
  fixedIndexPositionsValue = '';
  rawIAKText = '';

  // Initial data
  public indexStrategyOptions = this.mapperService.mapReadTypes(
    INITIAL_INDEX_STRATEGY_OPTIONS
  );
  // Text to be shown in dropdown
  public selectedIAKText = 'Select';
  // If paired read type is selected
  public pairedReadTypeSelected = true;
  // Whether to display tab group for switching between UI and Text editor
  public showTabGroup = false;

  private indexAdapterKitId: string;

  constructor(
    private mapperService: CloudRunMapperService,
    private indexAdapterKitService: IndexAdapterKitsService,
    private spinnerService: SharedSpinnerService,
    public ngxSmartModalService: NgxSmartModalService,
    private codefeatureService: CodeFeaturesService,
    private toastr: ToastrService
  ) {
    super(ngxSmartModalService);
  }

  overrideCyclesValidator: ValidatorFn = (control) => {
    if (!control.value) {
      return;
    }
    const overrideCycles = control.value.split(';');
    if (overrideCycles.length !== 4) {
      return { format: true };
    }
  }

  defaultIndexStrategyValidator: ValidatorFn = (control) => {
    if (this.allowedIndexStrategies && this.allowedIndexStrategies.valid) {
      if (!this.allowedIndexStrategies.value.includes(control.value)) {
        return { nonAllowedChoice: true };
      }
    }
    return; 
  }

  public iakFormGroup: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.pattern(this.ValidationRule.name.pattern),
      Validators.maxLength(this.ValidationRule.name.maxLength),
    ]),
    displayName: new FormControl(''),
    description: new FormControl(''),
    allowedIndexStrategies: new FormControl(INITIAL_INDEX_STRATEGY_OPTIONS, [
      Validators.required,
    ]),
    adapterSequenceRead1: new FormControl('', [Validators.pattern(this.ValidationRule.adapterSequenceRead.pattern)]),
    adapterSequenceRead2: new FormControl('', [Validators.pattern(this.ValidationRule.adapterSequenceRead.pattern)]),
    defaultIndexStrategy: new FormControl(undefined, [Validators.required, this.defaultIndexStrategyValidator]),
    numCyclesIndex1Override: new FormControl(undefined, [Validators.pattern(this.ValidationRule.integer.pattern)]),
    numCyclesIndex2Override: new FormControl(undefined, [Validators.pattern(this.ValidationRule.integer.pattern)]),
    overrideCycles: new FormControl(undefined, [this.overrideCyclesValidator]),
    skipIndexDiversityValidation: new FormControl(false),
    force: new FormControl(false),
  });

  public getIAKNameErrorMessage() {
    if (!this.name.dirty || !this.name.errors) {
      return null;
    }
    if (this.name.errors.maxLength) {
      return 'Maximum 255 characters.';
    }
    if (this.name.errors.required) {
      return 'Kit Name is required';
    }
    if (this.name.errors.pattern) {
      return 'Kit Name can contain alphanumeric characters, hyphens, underscores, and periods.';
    }
    return '';
  }

  getOverrideCyclesErrorMessage() {
    if (this.overrideCycles.errors.format) {
      return 'Please ensure Override Cycles are in the following format: Read1;Index1;Index2;Read2';
    } else if (this.overrideCycles.errors.regex) {
      return 'Please check Override Cycles format. Hover your mouse over the tooltip for more info.'
    }
  }

  getDefaultIndexStrategyErrorMessage() {
    if (this.defaultIndexStrategy.errors.required) {
      return 'Default Index Strategy is required.';
    } else if (this.defaultIndexStrategy.errors.nonAllowedChoice) {
      return 'Please select an allowed Index Strategy.';
    }
  }

  ngOnInit() {
    super.ngOnInit();

    // if code feature enabled - both editors enabled, show UI editor by default
    // if code feature disabled - only text editor enabled
    this.showTabGroup = this.codefeatureService.resourcesCustomIAKEditorEnabled;
    this.isUiEditor = this.showTabGroup ? true : false;

    this.subs.sink = this.data.subscribe(
      (input: IEditIndexAdapterKitModalInput) => {
        this.indexAdapterKitId = input.indexAdapterKit.id;
        // fill up both UI and text editors so that users will see 
        // content even if there is an error converting between the two
        this.buildFormGroup(input.indexAdapterKit);
        this.buildTextEditorContent();
        this.spinnerService.stopSpinner();
      }
    );
  }
  
  buildTextEditorContent() {
    const allowedIndexStrategiesString =
      (this.allowedIndexStrategies.value as IndexStrategy[]).reduce(
        (prev, curr) => `${prev}\n  - "${curr}"`,
        ''
      );
    const [fixedIndexPositions, usesPlateName] = this.parseFixedIndexPositions();
    const fixedIndexPositionString =
      fixedIndexPositions.reduce(
        (prev, curr) => `${prev}\n    - "${curr}"`,
        ''
      );
    this.rawIAKText =
      '---\n' +
      `Name: "${this.name.value}"\n` +
      `DisplayName: "${this.displayName.value}"\n` +
      `Description: "${this.description.value}"\n` +
      `AllowedIndexStrategies: ${allowedIndexStrategiesString}\n` +
      `AdapterSequenceRead1: "${this.adapterSequenceRead1.value}"\n`;
    if (this.adapterSequenceRead2) {
      // TODO: pending GSS fix to allow adapterSequenceRead2 to be set to empty when updating IAK.
      // For now, exclude adapterSequenceRead2 from request payload when it is empty (i.e. don't patch the value),
      // so that IAK created with only adapterSequenceRead1 can still be saved.
      this.rawIAKText += `AdapterSequenceRead2: "${this.adapterSequenceRead2.value}"\n`;
    }
    this.rawIAKText +=
      `IndexSequences:\n` +
      '  i7Index1: ' +
        this.index1SequencesValue.split('\n').filter(line => line)
          .map(line => line.includes(':')
            ? `\n    ${line.split(':')[0].trim()}: "${line.split(':')[1].trim()}"`
            : `\n    ${line.trim()}`).join('') +
        '\n' +
      '  i5Index2: ' +
        this.index2SequencesValue.split('\n').filter(line => line)
          .map(line => line.includes(':')
            ? `\n    ${line.split(':')[0].trim()}: "${line.split(':')[1].trim()}"`
            : `\n    ${line.trim()}`).join('') +
        '\n' +
      `Settings:\n` +
      `  FixedLayout: ${!isEmpty(fixedIndexPositions)}\n` +
      `  Multiplate: ${!isEmpty(fixedIndexPositions) && usesPlateName}\n` +
      `  FixedIndexPositions: ${fixedIndexPositionString}\n` +
      `  DefaultIndexStrategy: "${this.defaultIndexStrategy.value}"`;
    // Exclude overrideCycles-related fields from request payload to unset their values
    if (this.numCyclesIndex1Override.value) {
      this.rawIAKText += `\n  NumCyclesIndex1Override: "${this.numCyclesIndex1Override.value}"`;
    }
    if (this.numCyclesIndex2Override.value) {
      this.rawIAKText += `\n  NumCyclesIndex2Override: "${this.numCyclesIndex2Override.value}"`;
    }
    if (this.overrideCycles.value) {
      this.rawIAKText += `\n  OverrideCycles: "${this.overrideCycles.value}"`;
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.aceEditors.forEach(aceEditor => {
      this.aceInstance = aceEditor.directiveRef.ace();
      this.aceInstance.setShowPrintMargin(false);
      setTimeout(() =>  this.aceInstance.resize(true));
    })
  }

  onCheckboxChanged(control: AbstractControl, event) {
    control.setValue(event.target.checked, { emitEvent: false });
  }

  onTabChange(selectedTab) {
    this.menuItems = this.menuItems.map((tab) => {
      tab.selected = tab === selectedTab ? true : null;
      return tab;
    });
    const previousIsUI = this.isUiEditor;
    this.isUiEditor = selectedTab.label === 'UI editor';
    if (previousIsUI !== this.isUiEditor) {
      try {
        if (this.isUiEditor) {
          this.buildFormGroup(this.parseYaml(), true);
        } else {
          this.buildTextEditorContent();
        };
      } catch (e) {
        this.toastr.show(
          `There was an error converting from the ${
            previousIsUI ? "UI content" : "text content"
          }.`
        );
      }
    }    
  }

  parseYaml(): any {
    const lines = this.rawIAKText.split('\n');
    if (lines[0].startsWith('---')) {
      lines.splice(0, 1); // remove first line (---)
    }
    return this.parseNodes(lines, 0);
  }
  
  parseNodes(lines: string[], level: number): any {
    const result: {} = {};
    let currentList = [];
    let currentNodes = [];
    let currentKey = null;
    let readNumber = 0;
    for (let index = 0; index < lines.length; index++) {
      const line = lines[index];
      // remove everything before comment if line has a comment
      const trimmedLine = line.includes('#')
        ? line.split('#')[0].trim()
        : line.trim();

      // Check for empty line
      if (trimmedLine === '') {
        continue;
      }
      const indentationLevel = line.search(/\S/);
      const isList = trimmedLine.startsWith('- ');

      if (currentKey === 'indexSequences' && indentationLevel !== 0) {
        if (indentationLevel === 2) {
          readNumber += 1;
        } else {
          const splitLine = line.split(':');
          const sequence = this.getQuoteSubstring(splitLine[1].trim());
          result[currentKey] = [
            ...(result[currentKey] ? result[currentKey] : []),
            {
              name: splitLine[0].trim(),
              sequence: sequence ? sequence : "",
              readNumber
            }
          ];
        }
      } else if (isList && indentationLevel - level === 2) {
        // if list, accumulate
        currentList.push(trimmedLine.slice(3, -1).trim());
      } else {
        if (!isEmpty(currentList)) {
          // if currentList isn't empty, means just finished accumulating list
          result[currentKey] = currentList;
          currentList = [];
        }
        if (indentationLevel === level) {
          if (!isEmpty(currentNodes)) {
            // if currentNodes isn't empty, means just finished accumulating nodes
            result[currentKey] = this.parseNodes(currentNodes, level + 2);
            currentNodes = [];
          }
          const splitLine = trimmedLine.split(':');
          currentKey = splitLine[0].trim();
          currentKey = currentKey.charAt(0).toLowerCase() + currentKey.slice(1);
          if (splitLine.length > 1) {
            let value = splitLine[1].trim();
            if (value) {
              if (currentKey === "FixedLayout" || currentKey === "Multiplate") {
                // boolean value, no need double quotes
                result[currentKey] = value;
              } else {
                // string value, needs to be wrapped in double quotes
                result[currentKey] = this.getQuoteSubstring(value);
              }
            }
          }
        } else if (indentationLevel > level) {
          // if positive indentation, accumulate nodes
          currentNodes.push(line);
        } else if (indentationLevel < level) {
          // if negative indentation, break loop early
          break;
        }
      }
    }
    if (!isEmpty(currentList)) {
      // if resultList isn't empty, means just finished accumulating
      result[currentKey] = currentList;
      currentList = [];
    }
    if (!isEmpty(currentNodes)) {
      result[currentKey] = this.parseNodes(currentNodes, level + 2);
    }
    return result;
  }

  private getQuoteSubstring(textValue: string) {
    const quoteMatches = textValue.match(/"[^"]*"/g);
    if (quoteMatches) {
      return quoteMatches[0].slice(1, -1);
    } else {
      return null;
    }
  }
  
  get name() {
    return this.iakFormGroup ? this.iakFormGroup.get('name') : null;
  }
  get displayName() {
    return this.iakFormGroup ? this.iakFormGroup.get('displayName') : null;
  }
  get allowedIndexStrategies() {
    return this.iakFormGroup
      ? this.iakFormGroup.get('allowedIndexStrategies')
      : null;
  }
  get defaultIndexStrategy() {
    return this.iakFormGroup
      ? this.iakFormGroup.get('defaultIndexStrategy')
      : null;
  }
  get description() {
    return this.iakFormGroup ? this.iakFormGroup.get('description') : null;
  }
  get adapterSequenceRead1() {
    return this.iakFormGroup
      ? this.iakFormGroup.get('adapterSequenceRead1')
      : null;
  }
  get adapterSequenceRead2() {
    return this.iakFormGroup
      ? this.iakFormGroup.get('adapterSequenceRead2')
      : null;
  }
  get skipIndexDiversityValidation() {
    return this.iakFormGroup 
      ? this.iakFormGroup.get('skipIndexDiversityValidation') 
      : null;
  }
  get force() {
    return this.iakFormGroup ? this.iakFormGroup.get('force') : null;
  }
  get numCyclesIndex1Override() {
    return this.iakFormGroup ? this.iakFormGroup.get('numCyclesIndex1Override') : null;
  }
  get numCyclesIndex2Override() {
    return this.iakFormGroup ? this.iakFormGroup.get('numCyclesIndex2Override') : null;
  }
  get overrideCycles() {
    return this.iakFormGroup ? this.iakFormGroup.get('overrideCycles') : null;
  }
  get defaultIndexStrategyOptions(): SimpleOption[] {
    // only show allowed index strategies as default options
    return this.indexStrategyOptions.filter(x => 
      this.allowedIndexStrategies.value.includes(x.value));
  }

  private buildFormGroup(iak: IndexAdapterKit, markAsDirty = false) {
    // split into read 1 and 2
    const [index1Sequences, index2Sequences] = partition(iak.indexSequences, (sequence) => sequence.readNumber === 1)
      .map(sequences => {
        // for each read, map to name: sequence, then join with \n
        return (sequences
          .map((sequence) => `${sequence.name}: ${sequence.sequence}`)
          .join('\n')
        )
      });
    const fixedIndexPositions = get(iak, 'settings.fixedIndexPositions', []).join('\n');
    this.index1SequencesValue = index1Sequences;
    this.index2SequencesValue = index2Sequences;
    this.fixedIndexPositionsValue = fixedIndexPositions;
    this.iakFormGroup.patchValue({
      name: iak.name ? iak.name : '',
      displayName: iak.displayName ? iak.displayName : '',
      description: iak.description ? iak.description : '',
      allowedIndexStrategies: iak.allowedIndexStrategies
        ? iak.allowedIndexStrategies.filter(x => Object.values(IndexStrategy).includes(x as IndexStrategy))
        : [],
      adapterSequenceRead1: iak.adapterSequenceRead1 ? iak.adapterSequenceRead1 : '',
      // TODO: pending GSS fix to allow adapterSequenceRead2 to be set to empty when updating IAK.
      // For now, exclude adapterSequenceRead2 from request payload when it is empty (i.e. don't patch the value),
      // so that IAK created with only adapterSequenceRead1 can still be saved.
      adapterSequenceRead2: iak.adapterSequenceRead2,
      defaultIndexStrategy: get(iak, 'settings.defaultIndexStrategy', ''),
      numCyclesIndex1Override: get(iak, 'settings.numCyclesIndex1Override'),
      numCyclesIndex2Override: get(iak, 'settings.numCyclesIndex2Override'),
      overrideCycles: get(iak, 'settings.overrideCycles')
    });
    if (markAsDirty) {
      this.markAsDirty([this.iakFormGroup]);
    }
    this.allowedIndexStrategies.valueChanges.subscribe(() =>
      this.defaultIndexStrategy.updateValueAndValidity()
    );
  }

  private markAsDirty(controls: AbstractControl[]) {
    controls.forEach(control => {
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        const childControls = control instanceof FormGroup
          ? Object.values((control as FormGroup).controls)
          : (control as FormArray).controls
        this.markAsDirty(childControls);
      }
    });
  }

  buildIAKRequest(): UpdateIndexAdapterKitRequest {
    const [fixedIndexPositions, usesPlateName] = this.parseFixedIndexPositions();
    const result: UpdateIndexAdapterKitRequest = {
      name: this.name.value,
      displayName: this.displayName.value,
      description: this.description.value,
      adapterSequenceRead1: this.adapterSequenceRead1.value,
      adapterSequenceRead2: this.adapterSequenceRead2.value,
      allowedIndexStrategies: this.allowedIndexStrategies.value,
      indexSequences: this.parseSequences(this.index1SequencesValue, 1).concat(this.parseSequences(this.index2SequencesValue, 2)),
      settings: {
        defaultIndexStrategy: this.defaultIndexStrategy.value,
        fixedIndexPositions: fixedIndexPositions,
        fixedLayout: !isEmpty(fixedIndexPositions),
        multiplate: !isEmpty(fixedIndexPositions) && usesPlateName,
        overrideCycles: this.overrideCycles.value,
        numCyclesIndex1Override: this.numCyclesIndex1Override.value,
        numCyclesIndex2Override: this.numCyclesIndex2Override.value
      },
      force: this.force.value,
      skipIndexDiversityValidation: this.skipIndexDiversityValidation.value
    };

    return result;
  }

  /**
   * Upon submission, process the form and submit request to GSS to update IAK and handle return result
   */
  accept(modal: NgxSmartModalComponent) {
    if (this.iakFormGroup) {
      let request;
      if (this.isUiEditor) {
        const requestBody: UpdateIndexAdapterKitRequest = this.buildIAKRequest();
        for (let control in this.iakFormGroup.value) {
          const value = this.iakFormGroup.get(control).value;
          if (requestBody.settings.hasOwnProperty(control)) {
            set(requestBody, `settings.${control}`, value);
          } else {
            requestBody[control] = value;
          }
        }

        requestBody.name = this.name.value.replace(/\s/g, '');

        if (isEmpty(requestBody.displayName)) {
          requestBody.displayName = requestBody.name;
        }

        // TODO: pending GSS fix to allow adapterSequenceRead2 to be set to empty when updating IAK.
        // For now, exclude adapterSequenceRead2 from request payload when it is empty (i.e. don't patch the value),
        // so that IAK created with only adapterSequenceRead1 can still be saved.
        if (isEmpty(requestBody.adapterSequenceRead2)) {
          delete requestBody.adapterSequenceRead2;
        }

        requestBody.indexSequences = this.parseSequences(this.index1SequencesValue, 1)
          .concat(this.parseSequences(this.index2SequencesValue, 2));
        
        const [fixedIndexPositions, usesPlateName] = this.parseFixedIndexPositions();
        set(requestBody, 'settings.fixedIndexPositions', fixedIndexPositions);
        set(requestBody, 'settings.fixedLayout', !isEmpty(fixedIndexPositions));
        set(requestBody, 'settings.multiplate', !isEmpty(fixedIndexPositions) && usesPlateName);
        
        const params: IndexAdapterKitsService.UpdateIndexAdapterKitParams = {
          indexAdapterKitId: this.indexAdapterKitId,
          body: requestBody,
        };
        this.toastr.clear(); // clear all toast messages      request = this.indexAdapterKitService.updateIndexAdapterKit(params);
        request = this.indexAdapterKitService.updateIndexAdapterKit(params);
      } else {
        const params: UpdateIndexAdapterKitByDefinitionRequest = {
          definitionFormat: 'Yaml',
          definition: this.rawIAKText,
          force: this.force.value,
          skipIndexDiversityValidation: this.skipIndexDiversityValidation.value
        }
        request = this.indexAdapterKitService.updateIndexAdapterKitByDefinition({indexAdapterKitId: this.indexAdapterKitId, body: params});
      }
      this.subs.sink = request.subscribe({
        next: iakEdited => this.processSuccessfulKitEdit(iakEdited, modal),
        error: response => this.parseErrorResponse(response.error, this.name.value)
      })
    }
  }

  parseSequences(sequenceString: string, readNumber: number): IndexSequence[] {
    if (!isEmpty(sequenceString)) {
      return sequenceString
        .split('\n') // now in the form ['D701: ATTACTCG', ...]
        .map((seq: string): IndexSequence => {
          const [name, sequence] = seq.split(':');
          return {
            name: name.trim(),
            readNumber,
            sequence: sequence.trim()
          }
        })
        .filter(x => x.sequence); // filter out anything with an undefined sequence
    } else {
      return [];
    }
  }

  parseFixedIndexPositions(): [string[], boolean] {
    const result = this.fixedIndexPositionsValue
      .split('\n') // ['A-A01/D701-D501', ...]
      .filter(x => x) // remove empty entries
      .map(x => x.trim()) // handle trailing/leading whitespaces

    // checks if the first half (e.g. 'A-A01') contains a plate name before well position
    const usesPlateName = !isEmpty(result) 
      ? result[0].includes('-') && !result[0].startsWith('-') 
      : false;
    return [result, usesPlateName];
  }

  /**
   * Parse error to proper error messages.
   * GSS error code mapping should handle here.
   * @returns error message as HTML string
   */
  private parseErrorResponse(
    err,
    name: string
  ) {
    let errMessage = 'Something is wrong, please try again later.';
    const errData = err || {};
    if (errData.message) {
      if (
        (errData.code || '') ===
        'GenomicSequencingService.IndexAdapterKits.IndexAdapterKitWithSameNameAndVersionExists'
      ) {
        errMessage = `Name '${
          name
        }' already exists, please choose a different name.`;
      } else {
        errMessage = `<h4>${errData.message}</h4>`;
        if (errData.details) {
          errMessage = errMessage.concat(this.getErrorDetails(errData.details));
        }
      }
    }
    this.toastr.error(errMessage, undefined, {disableTimeOut: true});
  }

  getErrorDetails(details) {
    let result = '';
    if (typeof details === 'string') {
      return details;
    } else if (details instanceof Array) {
      details.forEach(detail => result = result.concat(`${this.getErrorDetails(detail)}\n`));
    } else if (details instanceof Object) {
      Object.keys(details).forEach(key => result = result.concat(`${key}: ${this.getErrorDetails(details[key])}\n`));
    }
    return result;
  }

  /**
   * Prompt successful toast message, emit the event and close the modal.
   * @param indexAdapterKitEdited created iak data returned from GSS
   * @param modal instance needed to close the modal
   */
  private processSuccessfulKitEdit(
    indexAdapterKitEdited: IndexAdapterKit,
    modal: NgxSmartModalComponent
  ) {
    this.toastr.success(
      `Your '${indexAdapterKitEdited.displayName}' index adapter kit has been successfully saved.`
    );
    this.confirm.emit();
    modal.close();
  }
}
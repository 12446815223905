
// Copied over from BSSH code base
export class BasePairUtils {
  static THOUSAND = 1000;
  static MILLION = Math.pow(BasePairUtils.THOUSAND, 2);
  static BILLION = Math.pow(BasePairUtils.THOUSAND, 3);
  static TRILLION = Math.pow(BasePairUtils.THOUSAND, 4);

  static BASE_ABBR = 'bp';
  static KILOBASE_ABBR = 'Kbp';
  static MEGABASE_ABBR = 'Mbp';
  static GIGABASE_ABBR = 'Gbp';
  static TERABASE_ABBR = 'Tbp';

  public static BASE: BasePairUnit = {
    abbreviation: BasePairUtils.BASE_ABBR,
    divisor: 1
  };

  public static KILOBASE: BasePairUnit = {
    abbreviation: BasePairUtils.KILOBASE_ABBR,
    divisor: BasePairUtils.THOUSAND
  };

  public static MEGABASE: BasePairUnit = {
    abbreviation: BasePairUtils.MEGABASE_ABBR,
    divisor: BasePairUtils.MILLION
  };

  public static GIGABASE: BasePairUnit = {
    abbreviation: BasePairUtils.GIGABASE_ABBR,
    divisor: BasePairUtils.BILLION
  };

  public static TERABASE: BasePairUnit = {
    abbreviation: BasePairUtils.TERABASE_ABBR,
    divisor: BasePairUtils.TRILLION
  };

  static toFixed(num: number, precision: number): number {
    // copying angular's implementation of toFixed -- more predictable than native Number.toFixed()
    return +(Math.round(+(num.toString() + 'e' + precision)).toString() + 'e' + -precision);
  }

  static getBasePairUnits(numBases: number, precision: number = 2): BasePairUnitResult {
    if (numBases < 0) {
      numBases = Math.abs(numBases);
    }
    if ((numBases >= 0) && (numBases < BasePairUtils.THOUSAND)) {
      return {
        basePairUnit: BasePairUtils.BASE,
        result: numBases.toString()
      };
    } else if ((numBases >= BasePairUtils.THOUSAND) && (numBases < BasePairUtils.MILLION)) {
      return {
        basePairUnit: BasePairUtils.KILOBASE,
        result: BasePairUtils.toFixed(numBases / BasePairUtils.THOUSAND, precision).toFixed(precision)
      };
    } else if ((numBases >= BasePairUtils.MILLION) && (numBases < BasePairUtils.BILLION)) {
      return {
        basePairUnit: BasePairUtils.MEGABASE,
        result: BasePairUtils.toFixed(numBases / BasePairUtils.MILLION, precision).toFixed(precision)
      };
    } else if ((numBases >= BasePairUtils.BILLION) && (numBases < BasePairUtils.TRILLION)) {
      return {
        basePairUnit: BasePairUtils.GIGABASE,
        result: BasePairUtils.toFixed(numBases / BasePairUtils.BILLION, precision).toFixed(precision)
      };
    } else if (numBases >= BasePairUtils.TRILLION) {
      return {
        basePairUnit: BasePairUtils.TERABASE,
        result: BasePairUtils.toFixed(numBases / BasePairUtils.TRILLION, precision).toFixed(precision)
      };
    } else {
      return {
        basePairUnit: BasePairUtils.BASE,
        result: numBases + BasePairUtils.BASE_ABBR
      };
    }
  }
}

export class BasePairUnit {
  abbreviation: string;
  divisor: number;
}

export class BasePairUnitResult {
  basePairUnit: BasePairUnit;
  result: string;
}

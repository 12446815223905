import { Injectable } from '@angular/core';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { BaseService } from '../base.service';
import { filter } from 'rxjs/operators';
import environment from '@environments/environment';
import { ResourceType } from '@app/core/model/resource-type';
import { HttpUtilityService } from '../http-utility/http-utility.service';
import { StringUtilities } from '@app/core/utilities/string-utilities';

// Service responsible to return the correct 'old website' url, taking into account the domain of the user.
@Injectable({
  providedIn: 'root'
})
export class LegacyBsApiEndpointsService extends BaseService {

  private currentUserDomain: string;
  private isPublicDomainuser: boolean;

  private websiteUrl = `${environment.siteUrl}`;

  constructor(private currentUserStore: CurrentUserStore, private httpUtilityService: HttpUtilityService) {
    super();
    this.subs.sink = this.currentUserStore.stateChanged.pipe(filter(state => state != null && state.currentUser != null)).subscribe({
      next: state => {
        this.currentUserDomain = state.currentUser.DomainName;
        this.isPublicDomainuser = state.currentUser.IsPublicDomainUser;
      }
    });
  }

  getInvitationsUrl(resourceType: ResourceType, resourceId: string): string {
    return `${this.getWebsiteUrlByDomain()}/api/collaborator/${resourceType.toString()}/${resourceId}`;
  }

  getShareByLinkUrl(resourceType: ResourceType, resourceId: string): string {
    return `${this.getWebsiteUrlByDomain()}/api/share_by_link/${resourceType.toString()}/${resourceId}`;
  }

  getRequeueSampleSheetUrl(runId: string): string {
    return `${this.getWebsiteUrlByDomain()}/run/${runId}/requeue`;
  }

  getRunSamplesUrl(runId: string): string {
    return `${this.getWebsiteApiBaseUrl()}/run/${runId}/sample`;
  }

  getTriggerDownloadUrl(): string {
    return `${this.getWebsiteUrlByDomain()}/apps/triggerDownload`;
  }

  getSamplesheetRequestUrl(runId: string): string {
    return `${StringUtilities.trimTrailingSlash(this.getWebsiteUrlByDomain())}/api/run/${runId}/samplesheet`;
  }
  getWebsiteApiBaseUrl(): string {
    return `${StringUtilities.trimTrailingSlash(this.getWebsiteUrlByDomain())}/api`;
  }

  getCopySamplesToProjectUrl(projectId: string): string {
    return `${this.getWebsiteApiBaseUrl()}/project/${projectId}/sample/copy`;
  }

  getMergeSamplesUrl(projectId: string): string {
    return `${this.getWebsiteApiBaseUrl()}/project/${projectId}/sample/merge`;
  }

  getWebsiteUrlByDomain(skipBrowserDomainCheck = false): string {
    // If public domain, return the url as is.
    if (!this.isPublicDomainuser) {
      // If enterprise domain, check if the url's domain in the browser = domain of the user.
      // Not likely to fail, but just in case.
      // We skip browser domain check incase current request is local-basespace.
      if (skipBrowserDomainCheck || this.httpUtilityService.getDomainFromCurrentRequestUrl() === this.currentUserDomain) {
        return this.websiteUrl.replace('https://', `https://${this.currentUserDomain}.`);
      }
    }
    return this.websiteUrl;
  }

}

import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { IConfirmationModalInput } from '../model/action-modal';
import { confirmationModalTexts, IConfirmationModalText } from './confirmation-modal-texts';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent extends BaseModalComponent implements OnInit {
  
  modalType = 'ConfirmModal';
  modalText: IConfirmationModalText;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit()
    // Assign modal text content according to input
    this.subs.sink = this.data.subscribe((modalInput: IConfirmationModalInput) => {
      if (modalInput && modalInput.type) {
        this.modalText = confirmationModalTexts[modalInput.type];
      }
    });
  }

  constructError(error: any): string | string[] {
    return error.error.ErrorMessage;
  }
}
<div class="lab-requeue">
    <div class="lab-requeue__container">
        <section class="lab-requeue__result">
            <ul class="lab-requeue__result__list">
                <li class="lab-requeue__result__item"
                *ngFor="let item of informationListToDisplay">
                    <span class="lab-requeue__result__name">
                        {{item.resourceName}}
                    </span>
                    <span class="lab-requeue__result__value">
                        {{item.resourceValue}}
                    </span>
                </li>
            </ul>
        </section>
        <section class="info-input-section" *ngIf="!errorMsg">
            <div class="lab-requeue__library_prep_kit"
                *ngIf="(libPrepKit.length > 0)">
                <label class="input-field__label">
                    Library Prep Kit
                </label>
                <div class="select">
                    <lib-select
                        [selected]="selectedLabWorkflowPrepRequestId"
                        (change)="onPrepKitChange($event)">
                        <lib-option
                            *ngFor="let option of libPrepKit"
                            [value]="option.value"
                            [label]="option.name">
                        </lib-option>
                    </lib-select>
                </div>
            </div>
            <div class="lab-requeue__requested_additional_yield">
                <label class="input-field__label" for="requestYieldInput">
                    Requested additional yield (in {{ abbreviation }})
                </label>
                <form 
                    name="formGroup"
                    [formGroup]="formGroup"
                    >
                    <lib-form-field #form
                        for="requestedYield"
                        [errorMessage]="getErrorMessage()"
                        [isValidateOnInputChange]="isValidateOnInputChange"
                    >    
                        <input
                            class="requestYieldInput"
                            id="requestYieldInput"
                            lib-input
                            type="number"
                            formControlName="requestedYield"
                            [value]="requestedAdditionalYield != null ? requestedAdditionalYield.toString(): null"
                            (input)="onYieldChange($event)"         
                        />
                    </lib-form-field>
                </form>
            </div>
        </section>
        <div>
            <lib-error-alert-message
                [alertMsg]="alertMsg"
                [errorMsg]="errorMsg">
            </lib-error-alert-message>
        </div>
    </div>
</div>

import { DateTimeUtilities } from '../date-time.utilities';
import { time } from 'jasmine-marbles';

export function TimeFilter(milliseconds: number) {
  const timeDuration = DateTimeUtilities.getMilliSecondsAsDuration(milliseconds);
  if (timeDuration != null) {
    return {
      Hours: timeDuration.hours,
      Minuets: timeDuration.minutes,
      Seconds: timeDuration.seconds
    };
  }
  return {
    Hours: '00',
    Minuets: '00',
    Seconds: '00'
  };
}

export class Time {
  Hours: Number;
  Minuets: Number;
  Seconds: Number;
}


<div class="input-wrapper comboBox"
    [ngClass]="contentClass" [class.error-box]="!control.valid && control.touched">
    <div
      class="input comboBox__content"
      [class.required]="required"
      #dropreference
    >
      <input
        class="comboBox__input-text"
        #input
        placeholder="{{ placeholder }}"
        [disabled]="disabled"
        [readonly]="true"
        (click)="toggleDropdown()"
        (keydown)="onKeyDown($event)"
        (blur)="onTouched($event)"
        autocomplete="off"
        [(ngModel)]="inputValue"
        [ngClass]="{
          'autocomplete-mode': searchMode
        }"
      />
      <span
        #deleteIcon
        *ngIf="enableDeleteBtn"
        [hidden]="!input.value || disabled"
        class="comboBox__dropdown-delete"
        (click)="onDeleteIconClick($event)"
      ></span>
      <span
        class="comboBox__dropdown-arrow"
        [ngClass]="{
          down: !(dropdown && dropdown.showing),
          up: dropdown && dropdown.showing
        }"
        (click)="onDropMenuIconClick($event)"
      >
        <i></i>
      </span>
    </div>
    <combobox-dropdown [reference]="dropreference" [startPosition]="'bottom'"
          [config]="config" #dropdownComp>
      <overlay-scrollbars class="comboBox__dropdown-options-container comboBox__drop-down"
        [ngClass]="{ 'comboBox__chooser-appearance-options': contentClass }">
        <ng-content></ng-content>
        <div style="padding:1rem 2rem; font-style: italic;"
          *ngIf="searchMode && !isLoading && (!options || !options.length)">
          No result...
        </div>
      </overlay-scrollbars>
    </combobox-dropdown>
  </div>
  <div *ngIf="control.touched && !control.valid && control.errors && control.errors.required" class="combobox-error-message">{{ label }} is required</div>

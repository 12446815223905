<div #LaneCommentsDiv>
    <ngx-smart-modal #LaneCommentsModal
        [identifier]="modalType"
        [title]="Title"
        [closable]="true"
        [showCloseButton]="false" 
        [showConfirmButton]="false">
        <hr>
        <div class="comment" *ngIf="laneComments!==undefined">
            <div *ngFor="let comment of laneComments">
                <div class="comment__event">Lane {{laneNumber}} QC Status Changed from {{comment.PriorValue}} to {{comment.NewValue}}</div>
                <hr>
                <div class="comment__user-meta"> {{comment.User.Name}} - {{comment.DateCreated |  date:'yyyy-MM-dd' }}</div>
                <div class="comment__body"> {{comment.Body}} </div>
                <hr>
            </div>
        </div>
    </ngx-smart-modal>

</div>

import { Component, AfterViewInit, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICancelOwnershipTransferModalInput } from '../model/action-modal';
import { Invitation } from '../../../core/model/v2-api/invitation';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { ResourceType } from '../../../core/model/resource-type';
import { isNullOrUndefined } from 'util';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';

@Component({
  selector: 'app-cancel-ownership-transfer-modal',
  templateUrl: './cancel-ownership-transfer-modal.component.html',
  styleUrls: ['./cancel-ownership-transfer-modal.component.scss']
})
export class CancelOwnershipTransferModalComponent extends BaseModalComponent implements OnInit, AfterViewInit{
  modalType = 'CancelOwnershipTransferModal';
  confirmButtonText = ModalTexts.CANCEL_OWNERSHIP_TRANSFER.CONFIRM_BUTTON_TEXT;

  public resourceType: ResourceType = null;
  public resourceName = null;
  public invite: Invitation = null;
  public pendingTransferMessage = null;
  public modalTitle = ModalTexts.CANCEL_OWNERSHIP_TRANSFER.MODAL_TITLE;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.sink = this.data.subscribe((input: ICancelOwnershipTransferModalInput) => {
      if (!input) {
        return;
      }

      this.resourceName = input.resourceName;
      this.resourceType = input.resourceType;
      this.initModalProperties();

      // If Invite call failed modal will show that errorMsg
      if (input.errorMsg) {
        this.error.next(input.errorMsg);
        return;
      }

      // when Invite api call is success but there are no invites
      if (isNullOrUndefined(input.invitation) || isNullOrUndefined(input.invitation.Id)) {
        this.error.next(ModalTexts.CANCEL_OWNERSHIP_TRANSFER.TRANSFER_ALREADY_ACCEPTED);
        return;
      }

      if (input.invitation) {
        this.invite = input.invitation;
      }
  });
  }

  ngAfterViewInit() {
    // calling the parent class method to open modal and to subscribe to confirm & close events
    super.ngAfterViewInit();

    // to set the initial data in modal that will be emitted on confirm event
    if (this.modal && this.invite) {
      this.modal.setData({invitationId: this.invite.Id}, true);
    }
  }

  private initModalProperties() {
    if (this.resourceType === ResourceType.RUN) {
      this.pendingTransferMessage = ModalTexts.CANCEL_OWNERSHIP_TRANSFER.RUN_MESSAGE;
    } else if (this.resourceType === ResourceType.PROJECT) {
      this.pendingTransferMessage = ModalTexts.CANCEL_OWNERSHIP_TRANSFER.PROJECT_MESSAGE;
    }
  }

  protected constructError(error: any): string {
    // error will be of this type only if api call fails...
    // so always returning errorMessage as ResponseStatus.Message and showing it in the modal
    return error.error.ResponseStatus.Message;
  }
}

<div #CancelAnalysisModalDiv>
    <ngx-smart-modal #CancelAnalysisModal
        [identifier]="modalType"
        [title]="modalTitle"
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButton" 
        [confirmButtonText]="confirmButtonText">

        <section #apiDataWrapperLoader 
            class="wrapper" 
            [ngClass]="{ active: (isLoadingApiData$ | async) }"
            *ngIf="(isLoadingApiData$ | async)" 
            data-cy="cancel-analysis-progress-overlay-wrapper"
        >
        </section>

        <lib-progress #apiDataSpinnerLoader
            [id]="apiDataSpinnerLoader" 
            class="'spinner'" 
            [loader]="false" 
            [fixed]="true"
            [spinner]="true" 
            [spinnerPosition]="'center'" 
            [progressBarContainer]="'modal'" 
            (started)="onStarted()" 
            (completed)="onCompleted()"
            data-cy="cancel-analysis-modal-progress-overlay-spinner"
        >
        </lib-progress>

        <div class="cancel-analysis"> 
            <span class="cancel-analysis__resource__name">{{ resourceName }}</span>
            <p *ngIf="errorMsg===null" class="cancel-analysis__resource__message">{{ cancelAnalysisMessage }}</p>
        </div>
        <br/>
        <div>
            <lib-error-alert-message
                [errorMsg]="errorMsg">
            </lib-error-alert-message>
        </div>
       
        
    </ngx-smart-modal>
</div>
import {
  Overlay,
  OverlayConfig,
  OverlayRef,
  ConnectedPosition
} from "@angular/cdk/overlay";
import { CdkPortal } from "@angular/cdk/portal";
import {
  Component,
  ChangeDetectorRef,
  HostListener,
  Input,
  ViewChild,
  OnDestroy,
  ViewEncapsulation,
  ElementRef
} from "@angular/core";
import OverlayScrollbars from "overlayscrollbars";

export interface IComboBoxDropDownConfig {
  panelClass?: string | string[];
  /** Whether the overlay has a backdrop. */
  hasBackdrop?: boolean;
  /** Custom class to add to the backdrop */
  backdropClass?: string | string[];
  /** The width of the overlay panel. If a number is provided, pixel units are assumed. */
  width?: number | string;
  /** The height of the overlay panel. If a number is provided, pixel units are assumed. */
  height?: number | string;
  /** The min-width of the overlay panel. If a number is provided, pixel units are assumed. */
  minWidth?: number | string;
  /** The min-height of the overlay panel. If a number is provided, pixel units are assumed. */
  minHeight?: number | string;
  /** The max-width of the overlay panel. If a number is provided, pixel units are assumed. */
  maxWidth?: number | string;
  /** The max-height of the overlay panel. If a number is provided, pixel units are assumed. */
  maxHeight?: number | string;
}

@Component({
  selector: "combobox-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  encapsulation: ViewEncapsulation.None,
  exportAs: "comboboxDropdown"
})
export class ComboBoxDropdownComponent implements OnDestroy {
  private _defaultConfig: IComboBoxDropDownConfig = {
    maxHeight: 256,
    height: "100%",
    hasBackdrop: true,
    backdropClass: "cdk-overlay-transparent-backdrop"
  };
  private _config: IComboBoxDropDownConfig = { ...this._defaultConfig };

  get config(): IComboBoxDropDownConfig {
    return this._config;
  }

  @Input("config")
  set config(data: IComboBoxDropDownConfig) {
    Object.assign(this._config, this._defaultConfig, data);
  }

  @Input()
  public startPosition: "top" | "center" | "bottom" = "bottom";

  @Input()
  public positions: ConnectedPosition[];

  @Input()
  public reference: HTMLElement;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.showing && this.config && !this.config.hasBackdrop && !this.reference.contains(event.target)
      && !this.eRef.nativeElement.contains(event.target)) {
      // dropdown is showing, and don't have backdrop, should hide dropdown upon click outside of dropdown
      this.hide();

    }
  }

  @ViewChild(CdkPortal, { static: true })
  public contentTemplate: CdkPortal;

  protected overlayRef: OverlayRef;

  public showing = false;

  public osInstance!: OverlayScrollbars;

  constructor(protected overlay: Overlay, private ref: ChangeDetectorRef, private eRef: ElementRef) { }

  public show() {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate);
    this.osInstance = OverlayScrollbars(
      this.overlayRef.hostElement.querySelector(".scroll-area"),
      {}
    );

    this.syncWidth();

    this.overlayRef.backdropClick().subscribe(() => this.hide());
    this.showing = true;

    this.ref.markForCheck();
  }

  public hide() {
    if (!this.showing) {
      return;
    }

    this.overlayRef.detach();
    this.showing = false;

    this.ref.markForCheck();
  }

  @HostListener("window:resize")
  public onWinResize() {
    this.syncWidth();
  }

  public getOverlayRef(): OverlayRef {
    return this.overlayRef;
  }

  protected getOverlayConfig(): OverlayConfig {
    const positions = this.positions
      ? this.positions
      : ([
        {
          originX: "center",
          originY: this.startPosition,
          overlayX: "center",
          overlayY: "top"
        },
        {
          originX: "center",
          originY: this.startPosition === "bottom" ? "top" : "bottom",
          overlayX: "center",
          overlayY: "bottom"
        }
      ] as ConnectedPosition[]);

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions(positions)
      .withGrowAfterOpen(true);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    return new OverlayConfig({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      ...this.config
    });
  }

  public updatePosition() {
    if (!this.overlayRef) {
      return;
    }
    this.overlayRef.updatePosition();    
  }

  private syncWidth() {
    if (!this.overlayRef) {
      return;
    }

    const refRect = this.reference.getBoundingClientRect();
    this.overlayRef.updateSize({ width: refRect.width });
  }

  ngOnDestroy(): void {
    if (this.osInstance && this.osInstance.destroy) {
      this.osInstance.destroy();
    }
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { IRun } from '../../../core/model/runs/run';
import { BehaviorSubject } from 'rxjs';
import { BaseModalComponent } from '../../../shared/modals/base-modal/base-modal.component';
import { IRequeueModalInput } from '../../../shared/modals/model/action-modal';
import { ModalTexts } from '../../../shared/modals/modal-texts';

@Component({
  selector: 'app-requeue-fastq-modal',
  templateUrl: './requeue-fastq-modal.component.html',
  styleUrls: ['./requeue-fastq-modal.component.scss']
})
export class RequeueFastqModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  modalTitle = ModalTexts.REQUEUE.MODAL_TITLE;
  modal: NgxSmartModalComponent;
  modalType = 'requeueFastQ';
  confirmButtonText = ModalTexts.REQUEUE.CONFIRM_BUTTON_TEXT;

  public requeueDetails ;
  public infoMsg = '';
  public run: IRun;
  public isRequeue: boolean;
  public requeuesLeft = 0;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
   }

  ngOnInit() {
    super.ngOnInit();

    this.subs.sink = this.data.subscribe((input: IRequeueModalInput) => {
      this.run = input.run;
      this.isRequeue = input.isRequeue;
      this.requeuesLeft = input.requeuesLeft;

      if (input.errorMsg) {
        this.error.next(input.errorMsg);
      }

    });

    this.constructRequeueDetails();
    this.setInfoMsg();
  }

  // Why are we not displaying Run Status for requeue.
  constructRequeueDetails() {
    if (this.isRequeue) {
      this.requeueDetails = [
        {
          name: 'Run Name',
          value: this.run.ExperimentName
        },
        {
          name: 'Requeues Left',
          value: this.requeuesLeft
        }
      ];
    } else {
      this.requeueDetails = [
        {
          name: 'Run Name',
          value: this.run.ExperimentName
        },
        {
          name: 'Run Status',
          value: this.run.Status
        },
        {
          name: 'Requeues Left',
          value: this.requeuesLeft
        }
      ];
    }
  }

  constructError(error) {
    if (error.error.ErrorMessage) {
      return error.error.ErrorMessage;
    }
    return '';
  }

  setInfoMsg() {
    if (!this.isRequeue) {
      this.infoMsg = ModalTexts.REQUEUE.REGENERATE_INFO;
    }
  }
}


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsApiEndPoints } from '../../core/services/bs-api/endpoints';
import { retryWhen, delay } from 'rxjs/operators';
import { observableEmitDelay, genericRetryWhen } from '../../core/rxjsutils/rxjs-utilities';
import { IV2ManifestImportResponse } from '../../core/bsshapi/api-wrappers';
import { Observable, throwError } from 'rxjs';
import { ErrorMessages } from '../../core/utilities/error-messages';

export interface IBiosampleWorkflowUploadService {
  uploadBiosampleWorkflow(file: File, preview: boolean): Observable<IV2ManifestImportResponse>;
}

@Injectable({
  providedIn: 'root'
})
export class BiosampleWorkflowUploadService implements IBiosampleWorkflowUploadService {

  constructor(private httpClient: HttpClient) { }

    // if preview not specified, assuming to be false, i.e. final submission
  uploadBiosampleWorkflow(biosampleFile: File, preview: boolean = false): Observable<IV2ManifestImportResponse> {

    if (!biosampleFile) {
      return throwError(ErrorMessages.BIOSAMPLE_UPLOAD.FILE_NOT_PROVIDED);
    }
    const requestUrl = BsApiEndPoints.getBiosampleWorkflowUploadUrl();

    return this.httpClient.post<IV2ManifestImportResponse>(requestUrl, biosampleFile, {params: {preview: preview.toString()}}).pipe(
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay)
    );

  }

}

import {
  LibraryPrepKit, IndexAdapterKit, IndexStrategy, ReadType
} from '@stratus/gss-ng-sdk';
import {SimpleOption} from '@app/core/store/cloud-run-prep/run-setup.state';

// Paths
export const RUN_SETUP_PATH = '/cloud-run-prep';
export const PREP_TAB_PATH = '/lab';
export const MICROARRAY_HUB_PATH = '/cloud-run-prep/microarray-analysis-setup';
export const MULTI_ANALYSIS_RUN_SETUP_PATH = '/run-planning';

// For post-validation error
export const NO_FIELD_ID = '_null_';
export const ERROR_DISPLAY_LENGTH = 70;

export const UNSPECIFIED_INDEX_ADAPTER_KIT: IndexAdapterKit = {
  // Self-defined unspecified IAK here
  id: 'Unspecified',
  name: 'Not Specified',
  displayName: 'Not Specified',
  allowedIndexStrategies: [
    IndexStrategy.NO_INDEX,
    IndexStrategy.SINGLE,
    IndexStrategy.DUAL
  ],
  settings: {
    defaultIndexStrategy: IndexStrategy.DUAL
  },
  numCyclesIndex1: 0,
  numCyclesIndex2: 0
};

export function isUnspecifiedIndexAdapterKit(indexAdapterPrepKit: IndexAdapterKit): boolean {
  if (!indexAdapterPrepKit) {
    return false;
  }
  return (indexAdapterPrepKit.id === UNSPECIFIED_INDEX_ADAPTER_KIT.id);
}

export const UNSPECIFIED_IAK_OPTION = {
  id: UNSPECIFIED_INDEX_ADAPTER_KIT.id, value: UNSPECIFIED_INDEX_ADAPTER_KIT.id,
  text: UNSPECIFIED_INDEX_ADAPTER_KIT.name, originalData: UNSPECIFIED_INDEX_ADAPTER_KIT
};

export const UNSPECIFIED_LIB_PREP_KIT: LibraryPrepKit = {
  // Self-defined unspecified IAK here
  id: 'Unspecified',
  name: 'Not Specified',
  displayName: 'Not Specified',
  allowedReadTypes: [
    ReadType.SINGLE,
    ReadType.PAIRED
  ]
};

export const UNSPECIFIED_LPK_OPTION = {
  id: UNSPECIFIED_LIB_PREP_KIT.id, value: UNSPECIFIED_LIB_PREP_KIT.id,
  text: UNSPECIFIED_LIB_PREP_KIT.name, originalData: UNSPECIFIED_LIB_PREP_KIT
};

export function isUnspecifiedLibraryPrepKit(libraryPrepKit: LibraryPrepKit): boolean {
  if (!libraryPrepKit) {
    return false;
  }
  return (libraryPrepKit.id === UNSPECIFIED_LIB_PREP_KIT.id);
}

export const RUN_SET_UP_MODE = ['Guided Setup (Recommended)', 'Sample Sheet (.csv)'];
export const NO_LANE = "NO_LANE";

export const VALIDATION_RULE = {
  maximumNumberOfSampleRows: 12288,
  freeText: {
    pattern: new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9_\-\.\s]*$/),
    maxLength: 255
  },
  validMicroArrayAnalysisRunName: {
    pattern : new RegExp(/(^[0-9a-zA-Z-_ \\.]*$)/),
    maxLength: 60
  },
  number: {
    pattern: new RegExp(/^(\-?\d+\.{0,1}\d+|0|\-?[1-9]\d*)$/)
  },
  integer: {
    pattern: new RegExp(/^(0|[1-9]\d*)$/)
  },
  maxReadLength: 2147483647,
  // https://git.illumina.com/SEES/Stratus/blob/master/Services/GenomicSequencingService/docs/sdd/gss-sdd.md#character-restrictions
  characterRestrictions: {
    restrictedNameRegex: new RegExp('^[a-zA-Z0-9_\-]{0,100}$'),
    sequenceRegex: new RegExp('^[AGCT]{0,10}$'),
  }
};

// Initial form values/list options
export const INITIAL_INDEX_STRATEGY_OPTIONS = [
  IndexStrategy.NO_INDEX,
  IndexStrategy.SINGLE,
  IndexStrategy.DUAL
];
export const INITIAL_READ_TYPE_OPTIONS = [ReadType.SINGLE, ReadType.PAIRED];

// GSS API request params
export const LIST_ANALYSIS_DEFINITIONS_PAGE_SIZE = 1000;
export const LIST_INDEX_ADAPTER_KIT_PAGE_SIZE = 1000;
export const LIST_LIBRARY_PREP_KIT_PAGE_SIZE = 1000;
export const LIST_GENOMES_PAGE_SIZE = 1000;

// Analysis Setup Custom File upload
export const STANDARD_FILE_INDEX = 1;
export const CUSTOM_FILE_INDEX = 0;

// Microarray Analysis
export const MICROARRAY_ANALYSIS_SAMPLE_SHEET_TITLE = 'Import Sample Sheet';
export const MICROARRAY_ANALYSIS_SAMPLE_SHEET_VALUE = 'sample-sheet';
export const MICROARRAY_ANALYSIS_BEADCHIPS_TITLE = 'Select BeadChips';
export const MICROARRAY_ANALYSIS_BEADCHIPS_VALUE = 'beadchips';
export const MICROARRAY_ANALYSIS_IMPORT_IDATS_TITLE = 'Import IDAT Files';
export const MICROARRAY_ANALYSIS_IMPORT_IDATS_VALUE = 'import-idats';
export const MICROARRAY_ANALYSIS_IMPORT_IDATS_TIMEOUT_ACKNOWLEDGEMENT_COOKIE = {
  name: 'acknowledgeIdleTimeoutImportIdats',
  expires: 30, // cookie expires in that many days
  path: '/',
  domain: '.illumina.com'
};

export const MICROARRAY_CUSTOM_CONFIG_DESCRIPTIONS =  {
  'CnModel': 'The .dat copy number model file for the Infinium BeadChip to be analyzed.',
  'CsvManifest': 'The .csv manifest file for the Infinium BeadChip to be analyzed.',
  'Egt': 'The .egt cluster file for the Infinium BeadChip to be analyzed.',
  'PrsInputFile': 'Use the Polygenic Score ID file template to enter between 1 and 24 Polygenic Risk Scores to be calculated.'
}

export const MICROARRAY_APP_ANALYSIS_SUPPORTING_CUSTOM_CONFIG = ['prs'];
export const MICROARRAY_ANALYSIS_SAMPLE_DATA_TYPE_OPTIONS: SimpleOption[] = [
  { text: MICROARRAY_ANALYSIS_SAMPLE_SHEET_TITLE, value: MICROARRAY_ANALYSIS_SAMPLE_SHEET_VALUE },
  { text: MICROARRAY_ANALYSIS_BEADCHIPS_TITLE, value: MICROARRAY_ANALYSIS_BEADCHIPS_VALUE },
];

export const MICROARRAY_SAMPLESHEET_TEMPLATE = 'assets/resources/Microarrays_Import_Samplesheet_Template.csv';

export const MICROARRAY_SAMPLESHEET_FILETYPE = 'SampleSheet';

export const MICROARRAY_ANALYSIS_FILE_FOR = {
  AnalysisConfiguration: 'AnalysisConfiguration',
  AnalysisFileAnalysisRun: 'AnalysisFileAnalysisRun'
}

// New Run button menu link ids
export const PREP_TAB_ID = 'prep-tab';
export const INSTRUMENT_RUN_ID = 'instrument-run-setup';
export const MICROARRAY_HUB_ID = 'microarray-hub';
export const RUN_PLANNING_ID = 'run-planning';

// Keyboard
export const KEY_TAB = 9;
export const KEY_ENTER = 13;
export const KEY_ESCAPE = 27;
export const KEY_LEFT = 37;
export const KEY_UP = 38;
export const KEY_RIGHT = 39;
export const KEY_DOWN = 40;
export const KEY_SPACE = 32;
export const ENTER_KEY = 'Enter';
export const ESCAPE_KEY = 'Escape';
export const UP_KEY = 'ArrowUp';
export const LEFT_KEY = 'ArrowLeft';
export const RIGHT_KEY = 'ArrowRight';
export const DOWN_KEY = 'ArrowDown';
export const SPACE_KEY = 'Space';

export function scrollItemIntoView(target: HTMLElement, keycode: number = KEY_DOWN) {
  if (!target || !target.parentElement) {
      return;
  }
  if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
      // scrollIntoView does not work perfectly for IE as it does not support nearest block
      if (keycode === KEY_UP) {
          // Up
          if (target.parentElement.scrollTop + target.parentElement.clientHeight < target.offsetTop + target.offsetHeight ||
              target.parentElement.scrollTop > target.offsetTop) {
              target.parentElement.scrollTop = target.offsetTop;
          }
          if (target.parentElement.getBoundingClientRect().top < 0 ||
              target.parentElement.getBoundingClientRect().bottom > window.innerHeight) {
              //item not in view
              window.scrollTo(window.pageXOffset, target.parentElement.getBoundingClientRect().top +
                  window.pageYOffset);
          }
      } else if (keycode === KEY_DOWN) {
          // Down
          if (target.parentElement.scrollTop + target.parentElement.clientHeight < target.offsetTop + target.offsetHeight ||
              target.parentElement.scrollTop > target.offsetTop) {
              target.parentElement.scrollTop = target.offsetTop - (target.parentElement.clientHeight - target.offsetHeight);
          }
          if (target.parentElement.getBoundingClientRect().top < 0 ||
              target.parentElement.getBoundingClientRect().bottom > window.innerHeight) {
              // item not in view
              window.scrollTo(window.pageXOffset,
                  window.pageYOffset + (target.parentElement.getBoundingClientRect().bottom - window.innerHeight));
          }
      }
  } else {
      // if it's not ie, follow standard behavior
      target.scrollIntoView(
          { behavior: "auto", block: "nearest", inline: "nearest" }
      );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonModule, FormFieldsModule, InputModule, LibPopoverModule, NgxSmartModalModule,
  ProgressModule,
  RadioCheckboxListModule,
  RadioCheckboxModule, SelectModule, TabNavigationModule
} from '@bssh/comp-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditIndexAdapterKitModalComponent } from './edit-index-adapter-kit-modal.component';
import { AceModule } from 'ngx-ace-wrapper';
import "@radiance-design-software/accordion"
import "@radiance-design-software/web-components/components/web-tab";

@NgModule({
  declarations: [EditIndexAdapterKitModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    InputModule,
    RadioCheckboxModule, 
    FormFieldsModule,
    FormsModule,
    RadioCheckboxListModule, 
    RadioCheckboxModule,
    ProgressModule, 
    LibPopoverModule,
    ReactiveFormsModule,
    SelectModule,
    NgxSmartModalModule,
    AceModule
  ],
  exports: [EditIndexAdapterKitModalComponent]
})
export class EditIndexAdapterKitModalModule { }

import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AppSessionExecutionStatus, IAppSession } from '@app/core/model/appSessions/appSession';
import { TimeFilter } from '../filters/time.filter';
import { DateTimeUtilities } from '../date-time.utilities';
import { isNullOrUndefined } from 'util';

/**
 * Takes an appsession and displays the duration:
 * e.g. '12 hours 10 minutes 02 seconds'
 *      '10 minutes 02 seconds'
 *      '02 seconds'
 */
@Pipe({
  name: 'appsessionDurationToDisplay'
})
@Injectable({
  providedIn: 'root'
})
export class AppSessionDurationToDisplay implements PipeTransform {
  constructor() {}

  transform(input: IAppSession): string {

    const dateStarted = input.DateStarted;
    const dateCompleted = input.DateCompleted;
    const status = input.ExecutionStatus;
    const now: Date = new Date();

    let differenceInMs = null;

    if (status === AppSessionExecutionStatus[AppSessionExecutionStatus.Running]) {
      const runningAppSessionDateStarted: string | Date = isNullOrUndefined(dateStarted) ? now : dateStarted;
      differenceInMs = DateTimeUtilities.getDifferenceInMs(runningAppSessionDateStarted, now);

    } else if ((status === AppSessionExecutionStatus[AppSessionExecutionStatus.Complete] ||
        status === AppSessionExecutionStatus[AppSessionExecutionStatus.Aborted]) &&
        dateStarted &&
        dateCompleted) {

      differenceInMs = DateTimeUtilities.getDifferenceInMs(dateStarted, dateCompleted);
    }

    if (isNullOrUndefined(differenceInMs)) {
      return '';
    }

    const runningDuration = TimeFilter(differenceInMs);
    const hours = runningDuration.Hours;
    const minutes = runningDuration.Minuets;
    const seconds = runningDuration.Seconds;

    if (hours === '00' && minutes === '00') {
      return `${seconds} seconds`;
    }

    if (hours === '00') {
      return `${minutes} minutes ${seconds} seconds`;
    }

    return `${hours} hours ${minutes} minutes ${seconds} seconds`;
  }
}
<ngx-smart-modal
    #indexAdapterKit
    class="iak-modal"
    [identifier]="modalType"
    [title]="title"
    closable="false"
    dismissible="false"
    escapable="false"
    [closeButtonText]="closeButtonText"
    [confirmButtonText]="confirmButtonText"
    [disableConfirmButton]="content=== ''">

    <small>Please enter a new custom index adapter kit or reload the template to edit it.</small>

    <div class="d-flex toolbar-section">
        <div class="template-chooser-label">
            <small>Template Type</small>
            <lib-popover
                popoverType="info"
                popoverTriggerEvents="mouseenter:mouseleave"
                popoverSize="small"
                popoverPlacement="top">
                <span>Pre-defined templates for different types of Index Adapter Kits.</span>
            </lib-popover>
        </div>
        <!-- Template dropdown -->
        <lib-select #templateSelect
            placeholder='Select'
            (change)="onTemplateSelection($event)">
            <lib-option *ngFor="let template of templateOptions"
                [label]="template.name"
                [value]="template.content">
            </lib-option>
        </lib-select>
        <!-- Reload Template Content -->
        <button lib-btn
            secondary
            small
            type="button"
            class="template-chooser-reload-btn"
            [disabled]="isDisableReloadTemplate"
            (click)="onReloadTemplate($event)">
            Reload Template
        </button>
        <!-- Import tsv Definition File -->
        <button lib-btn
            secondary
            small
            type="button"
            class="import-tsv-btn"
            (click)="tsvFileInput.click()">
            Import .tsv File
        </button>
        <input #tsvFileInput
            hidden
            type="file"
            accept=".tsv"
            (click)="tsvFileInput.value = null"
            (change)="onImportTsvDefinitionFile($event)">
        <!-- Download tsv template file link -->
        <button #tsvFileDownload lib-btn link small
          type="button"
          (click)="onDownloadTsvDefinitionFile($event)"
        >
          Download .TSV Template
        </button>
        <!-- Skip Index Diversity Validation Checkbox  -->
        <lib-radio-checkbox type="checkbox"
            [checked]="isSkipIndexDiversityValidation"
            (change)="toggleSkipIndexDiversityValidation($event)">
            Override Index Diversity Check
        </lib-radio-checkbox>
    </div>

    <!-- Error message Banner -->
    <div #scrollbarContainer
        class="error-message"
        [hidden]="!errorMessage">
        <span>{{ errorMessage }}</span>
    </div>

    <!-- Text Editor -->
    <div class="ace-editor-container">
        <ace [config]="config"
            [(value)]="content">
        </ace >
    </div>

</ngx-smart-modal>

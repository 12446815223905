import { OnInit, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

/**
 * Represents the base class for all state less services
 */
export class BaseService implements OnDestroy {

    protected subs: SubSink = new SubSink();

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
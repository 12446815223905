import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { ISearchResourceConfig } from '@app/core/services/resource-dictionary/search-resource-dictionary-types';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { OverrideColumnDefinition, OverrideColumnParameters, SearchGridOptionsFormatter } from './search-grid-options-formatter';
import { DataTableUtilities } from '@app/core/utilities/data-table-utilities';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';

export enum RunSearchColumnFields {
    Name = 'ExperimentName',
    Owner = 'Owner',
    Instrument = 'Instrument',
    Status = 'AggregateRunStatus',
    Created = 'TimeCreated'
}

export interface RunOverrideColumnDefinition extends OverrideColumnDefinition<RunSearchColumnFields> {}

export class RunSearchGridOptionsFormatter extends SearchGridOptionsFormatter<RunSearchColumnFields> {
    private runResourceConfig: ISearchResourceConfig;

    constructor(
        private resourceDictionaryService: SearchResourceDictionaryService,
        private bsshDatePipe: BsshDatePipe
    ) {
        super();
        this.runResourceConfig = this.resourceDictionaryService.getResourceConfigByType(SearchResourceType.Run);
    }

    protected getOverrideColumnParameters(): OverrideColumnParameters<RunSearchColumnFields> {
        const inclusiveColumns: RunSearchColumnFields[] = [
            RunSearchColumnFields.Name,
            RunSearchColumnFields.Owner,
            RunSearchColumnFields.Instrument,
            RunSearchColumnFields.Created
        ];

        const overrideColumnDefinitions: OverrideColumnDefinition<RunSearchColumnFields>[] = [
            {
              colId: RunSearchColumnFields.Name,
              checkboxSelection: false
            }
        ];

        return {
            inclusiveColumns,
            overrideColumnDefinitions
        };
    }

    protected getDefaultColumnDefinitions() {
        const runConfig = this.runResourceConfig;
        const defaultColumnDefinitions = [
            {
                headerName: 'Name',
                field: 'Run.ExperimentName',
                cellRendererSelector: (params) => {
                    if(params.data) {
                        return DataTableUtilities.getLinkRenderer(
                            params.data.Run.ExperimentName,
                            runConfig.uiHrefAccessor(params.data)
                        );
                    } else {
                        return DataTableUtilities.getSpanRenderer();
                    }
                },
                colId: RunSearchColumnFields.Name,
                sortable: false,
                flex: 4.5
            },
            {
                headerName: 'Owner',
                cellRenderer: 'span',
                field: 'Run.UserOwnedBy.Name',
                suppressSizeToFit: false,
                colId: RunSearchColumnFields.Owner,
                filterActive: false,
                sortable: false,
                flex: 2
            },
            {
                headerName: 'Instrument',
                colId: RunSearchColumnFields.Instrument,
                field: 'Run.Instrument.Name',
                cellRenderer: 'tooltip',
                sortable: false,
                flex: 1.5
            },
            {
                headerName: 'Status',
                colId: RunSearchColumnFields.Status,  // gss sortField name needed for sorting param
                field: 'Run.Status',
                cellRenderer: 'tooltip',
                sortable: false,
                width: 120
              },
              {
                headerName: 'Created',
                colId: RunSearchColumnFields.Created,
                cellRenderer: 'tooltip',
                valueGetter: params => {
                  if (!params.data) { return; }
                  return this.bsshDatePipe.transform(params.data.Run.DateCreated);
                },
                sortable: false,
                flex: 2
              }
        ];
        return defaultColumnDefinitions;
    }
}

import { ISubscription } from './subscription';

export interface IWorkgroup {
    DisplayName: string;
    DomainName: string;
    ExternalProviderId: string;
    Id: string;
    IsCollaborative: boolean;
    Name: string;
    Subscription: ISubscription;
    IsDefaultRunUploadWorkgroup?: boolean;
    IsActive?: boolean;
}


export interface IUserOrWorkgroup {
    Id: string;
    Name: string;

}

export class WorkgroupRoleTypes {
    public static Owner = 'Organization.owner';
    public static Admin = 'Organization.Workgroup Admin';
    public static HasAccess = 'Organization.Has Access';
    public static DomainAdmin = 'Domain.admin';
}


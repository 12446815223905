// https://www.learnrxjs.io/learn-rxjs/operators/error_handling/retrywhen
import { Observable, throwError } from 'rxjs';
import { timer } from 'rxjs';
import {mergeMap, finalize, catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorResponse} from '@stratus/gss-ng-sdk';

export const genericRetryWhen = (
    {
        maxRetryAttempts = 2,
        scalingDuration = 500,
        retry500ErrorsOnly = true
    }: {
        maxRetryAttempts?: number;
        scalingDuration?: number;
        retry500ErrorsOnly?: boolean
    } = {}
) => (attempts: Observable<any>) => {
    return attempts.pipe(
        mergeMap((error, i) => {
            const retryAttempt = i + 1;
            // if maximum number of retries have been met
            if (retryAttempt >= maxRetryAttempts || (retry500ErrorsOnly && error.status < 500)) {
                return throwError(error);
            }
            // retry after 1s, 2s, etc...
            return timer(retryAttempt * scalingDuration);
        })
    );
};

/**
 * The amount of delay to be added to a RxJS 'delay' operators (delay() deBounceTime() or similar)
 * to avoid content flash
 */
export const observableEmitDelay: number = 250;


/**
 * Catches GSS api error response, extract the gss error object and rethrow to the subscriber.
 * @param source$ observable source
 */
export function catchAndThrowGSSApiError<T>(source$: Observable<T>): Observable<T> {
  return source$.pipe(
    catchError((errorResponse: HttpErrorResponse) => {
      const gssErrorResponse: ErrorResponse = errorResponse.error;
      return throwError(gssErrorResponse);
    })
  );
}

import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import environment from '../../../../environments/environment';
import { Constants } from '../../utilities/constants';
import { StringUtilities } from '../../utilities/string-utilities';
import { HttpUtilityService } from '../http-utility/http-utility.service';
import { LogOnOptions } from './logon-options';
import _ from 'lodash';
import { Observable, ReplaySubject, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { map, finalize, catchError } from 'rxjs/operators';
import { PlatformSessionService } from '../platform-service/session/platform-session.service';
import { SiteNavigationLinksService } from '../site-navigation/site-navigation-links.service';
import { BasespaceService } from '@bssh/ng-sdk';
import { ConsoleLogger } from '@app/core/utilities/consolelogger';
export interface IAuthorizationService {
  logon(logOnOptions: LogOnOptions): void;
  logout(force: boolean, logOnOptions: LogOnOptions): void;
  validateUserSession(): Observable<boolean>;
  getPsToken(): string;
  getDomainNameForCurrentLoggedInUser(): string;
  checkIfRequestedPathIsPublic(requestedPath: string): boolean;
}

/**
 * An Authorization service which provides auth actions such login, logout and session validation
 */
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService extends BaseService implements IAuthorizationService {

  private isAuthorizedSubject = new ReplaySubject<boolean>(1);

  /**
   * An Observable which indicates whether the user is authorized
   */
  public isAuthorized$ = this.isAuthorizedSubject.asObservable();

  constructor(private cookieService: CookieService,
              private httpUtilityService: HttpUtilityService,
              private platformSessionService: PlatformSessionService,
              private siteNavigationLinksService: SiteNavigationLinksService,
              private basespaceApi: BasespaceService) {
    super();
  }

  /**
   * Handles user log on
   * @param logOnOptions Login options
   */
  logon(logOnOptions: LogOnOptions = { returnUrl: this.httpUtilityService.getCurrentRequestUrl(), uiMode: null, deviceType: null }) {
    const psToken = this.getPsToken();
    this.subs.sink = this.validateUserSession().subscribe({
      next: (isSessionValid) => {
        // If psToken cookie is not present
        // Or if the session has expired on the platform side, redirect to login.
        if (StringUtilities.isBlank(psToken) || !isSessionValid) {
          this.tryRedirectToPlatformLoginPage(logOnOptions);
        } else {
          // user has a cookie and it is valid.
          this.isAuthorizedSubject.next(isSessionValid);
        }
      }
    });
  }
  /**
   * Handle user log out
   * @param force Force logout by redirecting to platform login page, even though the user may be on one of the publicly accesible pages
   * @param logOnOptions The logon options
   */
  // tslint:disable-next-line: max-line-length
  logout(force: boolean = false, logOnOptions: LogOnOptions = { returnUrl: this.httpUtilityService.getCurrentRequestUrl(), uiMode: null, deviceType: null }): void {

    const psToken = this.getPsToken();

    if (!StringUtilities.isBlank(psToken)) {
       // Todo: remove Platform logout logic once we ascertain that BSSH api is stable for a release.
      if (environment.useBsshSessionInvalidate) {
        ConsoleLogger.logMessage('Using BSSH Session Invalidate API');
        // Call out to BSSH API to invalidate User Session on Platform + Update Login session
        this.subs.sink = this.basespaceApi.DeleteV2SessionInvalidate().pipe(
          finalize(() => {
            // Delete psToken cookie locally
            this.deletePsTokenCookie();
            // Redirect to platform login.
            this.tryRedirectToPlatformLoginPage(logOnOptions, force);
          })
        ).subscribe();

        return;
      }
      ConsoleLogger.logMessage('Using Platform Session Invalidate API');
      // Call out to platform to invalidate the pstoken.
      this.subs.sink = this.platformSessionService.invalidateSessionToken(psToken).pipe(
        finalize(() => {
          // Delete psToken cookie locally
          this.deletePsTokenCookie();
          // Redirect to platform login.
          this.tryRedirectToPlatformLoginPage(logOnOptions, force);
        })
      ).subscribe();
    } else {
      // If there is no cookie, just redirect.
      this.tryRedirectToPlatformLoginPage(logOnOptions, force);
    }

  }

  /**
   * Checks if the current user session(defined by psToken cookie) is currently active.
   */
  validateUserSession(): Observable<boolean> {
    return this.platformSessionService.validateSessionToken(this.getPsToken()).pipe(
      map((value) => {
        const isActive = value && value.isValid;
        this.isAuthorizedSubject.next(isActive);
        return isActive;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * Generates a platform logon url
   * @param returnUrl The Url to redirect upon successful logon
   * @param uiMode The Ui Mode
   * @param deviceType The Device Type
   */
  private generateLogonPlatformUrl(returnUrl?: string, uiMode?: string, deviceType?: string) {
    const platformBaseUrl = environment.platformUrl;
    const clientId = environment.clientId;
    const baseUrl = this.httpUtilityService.getBaseUrlFromCurrentRequestUrl();
    // If no return Url is specified, default to the base url.
    const rUrl = new URL(StringUtilities.isBlank(returnUrl) ? baseUrl : returnUrl);

    // assign Client vars
    let clientVars = '';
    clientVars = StringUtilities.toBase64String(rUrl.href).replace('+', '-').replace('/', '_');
    clientVars = _.trimEnd(clientVars, '=');

    const delimiter = platformBaseUrl.includes('?') ? '&' : '?';

    const options: string[] = [];
    if (!StringUtilities.isBlank(uiMode)) {
      options.push(`uiMode=${uiMode}`);
    }

    if (!StringUtilities.isBlank(deviceType)) {
      options.push(`deviceType=${deviceType}`);
    }
    const displayOptions = _.join(options, '&');

    // tslint:disable-next-line: max-line-length
    return `${platformBaseUrl}${delimiter}rURL=${baseUrl}&clientId=${clientId}&clientVars=${clientVars}&redirectMethod=GET${displayOptions}`;

  }

  getPsToken(): string {
    return this.cookieService.get(Constants.Auth.authpsToken);
  }

  /**
   * Deletes pstoken cookie and calls out to Platform to invalidate session token on the server.
   */
  private deletePsTokenCookie() {
    this.cookieService.delete(Constants.Auth.authpsToken, '/', environment.psTokenDomain);
  }

  /**
   * Redirects to Platform's login page.
   */
  // tslint:disable-next-line: max-line-length
  private tryRedirectToPlatformLoginPage(logOnOptions: LogOnOptions = { returnUrl: null, uiMode: null, deviceType: null }, force: boolean = false) {
    // If the requested page is public, do not redirect to platform logon page.
    if (!this.checkIfRequestedPathIsPublic() || force) {
      const platformLogonUrl = this.generateLogonPlatformUrl(logOnOptions.returnUrl, logOnOptions.uiMode, logOnOptions.deviceType);
      // User is not logged in, redirect to platform login.
      this.httpUtilityService.redirectToUrl(platformLogonUrl);
    } else {
      this.isAuthorizedSubject.next(true);
    }
  }

  /**
   * Gets the domain name for the currently logged in user
   */
  getDomainNameForCurrentLoggedInUser(): string {
    return this.httpUtilityService.getDomainFromPsToken(this.getPsToken());
  }


  /**
   * Detremines if the requested page path can be accessed without authorization.
   * @param requestedPath Optional. The requested path to check.
   * The current request path is considered if no value is supplied
   */
  public checkIfRequestedPathIsPublic(requestedPath?: string): boolean {
    const requestedUrlPath = requestedPath ? requestedPath : this.httpUtilityService.getPathFromCurrentRequestUrl();
    return this.siteNavigationLinksService.noAuthPageUrls.includes(StringUtilities.trimTrailingSlash(requestedUrlPath));
  }

}

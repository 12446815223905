export interface IConfirmationModalText {
    title: string;
    closeButtonText: string;
    confirmButtonText: string;
    content: string;
}

export type ConfirmationModalType =
    'UNZIP_RUN';

export const confirmationModalTexts: Readonly<{[key in ConfirmationModalType]: IConfirmationModalText}> = Object.freeze({
    UNZIP_RUN: {
        title: 'Unzip Run',
        closeButtonText: 'Cancel',
        confirmButtonText: 'Unzip',
        content: 
        `<p> Unzipping a run may take a while and many run tools (e.g., move to trash) will be temporarily
         disabled while it is in progress. You don't need to stay logged in until it completes.
        <p>`
    }
});

import { Injectable } from '@angular/core';
import { UserSessionType } from '@models/user/session-type';
import { V2UserSetting } from '@bssh/ng-sdk';
import { UserSettingsStore } from '@app/core/store/usersettings/user-settings.store';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { BaseService } from '../base.service';

export enum UserTableSetting {
  USER_PROJECT_DETAILS_ANALYSIS_SETTINGS_NAME = 'UI.ColumnSettings.ProjectDetailsAnalyses',
  USER_PROJECT_DETAILS_BIOSAMPLE_SETTINGS_NAME = 'UI.ColumnSettings.ProjectDetailsBiosamples',
  USER_PROJECT_DETAILS_SAMPLE_SETTINGS_NAME = 'UI.ColumnSettings.ProjectDetailsSamples',
  USER_PROJECT_DETAILS_FASTQ_DATASET_SETTINGS_NAME = 'UI.ColumnSettings.ProjectDetailsFastqDatasets',
  USER_PROJECT_DETAILS_OTHER_DATASET_SETTINGS_NAME = 'UI.ColumnSettings.ProjectDetailsOtherDatasets',
  USER_BIOSAMPLE_DETAILS_ANALYSIS_SETTINGS_NAME = 'UI.ColumnSettings.BiosampleDetailsAnalyses',
  USER_BIOSAMPLE_DETAILS_FASTQ_DATASET_SETTINGS_NAME = 'UI.ColumnSettings.BiosampleDetailsFastqDatasets',
  USER_BIOSAMPLE_DETAILS_OTHER_DATASET_SETTINGS_NAME = 'UI.ColumnSettings.BiosampleDetailsOtherDatasets',
}

export interface IBsUserSettingsService {
  getUserSettings(userSessionType: UserSessionType): V2UserSetting[];
  setUserSettings(userSessionType: UserSessionType, settings: V2UserSetting[]): void;
  getUserSetting(userSessionType: UserSessionType, settingName: string): V2UserSetting;
}

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService extends BaseService implements IBsUserSettingsService {
  public static readonly UserTimeSeriesFilterSettingsName = 'UI.TimeSeriesFilterSettings.RunInsights';
  public static readonly UserRunMasterListSettingsName = 'UI.ColumnSettings.RunMasterList';
  public static readonly UserAnalysisMasterListSettingsName = 'UI.ColumnSettings.AnalysisMasterList';
  public static readonly UserBiosampleMasterListSettingsName = 'UI.ColumnSettings.BiosamplesMasterList';
  public static readonly UserAppBookmarksSettingsName = 'UI.AppSettings.Bookmarks';

  private loggedInUserSettings: V2UserSetting[];
  private actingUserSettings: V2UserSetting[];

  constructor(private userSettingsStore: UserSettingsStore,
    private currentUserStore: CurrentUserStore) {
    super();
    this.init();
  }

  private init() {
    // Update the settings in this service when the settings in the store has changed
    this.subs.sink = this.userSettingsStore.stateChanged.subscribe({
      next: state => {
        if (state) {
          this.loggedInUserSettings = state.loggedInUserSettingsList;
          this.actingUserSettings = state.actingUserSettingsList;
        }
      }
    });

    // Refresh settings for the acting user when the context has changed
    this.subs.sink = this.currentUserStore.contextChanged$.subscribe({
      next: () => {
        this.userSettingsStore.loadUserSettings(UserSessionType.ActingUser, true);
      }
    });
  }

  getUserSettings(userSessionType: UserSessionType): V2UserSetting[] {
    if (userSessionType === UserSessionType.LoggedInUser) {
      return this.loggedInUserSettings;
    } else {
      return this.actingUserSettings;
    }
  }

  setUserSettings(userSessionType: UserSessionType, settings: V2UserSetting[]): void {
    this.userSettingsStore.setUserSettings(userSessionType, settings);
  }

  getUserSetting(userSessionType: UserSessionType, settingName: string): V2UserSetting {
    return this.getUserSettings(userSessionType)
      .find(x => x.Name.toLocaleLowerCase() === settingName.toLocaleLowerCase());
  }
}

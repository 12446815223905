import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NgxSmartModalService, ProgressComponent } from '@bssh/comp-lib';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-launch-analysis-progress-bar-modal',
  templateUrl: './launch-analysis-progress-bar-modal.component.html',
  styleUrls: ['./launch-analysis-progress-bar-modal.component.scss'],
})
export class LaunchAnalysisProgressBarModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {

  modalTitle = 'Launching Analysis...';
  modalType = 'LaunchAnalysisProgressBarModal';
  modalClass = 'modal-dialog-centered';

  @ViewChild('progressBar', { static: false }) progressBar: ProgressComponent;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // TODO: Basically just faking the progress here, could be nice to actually reflect real progress
    interval(1000).pipe(take(20)).subscribe({
      next: (i) => {
        this.progressBar.set(i*5);
      },
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onCompleted(): void {
    this.modal.close();
  }

}

import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { BytesPipe } from './bytes.pipe';
import { IRun } from '@app/core/model/runs/run';

@Pipe({
    name: 'runTotalSize',
})
@Injectable({
    providedIn: 'root'
})
export class RunTotalSizePipe implements PipeTransform {
    constructor(private _bytePipe: BytesPipe) {
    }

    transform(run: IRun): string {
        let formattedBytes = '--';

        if (run && run.Status && run.TotalSize != undefined ) {
            switch (run.Status) {
                case 'Running':
                case 'Uploading':
                    if (run.TotalSize < 1) {
                        break;
                    }
                default:
                    formattedBytes = this._bytePipe.transform(run.TotalSize);
            }
        }

        return formattedBytes;
    }
}

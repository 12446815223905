import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { INewLibraryRequeueInput, INewLibraryRequeueOutput } from '../model/action-modal';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';
import { ILabWorkflow } from '@app/core/model/labWorkflow';
import { IBioSample } from '@app/core/model/bioSamples/bioSample';
import { BasePairUtils } from '@app/core/utilities/base-pair.utilities';
import { isNullOrUndefined } from 'util';
import { ILabRequeueComponentOutput } from '../components/lab-requeue/lab-requeue.component';

const CANCELLED = 'Canceled';

@Component({
  selector: 'app-requeue-biosample-modal',
  templateUrl: './requeue-biosample-modal.component.html',
  styleUrls: []
})
export class RequeueBiosampleModalComponent extends BaseModalComponent implements OnInit {
  modalType = 'RequeueBiosampleModalComponent';
  modalTitle = ModalTexts.REQUEUE_BIOSAMPLE.MODAL_TITLE;
  confirmButtonText = ModalTexts.REQUEUE_BIOSAMPLE.CONFIRM_BUTTON_TEXT;

  informationListToDisplay = [];
  biosample: IBioSample = null;
  selectedLabWorkflow: ILabWorkflow = null;
  labWorkflows: ILabWorkflow[] = null;

  constructor(public ngxSmartModalService: NgxSmartModalService,
    private cdr: ChangeDetectorRef) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subs.sink = this.data.subscribe((input: INewLibraryRequeueInput) => {
      if (!input) {
        return ;
      }

      this.biosample = input.biosample;
      this.selectedLabWorkflow = input.selectedLabWorkflow;
      this.labWorkflows = input.labWorkflows;
      this.errorMsg = this.validateIsAllowed();
      this.initInformationList();
    });

    this.cdr.detectChanges();
  }

  private initInformationList() {
    this.informationListToDisplay = [
      {resourceName: 'Biosample Name', resourceValue: this.biosample.BioSampleName},
      {resourceName: 'Current Status', resourceValue: this.biosample.Status}
    ];

    if (this.selectedLabWorkflow && this.selectedLabWorkflow.prepRequest &&
      this.selectedLabWorkflow.prepRequest.RequiredYield) {
      const bpu = BasePairUtils.getBasePairUnits(this.selectedLabWorkflow.prepRequest.RequiredYield);
      const resourceValue = bpu.result + ' ' + bpu.basePairUnit.abbreviation;
      this.informationListToDisplay.push({resourceName: 'Required Yield', resourceValue});
    }

    if (this.selectedLabWorkflow && this.selectedLabWorkflow.missingYield) {
      const bpu = BasePairUtils.getBasePairUnits(Math.max(this.selectedLabWorkflow.missingYield, 0));
      const resourceValue = bpu.result + ' ' + bpu.basePairUnit.abbreviation;
      this.informationListToDisplay.push({resourceName: 'Missing Yield', resourceValue});
    }
  }

  protected constructError(error: any): string {
    return error.error.ErrorMessage;
  }


  dataChanged($event: ILabRequeueComponentOutput) {
    this.modal = this.ngxSmartModalService.getModal(this.modalType);
    const componentOutput: INewLibraryRequeueOutput = {
      PrepRequestId: $event.PrepRequestId,
      RequestedAdditionalYield: $event.RequestedAdditionalYield,
    };

    if (!isNullOrUndefined(componentOutput) && !isNullOrUndefined(this.modal)) {
      this.updateDisableConfirmButton($event);
      this.modal.setData(componentOutput, true);
    }
  }

  /**
   * determines whether a requeue is possible (i.e., must have at least one active lab workflow)
   * does not account for user input (see updateValidity())
   */
  private validateIsAllowed(): string {
      if (this.labWorkflows.length < 1) {
          return ModalTexts.REQUEUE_BIOSAMPLE.NO_ACTIVE_PREP_REQUEST;
      }

      // Customized error message for canceled status biosample status
      if (this.biosample.Status === CANCELLED) {
          return ModalTexts.REQUEUE_BIOSAMPLE.CANCEL_BIOSAMPLE_STATUS;
      }

      return;
  }

  /**
   * Checks if the Confirm button should be enabled
   * @param output Output from the modal
   */
  private updateDisableConfirmButton(output: ILabRequeueComponentOutput) {

    // checks if the form is valid and the requested additional yield
    // is within the min and max range
    if (!isNullOrUndefined(output.errorMsg) ||
        isNullOrUndefined(output.RequestedAdditionalYield) ||
        !output.isInputValid ||
        isNullOrUndefined(output.PrepRequestId)) {
      this.disableConfirmButton = true;
    } else {
      this.disableConfirmButton = false;
    }
  }

}


import { Injectable, OnDestroy } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthorizationService } from '../../authorization/authorization.service';
import { ConsoleLogger } from '@app/core/utilities/consolelogger';
import { SessionTrackerService } from './platform-session-tracker.service';
import { Subject } from 'rxjs';
import { UtilityService } from '@app/core/utilities/utility.service';
import { throttleTime } from 'rxjs/operators';

enum IdleState {
  NOT_STARTED,
  STARTED,
  TIMEDOUT,
  WARNING
}

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService implements OnDestroy {

    public idleState = IdleState.NOT_STARTED;
    public lastActiveAt: number;

    private sessionCloseToTimeOut: Subject<any> = new Subject();
    public sessionCloseToTimeOut$ = this.sessionCloseToTimeOut.asObservable();

    private sessionTimedOut: Subject<boolean> = new Subject();
    public sessionTimedOut$ = this.sessionTimedOut.asObservable();

    constructor(private idle: Idle,
                private keepAlive: Keepalive,
                private authService: AuthorizationService,
                private sessionTrackerService: SessionTrackerService,
                private utilityService: UtilityService) { }

    public startIdleWatcher(enabled: boolean) {
        if (enabled) {
            this.init();
        }
    }

    /**
     * start Idle Watch using platform Session Tracker API(s)
     * https://confluence.illumina.com/display/PLAT/Idle+Timeout+Service
     */
    public init() {
        this.log('init session idle timeout');
        this.idle.onIdleStart.subscribe(() => this.onIdleStart()); // Fires when timeout is about to start
        this.idle.onTimeout.subscribe(() => this.onUserIdleTimeout());
        this.idle.onTimeoutWarning.subscribe((countdown: number) => this.onTimeoutWarning(countdown), () => this.logout());
        this.idle.onIdleEnd.subscribe(() => this.reset());

        // Debounce idle interrupt event, update active time to latest every 1 min
        this.idle.onInterrupt.pipe(throttleTime(60 * 1000)).subscribe(() => this.setActiveTime(this.utilityService.getCurrentTimeInSec()));

        this.keepAlive.onPing.subscribe(() =>  this.updateSessionTracker());

        this.idle.watch();
        this.idleState = IdleState.STARTED;
    }

    public logout() {
        this.idle.stop();
        this.keepAlive.stop();
        this.authService.logout();
    }

    public reset() {
        this.idle.watch();
        this.idleState = IdleState.STARTED;
        this.sessionCloseToTimeOut.next();
        this.sessionTimedOut.next(false);
        this.log('Timer reset');
    }

    // Fires when timeout is about to start
    public onIdleStart() {
        this.idleState = IdleState.STARTED;
    }

    public onTimeoutWarning(countdown: number) {
        this.idleState = IdleState.WARNING;
        this.sessionCloseToTimeOut.next({
            type: IdleState[IdleState.WARNING].toString(),
            idleTimeOutCountdown: countdown
        });
    }

    public onUserIdleTimeout() {
        this.log('User Timed Out. Checking Platform for session activity.');
        this.idleState = IdleState.TIMEDOUT;
        this.sessionTimedOut.next(true);
        // Check for session and log out
        this.sessionTrackerService.isIdle().subscribe((response) => {
        if (response.isIdle) {
            /*
            * Get user active login session from BSSH DB
            * and update loginsession.LoggedOffOn
            */
            this.log('Logging out.');
            this.logout();
        } else {
            this.log('User active in other app. Reset timers');
            this.reset();
        }
        }, () => this.logout());
    }

    private updateSessionTracker() {
        if (this.idleState !== IdleState.TIMEDOUT) {
            const lastActiveBefore = this.utilityService.getCurrentTimeInSec() - this.lastActiveAt;
            this.sessionTrackerService.updateSessionTracker(lastActiveBefore)
                .subscribe(
                    () => this.log('Heartbeat sent'),
                    () => this.logout());

        }
    }

    public setIdle(value: number) {
        this.idle.setIdle(value);
    }

    public getIdle(): number {
      return this.idle.getIdle();
    }

    public setTimeout(value: number) {
        this.idle.setTimeout(value);
    }

    public getTimeout(): number {
        return this.idle.getTimeout();
    }

    public setKeepaliveInterval(value: number) {
        this.keepAlive.interval(value);
    }

    public setInterrupts(interruptSources: any[]) {
        this.idle.setInterrupts(interruptSources);
    }

    public setActiveTime(time: number) {
        this.lastActiveAt = time;
    }

    private log(message) {
        const timestamp = new Date();
        const formattedDate = timestamp.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
        const formattedTime = + timestamp.getHours() + ':' + timestamp.getMinutes() + ':' + timestamp.getSeconds();
        ConsoleLogger.logMessage(`[BsshIdle] ${formattedDate} ${formattedTime}`, message);
        ConsoleLogger.logMessage(`[BsshIdle] isRunning:${this.idle.isRunning()} isIdling:${this.idle.isIdling()}`);
    }
    ngOnDestroy(){
        this.idle.stop();
        this.keepAlive.stop();
    }
}

<div class="requeue-fastq">
    <div class="requeue-fastq__container">
        <div class="requeue-fastq__content">
            <div class="requeue-fastq__content--scrollable" #scrollContainer>
                <section class="requeue-fastq__result">
                    <ul class="requeue-fastq__result__list" *ngIf="requeueDetailsList?.length > 0">
                        <li class="requeue-fastq__result__item" *ngFor="let item of requeueDetailsList">
                            <span class="requeue-fastq__result__name">
                                {{item.name}}
                            </span>
                            <span class="requeue-fastq__result__value">
                                {{item.value}}
                            </span>
                        </li>
                    </ul>
                </section>
            </div>            
        </div>
    </div>
</div>
import { SearchResourceDictionaryService } from "@app/core/services/resource-dictionary/search-resource-dictionary.service";
import { BsshDatePipe } from "@app/core/utilities/pipes/bssh-date.pipe";
import { BytesPipe } from "@app/core/utilities/pipes/bytes.pipe";
import { ProjectSearchColumnFields, ProjectSearchGridOptionsFormatter } from "@app/search/gridOptions/project-search-grid-options-formatter"
import { OverrideColumnDefinition, OverrideColumnParameters } from "@app/search/gridOptions/search-grid-options-formatter";

// Inheriting from ProjectSearchGridOptionsFormatter because chooser uses search api, datasource, and table columns
export class ProjectChooserGridOptionsFormatter extends ProjectSearchGridOptionsFormatter {
    constructor(
        public resourceDictionaryService: SearchResourceDictionaryService,
        public bytePipe: BytesPipe,
        public bsshDatePipe: BsshDatePipe
    ) {
        super(resourceDictionaryService, bytePipe, bsshDatePipe);
    }

    protected getOverrideColumnParameters(): OverrideColumnParameters<ProjectSearchColumnFields> {
        const inclusiveColumns: ProjectSearchColumnFields[] = [
            ProjectSearchColumnFields.Name,
            ProjectSearchColumnFields.Owner,
            ProjectSearchColumnFields.DateUpdated
          ];

        const overrideColumnDefinitions: OverrideColumnDefinition<ProjectSearchColumnFields>[] = [];

        return {
            inclusiveColumns,
            overrideColumnDefinitions
        };
    }
}
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Renderer2
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

// Copied from https://git.illumina.com/SEES/kratos/blob/develop/kratos-ui/src/app/directives/rad-dropdown-form.directive.ts
// TODO: We really should have a shared library to implement these radiance directives. I straight up copy pasta this from the codebase above

@Directive({
  selector: 'rad-select',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadSelectDirective,
      multi: true
    }
  ]
})
export class RadSelectDirective implements ControlValueAccessor {
  constructor(private element: ElementRef, private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    this.onChange = () => {};
    this.onTouched = () => {};
  }

  onChange: (value: string) => void;
  onTouched: () => void;

  writeValue(value: string) {
    this.renderer.setProperty(this.element.nativeElement, 'value', value);
  }

  @HostListener('change', ['$event.detail'])
  _handleChange(value: any) {
    if (value && (value.option || value.options)) {
      if (value.option) {
        this.onChange(value.option.value);
      }

      if (value.options) {
        this.onChange(value.options.map((ele: any) => ele.value));
      }

      this.cdr.detectChanges();
    }
  }

  registerOnChange(fn: (value: string) => void) {
    this.onChange = (event) => {
      fn(event);
    };
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.element.nativeElement, 'disabled', isDisabled);
  }

}

<ngx-smart-modal
  #UpdateIndexAdapterKit
  class="update-iak-modal"
  [identifier]="modalType"
  [title]="title"
  closable="false"
  dismissible="false"
  escapable="false"
  [closeButtonText]="closeButtonText"
  [confirmButtonText]="confirmButtonText"
  [disableConfirmButton]="isUiEditor && !iakFormGroup.valid"
>
  <rad-tab-group class="tab-group" size="small" new-tab-button="false" *ngIf="showTabGroup">
    <rad-tab *ngFor="let tab of menuItems"
      [attr.label]="tab.label"
      [attr.selected]="tab.selected"
      (selectedTabChange)="onTabChange(tab)"
    >
    </rad-tab>
  </rad-tab-group>
  <form
    [formGroup]="iakFormGroup"
    novalidate
    ng-submit="onCreateNewKitClick()"
    autocomplete="off"
  >
    <div *ngIf="isUiEditor">
      <!-- Name/Display Name -->
      <div class="form-group">
        <h4 class="form-group-title">Name*</h4>
        <lib-form-field>
          <input
            lib-input
            type="text"
            formControlName="name"
            placeholder="Type Index Adapter Kit name here..."
            data-cy="name"
          />
        </lib-form-field>
        <div *ngIf="!(name.errors && (name.dirty || name.touched))" class="hint-text">
          (alphanumeric, underscore, hyphens, and periods allowed)
        </div>
        <div *ngIf="name.errors && (name.dirty || name.touched)" class="error-message">
          {{ getIAKNameErrorMessage() }}
        </div>
      </div>

      <div class="form-group">
        <h4 class="form-group-title">Display Name</h4>
        <lib-form-field>
          <input
            lib-input
            type="text"
            formControlName="displayName"
            placeholder="Type display name here..."
            data-cy="displayName"
          />
        </lib-form-field>
      </div>

      <div class="form-group">
        <h4 class="form-group-title">Description</h4>
        <!-- Description -->
        <lib-form-field>
          <input
            lib-input
            type="text"
            formControlName="description"
            placeholder="Type description here..."
            data-cy="description"
          />
        </lib-form-field>
      </div>

      <div class="form-group">
        <h4 class="form-group-title">Adapter Sequence Read 1</h4>
        <!-- Adapter Sequence Read 1 -->
        <lib-form-field>
          <input
            lib-input
            type="text"
            formControlName="adapterSequenceRead1"
            placeholder="Type adapter sequence read 1 here..."
            data-cy="adapterSequenceRead1"
          />
        </lib-form-field>
        <div
          class="error-message"
          *ngIf="(adapterSequenceRead1.dirty || adapterSequenceRead1.touched) && adapterSequenceRead1.errors"
        >
          Adapter Sequence Read must only contain a sequence of AGCT in any order.
          Each base (AGCT) can optionally be followed by a +.
        </div>
      </div>
      <div class="form-group">
        <h4 class="form-group-title">Adapter Sequence Read 2</h4>
        <!-- Adapter Sequence Read 2 -->
        <lib-form-field>
          <input
            lib-input
            type="text"
            formControlName="adapterSequenceRead2"
            placeholder="Type adapter sequence read 2 here..."
            data-cy="adapterSequenceRead2"
          />
        </lib-form-field>
        <div
          class="error-message"
          *ngIf="(adapterSequenceRead2.dirty || adapterSequenceRead2.touched) && adapterSequenceRead2.errors"
        >
          Adapter Sequence Read must only contain a sequence of AGCT in any order.
          Each base (AGCT) can optionally be followed by a +.
        </div>  

        <div class="form-group">
          <!-- <div class="d-flex"> -->
          <h4 class="form-group-title">Allowed Index Strategies*</h4>
          <!-- </div> -->
          <!-- Allowed Index Strategies radio -->
          <lib-radio-checkbox-list
            type="checkbox"
            [options]="indexStrategyOptions"
            name="allowedIndexStrategies"
            formControlName="allowedIndexStrategies"
            valueProperty="value"
            labelProperty="text"
          >
          </lib-radio-checkbox-list>
          <div
            class="error-message"
            *ngIf="allowedIndexStrategies && allowedIndexStrategies.value.length === 0"
          >
            Must select at least one.
          </div>
      </div>
      <div class="form-group">
          <h5 class="form-group-title">Default Index Strategy</h5>
          <!-- Default Index Strategies radio -->
          <lib-radio-checkbox-list
            type="radio"
            [options]="defaultIndexStrategyOptions"
            name="defaultIndexStrategy"
            formControlName="defaultIndexStrategy"
            valueProperty="value"
            labelProperty="text"
          >
          </lib-radio-checkbox-list>
          <div class="error-message" *ngIf="defaultIndexStrategy.errors">
          {{ getDefaultIndexStrategyErrorMessage() }}
        </div>
      </div>
        <div class="row">
          <div class="form-group col-6">
            <!-- Index 1 Sequences -->
            <h4 class="form-group-title">
              Index 1 Sequences
              <lib-popover
                popoverType="info"
                popoverTriggerEvents="mouseenter:mouseleave"
                popoverSize="medium"
                popoverPlacement="right"
              >
                <span>Enter IndexName: IndexSequence, e.g. D701: ATTACTCG</span>
              </lib-popover>
            </h4>
            <div class="ace-editor-container mt-4">
              <ace [(value)]="index1SequencesValue"></ace>
            </div>
          </div>
          <div class="form-group col-6">
            <!-- Index 2 Sequences -->
            <h4 class="form-group-title">
              Index 2 Sequences
              <lib-popover
                popoverType="info"
                popoverTriggerEvents="mouseenter:mouseleave"
                popoverSize="medium"
                popoverPlacement="right"
              >
                <span>Enter IndexName: IndexSequence, e.g. D501: ATTACTCG</span>
              </lib-popover>
            </h4>
            <div class="ace-editor-container mt-4">
              <ace [(value)]="index2SequencesValue"></ace>
            </div>
          </div>
        </div>
        <div class="form-group">
          <!-- Fixed Index Positions -->
          <h4 class="form-group-title">
            Fixed Index Positions
            <lib-popover
              popoverType="info"
              popoverTriggerEvents="mouseenter:mouseleave"
              popoverSize="auto"
              popoverPlacement="right"
            >
              <span>
                Enter PlateName-WellPosition/Index1Name-Index2Name, e.g. A-A01/D701-D501.<br>
                Omit PlateName if not applicable, e.g. A01/D701-D501
              </span>
            </lib-popover>
          </h4>
          <div class="ace-editor-container mt-4">
            <ace [config]="config" [(value)]="fixedIndexPositionsValue"></ace>
          </div>
        </div>
      </div>
    </div>

    <!-- Text Editor -->
    <div *ngIf="!isUiEditor" class="form-group">
      <div class="ace-editor-container__large mt-4">
        <ace [config]="config" [(value)]="rawIAKText"></ace>
      </div>
    </div>

    <div class="form-group" *ngIf="isUiEditor">
      <rad-accordion
        header="Advanced Settings"
        style="--rad-accordion-content-margin: 0px"
      >
        <div slot="content">
          <div class="form-group">
            <h4 class="form-group-title">
              Override Index1 Length
              <lib-popover
                popoverType="info"
                popoverTriggerEvents="mouseenter:mouseleave"
                popoverSize="medium"
                popoverPlacement="right"
              >
                <span>
                  Enter a value if the number of cycles for Index1 should be
                  different from the actual index1 sequence length
                </span>
              </lib-popover>
            </h4>
            <!-- Override Index 1 length -->
            <lib-form-field>
              <input
                lib-input
                type="text"
                formControlName="numCyclesIndex1Override"
                data-cy="numCyclesIndex1Override"
              />
            </lib-form-field>
          </div>
          <div class="form-group">
            <h4 class="form-group-title">
              Override Index2 Length
              <lib-popover
                popoverType="info"
                popoverTriggerEvents="mouseenter:mouseleave"
                popoverSize="medium"
                popoverPlacement="right"
              >
                <span>
                  Enter a value if the number of cycles for Index2 should be
                  different from the actual index2 sequence length
                </span>
              </lib-popover>
            </h4>
            <!-- Override Index 2 Length -->
            <lib-form-field>
              <input
                lib-input
                type="text"
                formControlName="numCyclesIndex2Override"
                data-cy="numCyclesIndex2Override"
              />
            </lib-form-field>
            <div
              class="error-message"
              *ngIf="
                ((numCyclesIndex1Override.dirty || numCyclesIndex1Override.touched) &&
                  numCyclesIndex1Override.errors) ||
                ((numCyclesIndex2Override.dirty || numCyclesIndex2Override.touched) &&
                  numCyclesIndex2Override.errors)
              "
            >
              'Override cycles length has to be a number.'
            </div>
          </div>
          <div class="form-group">
            <h4 class="form-group-title">
              Override Cycles Pattern
              <lib-popover
                popoverType="info"
                popoverTriggerEvents="mouseenter:mouseleave"
                popoverSize="auto"
                popoverPlacement="right"
              >
                <span ngNonBindable>
                  Enter the OverrideCycles pattern in Read1;Index1;Index2;Read2 format.<br>
                  For Read1, the valid values are: Y, U, N, {{Read1Length}}<br>
                  For Read2, the valid values are: Y, U, N, {{Read2Length}}<br>
                  For Index1, the valid values are: I, U, N, {{Index1Length}}<br>
                  For Index2, the valid values are: I, U, N, {{Index2Length}}<br>
                  Read1Length and Read2Length are special tokens, which system will replace with the default read length defined in the library prep kit.<br>
                  Index1Length and Index2Length are special tokens, which system will replace with the Index1 or Index2 length defined in the index kit.<br>
                  e.g. U28N{{Read1Length-28}};I10N{{Index1Length-10}};N{{Index2Length-10}}I10;Y90N{{Read2Length-90}}<br>
                  Y{{Read1Length}};I8;U2I8;Y{{Read2Length}}
                </span>
              </lib-popover>
            </h4>
            <lib-form-field>
              <input
                lib-input
                type="text"
                formControlName="overrideCycles"
                data-cy="overrideCycles"
              />
            </lib-form-field>
            <div
              class="error-message"
              *ngIf="(overrideCycles.dirty || overrideCycles.touched) && overrideCycles.errors"
            >
              {{ getOverrideCyclesErrorMessage() }}
            </div>
          </div>
        </div>
      </rad-accordion>
    </div>
    <div class="form-group">
      <!-- Skip Index Diversity Validation -->
      <lib-radio-checkbox
        [checked]="skipIndexDiversityValidation.value"
        (change)="onCheckboxChanged(skipIndexDiversityValidation, $event)"
      >
        Override Index Diversity Check
      </lib-radio-checkbox>
      <!-- Force Update -->
      <!-- TODO: add tooltip -->
      <lib-radio-checkbox
        [checked]="force.value"
        (change)="onCheckboxChanged(force, $event)"
      >
        Force Update
      </lib-radio-checkbox>
    </div>
  </form>
</ngx-smart-modal>

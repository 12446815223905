<ngx-smart-modal
  #fileUploadProgressModal
  class="file-upload-progress-modal"
  identifier="fileUploadProgressModal"
  title="Uploading"
  [closable]="false"
  [escapable]="false"
  [dismissible]="false"
  [showCloseButton]="false"
  [showConfirmButton]="true"
  [disableConfirmButton]="disableCancel"
  confirmButtonText="Cancel"
  (onOpenFinished)="onModalOpen()">

  <section class="file-upload-progress-modal__progress-wrapper">
    <div>{{ statusText }}</div>
    <lib-progress 
      class="file-upload-progress-modal__progress"
      #fileUploadProgressBar
      [id]="fileUploadProgressBar"
      [loader]="true" 
      [spinner]="false"
      [min]="0">
    </lib-progress>
    <div>{{ progressText }}</div>
  </section>

</ngx-smart-modal>
import { ObservableStore, StateWithPropertyChanges } from '@codewithdan/observable-store';
import * as _ from 'lodash';
import { IBaseState } from './base.state';
import { ConsoleLogger } from '../../utilities/consolelogger';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { isNullOrUndefined } from 'util';
import { delay, finalize, retryWhen } from 'rxjs/operators';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';

// See https://github.com/DanWahlin/Observable-Store#store-api for more details on the observable store api
export interface IObservableStore<T> {
    // Subscribe to the changes of state of the store
    stateChanged: Observable<T>;
    // Subscribe to the changes of state of the store and also the specific properties that changed
    stateWithPropertyChanges: Observable<StateWithPropertyChanges<T>>;
    loading$: Observable<boolean>;
}

/*
  Base class for the store services which maintain application state.
  Since duplicate properties in store causes problems, it is necessary to have unique property names in the state.
  An exception is when a Store is interested in changes to a property managed by another Store, it can be supplied in the base constructor,
  but it should be treated as readonly, and no changes should be made.
  E.g: 'currentUser' is managed by CurrentUserStore but other stores can subscribe to the changes.
*/
export class BaseObservableStore<T extends IBaseState> extends ObservableStore<T> implements OnDestroy {
    /**
     * Creates an Observable store
     * @param statePropertyNames The list of property names in 'T' that will be monitored for changes
     * and a 'stateChanged' observable will emit. If a property name is not specified in this list, changes to that property will not
     * trigger notifications via 'stateChanged' observable.
     */
    constructor(protected statePropertyNames: Array<keyof T>) {
        // The log and state history tracking settings are set at start up in main.ts
        super({
            stateSliceSelector: state => {
                return this.getStateSliceForType(statePropertyNames, state);
            }
        });
        this.validatePropertyNames();
    }

    static allStorePropertyNames: string[] = [];
    /**
     * Manage subscriptions to rxjs observables in the observable-stores
     * Assign subscriptions to this property.
     */
    protected subs = new SubSink();
    protected loadingSubject = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject.asObservable();

    /**
     * Override clearState() from ObservableStore so that it cannot be used.
     *
     * clearState() clears the state for all stores that extend this class
     * As such, child classes should implement an instance method called clear() instead that clears only the state it is concerned with.
     */
    static clearState(dispatchState?: boolean): void {
      throw new Error('Do not call this as it clears the state for all stores that extend this class.' +
                      'Child classes should implement an instance method called clear() instead.');
    }

    /**
     * Dispatches the current state to the subscribers without changing it.
     * @param currentState The current state
     */
    protected dispatchCurrentState(currentState: T): void {
        this.dispatchState(currentState);
        this.loadingSubject.next(false);
    }


    /**
     * Handles HTTP errors
     * @param res the error returned by the api
     * @param statefn the arrow function to invoke to set the state in case of error.
     */
    protected handleError(errorToHandle: any, statefn: () => T): void {
        ConsoleLogger.logError(`Error in store:`, errorToHandle);
        // Set the state to error so that subscribers get a state changed event.
        this.setState(statefn(), 'STORE_ERROR');
    }


    /**
     * A generic method to handle loading state slices into the store.
     * @param loadingSubject The loading subject
     * @param fetchDataFromApi$ The Observable responsible to load data from the api
     * @param nextCallBack When fetching data is successful, the `next` call back to load data into the state slice
     * @param errorCallBack Error callback
     */
    protected loadStateSlice(
        loadingSubject: BehaviorSubject<boolean>,
        fetchDataFromApi$: Observable<any>,
        nextCallBack: (apiResponse) => void,
        errorCallBack: (error) => void) {

        loadingSubject.next(true);

        this.subs.sink = fetchDataFromApi$.
            pipe(
                retryWhen(genericRetryWhen()),
                delay(observableEmitDelay),
                finalize(() => loadingSubject.next(false))
            ).
            subscribe({
                next: (apiResponse) => nextCallBack(apiResponse),
                error: error => errorCallBack(error)
            });
    }

    /**
     * Gets the 'slice' of the observable store
     * @param statePropertyNames The property names which the caller is interested in for change notifications
     * @param state The state
     */
    private getStateSliceForType(statePropertyNames: Array<keyof T>, state: any) {
        if (_.isEmpty(statePropertyNames)) {
            return null;
        }
        const keyValuePairs: any[] = [];
        statePropertyNames.forEach(prop => keyValuePairs.push([prop, state ? state[prop] : null]));
        return _.fromPairs(keyValuePairs);
    }

    private validatePropertyNames() {
        this.statePropertyNames.forEach((key) => {
            const propName = key.toString();
            if (BaseObservableStore.allStorePropertyNames.indexOf(propName) < 0) {
                BaseObservableStore.allStorePropertyNames.push(propName);
            } else {
                const observableStoreMatch = ObservableStore.allStoreServices.find(val => {
                    if (val.statePropertyNames != null) {
                        return val.statePropertyNames.indexOf(propName) >= 0;
                    }
                    return false;
                });
                // tslint:disable-next-line: max-line-length
                let warningMessage = `Found Duplicate state slice property "${propName}" in "${(this as any).constructor.name}" state. `;
                // tslint:disable-next-line: max-line-length
                warningMessage = (`${warningMessage}${observableStoreMatch != null && !isNullOrUndefined(observableStoreMatch.constructor) ? observableStoreMatch.constructor.name : 'Another store'} already defines this property. Verify that Only One store manages changes to it.`);
                ConsoleLogger.logWarning(warningMessage);
            }
        });
    }

    /**
     * Clean up
     */
    ngOnDestroy(): void {
        this.loadingSubject.unsubscribe();
        this.subs.unsubscribe();
    }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService, ProgressComponent } from '@bssh/comp-lib';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';
import { ModalTexts } from '../modal-texts';
import { IModalOutput, IModalInput } from '../model/action-modal';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-base-modal',
  template: `
    <p>
      base-modal works!
    </p>
  `,
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent implements AfterViewInit, OnInit, OnDestroy {

  closable = true;
  showCloseButton = true;
  showConfirmButton = true;
  closeButtonText = ModalTexts.CANCEL_BUTTON_TEXT;
  disableConfirmButton = false;

  protected subs: SubSink = new SubSink();

  modalType: string;
  errorMsg: string | string[] = '';
  modal: NgxSmartModalComponent;
  isLoadingApiData$ = new BehaviorSubject<boolean>(false);


  @Input() error = new BehaviorSubject<any>(null);
  @Input() data = new BehaviorSubject<IModalInput>(undefined);
  @Output() confirm: EventEmitter<IModalOutput> = new EventEmitter();
  @Output() newAction: EventEmitter<any> = new EventEmitter();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() closeModalFinished: EventEmitter<boolean> = new EventEmitter();

  // define loader with hash 'apiDataSpinnerLoader' in template of modal to add loader
  @ViewChild('apiDataSpinnerLoader', { static: false }) public apiDataSpinnerLoader: ProgressComponent;
  @ViewChild('apiDataWrapperLoader', { static: false }) public apiDataWrapperLoader: ElementRef<any>;

  constructor(
    public ngxSmartModalService: NgxSmartModalService
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.error.subscribe(err => {
      this.isLoadingApiData$.next(false);
      if (err) {
        if (typeof (err) === 'string') {
          this.errorMsg = err;
          this.disableConfirmButton = true;

        } else {
          this.errorMsg = this.constructError(err);
          this.disableConfirmButton = true;
        }
      } else {
        this.errorMsg = null;
        this.disableConfirmButton = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.modal = this.ngxSmartModalService.getModal(this.modalType);

    if (this.modal) {
      this.modal.open();

      this.subs.sink = this.modal.onConfirm.subscribe((modal: NgxSmartModalComponent) => {
        this.accept(modal);
      });

      this.subs.sink = this.modal.onClose.subscribe((modal: NgxSmartModalComponent) => {
        this.close(modal);
      });

      // To detach the wrapper component from body on clicking outside the modal dialog
      this.subs.sink = this.modal.onDismiss.subscribe((modal: NgxSmartModalComponent) => {
        this.close(modal);
      });

      // Subscribe to the New Event from the modal
      this.subs.sink = this.modal.onNew.subscribe(modal => {
        // Supply any data set in the modal along with the new event.
        // May be useful if the component wants to send along some context data to handle the 'new' event
        this.newAction.emit(modal.getData());
      });

      // Provide a way for components to be notified when a modal has finished closing,
      // so that another modal can be opened safety afterwards
      this.subs.sink = this.modal.onCloseFinished.subscribe((modal: NgxSmartModalComponent) => {
        this.closeFinished();
      });
      this.subs.sink = this.modal.onDismissFinished.subscribe((modal: NgxSmartModalComponent) => {
        this.closeFinished();
      });
    }

    this.subscribeApiDataLoader();
  }

  protected subscribeApiDataLoader() {
    this.subs.sink = this.isLoadingApiData$.subscribe({
      next: isLoading => {
        if (isLoading === true) {
          if (!isNullOrUndefined(this.apiDataSpinnerLoader)) {
            this.apiDataSpinnerLoader.start();
          }
        } else {
          if (!isNullOrUndefined(this.apiDataSpinnerLoader)) {
            this.apiDataSpinnerLoader.complete();
          }
        }
      }
    });
  }

  close(modal: NgxSmartModalComponent) {
    this.closeModal.emit();
  }

  closeFinished() {
    this.closeModalFinished.emit(true);
  }

  accept(modal: NgxSmartModalComponent) {
    this.isLoadingApiData$.next(true);
    this.confirm.emit(modal.getData());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  // Child classes must override this and set the error based on the error code.
  protected constructError(error: any): string | string[] {
    return '';
  }

  // Almost all the models can be closed on confirm but models with exception can override this, like GetLinkModal.
  canCloseOnConfirm(): boolean {
    return true;
  }

  public onStarted(): void {
    this.apiDataSpinnerLoader.spinner = true;
    this.disableConfirmButton = true;
  }

  public onCompleted(): void {
    this.apiDataSpinnerLoader.spinner = false;
    this.disableConfirmButton = false;
  }

}

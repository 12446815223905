<ngx-smart-modal
  #requeueFastQ
  identifier="requeueFastQ"
  [closable]="true"
  [closeButtonText]="closeButtonText"
  [confirmButtonText]="confirmButtonText"
  [title]="modalTitle"
  [disableConfirmButton]="disableConfirmButton"
>
  <app-requeue-fastq
    [requeueDetailsList] = "requeueDetails"
  ></app-requeue-fastq>

  <lib-error-alert-message
    [alertMsg]="infoMsg"
    [errorMsg]="errorMsg">
  </lib-error-alert-message>

</ngx-smart-modal>
import _ from 'lodash';

export class StringUtilities {

    static REGEX_NON_WORDS: RegExp = /[^\w]+/g;
    static REGEX_SPECIAL_CHARACTERS: RegExp = /[.*+?^${}()|[\]\\]/g;

    /**
     * Checks if a passed in value is null or undefined or a blank object.
     * 
     * @param value The value to verify.
     */
    static isBlank(value: any): boolean {
        return _.isEmpty(value) && !_.isNumber(value) || _.isNaN(value);
    }

    static toBase64String(value: string): string {
        return this.isBlank(value) ? null : window.btoa(value);
    }

    static fromBase64String(value: string): string {
        return this.isBlank(value) ? null : window.atob(value);
    }

    static IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return !this.isBlank(str);
    }

    static TryParseJsonString<T>(str: string): T {
        try {
            return JSON.parse(str) as T;
        } catch (e) {
            return null;
        }
    }

    static areEqualIgnoreCase(value: string, value2: string) {
        return value.localeCompare(value2, undefined, { sensitivity: 'accent' }) === 0;
    }

    /**
     * remove trailing slash '/' if found, only remove one character
     * @param value string to trim
     * @returns trimmed string without trailing slash
     */
    static trimTrailingSlash(value: string): string {
        return this.trimTrailingCharacter(value, '/');
    }

    static trimTrailingCharacter(value: string, character: string): string {
        return value.charAt(value.length - 1) === character ? value.slice(0, -1) : value;
    }

    /**
     * Converts string to regular expression pattern string.
     * @param str
     */
    static toUriEncodedString(str: string): string {
        return encodeURIComponent(str.replace(StringUtilities.REGEX_NON_WORDS, "-"));
    }

    /**
     * Converts string to regular expression pattern string.
     * Escapes regex special chars properly, eg. 'abc.123' -> 'abc\.123',
     * in order to use the pattern in a RegExp constructor.
     * @param str
     */
    static toRegExpString(str: string): string {
        // escape regex special characters & string escape
        return str.replace(StringUtilities.REGEX_SPECIAL_CHARACTERS, '\\$&');
    }
}


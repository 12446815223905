import { Pipe, PipeTransform } from "@angular/core";
import { get, set } from "lodash";

@Pipe({
  name: "comboBoxFilter"
})
export class ComboBoxFilterPipe implements PipeTransform {
  private split_term = '-';
  transform(items: any[], searchTerm: string, valueKey = "value", childItemKey?: string, childItemValueKey?: string): any {
    if (!items || !searchTerm) {
      return items;
    }

    // match regardless of the white space
    searchTerm = searchTerm.replace(/ /g, '');

    const filteredOptions = items.reduce((filtered, item) => {
        if (this.match(get(item, valueKey, ''), searchTerm)) {
          filtered.push(item);
        } else if (childItemKey) {
          const options = get(item, childItemKey, []).filter(
            childItem => 
              // Check if match with child value
              this.match(get(childItem, childItemValueKey || "value", ''), searchTerm) ||
              // Or match with parent + child combination
              this.matchAll(get(item, valueKey, '') + this.split_term + get(childItem, childItemValueKey || "value", ''), 
                searchTerm));          
          filtered.push({...item, [childItemKey]: options});          
        }
        return filtered;
      }
      , []);
    return filteredOptions;
  }

  match(text: string, searchTerm: string) {
    return text.replace(/ /g, '').toLowerCase().includes(searchTerm.toLowerCase());
  }
  
  /**
   * For more complicated match check:
   * E.g. text = 'ABC-123-456-789', search terms are AB-1-4 - should match
   * E.g. text = 'ABC-123-456-789', search terms are AB-1-7 - should match
   * E.g. text = 'ABC-123-456-789', search terms are A-B-1 - should not match
   * E.g. text = 'ABC-123-456-789', search terms are A-1-B - should not match
   */
  matchAll(text: string, searchTerm: string) {
    if(this.match(text, searchTerm)){
      return true;
    }
    const splittedText = text.split(this.split_term);
    const searchTerms = searchTerm.split(this.split_term);
    return searchTerms.every((searchTerm, index) => 
      splittedText.findIndex((text, indexOfText) => indexOfText >= index && this.match(text, searchTerm)) >= 0);
  }
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import environment from '@environments/environment';
import { ReloadPageService } from '../reload-page.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectPathResolverService implements Resolve<string | null> {

  constructor(private reloadPageService: ReloadPageService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Observable<string> | null {

    const routerStateUrl = routerState.url;

    if (environment.redirect404s) {
      this.reloadPageService.reloadPage(routerStateUrl);
      return EMPTY;
    }
    return of(`${routerStateUrl}`);
  }
}

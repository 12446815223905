<div #InitiateEditInfoDiv>
    <ngx-smart-modal #InitiateEditInfoModal
        [identifier]="modalType"
        [title]="ngxSmartModalTitle"
        closable="true"
        showCloseButton="true" 
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButton" 
        [confirmButtonText]="confirmButtonText">

        <!-- to handle ivy tpyecheck issue, passing errorMsg.toString() -->
        <app-edit-info
            [editableFields]="fieldsToEdit"
            (confirm)="dataChanged($event)"
            [errorMsg]="errorMsg?.toString()">
        </app-edit-info>

    </ngx-smart-modal>
</div>
import { Component, ViewChild } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';

export enum ChangeSampleDataAction {
  Replace = 1,
  Append = 2
}

@Component({
  selector: 'app-change-sample-data-modal',
  templateUrl: './change-sample-data-modal.component.html',
  styleUrls: ['./change-sample-data-modal.component.scss'],
})
export class ChangeSampleDataModalComponent extends BaseModalComponent {
  public modalType = 'changeSampleDataModal';
  private changeAction = ChangeSampleDataAction.Replace;

  constructor(
    ngxSmartModalService: NgxSmartModalService) { 
    super(ngxSmartModalService);
  }

  /**
   * Upon submission, process the form and submit request to GSS to create LPK and handle return result
   */
   accept(modal: NgxSmartModalComponent) {
    this.confirm.emit(this.changeAction);
    modal.close();
  }

  changeToReplaceData(replace: boolean) {
    this.changeAction = replace ? ChangeSampleDataAction.Replace : ChangeSampleDataAction.Append;
  }
}

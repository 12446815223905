<ngx-smart-modal
    #DeleteConfigModal
    class="delete-config-modal"
    [identifier]="modalType"
    [closable]="true"
    [title]="modalTitle"
    [showCloseButton]="true"
    [showConfirmButton]="true"
    [closeButtonText]="closeButtonText"
    [confirmButtonText]="confirmButtonText">
  <!-- Alert message content -->
  <div [innerHTML]="modalContent"></div>
</ngx-smart-modal>
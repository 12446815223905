import { StatusChangeResource, StatusChangeComponentOutput, IAllowedStatus, NgxSmartModalService } from '@bssh/comp-lib';
import { isNullOrUndefined } from 'util';
import { OnInit } from '@angular/core';
import { StatusToDisplayName } from '@app/core/utilities/pipes/status-to-display-name.pipe';
import { IChangeStatusModalInput, IChangeStatusModalOutput } from '../model/action-modal';
import { ModalTexts } from '../modal-texts';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { StringUtilities } from '@app/core/utilities/string-utilities';

/**
 * abtract class with T1 as input type of modal and t2 as enum of status values
 */
export abstract class ChangeStatusModalComponent<T1, T2> extends BaseModalComponent implements OnInit {

    allowedStatuses: IAllowedStatus[] = null;
    resources: StatusChangeResource[] = [];
    commentMaxLength = 1024;
    commentFieldName = 'New comment';
    confirmButtonText: string = ModalTexts.CHANGE_STATUS.CONFIRM_BUTTON_TEXT;
    alertMsg: string = null; // set to proper msg if selected analysess are not appropriate for status change
    allPossibleStatuses = [];
    reverseMap: Map<string, T2> = new Map<string, T2>();

    constructor(public ngxSmartModalService: NgxSmartModalService,
                public statusToDisplayName: StatusToDisplayName) {
      super(ngxSmartModalService);
    }

    ngOnInit() {
      super.ngOnInit();
      this.disableConfirmButton = true;
      this.setUpInputSubscriber();
    }

    private setUpInputSubscriber() {
      this.subs.sink = this.data.subscribe(response => {
        if (response) {
          const inputResources: T1[] = (response as IChangeStatusModalInput<T1>).resources;

          this.resources = this.getStatusChangeResources(inputResources);
          this.removeRedundantStatusesAndSetErrorMsg();
          if (response.alertMsg != null && StringUtilities.isBlank(this.errorMsg)) {
            this.alertMsg = response.alertMsg;
          }
        }
      });
    }

    removeRedundantStatusesAndSetErrorMsg(): void {
      if (!this.resources || this.resources.length === 0) {
        return;
      }

      const status: string = this.resources[0].currentStatus;

      // if all resources don't have same status, status change action not allowed
      for (const resource of this.resources) {

        if (resource.currentStatus !== status) {
          this.error.next(ModalTexts.CHANGE_STATUS.STATUS_NOT_SAME_ERROR_MESSAGE);
          return;
        }
      }

      this.allowedStatuses = [];
      this.allPossibleStatuses.forEach((status: string) => {
        this.allowedStatuses.push({value: status, label: this.statusToDisplayName.transform(status)})
      });

      // remove redundant status if all resources have same status
      this.allowedStatuses = this.allowedStatuses.filter(s => s.value !== status);

      return;
    }

    dataChanged($event: StatusChangeComponentOutput) {
      const componentOutput: StatusChangeComponentOutput = $event;

      if (isNullOrUndefined(componentOutput) || !isNullOrUndefined(this.errorMsg))  {
        return;
      }

      if (isNullOrUndefined(componentOutput.newStatus)) {
        this.disableConfirmButton = true;
        return; // no need to set model data
      }

      const newStatus = this.reverseMap.get(componentOutput.newStatus);
      const data: IChangeStatusModalOutput<T2> = {
        newStatus: newStatus != null ? newStatus : componentOutput.newStatus as unknown as T2,
        comments: componentOutput.comments
      };

      this.modal.setData(data, true);
      if (this.disableConfirmButton) {
        this.disableConfirmButton = false;
      }
    }

    constructError(error: any): string | string[] {
      if (error.data && Array.isArray(error.data)) {

        // check if resource name is in errors list
        this.resources = this.resources.filter(resource => {
          return error.data.find(e => (e.Id === resource.resourceName));
        });
        this.resources = this.setHasIssues(this.resources);
      }

      if (!isNullOrUndefined(error.errorMessage)) {
        return error.errorMessage;
      }

      if (isNullOrUndefined(error.errorCode)) {
        return ModalTexts.CHANGE_STATUS.ERROR;
      }

      // TODO: can add a common error message
      return '';
    }

    // set hasIssues flag to all resources having errors
    private setHasIssues(resourcesWithError: StatusChangeResource[]) {
      resourcesWithError.forEach(resourceWithError => {
        resourceWithError.hasIssues = true;
      });
      return resourcesWithError;
    }

    protected abstract getStatusChangeResources(resources: T1[]): StatusChangeResource[];
}

<div class="app-launcher-wrapper">
    <div class="app-launcher-header">
      <ng-container *ngIf="canFilter && !loading">
        <div class="app-launcher-header__search">
            <rad-text-field 
            icon-name="search" 
            icon-size="Sm"
            [formControl]="appSearchControl"
            (keydown.enter)="triggerUpdateDisplayedApps()"
            placeholder="Search Apps ..."
            ></rad-text-field>
        </div>
  
        <div class="app-launcher-header__classifications-select">
          <rad-select class="rad-theme--light" #classificationDropdown [formControl]="appClassificationControl">
              <rad-option 
                  *ngFor="let classification of displayedClassifications | async"
                  [attr.value]="classification.Id"
                  [attr.label]="classification.Description"
              ></rad-option>
          </rad-select>
        </div>
      </ng-container>

      <div *ngIf="displayDevPortal && !loading" class="app-launcher__dev-portal-link-container" >
        <rad-link 
        [attr.href]="devPortalUrl" 
        target="_blank"
        [attr.external]="true"
        >App Developer Portal</rad-link>
      </div>
      
      
    </div>
    <section>
  
        <ng-container *ngIf="loading">
          <div class="app-launcher__launcher-title--loading">
            <lib-skeleton type="text" [lines]="1"></lib-skeleton>
          </div>
         
          <div class="app-launcher__launcher-list">
  
            <div class="app-launcher__launcher-card" *ngFor="let skeletonCard of skeletonCards">
              <div class="app-launcher__launcher-card__image">
                <lib-skeleton type="rect"></lib-skeleton>
              </div>
  
              <div class="app-launcher__launcher-card__content">
                <lib-skeleton type="text" [lines]="2"></lib-skeleton>
              </div>
            </div>
            
          </div>
        </ng-container>
  
        <ng-container *ngIf="!loading">
          <ng-container *ngIf="(bookmarkedApps | async)?.length > 0">
            <h3 class="app-launcher__launcher-title">
              Bookmarked Apps
            </h3>
  
            <div
              class="app-launcher__launcher-list"
            >
                <app-bs-app-card
                  *ngFor="let app of bookmarkedApps | async"
                  [displayExpandedContent]="displayExpandedContent && contentStatus === 'expanded'"
                  [appClassifications]="classifications | async"
                  [appData]="app"
                  [createAppLaunchUrl]="createAppLaunchUrl"
                  [displayLaunch]="displayLaunch"
                  (toggleBookmark)="toggleAppBookmark($event)"
                ></app-bs-app-card>
              </div>
          </ng-container>

          <h3 class="app-launcher__launcher-title">
            All Apps
          </h3>
  
            <div 
              class="app-launcher__launcher-list" 
              *ngIf="(appResultsMessage | async) as appResultsMessage">
              {{appResultsMessage}}
            </div>
  
            <div
              class="app-launcher__launcher-list"
              *ngIf="(displayedApplications | async)?.length > 0"
            >
              <app-bs-app-card
                *ngFor="let app of displayedApplications | async"
                [displayExpandedContent]="displayExpandedContent && contentStatus === 'expanded'"
                [appClassifications]="classifications | async"
                [appData]="app"
                [createAppLaunchUrl]="createAppLaunchUrl"
                [displayLaunch]="displayLaunch"
                (toggleBookmark)="toggleAppBookmark($event)"
              ></app-bs-app-card>
            </div>
  
        </ng-container>
      </section>
  </div>
  
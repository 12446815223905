<div id='SendInvitationDiv'>
    <ngx-smart-modal #SendInvitationModal
        [identifier]="modalType"
        [title]="modalTitle"
        [closable]="closable"
        [showCloseButton]="showCloseButton" 
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButtonSubject | async"
        [confirmButtonText]="confirmButtonText">

        <!-- to handle ivy tpyecheck issue, passing errorMsg.toString() -->
        <lib-share-data
            [projectTitle]="projectTitle"
            [inputLabel]="inputLabel"
            [inputPlaceholder]="inputPlaceholder"
            [userListTitle]="userListTitle"
            [optionalMessageLabel]="optionalMessageLabel"
            [optionalMessagePlaceholder]="optionalMessagePlaceholder"
            [userList]="userList"
            [permissionOptions]="permissionOptions"
            [alertMsg]="alertMessage"
            [errorMsg]="errorMsg"
            [includeAssociatedResources]="includeAssociatedResources"
            [associateResourceMessage]= "associateResourceMessage"
            (outputDataChange)="dataChanged($event)">
        </lib-share-data>

    </ngx-smart-modal>
</div>
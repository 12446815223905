import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { ISearchResourceConfig } from '@app/core/services/resource-dictionary/search-resource-dictionary-types';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { BytesPipe } from '@app/core/utilities/pipes/bytes.pipe';
import { get } from 'lodash';
import { OverrideColumnDefinition, OverrideColumnParameters, SearchGridOptionsFormatter } from './search-grid-options-formatter';
import { DataTableUtilities } from '@app/core/utilities/data-table-utilities';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';

export enum ProjectSearchColumnFields {
    Name = 'Name',
    DateUpdated = 'Updated',
    Owner = 'Owner',
    Size = 'Size'
}

export interface ProjectOverrideColumnDefinition extends OverrideColumnDefinition<ProjectSearchColumnFields> {}

export class ProjectSearchGridOptionsFormatter extends SearchGridOptionsFormatter<ProjectSearchColumnFields> {
    private projectResourceConfig: ISearchResourceConfig;

    constructor(
        public resourceDictionaryService: SearchResourceDictionaryService,
        public bytePipe: BytesPipe,
        public bsshDatePipe: BsshDatePipe
    ) {
        super();
        this.projectResourceConfig = this.resourceDictionaryService.getResourceConfigByType(SearchResourceType.Project);
    }

    protected getOverrideColumnParameters(): OverrideColumnParameters<ProjectSearchColumnFields> {
        const inclusiveColumns: ProjectSearchColumnFields[] = [
            ProjectSearchColumnFields.Name,
            ProjectSearchColumnFields.Owner,
            ProjectSearchColumnFields.Size,
            ProjectSearchColumnFields.DateUpdated
        ];

        const overrideColumnDefinitions: OverrideColumnDefinition<ProjectSearchColumnFields>[] = [
            {
                colId: ProjectSearchColumnFields.Name,
                headerName: 'Name',
                checkboxSelection: false
            }
        ];

        return {
            inclusiveColumns,
            overrideColumnDefinitions
        };
    }

    protected getDefaultColumnDefinitions() {
        const projectConfig = this.projectResourceConfig;
        const defaultColumnDefinitions = [
            {
                headerName: 'Project',
                field: 'Project.Name',
                cellRendererSelector: (params) => {
                    if(params.data) {
                        return DataTableUtilities.getLinkRenderer(
                            params.data.Project.Name,
                            projectConfig.uiHrefAccessor(params.data)
                        );
                    } else {
                        return DataTableUtilities.getSpanRenderer();
                    }
                },
                colId: ProjectSearchColumnFields.Name,
                filterActive: false,
                sortable: false,
                filter: 'customFilter',
                flex: 4.5
            },
            {
                headerName: 'Owner',
                cellRenderer: 'span',
                field: 'Project.UserOwnedBy.Name',
                colId: ProjectSearchColumnFields.Owner,
                filterActive: false,
                sortable: false,
                flex: 2
            },
            {
                headerName: 'Size',
                cellRenderer: 'span',
                valueGetter: params => {
                  if (!params.data) { return; }
                  const totalSize = get(params.data, 'TotalSize', 0);
                  if (totalSize === 0) {
                    return '--';
                  }
                  return this.bytePipe.transform(totalSize, 2);
                },
                colId: ProjectSearchColumnFields.Size,
                sortable: false,
                flex: 1.5
            },
            {
                headerName: 'Created',
                cellRenderer: 'span',
                valueGetter: (params) => {
                    if (!params.data) { return; }
                    return this.bsshDatePipe.transform(params.data.Project.DateCreated);
                },
                suppressSizeToFit: false,
                colId: ProjectSearchColumnFields.DateUpdated,
                filterActive: false,
                sortable: false,
                flex: 2
            }
        ];
        return defaultColumnDefinitions;
    }
}

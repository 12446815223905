import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { delay, retryWhen } from 'rxjs/operators';
import { DownloadUrl } from '../../model/download-url';
import IResource from '../../model/resource';
import { ResourceType } from '../../model/resource-type';
import { genericRetryWhen, observableEmitDelay } from '../../rxjsutils/rxjs-utilities';
import { ErrorMessages } from '../../utilities/error-messages';
import { IApiResponseWrapper } from '../../model/v2-api/v2-api-wrappers';
import { BsApiEndPoints } from '../bs-api/endpoints';
import { LegacyBsApiEndpointsService } from '../legacy-bs-api/legacy-bs-api-endpoints.service';

export interface IDownloadService {
  canDownload(resource: IResource): boolean;
  getDownloadUri(resourceIds: string[], resourceType: ResourceType): Observable<DownloadUrl>;
  getDownloadInfo(resourceId: string, resourceType: ResourceType): Observable<IApiResponseWrapper<IResource>>;
}

@Injectable({
  providedIn: 'root'
})
export class DownloadService implements IDownloadService {

  constructor( private httpClient: HttpClient, private legacyBsApiEndpointsService: LegacyBsApiEndpointsService ) { }

  /* Given a resource, return whether the resource is downloadable.  */
  canDownload(resource: IResource): boolean {
    // null check
    if (!resource) {
      return false;
    }

    return !resource.IsArchived;
  }

  /* Given a Resource, return a download uri for the resource  */
  getDownloadUri(resourceIds: string[], resourceType: ResourceType): Observable<DownloadUrl> {

    if (!resourceIds || resourceIds.length === 0) {
      return throwError(ErrorMessages.DOWNLOAD.NO_RESOURCE_ID_DOWNLOAD_URI); // return of(null);
    }

    if (!resourceType) {
      return throwError(ErrorMessages.DOWNLOAD.NO_RESOURCE_TYPE_DOWNLOAD_URI); // return of(null);
    }

    const requestUrl = this.legacyBsApiEndpointsService.getTriggerDownloadUrl();
    const postData: any = { ids: resourceIds, downloadType: resourceType.toString() };
    return this.httpClient.post<DownloadUrl>(requestUrl, postData, this.v1ApiRequestOptions()).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  /* Given a ResourceId, return the complete Resource information for download  */
  getDownloadInfo(resourceId: string, resourceType: ResourceType): Observable<IApiResponseWrapper<IResource>> {
    if (!resourceId) {
      return throwError(ErrorMessages.DOWNLOAD.NO_RESOURCE_ID_DOWNLOAD_INFO); // of(null);
    }

    if (!resourceType) {
      return throwError(ErrorMessages.DOWNLOAD.NO_RESOURCE_TYPE_DOWNLOAD_INFO);
    }

    const requestUrl = BsApiEndPoints.getDownloadInfoUrl(resourceType, resourceId);
    return this.httpClient.get<IApiResponseWrapper<IResource>>(requestUrl, this.v1ApiRequestOptions()).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  /* Builds Request for calling v1 APIs */
  // tslint:disable-next-line: ban-types
  private v1ApiRequestOptions(): Object {
    const headerArray = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return {
      headers: headerArray,
    };
  }
}

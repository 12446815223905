import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedSpinnerService {
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  numberOfLocks = 0;

  constructor() { }

  /**
   * Start the shared spinner
   * @param oneLockAdded when this is true, one lock will be added to the spinner
   */
  startSpinner(oneLockAdded: boolean = false) {
    if (oneLockAdded) {
      this.numberOfLocks += 1;
    }
    if (this.numberOfLocks === 0 || this.numberOfLocks === 1) {
      this.isLoadingSubject.next(true);
    }
  }

  /**
   * Stop the shared spinner
   * @param oneLockRemoved when this is true, one lock will be removed from the spinner
   * The spinner is only stopped when all locks are removed
   */
  stopSpinner(oneLockRemoved: boolean = false) {
    if (oneLockRemoved && this.numberOfLocks > 0) {
      this.numberOfLocks -= 1;
    }
    if (this.numberOfLocks === 0) {
      this.isLoadingSubject.next(false);
    }
  }
}

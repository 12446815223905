import { Injectable } from '@angular/core';
import { IMenuLink, IApplicationMenu, ISimpleLink } from '@bssh/comp-lib';
import { BaseService } from '../base.service';
import { CurrentUserGlobalAppsStore } from '../../../user/store/current-user-global-apps/current-user-global-apps.store';
import { IUserGlobalApp } from '../../model/userproduct';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ICurrentUserApplicationState } from '@bssh/comp-lib/lib/shared/user/models';
import { default as globalStaticAppsList } from './global-static-apps-list.json';
import environment from '@environments/environment';
import { isNullOrUndefined } from 'util';
import { Constants } from '@app/core/utilities/constants';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import { of } from 'rxjs';
import { IUser } from '@app/core/model/user';
import { WorkgroupRoleTypes } from '@app/core/model/workgroup';
import { HttpUtilityService } from '../http-utility/http-utility.service';
import { SkinnyBsshService } from '../skinny-bssh/skinny-bssh.service';
/**
 * An implementation of this interface will provide the menu items on the nav bar.
 */
export interface IUniversalNavLinksService {
  getNavItems(isFullVersion?: boolean): IMenuLink[];
  getGlobalAppItemsState(): Observable<ICurrentUserApplicationState>;
  getUserProfileLinkItems(currentUser?: IUser): ISimpleLink[];
  getNavActions(): any[];
}

@Injectable({
  providedIn: 'root'
})
export class UniversalNavLinksService extends BaseService implements IUniversalNavLinksService {

  private variantInterpreterAppName = 'Variant Interpreter';

  public logoUrl = 'https://www.illumina.com';
  public bsHelpUrl = 'https://help.basespace.illumina.com';
  public trashUrl = '/settings/trash';

  public navBartitle = 'sequence hub';
  public titleLink = '/';
  public logoLinkNewWindow = true;
  public titleLinkNewWindow = false;

  private setupAppCategory = 'Setup, Run & Analyze';
  private regionCode: string;
  private navItems: IMenuLink[] = [];
  private isSkinnyBssh: boolean = false;

  constructor(public userGlobalAppsStore: CurrentUserGlobalAppsStore,
    private httpUtilityService: HttpUtilityService,
    private skinnyBsshService: SkinnyBsshService
    ) {
    super();
    this.regionCode = this.getCurrentRegionCode();

    //Check if Skinny BSSH to add appropriate links
    this.isSkinnyBssh = this.skinnyBsshService.isSkinnyBssh();
  }

  /**
   * Gets a list of top level navigation links
   */
  getNavItems(isFullVersion: boolean = true): IMenuLink[] {
    this.navItems = [
      {
        title: 'Home',
        url: '/dashboard',
        disabled: false
      },
      {
        title: 'Runs',
        url: '/runs',
        disabled: false
      },
      {
        title: 'Biosamples',
        url: '/biosamples',
        disabled: false
      }
    ];

    if(isFullVersion){ 
      this.navItems.push(
        {
          title: 'Projects',
          url: '/projects',
          disabled: false
        },
        {
          title: 'Analyses',
          url: '/analyses',
          disabled: false
        },
        {
          title: 'Apps',
          url: '/apps',
          disabled: false
        },
        {
          title: 'Demo Data',
          url: '/datacentral',
          disabled: false
        }
      )
    }
    return this.navItems
  }

  /**
   * Gets the app links that are the user has access to.
   * (Currently returns apps in pre-defined categories)
   */
  getGlobalAppItemsState(): Observable<ICurrentUserApplicationState> {

    // The current requirement is to show a static apps list in the app switcher
    // A static list is being maintained in the accompnying JSON file.
    // When platform is ready with thier new API, refactoring can be done.
    const userApps = globalStaticAppsList as IApplicationMenu[];
    const setupApps = userApps.find(app => app.title === this.setupAppCategory).data;
    const viApp = setupApps.find(app => app.title === this.variantInterpreterAppName);
    // assign fall back in case the platform call fails.
    viApp.url = this.getFallBackViUrl();
    // In the static list of apps, need to substitute the Url for BSSH with region specific Url.
    // Variant Interpreter URL cannot be obtained from any BSSH related settings. Need to make a platform call to get it.
    return this.userGlobalAppsStore.stateChanged.pipe(
      map(
        state => {
          if (state != null && state.userGlobalApps != null) {
            const viAppFromPlatform = state.userGlobalApps.find(app => this.IsAppVariantInterpreterInCurrentRegion(app));
            if (!isNullOrUndefined(viAppFromPlatform)) {
              viApp.url = viAppFromPlatform.href;
            }
            // We tried getting it from platform and also from fall back. If still the url was not found.
            // Remove it from the list of apps.
            if (StringUtilities.isBlank(viApp.url)) {
              _.remove(setupApps, app => app.title === this.variantInterpreterAppName);
            }
          }
          return { currentUserApplications: userApps };
        }),
      catchError(error => {
        return of({ currentUserApplications: userApps });
      })
    );
  }

  /**
   * Gets a list of links that are displayed in the user profile drop down.
   * May return additional items based on currentUser's roles
   */
  getUserProfileLinkItems(currentUser?: IUser): ISimpleLink[] {

    let links = [
      {
        title: 'SETTINGS',
        url: '/settings',
        inNew: false,
        type: 'link'
      },
      {
        title: 'RESOURCES',
        url: '/resources',
        inNew: false,
        type: 'link'
      },
      {
        title: 'TERMS',
        url: '/agreements/toc',
        inNew: false,
        type: 'link'
      },
      {
        title: 'SIGN OUT',
        type: 'signout',
        url: null,
        inNew: null
      }
    ];

    // users that are domain-admins or workgroup-admins/owners should see an additional link
    if (currentUser
      && currentUser.Roles
      && currentUser.Roles.some(role =>
        role === WorkgroupRoleTypes.DomainAdmin || role === WorkgroupRoleTypes. Admin || role === WorkgroupRoleTypes. Owner)) {
        // insert it in the 2nd position of the list
        const adminConsoleLink = {
          title: 'ADMIN CONSOLE',
          url: this.httpUtilityService.getWorkgroupAdminConsoleUrl(currentUser.IsPublicDomainUser),
          inNew: true,
          type: 'link'
        };
        links.splice(1, 0, adminConsoleLink);
    }

    return links;
  }

  /**
   * Gets the Nav actions for the univ nav bar
   */
  getNavActions(): any[] {
    return [
      {
        flag: 'isNotificationsOpen',
        trigger: 'notificationsOpen',
        icon: 'notification-bell'
      },
      {
        flag: 'isHelpOpen',
        trigger: 'helpOpen',
        icon: 'help-circle'
      },
      {
        flag: 'isTrashOpen',
        trigger: 'trashOpen',
        icon: 'trash'
      }
    ];
  }

  /**
   * Get the link that needs to be shown at the bottom of the waffle menu with global apps
   */
  getWaffleMenuApplicationsFooterLink() {
    return {
      title: 'See All Illumina Resources & Tools',
      url: 'https://www.illumina.com/science/education/resources-tools.html',
      inNew: true
    };
  }


  /**
   * Checks if an app is Variant Interpreter specific to the current region.
   */
  private IsAppVariantInterpreterInCurrentRegion(app: IUserGlobalApp): boolean {
    return app.title.includes(this.variantInterpreterAppName) && app.href.includes(this.regionCode);
  }
  /**
   * Gets the fall back VI Url in case Platform does not return it
   * (Should not happen, but just in case)
   */
  private getFallBackViUrl(): string {
    return environment.variantInterpreterUrls[environment.subscriptionRegion];
  }

  /**
   * Gets the 4 character region code for the current region
   */
  private getCurrentRegionCode(): string {
    if (environment.subscriptionRegion !== 'US' && !StringUtilities.isBlank(environment.siteUrl)) {
      const urlHost = new URL(environment.siteUrl).host;
      // Urls in other region are of the format <regioncode>.sh.basespace.illumina.com
      // E.g: https://euc1.sh.basespace.illumina.com
      const matches = new RegExp(Constants.RegionalBsshHostMatcher).exec(urlHost);
      if (!isNullOrUndefined(matches) && matches.length > 1) {
        return matches[1];
      }
    }
    // US & fall back
    return Constants.SubscriptionRegionMapper[environment.subscriptionRegion];
  }
}

import { Injectable } from '@angular/core';
import { IObservableStore, BaseObservableStore } from '../../infrastructure/base-observable.store';
import { IUserGlobalNotificationsState } from './user-global-notifications.state';
import { UserNotificationStream, IUserNotificationInfo } from '@app/core/model/user-notifications/user-notifications';
import _ from 'lodash';
import { INotification } from '@app/core/model/v2-api/v2-api-wrappers';
import { BasespaceService, V2UserNotificationCompactList } from '@bssh/ng-sdk';
import { SubSink } from 'subsink';
import { map, filter, delay, retryWhen, shareReplay, finalize, catchError } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { of, EMPTY } from 'rxjs';
import { TokenReplacerPipe } from '@app/core/utilities/pipes/token-replacer.pipe';




export interface IUserGlobalNotificationsStore extends IObservableStore<IUserGlobalNotificationsState> {
  loadGlobalNotifications(stream: UserNotificationStream, offsetParam?: number, limitParam?: number, forceLoad?: boolean): void;
  dismissNotification(notification: IUserNotificationInfo, dispatchState: boolean): void;
}


@Injectable({
  providedIn: 'root'
})
export class UserGlobalNotificationsStore extends BaseObservableStore<IUserGlobalNotificationsState>
  implements IUserGlobalNotificationsStore {

  constructor(private baseSpaceApi: BasespaceService, private tokenReplacerPipe: TokenReplacerPipe) {
    super(['cmsNotifications',
      'cmsNotificationsOffset',
      'cmsNotificationsLimit',
      'dataDeletionNotifications',
      'storageNotifications',
      'subscriptionNotifications',
      'restrictedActivityNotifications'
    ]);

  }

  /**
   * Loads notifications into the store for a given Stream
   * @param stream The stream for which to load notifications
   * @param offsetParam Offset (applies only to notifications of Stream 'CMS)
   * @param limitParam Limit (applies only to notifications of Stream 'CMS)
   * @param forceLoad Whether to discard current state and reload from api
   */
  loadGlobalNotifications(stream: UserNotificationStream, offsetParam?: number, limitParam?: number, forceLoad: boolean = false): void {
    this.loadingSubject.next(true);
    const currentState = this.getState();
    if (!forceLoad &&
      !_.isEmpty(this.areNotificationsLoaded(stream, currentState, offsetParam, limitParam))) {
      this.dispatchCurrentState(currentState);
    } else {
      // Set the corresponding notifications to null in store.
      this.setNotificationsInStore(null, offsetParam, limitParam, stream,
        UserGlobalNotificationsStoreActions.getLoadActionForStream(stream));
      const requestParams: any = {
        streamtype: stream,
        offset: offsetParam,
        limit: limitParam
      };
      this.subs.sink = this.baseSpaceApi.GetV2UsersCurrentNotifications(requestParams).pipe(
        shareReplay(1),
        retryWhen(genericRetryWhen()),
        filter(apiResponse => !isNullOrUndefined(apiResponse)),
        // This store will mostly used to render global notifications
        // Probably OK to ignore errors
        catchError(error => {
          // log to know that there was a failure
          console.log('Error in Global User Notifications Store', error);
          return of(this.getDefaultApiResponse());
        }),
        finalize(() => this.loadingSubject.next(false)),
      ).subscribe({
        next: (apiResponse) => {
          const notifications = apiResponse.Items.map(item => {
            const notificationInfo = item.NotificationInfo as IUserNotificationInfo;
            // Replace any tokens that may be present.
            notificationInfo.Description = this.tokenReplacerPipe.transform(notificationInfo.Description);
            return notificationInfo;
          });
          this.setNotificationsInStore(notifications, offsetParam, limitParam, stream,
            UserGlobalNotificationsStoreActions.getLoadActionForStream(stream));
        }
      });
    }
  }

  /**
   * Calls the backend dismiss api to set Global notification as dismissed. 
   * @param streamParam The Stream of the notification
   * @param typeParam The type of the notification
   */
  dismissNotification(notification: IUserNotificationInfo, dispatchState: boolean = true) {
    // Basic null checks
    if (isNullOrUndefined(notification) ||
      isNullOrUndefined(notification.Stream) ||
      isNullOrUndefined(notification.Type) ||
      !notification.IsDismissable) {
      return;
    }
    const streamParam = notification.Stream as UserNotificationStream;
    // request to call the api
    const dismissParams = {
      stream: streamParam,
      type: notification.Type
    };

    this.loadingSubject.next(true);
    const currentState = this.getState();
    this.subs.sink = this.baseSpaceApi.PutV2NotificationsDismiss(dismissParams).pipe(
      catchError(error => {
        // Need not handle this as the notification is already removed from DOM, and would
        // serve no purpose to show an error
        return EMPTY;
      }),
      finalize(() => {
        // dataDeletionNotifications or storageNotifications etc.
        let notifications = currentState[_.camelCase(streamParam) + 'Notifications'] as IUserNotificationInfo[];
        notifications = notifications.filter(notif => !(notif.Stream === notification.Stream && notif.Type === notification.Type));
        // Update notifications in store for consistent state
        this.setNotificationsInStore(notifications, null, null, streamParam,
          UserGlobalNotificationsStoreActions.getDismissActionForStream(streamParam), dispatchState);
        this.loadingSubject.next(false);
      })
    ).subscribe();
  }

  /**
   * Checks if the notifications are already loaded in store for a given stream
   * @param stream The stream
   * @param currentState Current state
   * @param offsetParam Offset
   * @param limitParam Limit
   */
  private areNotificationsLoaded(stream: UserNotificationStream,
                                 currentState: IUserGlobalNotificationsState,
                                 offsetParam?: number, limitParam?: number): boolean {
    switch (stream) {
      case UserNotificationStream.DataDeletion:
        return !_.isEmpty(currentState.dataDeletionNotifications);
      case UserNotificationStream.Storage:
        return !_.isEmpty(currentState.storageNotifications);
      case UserNotificationStream.Subscription:
        return !_.isEmpty(currentState.subscriptionNotifications);
      case UserNotificationStream.Cms:
        return !_.isEmpty(currentState.cmsNotifications) &&
          currentState.cmsNotificationsOffset === offsetParam &&
          currentState.cmsNotificationsLimit === limitParam;
      case UserNotificationStream.RestrictedActivity:
        return !_.isEmpty(currentState.restrictedActivityNotifications);
      default:
        // null/defined/un supported
        throw new Error('Unsupported stream parameter');
    }
  }


  /**
   * Sets notifications in the store
   * @param notifications The notifications
   * @param offset The offset
   * @param limit The limit
   * @param error The state error
   * @param stream The stream
   * @param currentState Current State in store
   * @param action The action which caused a change in state
   * @param dispatchState Indicates whether to dispatch state to the consumers
   */
  private setNotificationsInStore(notifications: IUserNotificationInfo[],
                                  offset: number,
                                  limit: number,
                                  stream: UserNotificationStream,
                                  action: string,
                                  dispatchState: boolean = true): void {

    switch (stream) {
      case UserNotificationStream.DataDeletion:
        this.setState({ dataDeletionNotifications: notifications },
          action, dispatchState);
        break;
      case UserNotificationStream.Storage:
        this.setState({ storageNotifications: notifications },
          action, dispatchState);
        break;
      case UserNotificationStream.Subscription:
        this.setState({ subscriptionNotifications: notifications },
          action, dispatchState);
        break;
      case UserNotificationStream.Cms:
        this.setState({
          cmsNotifications: notifications,
          cmsNotificationsOffset: offset,
          cmsNotificationsLimit: limit,
        }, action, dispatchState);
        break;
      case UserNotificationStream.RestrictedActivity:
        this.setState({ restrictedActivityNotifications: notifications },
          action, dispatchState);
        break;
      default:
        // null/defined/un supported
        throw new Error('Unsupported stream parameter');
    }
  }

  private getDefaultApiResponse(): V2UserNotificationCompactList {
    return {
      Items: [],
      Paging: {
        DisplayedCount: 0,
        Limit: 0,
        Offset: 0
      }
    };
  }

}


export class UserGlobalNotificationsStoreActions {
  static getLoadActionForStream(stream: UserNotificationStream): string {
    return `LOAD_${stream.toUpperCase()}_NOTIFICATIONS`;
  }

  static getDismissActionForStream(stream: UserNotificationStream): string {
    return `DISMISS_${stream.toUpperCase()}_NOTIFICATION`;
  }
}

import { OnInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-resource-delete',
    templateUrl: './resource-deleted.component.html',
    styleUrls: ['./resource-deleted.scss']
})
export class ResourceDeletedComponent implements OnInit {

  attemptedRequest: string =  null;

  constructor(private route: ActivatedRoute) { }

    ngOnInit() {
      this.getAttemptedRequestFromRoute();
    }

    private getAttemptedRequestFromRoute() {
      this.attemptedRequest = this.route.snapshot.queryParamMap.get('url');
    }

}

import { Directive, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { AuthorizationService } from '../services/authorization/authorization.service';
import { SubSink } from 'subsink';
import { take } from 'rxjs/operators';

/**
 * A directive when applied to any element will hide it if user is not authenticated.
 * Currently being used in the app component, normally, we wouldn't have to use this elsewhere.
 */
@Directive({
  selector: '[appAuthProtected]'
})
export class AuthProtectedDirective implements OnInit, OnDestroy {

  private subs: SubSink = new SubSink();

  constructor(private authService: AuthorizationService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {

  }

  ngOnInit() {
    this.subs.sink = this.authService.isAuthorized$.pipe(take(1)).subscribe(
      (isAuthenticated) => {
        if (isAuthenticated) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { ModalTexts } from '../modal-texts';

@Component({
  selector: 'app-get-link',
  templateUrl: './get-link.component.html',
  styleUrls: ['./get-link.component.scss']
})
export class GetLinkComponent implements OnInit {

  @Input() public shareUrl = '';
  @Input() public title = '';
  @Input() public canDisplayActivationText;

  public activateText = ModalTexts.SHARE.ACTIVATE_TEXT;
  public copyUrlText = ModalTexts. SHARE.COPY_URL_TEXT;

  constructor() { }

  ngOnInit() {
  }

  copyShareUrl(inputElement) {
    inputElement.select();
    document.execCommand('copy');
  }
}

<section class="layout__spotlight">
  <div class="layout__spotlight__priority-info">
    <h1 class="layout__spotlight__page-header">{{ pageHeader }}</h1>
    <div class="d-flex layout__spotlight__navbar-row">
      <div class="flex-grow-1">
        <lib-tab-nav [menuItems]="menuItems" class="layout__spotlight__tab-menu"></lib-tab-nav>
      </div>
      <ng-content select="[navbar-after]"></ng-content>
    </div>
  </div>
</section>
  
<section class="layout__page-container" *ngIf="activateFn && isTabsLoaded" #layoutContainer>
  <div class="layout__page-container__content">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</section>

import { OnInit, OnDestroy, Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
  })
export class MomentWrapperService implements OnInit {

    private language: string;

    constructor() {
        this.language = 'en-us';
    }

    ngOnInit() { }

    public getMomentWithLocale() {
        const momentjsLocale = moment;
        momentjsLocale.locale(this.language);
        return momentjsLocale;
    }
}

<div #InitiateCancelRequeueModalDiv>
    <ngx-smart-modal #InitiateCancelRequeueModal
        [identifier]="modalType"
        [title]="ngxSmartModalTitle"
        closable="true"
        showCloseButton="true" 
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButton" 
        [confirmButtonText]="confirmButtonText">
        <div>
            <dl>
                <dt> <span>Requeue ID</span>
                </dt>
                <dd> {{requeue.Id}}
                </dd><hr>
                <dt>{{headerKey}}
                </dt>
                <dd>{{headerValue}}<hr>
                </dd>
                <dt *ngIf="requeue.PrepType"><span>Prep Kit</span>
                </dt>
                <dd *ngIf="requeue.PrepType">{{requeue.PrepType}}<hr>
                </dd>
                <dt> <span>Current Status</span>
                </dt>
                <dd> {{requeue.Status}}
                </dd><hr>
            </dl>
        </div>
        <p *ngIf="errorMsg===null">Are you sure you want to cancel this requeue?</p>
        <div>
            <lib-error-alert-message 
                [alertMsg]="alertMsg"
                [errorMsg]="errorMsg">
            </lib-error-alert-message>
        </div>
    
    </ngx-smart-modal>
</div>

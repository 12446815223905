<ngx-smart-modal #requeueAnalysisModal class="requeue-analysis-modal" identifier="requeueAnalysisModal"
    [title]="'Requeue Analysis'" confirmButtonText="Continue" [disableConfirmButton]="!requeueFormGroup.valid">
    <form [formGroup]="requeueFormGroup" novalidate autocomplete="off">
        <div class="requeue-analysis">
            <p>Please select the source sample sheet to be opened in Run Planning tool. You can continue updating the
                configuration(s) before submitting it for requeue.</p>
            <br />
            <!-- Requeue types -->
            <div *ngFor="let option of requeueTypeOptions; let  i = index">
                <div class="requeue-types">
                    <lib-radio-checkbox (change)="onRequeueTypeChange(option.value)"
                        [disabled]="option.disabled" [checked]="option.checked" type="radio" name='requeueType'>
                        {{option.text}}
                    </lib-radio-checkbox>
                    <lib-popover *ngIf="option.disabled" popoverType="info" style="font-size: 1.4rem"
                        popoverSize="medium" popoverPlacement="right" popoverTriggerEvents="mouseenter:mouseleave">
                        <span>Sample Sheet is not found in this run</span>
                    </lib-popover>
                </div>
            </div>
            <div class="file-upload" *ngIf="showSampleSheetControl">
                <input #sampleSheetInput type="file" hidden id="sampleSheetInput" accept=".csv"
                    (change)="handleSampleSheet($event)" />
                <a lib-btn link class="btn btn--link small" style="margin-left: -2.4rem;"
                    (click)="sampleSheetInput.click()">
                    Upload
                </a>
                <span style="font-size:1.2rem; cursor: pointer" (click)="sampleSheetInput.click()">
                    {{sampleSheetName || 'Upload a sample sheet'}}
                </span>
            </div>
        </div>
    </form>
</ngx-smart-modal>
import { Injectable } from '@angular/core';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { SubscriptionMode } from '@app/core/model/user/subscription-modes';
import { BaseService } from '../base.service';
import { filter } from 'rxjs/operators';
import { InstrumentPlatforms } from '@app/core/model/runs/instrument';

@Injectable({
  providedIn: 'root'
})
export class CodeFeaturesService extends BaseService {
  public static readonly MICROARRAY = 'Microarray';
  public static readonly MICROARRAY_CUSTOM_CONFIG = 'MicroarrayCustomConfig';
  public static readonly MICROARRAY_IMPORT_IDATS = 'MicroarrayImportIdats';
  public static readonly MICROARRAY_DATA_MANAGEMENT = 'MicroarrayDataManagement';
  public static readonly BIOSAMPLE_REGISTRY = 'BioSampleRegistry';
  public static readonly APPLICATIONS_PROMOTION = 'ApplicationsPromotion';
  public static readonly APPCATEGORY_DEFAULT_ALL_ENABLED = 'AppCategoryDefault_All';
  public static readonly COMPUTE_METERING = 'ComputeMeteringEnabled';
  public static readonly FREE_TRIAL = 'FreeTrial';
  public static readonly FREE_TRIAL_DISABLED = 'FreeTrialTemporarilyDisabled';
  public static readonly POOL_LIBRARY_PAGE_HIDE_YIELDS = 'PoolLibraryPageHideYields';
  public static readonly USAGE_TRACKING = 'UsageTracking';
  public static readonly SUBSCRIPTIONSERVICEMODE_LEGACYZOURA = 'SubscriptionMode_LegacyZuora';
  public static readonly SUBSCRIPTIONSERVICEMODE_ZEUS = 'SubscriptionMode_Zeus';
  public static readonly SUBSCRIPTIONSERVICEMODE_PLATFORMAPI = 'SubscriptionMode_PlatformApi';
  public static readonly V1V2TOGGLE = 'V1V2ToggleSwitch';
  public static readonly ARCHIVING = 'Archiving';
  public static readonly RUN_ZIPPING = 'RunZipping';
  public static readonly CLOUD_RUN_PREP = 'CloudRunPrep';

  public static readonly RUN_PLANNING_MULTI_ANALYSIS = 'RunPlanning_MultiAnalysis';
  public static readonly RUN_PLANNING_UNIFIED_UI = 'RunPlanning_UnifiedUI';
  public static readonly SHOW_INSTRUMENT_300M = 'RunPlanning_ShowInstrument300M';
  public static readonly SHOW_INSTRUMENT_R2D2 = 'RunPlanning_ShowInstrumentR2D2';
  public static readonly ANALYSIS_CONFIG_TEMPLATE = 'AnalysisConfigTemplate';
  public static readonly ANALYSIS_CONFIG_TEMPLATE_TOGGLE_SWITCH = 'AnalysisConfigTemplateToggleSwitch';
  public static readonly REFERENCE_FILE = 'ReferenceFile';
  public static readonly IMPORT_PLANNED_RUN = 'ImportPlannedRun';
  public static readonly RUN_PLANNING_IMPORT_FROM_ACT = 'RunPlanning_ImportFromAct';
  public static readonly SAVE_LOCAL_PLAN_RUN = 'SaveLocalPlanRun';
  public static readonly UNIFIED_REQUEUE_EXPERIENCE = 'UnifiedRequeueExperience';
  public static readonly REQUEUE_FASTQ_GENERATION_MENU = 'RequeueFastqGenerationMenu';
  public static readonly BSSH_RUN_COMPLETED_SEND_TO_ICA = 'BsshRunCompletedSendToIca';

  public static readonly CUSTOM_KIT = 'CustomKit';
  public static readonly GDS_DELETE = 'GdsDelete';
  public static readonly WEB_ROUTE_LOGGING = 'WebRouteLogging';
  public static readonly RUN_INSIGHTS_DISABLED = 'RunInsightsDisabled';
  public static readonly SHOW_REGISTER_FASTQS_TO_STRATUS_SETTING = 'ShowRegisterFastqsToStratusSetting';
  public static readonly SHOW_UPLOAD_ICA_TOGGLE = 'EnableShowUploadIcaToggle';
  public static readonly NULLIFY_BIOSAMPLE_DEFAULT_PROJECT_ENABLED= 'NullifyBioSampleDefaultProject';
  public static readonly IGNORE_INSTRUMENT_SEQUENCING_STATS = 'IgnoreInstrumentSequencingStats';
  public static readonly RESOURCES_CUSTOM_IAK_EDITOR = 'ResourcesCustomIAKEditor';

  /** we were not able to use these static values in current use store
  * because of circular dependency issue with currentUserStore
  */
  public static readonly WALK_ME_ENABLED = "WalkMeEnabled";
  public static readonly WALK_ME_TEST_MODE = "WalkMeTestMode";
  public static readonly GOOGLE_TAG_MANAGER = "GoogleTagManager";
  public static readonly PENDO_ANALYTICS = 'PendoAnalytics';

  public static readonly USER_INTERFACE_BETA_PREVIEW_ACCESSIBLE = "UserInterfaceBetaPreviewAccessible";

  private codeFeatures: string[];

  constructor(private currentUserStore: CurrentUserStore) {
    super();
    this.init();
  }

  private init() {
    this.codeFeatures = [];
    this.subs.sink = this.currentUserStore.stateChanged.pipe(
      filter(state => state != null && state.currentUserCodeFeatures != null)
    ).subscribe({
      next: state => {
        this.codeFeatures = state ? state.currentUserCodeFeatures : [];
      }
    });
  }

  get microarrayIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.MICROARRAY);
  }

  get microarrayCustomConfigIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.MICROARRAY_CUSTOM_CONFIG);
  }

  get microarrayImportIdatsIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.MICROARRAY_IMPORT_IDATS);
  }

  get microarrayDataManagementIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.MICROARRAY_DATA_MANAGEMENT);
  }

  get applicationsPromotionIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.APPLICATIONS_PROMOTION);
  }

  get isAppCategoryDefault_AllEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.APPCATEGORY_DEFAULT_ALL_ENABLED);
  }

  get freeTrialIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.FREE_TRIAL);
  }

  get freeTrialTemporarilyDisabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.FREE_TRIAL_DISABLED);
  }

  get runInsightsDisabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.RUN_INSIGHTS_DISABLED);
  }

  get poolLibraryPageHideYieldsIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.POOL_LIBRARY_PAGE_HIDE_YIELDS);
  }

  get usageTrackingIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.USAGE_TRACKING);
  }

  get archivingIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.ARCHIVING);
  }

  get runZippingIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.RUN_ZIPPING);
  }

  get gdsDeleteIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.GDS_DELETE);
  }

  get v1v2ToggleSwitchIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.V1V2TOGGLE);
  }

  get cloudRunPrepIsEnabled(): boolean {
    return !this.codeFeatures.includes(CodeFeaturesService.RUN_PLANNING_UNIFIED_UI)
      && this.codeFeatures.includes(CodeFeaturesService.CLOUD_RUN_PREP);
  }

  get runPlanningMultiAnalysisEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.RUN_PLANNING_MULTI_ANALYSIS);
  }

  get runPlanningUnifiedUIEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.RUN_PLANNING_MULTI_ANALYSIS)
      && this.codeFeatures.includes(CodeFeaturesService.RUN_PLANNING_UNIFIED_UI);
  }

  get showNovaSeqXSeriesInstrument(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.SHOW_INSTRUMENT_300M);
  }

  get showMiSeqi100SeriesInstrument(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.SHOW_INSTRUMENT_R2D2);
  }

  get analysisConfigTemplateEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.ANALYSIS_CONFIG_TEMPLATE);
  }

  get analysisConfigTemplateToggleIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.ANALYSIS_CONFIG_TEMPLATE_TOGGLE_SWITCH);
  }

  get referenceFileEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.REFERENCE_FILE);
  }

  get importPlannedRunEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.IMPORT_PLANNED_RUN);
  }

  get importFromActEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.RUN_PLANNING_IMPORT_FROM_ACT);
  }

  get saveLocalPlanRunEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.SAVE_LOCAL_PLAN_RUN);
  }

  get customKitIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.CUSTOM_KIT);
  }

  get showIcaRunContextSwitchSetting(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.SHOW_UPLOAD_ICA_TOGGLE);
  }

  get unifiedRequeueExperienceEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.UNIFIED_REQUEUE_EXPERIENCE);
  }

  get requeueFastqGenerationMenuEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.REQUEUE_FASTQ_GENERATION_MENU);
  }

  get bsshRunCompletedSendToIcaEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.BSSH_RUN_COMPLETED_SEND_TO_ICA);
  }

  /**
   * User has a wallet for storing an icredit balance if:
   * a) we're not using a Zuora alternative, i.e., everything is in Zuora, or
   * b) one of the Zeus or Platform SS code features is on AND the LegacyZuora code feature is on.
   */
  get hasWallet(): boolean {
    const zeusSubscriptionsEnabled = this.codeFeatures.includes(CodeFeaturesService.SUBSCRIPTIONSERVICEMODE_ZEUS);
    const platformSubscriptionsEnabled = this.codeFeatures.includes(CodeFeaturesService.SUBSCRIPTIONSERVICEMODE_PLATFORMAPI);
    const isEverythingInZuora = !zeusSubscriptionsEnabled && !platformSubscriptionsEnabled;
    const isWalletOnlyInZuora = this.codeFeatures.includes(CodeFeaturesService.SUBSCRIPTIONSERVICEMODE_LEGACYZOURA);
    return isEverythingInZuora || isWalletOnlyInZuora;
  }

  get getSubscriptionMode(): SubscriptionMode {
    if (this.codeFeatures.includes(CodeFeaturesService.SUBSCRIPTIONSERVICEMODE_PLATFORMAPI)) {
      return SubscriptionMode.PLATFORM_API;
    }

    if (this.codeFeatures.includes(CodeFeaturesService.SUBSCRIPTIONSERVICEMODE_ZEUS)) {
      return SubscriptionMode.ZEUS;
    }

    // LEGACYZUORA or none
    return SubscriptionMode.LEGACY_ZUORA;
  }

  get isWalkMeEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.WALK_ME_ENABLED);
  }

  get walkMeTestMode(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.WALK_ME_TEST_MODE);
  }

  get isGoogleTagMgrEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.GOOGLE_TAG_MANAGER);
  }

  get webRouteLoggingIsEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.WEB_ROUTE_LOGGING);
  }

  get bioSampleRegistryEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.BIOSAMPLE_REGISTRY);
  }

  get showRegisterFastqsToStratusSetting(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.SHOW_REGISTER_FASTQS_TO_STRATUS_SETTING);
  }

  get nullifyBioSampleDefaultProjectEnabled(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.NULLIFY_BIOSAMPLE_DEFAULT_PROJECT_ENABLED);
  }

  get isUserInterfaceBetaPreviewAccessible(): boolean {
    return this.codeFeatures.includes(CodeFeaturesService.USER_INTERFACE_BETA_PREVIEW_ACCESSIBLE);
  }

  ignoreInstrumentSequencingStatsEnabled(instrumentPlatform: string): boolean {
    let codeFeatureInstrumentName: string;
    switch (instrumentPlatform) {
      case InstrumentPlatforms.NOVASEQ_X_SERIES:
        // code feature was already in use before instrument name was finalized
        codeFeatureInstrumentName = "NovaSeqX";
        break;
      case InstrumentPlatforms.MISEQ_I100_SERIES:
        codeFeatureInstrumentName = instrumentPlatform;
        break;
      default:
        return false;
    }
    return this.codeFeatures.includes(`${CodeFeaturesService.IGNORE_INSTRUMENT_SEQUENCING_STATS}.${codeFeatureInstrumentName}`);
  }

  get resourcesCustomIAKEditorEnabled(): boolean { 
    return this.codeFeatures.includes(CodeFeaturesService.RESOURCES_CUSTOM_IAK_EDITOR);
  }
}

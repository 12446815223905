import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ResourceType } from '@app/core/model/resource-type';
import { IRun } from '@app/core/model/runs/run';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { IUser } from '@app/core/model/user';
import { retryWhen, delay, timeout } from 'rxjs/operators';
import { ErrorMessages } from '@app/core/utilities/error-messages';
import { IApiResponseWrapper, IApiListResponseWrapper } from '@app/core/model/v2-api/v2-api-wrappers';
import { Invitation } from '@app/core/model/v2-api/invitation';
import IResource from '@app/core/model/resource';
import { UserRestrictionService } from '@app/core/services/user/user-restriction.service';
import { BsApiEndPoints } from '../bs-api/endpoints';

@Injectable({
  providedIn: 'root'
})
export class TransferOwnershipService {

  constructor(private httpClient: HttpClient, private userRestrictionService: UserRestrictionService) { }

  /**
   * Determines whether the currentUser can transfer the ownership for currentRun,
   * according the store's state.
   */
  canTransferResourceOwnership(currentRun: IRun, currentUser: IUser): boolean {
    // check for common conditions
    const isTransferAllowed: boolean = this.canTransferOwnership(currentRun, currentUser);

    // check for resource specific conditions
    return isTransferAllowed && !currentRun.IsArchived; // run should not be archived
  }

  /**
   * Determines whether the currentUser can transfer the ownership for currentRun,
   * according the store's state.
   * NOTE: this method only checks for conditions which are common accross all types
   * of resource. Any resource specific condition will still need to be checked by the
   * individual calling functions.
   * @param currentResource the resource on which permission needs to be checked
   * @param currentUser     the user for which permission needs to be checked
   */
  private canTransferOwnership(currentResource: IResource, currentUser: IUser): boolean {
    if (!currentUser) {
      return false;
    }

    return this.isResourceOwner(currentUser.Id, currentResource) && // current user should be run owner
            !currentResource.IsTransferPending &&              // run should not have pending transfer
            !this.isUserRestricted();                          // user should not have restricted acess
  }

  private getTransferRequestUrl(resourceType: ResourceType, currentResourceId: string): string {
    return BsApiEndPoints.getTransferUrl(resourceType, currentResourceId);
  }

  private getTransferRequestPostData(resourceType: ResourceType, currentResourceId: string,
                                     email: string, comment?: string): {[key: string]: string} {
    const postData = {
      Email: email,
      Permission: 'OWN',
      Description: comment
    };

    switch (resourceType) {
      case ResourceType.RUN:
      case ResourceType.PROJECT:
        return {...postData, Id: currentResourceId};
      default:
        throw new Error(ErrorMessages.UNSUPPORTED_RESOURCE_TYPE + resourceType);
    }
  }

  /**
   * Calls BSSH API to transfer a run's ownership. Only called from the run-details pages, need not handle other page-contexts
   * @param currentRun  the run for which ownership needs to be changed
   * @param email   the email id of user to whom the run ownership is to be transferred
   * @param comment the user comments for performing this action
   */
  transferOwnership(resourceType: ResourceType, currentResourceId: string, email: string, comment?: string):
    Observable<IApiResponseWrapper<Invitation>> {
    // note: have to use v1Pre3Id throughout to call v1 APIs

    if (!currentResourceId) {
      return throwError(ErrorMessages.MISSING_RESOURCE_ID);
    }

    if (!email) {
      return throwError(ErrorMessages.MISSING_TRANSFEREE_EMAIL);
    }

    const requestUrl = this.getTransferRequestUrl(resourceType, currentResourceId);
    const postData = this.getTransferRequestPostData(resourceType, currentResourceId, email, comment);

    return this.httpClient.post<IApiResponseWrapper<Invitation>>(requestUrl, postData).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
      // Add a timeout close to 5 min to manually fail the request before the api timeout. The
      // error that is thrown when it timeouts is the same as the error thrown when the api is down, thus
      // we need to manually throw an error to differentiate on the frontend to handle it appropriately
      timeout(299000)
    );
  }

  /**
   * checks if the given user is allowed to cancel a pending transfer ownership request
   * for the given run
   * @param currentRun  the run for which transfer ownership request needs to be cancelled
   * @param currentUser the user trying to cancel the transfer request
   */
  canCancelResourceOwnershipTransfer(currentRun: IRun, currentUser: IUser): boolean {
    // check for common conditions
    const isRunTransferAllowed: boolean = this.canCancelOwnershipTransfer(currentRun, currentUser);

    // check for resource specific conditions
    // In this case, there are no run-specific conditions to be checked
    return isRunTransferAllowed;
  }

  /**
   * checks if the given used is allowed to cancel a panding transfer ownership request
   * for the given resource.
   * NOTE: this method only checks for conditions which are common accross all types
   * of resource. Any resource specific condition will still need to be checked by the
   * individual calling functions.
   * @param currentResource the resource on which permission needs to be checked
   * @param currentUser     the user for which permission needs to be checked
   */
  private canCancelOwnershipTransfer(currentResource: IResource, currentUser: IUser): boolean {
    if (!currentUser) {
      return false;
    }

    return this.isResourceOwner(currentUser.Id, currentResource) &&  // user should own the run
            currentResource.IsTransferPending &&                // run should have pending transfer
            !this.isUserRestricted();                           // user should not be restricted
  }

  /**
   * Fetches the list of pending transfer requests for the given resource
   * @param resourceType  type of the resource for which pending transfer requests are required
   * @param resourceId    ID of the resource for which pending transfer requests are required
   */
  getPendingTransfers(resourceType: ResourceType, resourceId: string): Observable<IApiListResponseWrapper<Invitation>> {
    if (!resourceId) {
      return throwError(ErrorMessages.MISSING_RESOURCE_ID);
    }

    let requestUrl = BsApiEndPoints.TRANSFER.LIST_PENDING_TRANSFER_URL;

    switch (resourceType) {
      case ResourceType.RUN:
        requestUrl = requestUrl + `?Permissions=OWN&&Statuses=Pending&RunId=${resourceId}`;
        break;
      case ResourceType.PROJECT:
        requestUrl = requestUrl + `?Permissions=OWN&&Statuses=Pending&ProjectId=${resourceId}`;
        break;
      default:
        throw new Error(ErrorMessages.UNSUPPORTED_RESOURCE_TYPE + ResourceType[resourceType]);
    }

    return this.httpClient.get<IApiListResponseWrapper<Invitation>>(requestUrl)
      .pipe(// Retry in case of HTTP errors
        retryWhen(genericRetryWhen()),
        // To avoid a Flash of content, maintain a delay
        delay(observableEmitDelay)
      );
  }

  /**
   * Cancel the currently pending resource transfer request
   * @param invitationId  ID for the invitation that needs to be cancelled
   */
  cancelInvitation(invitationId: string): Observable<IApiResponseWrapper<Invitation>> {
    if (!invitationId) {
      return throwError(ErrorMessages.MISSING_INVITATION_ID);
    }

    const cancelInvitationUrl = BsApiEndPoints.getCancelInvitationUrl(invitationId);
    const postData: any = {
      Id: invitationId
    };

    return this.httpClient.post<IApiResponseWrapper<Invitation>>(cancelInvitationUrl, postData)
      .pipe(
        // Retry in case of HTTP errors
        retryWhen(genericRetryWhen()),
        // To avoid a Flash of content, maintain a delay
        delay(observableEmitDelay)
      );
  }

  /**
   * checks whether the user is owner of the given run
   * @param currentUserId user id of the user to be checked
   * @param resourceOwnerId user id of the user who owns the resource
   * @returns true if the given user is owner of the run, false otherwise
   */
  // TODO: move to util
  isResourceOwner(currentUserId: string, resource: IResource): boolean {
    return !!(currentUserId && resource &&
      currentUserId === resource.UserOwnedBy.Id);

  }

  private isUserRestricted(): boolean {
    return this.userRestrictionService.userHasStorageRestrictions();
  }

}

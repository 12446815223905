import { Injectable } from '@angular/core';
import { IObservableStore, BaseObservableStore } from '@app/core/store/infrastructure/base-observable.store';
import { ICurrentUserWorkgroupsState } from './current-user-workgroups-state';
import { BasespaceService, V2Workgroup, V2WorkgroupList } from '@bssh/ng-sdk';
import { ConsoleLogger } from '../../../core/utilities/consolelogger';
import { shareReplay, retryWhen, finalize, map, delay, catchError } from 'rxjs/operators';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { IWorkgroup } from '@app/core/model/workgroup';
import { Observable } from 'rxjs';
import _ from 'lodash';


// Interface for the store.
export interface ICurrentUserWorkgroupsStore extends IObservableStore<ICurrentUserWorkgroupsState> {
  loadCurrentUserWorkgroups(refreshState: boolean): void;
  getCurrentUserWorkgroup(id: string): Observable<IWorkgroup>;
  getWorkgroupsWithActiveSubscriptions(): Observable<V2WorkgroupList>;
}


@Injectable({
  providedIn: 'root'
})
export class CurrentUserWorkgroupsStore extends BaseObservableStore<ICurrentUserWorkgroupsState>
  implements ICurrentUserWorkgroupsStore {

  // To do: See what common code in the stores can be moved to base

  constructor(private basespaceApi: BasespaceService) {
    super(['currentUserWorkgroups', 'currentWorkgroup', 'currentWorkgroupsStateError']);
  }

  /**
   * Calls BSSH API to load user workgroups in the user workgroup store cache
   * @param refreshState Whether to discard the current state and refresh it with the api response
   */

  loadCurrentUserWorkgroups(refreshState: boolean = false, limit: number = 100): void {
    this.loadingSubject.next(true);
    const currentState = this.getState();


    if (!(currentState ? currentState.currentUserWorkgroups : null) || refreshState) {
      // Get an observable which holds workgroups from API
      // For peformance, don't include subscriptions (which implies we can't filter out workgroups with inactive subscriptions). 
      const currentUserWorkgroups$ = this.getCurrentUserWorkGroups(limit);

      // Subscribe to retrieve
      this.subs.sink = currentUserWorkgroups$.pipe(
        // Do not make an additional API call if any additional subscribers have subscribed later on
        shareReplay(1),
        // Retry in case of HTTP errors
        retryWhen(genericRetryWhen()),
        // To avoid a Flash of content, maintain a delay
        delay(observableEmitDelay)
      ).subscribe({
        next: (v2WorkgroupList) => {
          v2WorkgroupList.Items = this.getSortWorkGroupList(v2WorkgroupList.Items);

          this.setState({ currentUserWorkgroups: v2WorkgroupList, currentWorkgroup: null, currentWorkgroupsStateError: null },
            CurrentUserWorkgroupsStoreActions.LoadUserWorkgroups);
          this.loadingSubject.next(false);
        },
        error: error => {
          this.handleError(error, () => ({ ...currentState, currentWorkgroupsStateError: error }));
          this.loadingSubject.next(false);
        }
      });
    } else {
      // If a subsequent call is made, just dispatch the current state
      // This will lead to a 'stateChanged' obs emit
      this.dispatchCurrentState(currentState);
    }

  }

  /**
   * Retrieves a user's workgroup by workgroup id
   * @param id workgroup Id
   * @return An Observable  of workgroup
   */
  getCurrentUserWorkgroup(id: string): Observable<IWorkgroup> {
    return this.basespaceApi.GetV2WorkgroupsId(id).pipe(
      retryWhen(genericRetryWhen()),
      map(v2Workgroup => v2Workgroup as IWorkgroup)
    );
  }

    /**
   * Gets the workgroups with active subscriptions in the current region from the API.
   * Does not cache results, since this filtering not desired by all workgroup store consumers.
   * This path is needed for the share/transfer modal, so that users cannot accept transfers into a workgroup without an active sub
   */
  getWorkgroupsWithActiveSubscriptions(): Observable<V2WorkgroupList> {

    // Setting both of these params to true will return workgroups with active subscriptions only. 
    // See the documentation in getCurrentUserWorkgroupsRequest for more details
    const includeSubscription = true;
    const activeSubsOnly = true;

    const currentUserWorkgroups$ = this.getCurrentUserWorkGroups(100, includeSubscription, activeSubsOnly);

    return currentUserWorkgroups$.pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a flash of content, maintain a delay
      delay(observableEmitDelay),
      map((v2WorkgroupList) => {
        v2WorkgroupList.Items = this.getSortWorkGroupList(v2WorkgroupList.Items);
        return v2WorkgroupList;
      }),
      catchError(error => {
        ConsoleLogger.logError(`Error getting workgroups: `, error);
        throw error;
      })
    );
  }

  /**
   * Gets the workgroups for currently logged in user from api
   */
  private getCurrentUserWorkGroups(limit: number, includeSubscription: boolean = false, activeSubsOnly: boolean = false) {
    return this.basespaceApi.GetV2UsersIdWorkgroups(this.getCurrentUserWorkgroupsRequest(limit, includeSubscription, activeSubsOnly));
  }

  /**
   * Builds the request to call BSSH current user workgroups api. Rely on server-side
   * default parameter values for consistency with Angular 1.x code.
   */
  private getCurrentUserWorkgroupsRequest(limitWorkgroups: number, includeSubscription: boolean, activeOnly: boolean) {
    return {
      // When includeSubscription is true, the API rersponse will include the first active or most recently expired subscription for each workgroup,
      // (but this behavior is modified by the activeOnly flag)
      includesubscription: includeSubscription,

      // The value of activeOnly only matters if includeSubscription is true. 
      // If activeOnly is also true, the API will filter out workgroups without an active subscription (eg, subscriptions are expired or active but in another region)
      activeonly: activeOnly,

      includeloggedinuser: true,
      limit: limitWorkgroups,
      offset: 0,
      sortby: 'Name',
      sortdir: 'Asc',
      id: 'current'
    } as BasespaceService.GetV2UsersIdWorkgroupsParams;
  }

  /*
   * sorting the workgroup list
   */
  private getSortWorkGroupList(workGroupList: V2Workgroup []) {

    // keeping personal aside so as to add to the front of the array after sorting
    const personalArray: V2Workgroup [] = _.remove(workGroupList, workGroup => {
      return workGroup.Name === 'Personal';
    });

    // display is on the basis of key 'Name', so sorting using 'Name'
    workGroupList = _.sortBy(workGroupList, [workGroup => workGroup.Name.toLowerCase()]);

    // adding personal to the front of the sorted list
    workGroupList.unshift(personalArray[0]);

    return workGroupList;
  }

}

// The actions enum for the Current User Store
export enum CurrentUserWorkgroupsStoreActions {
  LoadUserWorkgroups = 'LOAD_USER_WORKGROUPS'
}

<div class="content">
    <div class="row">
        <p>An active subscription is required to gain access to this workgroup. Subscription plans that include iCredits for
                compute and storage are required to use these features and unlock all the value of Sequence Hub.</p>
    </div>
    <div class="row">
        <p><a class="purchase-subscription-iCredits" href="https://www.illumina.com/products/by-type/informatics-products/basespace-sequence-hub.html" target="_blank">
            <button  lib-btn primary type="button">Purchase Subscription and iCredits</button></a></p>
        
    </div>
    <div class="row"><p>Get additional support:</p></div>
    <div class="row">
        <div class="col-6">
            <h5>Online</h5>
            <p>Access support online 24 hours a day, 7 days a week.</p>
            <ul>
                <li><a class="content__link" href="https://help.basespace.illumina.com" target="_blank">Sequence Hub Help Center</a></li>
                <li><a class="content__link" href="https://www.illumina.com/company/contact-us.html" target="_blank">Contact Us</a></li>
                <li><a class="content__link" href="mailto:techsupport@illumina.com">techsupport@illumina.com</a></li>
            </ul>
        </div>
        <div class="col-6">
            <h5>By Phone</h5>
            <p>Our customer support team is available Monday through Friday from 7 a.m. to 5 p.m. PST at:</p>
            <ul>
                <li>800.809.4566 (North America)</li>
                <li>858.202.4566 (Outside)</li>
                <li>858.202.4766 (Fax)</li>
            </ul>
        </div>
    </div>
</div>

        
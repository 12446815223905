import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsAppCardComponent } from '@app/apps/bs-app-card/bs-app-card.component';
import { ComboBoxModule } from "@app/cloud-run-prep/directives/combobox/combobox.module";
import { BsGlobalBannersComponent } from '@app/comp-lib-wrappers/bs-global-banners/bs-global-banners.component';
import { RadTextFieldDirective } from '@app/core/directives/rad-text-field.directive';
import { AppSessionDurationToDisplay } from '@app/core/utilities/pipes/appsession-duration-to-display.pipe';
import { BasePairsPipe } from '@app/core/utilities/pipes/base-pairs-format-pipe';
import { BytesPipe } from '@app/core/utilities/pipes/bytes.pipe';
import { CycleCountPipe } from '@app/core/utilities/pipes/cycle-count.pipe';
import { DecodeHtmlPipe } from '@app/core/utilities/pipes/decode-html.pipe';
import { ElapsedTimePipe } from '@app/core/utilities/pipes/elapsed-time.pipe';
import { IsoDateTime } from '@app/core/utilities/pipes/iso-date-time.pipe';
import { RunTotalSizePipe } from '@app/core/utilities/pipes/run-total-size.pipe';
import { StatusToDisplayName } from '@app/core/utilities/pipes/status-to-display-name.pipe';
import { SassHelperComponent } from '@app/core/utilities/sass-helper/sass-helper.component';
import { PageNotFoundComponent } from '@app/settings/page-not-found/page-not-found.component';
import {
  ReferenceFileModalModule
} from "@app/shared/modals/custom-kits/reference-file-modal/reference-file-modal.module";
import { ResourceDeletedComponent } from '@app/shared/resource-deleted/resource-deleted.component';
import {
  BannerNotificationsContainerModule,
  BannerNotificationsModule,
  ChooserModule,
  DataTableModule,
  DownloadDataModule,
  ErrorAlertMessageModule,
  FileBrowserModule,
  FilePickerModule,
  FilePreviewModule,
  FormFieldsModule,
  InputModule,
  LibPopoverModule,
  NgxSmartModalModule,
  ProgressModule, 
  RadioCheckboxListModule,
  RadioCheckboxModule,
  SelectModule,
  ShareDataModule,
  SkeletonModule,
  StatusChangeModule,
  SuccessInfoMessageModule,
  TabNavigationModule
} from '@bssh/comp-lib';
import { BsAppLauncherComponent } from '@root/src/app/apps/bs-app-launcher/bs-app-launcher.component';
import { RadSelectDirective } from '@root/src/app/core/directives/rad-select.directive';
import { AgGridModule } from 'ag-grid-angular';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';
import { MetricsTooltipRendererComponent } from '../analyses/analysis-details/tabs/analysis-details-reports/analysis-details-metrics/metrics-tooltip-renderer/metrics-tooltip-renderer.component';
import { OperatorToSymbolPipe } from '../core/utilities/pipes/operator-to-symbol.pipe';
import { TokenReplacerPipe } from '../core/utilities/pipes/token-replacer.pipe';
import { CommentsTriggerCellRendererComponent } from './cell-renderers/comments-trigger-cell-renderer/comments-trigger-cell-renderer.component';
import { FastqDatasetNameRendererComponent } from './cell-renderers/fastq-dataset-name-renderer/fastq-dataset-name-renderer.component';
import { FileBrowserWrapperComponent } from './file-browser-wrapper/file-browser-wrapper.component';
import { HttpErrorComponent } from './http-error/http-error.component';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { AppAgreementModalComponent } from './modals/app-agreement-modal/app-agreement-modal.component';
import { AppLauncherModalComponent } from './modals/app-launcher-modal/app-launcher-modal.component';
import { ArchiveModalComponent } from './modals/archive-modal/archive-modal.component';
import { BaseModalComponent } from './modals/base-modal/base-modal.component';
import { BiosampleUnlockModalComponent } from './modals/biosample-unlock-modal/biosample-unlock-modal.component';
import { BiosampleWorkflowUploadModalComponent } from './modals/biosample-workflow-upload-modal/biosample-workflow-upload-modal.component';
import { CancelAnalysisModalComponent } from './modals/cancel-analysis-modal/cancel-analysis-modal.component';
import { CancelOwnershipTransferModalComponent } from './modals/cancel-ownership-transfer-modal/cancel-ownership-transfer-modal.component';
import { CancelRequeueModalComponent } from './modals/cancel-requeue-modal/cancel-requeue-modal.component';
import { ChangeAnalysesDeliveryStatusModalComponent } from './modals/change-analyses-delivery-status-modal/change-analyses-delivery-status-modal.component';
import { ChangeAnalysesQCStatusModalComponent } from './modals/change-qc-status/change-analyses-qc-status-modal/change-analyses-qc-status-modal.component';
import { ChangeFastqQcModalComponent } from './modals/change-qc-status/change-fastq-qc-modal/change-fastq-qc-modal.component';
import { ChangeLaneQcModalComponent } from './modals/change-qc-status/change-lane-qc-modal/change-lane-qc-modal.component';
import { ChangeSampleDataModalComponent } from './modals/change-sample-data-modal/change-sample-data-modal.component';
import { ChooserModalComponent } from './modals/chooser/chooser-modal.component';
import { CommentsModalComponent } from './modals/comments-modal/comments-modal.component';
import { ArchiveOrRestoreDataComponent } from './modals/components/archive-or-restore-data/archive-or-restore-data.component';
import { EditInfoComponent } from './modals/components/edit-info/edit-info.component';
import { LabRequeueComponent } from './modals/components/lab-requeue/lab-requeue.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { CreateResourceModalComponent } from './modals/create-resource-modal/create-resource-modal.component';
import { IndexAdapterKitModalComponent } from './modals/custom-kits/index-adapter-kit-modal/index-adapter-kit-modal.component';
import { IndexAdapterKitModalModule } from './modals/custom-kits/index-adapter-kit-modal/index-adapter-kit-modal.module';
import { LibraryPrepKitModalComponent } from './modals/custom-kits/library-prep-kit-modal/library-prep-kit-modal.component';
import { LibraryPrepKitModalModule } from './modals/custom-kits/library-prep-kit-modal/library-prep-kit-modal.module';
import { PrepTabLibraryPrepKitModalComponent } from './modals/custom-kits/prep-tab-library-prep-kit-modal/prep-tab-library-prep-kit-modal.component';
import { PrepTabLibraryPrepKitModalModule } from './modals/custom-kits/prep-tab-library-prep-kit-modal/prep-tab-library-prep-kit-modal.module';
import { DeleteConfigModalComponent } from './modals/delete-config-modal/delete-config-modal.component';
import { DeleteKitModalComponent } from './modals/delete-kit-modal/delete-kit-modal.component';
import { DownloadModalComponent } from './modals/download-modal/download-modal.component';
import { DynamicAlertModalComponent } from './modals/dynamic-alert-modal/dynamic-alert-modal.component';
import { EditInfoModalComponent } from './modals/edit-info-modal/edit-info-modal.component';
import { EmptyTrashModalComponent } from './modals/empty-trash-modal/empty-trash-modal.component';
import { FilePreviewModalComponent } from './modals/file-preview-modal/file-preview-modal.component';
import { FileUploadProgressModalComponent } from './modals/file-upload-progress-modal/file-upload-progress-modal.component';
import { GetLinkModalComponent } from './modals/get-link-modal/get-link-modal.component';
import { GetLinkComponent } from './modals/get-link/get-link.component';
import { ImportRunErrorsModalComponent } from './modals/import-run-errors-modal/import-run-errors-modal.component';
import { LaneCommentsModalComponent } from './modals/lane-comments-modal/lane-comments-modal.component';
import { LaunchAnalysisProgressBarModalComponent } from './modals/launch-analysis-progress-bar-modal/launch-analysis-progress-bar-modal.component';
import { MergeSamplesModalComponent } from './modals/merge-samples-modal/merge-samples-modal.component';
import { RequeueAnalysisModalComponent } from './modals/requeue-analysis-modal/requeue-analysis-modal.component';
import { RequeueBiosampleModalComponent } from './modals/requeue-biosample-modal/requeue-biosample-modal.component';
import { RequeueFastqModalComponent } from './modals/requeue-fastq-modal/requeue-fastq-modal.component';
import { RequeueFastqComponent } from './modals/requeue-fastq/requeue-fastq.component';
import { RequeueLibraryModalComponent } from './modals/requeue-library-modal/requeue-library-modal.component';
import { RequeueRunErrorsModalComponent } from './modals/requeue-run-errors-modal/requeue-run-errors-modal.component';
import { EditIndexAdapterKitModalModule } from './modals/custom-kits/index-adapter-kit-modal/edit-index-adapter-kit-modal.module';
import { RestoreModalComponent } from './modals/restore-modal/restore-modal.component';
import { ShareModalComponent } from './modals/share-modal/share-modal.component';
import { SwitchWorkgroupModalComponent } from './modals/switch-workgroup/switch-workgroup-modal.component';
import { TransferModalComponent } from './modals/transfer-modal/transfer-modal.component';
import { TrashModalComponent } from './modals/trash-modal/trash-modal.component';
import { TrashPlannedRunsModalComponent } from './modals/trash-planned-runs-modal/trash-planned-runs-modal.component';
import { TabsPageLayoutComponent } from './tabs-page-layout/tabs-page-layout.component';
import { ValidatedCsvComponent } from './validated-csv/validated-csv.component';
import { MarkdownPipe } from '@app/core/utilities/pipes/markdown.pipe';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';

import "@radiance-design-software/option";
import "@radiance-design-software/select";
import "@radiance-design-software/text-field";
import "@radiance-design-software/button";
import "@radiance-design-software/link";
import "@radiance-design-software/definition-list";
import "@radiance-design-software/banner-notification";
import { ReadMoreDirective } from '@app/core/directives/read-more.directive';
import "@radiance-design-software/modal";

// this will probably be reserved for components, directives, pipes, etc.
// by default, DO NOT put services here, as that will oftenresult in 1 instance of that
// service for each feature module this is injected into,
// and we usually want shared-services to be singletons
@NgModule({
  declarations: [
    IsoDateTime,
    ElapsedTimePipe,
    BytesPipe,
    MarkdownPipe,
    BsshDatePipe,
    DecodeHtmlPipe,
    ArchiveOrRestoreDataComponent,
    ArchiveModalComponent,
    RestoreModalComponent,
    RequeueFastqModalComponent,
    RequeueFastqComponent,
    EditInfoComponent,
    EditInfoModalComponent,
    GetLinkModalComponent,
    GetLinkComponent,
    ShareModalComponent,
    BasePairsPipe,
    CycleCountPipe,
    RunTotalSizePipe,
    DownloadModalComponent,
    BaseModalComponent,
    FilePreviewModalComponent,
    FileBrowserWrapperComponent,
    CancelOwnershipTransferModalComponent,
    TransferModalComponent,
    TrashModalComponent,
    BsGlobalBannersComponent,
    TrashPlannedRunsModalComponent,
    ChangeLaneQcModalComponent,
    PageNotFoundComponent,
    ResourceDeletedComponent,
    SwitchWorkgroupModalComponent,
    LaneCommentsModalComponent,
    SassHelperComponent,
    TokenReplacerPipe,
    ChangeAnalysesDeliveryStatusModalComponent,
    AlertModalComponent,
    DynamicAlertModalComponent,
    AppAgreementModalComponent,
    CommentsTriggerCellRendererComponent,
    ChangeAnalysesQCStatusModalComponent,
    BiosampleWorkflowUploadModalComponent,
    ValidatedCsvComponent,
    OperatorToSymbolPipe,
    CreateResourceModalComponent,
    StatusToDisplayName,
    AppSessionDurationToDisplay,
    CancelRequeueModalComponent,
    RequeueLibraryModalComponent,
    RequeueBiosampleModalComponent,
    RequeueAnalysisModalComponent,
    LabRequeueComponent,
    BiosampleUnlockModalComponent,
    DeleteKitModalComponent,
    CommentsModalComponent,
    FastqDatasetNameRendererComponent,
    ChangeFastqQcModalComponent,
    ChooserModalComponent,
    HttpErrorComponent,
    CancelAnalysisModalComponent,
    DeleteConfigModalComponent,
    ImportRunErrorsModalComponent,
    RequeueRunErrorsModalComponent,
    LaunchAnalysisProgressBarModalComponent,
    AppLauncherModalComponent,
    BsAppLauncherComponent, 
    ConfirmationModalComponent,
    EmptyTrashModalComponent,
    MergeSamplesModalComponent,
    TabsPageLayoutComponent,
    ChangeSampleDataModalComponent,
    FileUploadProgressModalComponent,
    MetricsTooltipRendererComponent,
    BsAppLauncherComponent, 
    BsAppCardComponent, 
    RadSelectDirective, 
    RadTextFieldDirective,
    ReadMoreDirective
  ],
  imports: [
    CommonModule,
    AgGridModule.withComponents([]),
    NgxSmartModalModule,
    LibPopoverModule,
    FormsModule,
    RouterModule,
    ShareDataModule,
    DownloadDataModule,
    FileBrowserModule,
    FilePreviewModule,
    BannerNotificationsModule.forRoot({ preventDuplicates: true }),
    BannerNotificationsContainerModule,
    StatusChangeModule,
    ErrorAlertMessageModule,
    RadioCheckboxModule,
    ProgressModule,
    LibraryPrepKitModalModule,
    FilePickerModule,
    IndexAdapterKitModalModule,
    EditIndexAdapterKitModalModule,
    SuccessInfoMessageModule,
    PrepTabLibraryPrepKitModalModule,
    ReferenceFileModalModule,
    OverlayscrollbarsModule,
    SelectModule,
    ChooserModule,
    ReactiveFormsModule,
    FormFieldsModule,
    InputModule,
    RadioCheckboxListModule,
    DataTableModule,
    TabNavigationModule,
    ComboBoxModule,
    SkeletonModule
  ],
  exports: [
    CommonModule,
    AgGridModule,
    IsoDateTime,
    ElapsedTimePipe,
    BytesPipe,
    MarkdownPipe,
    BsshDatePipe,
    DecodeHtmlPipe,
    AgGridModule,
    RequeueFastqModalComponent,
    RequeueFastqComponent,
    GetLinkModalComponent,
    GetLinkComponent,
    BaseModalComponent,
    ShareModalComponent,
    BasePairsPipe,
    CycleCountPipe,
    RunTotalSizePipe,
    DownloadModalComponent,
    TransferModalComponent,
    FileBrowserWrapperComponent,
    FilePreviewModalComponent,
    TrashModalComponent,
    CancelOwnershipTransferModalComponent,
    BsGlobalBannersComponent,
    TrashPlannedRunsModalComponent,
    LaneCommentsModalComponent,
    ChangeLaneQcModalComponent,
    TokenReplacerPipe,
    LibraryPrepKitModalModule,
    ChangeAnalysesDeliveryStatusModalComponent,
    PrepTabLibraryPrepKitModalModule,
    ReferenceFileModalModule,
    AlertModalComponent,
    DynamicAlertModalComponent,
    AppAgreementModalComponent,
    OperatorToSymbolPipe,
    CommentsModalComponent,
    CommentsTriggerCellRendererComponent,
    ChangeFastqQcModalComponent,
    ChooserModalComponent,
    StatusToDisplayName,
    AppSessionDurationToDisplay,
    ChangeAnalysesQCStatusModalComponent,
    ValidatedCsvComponent,
    CancelRequeueModalComponent,
    RequeueLibraryModalComponent,
    RequeueBiosampleModalComponent,
    RequeueAnalysisModalComponent,
    BiosampleUnlockModalComponent,
    ChooserModalComponent,
    OperatorToSymbolPipe,
    CancelAnalysisModalComponent,
    SassHelperComponent,
    ConfirmationModalComponent,
    EmptyTrashModalComponent,
    MergeSamplesModalComponent,
    TabsPageLayoutComponent,
    ChangeSampleDataModalComponent,
    BsAppLauncherComponent, 
    BsAppCardComponent, 
    RadSelectDirective, 
    RadTextFieldDirective,
    ReadMoreDirective
  ],
  providers: [
    IsoDateTime,
    ElapsedTimePipe,
    BytesPipe,
    MarkdownPipe,
    BsshDatePipe,
    DecodeHtmlPipe,
    TitleCasePipe,
    BasePairsPipe,
    CycleCountPipe,
    RunTotalSizePipe,
    TokenReplacerPipe,
    OperatorToSymbolPipe,
    StatusToDisplayName,
    AppSessionDurationToDisplay,
    DecimalPipe
  ],
  entryComponents: [
    TransferModalComponent,
    GetLinkModalComponent,
    ShareModalComponent,
    FilePreviewModalComponent,
    TrashPlannedRunsModalComponent,
    TrashModalComponent,
    DownloadModalComponent,
    CancelOwnershipTransferModalComponent,
    ChangeLaneQcModalComponent,
    IndexAdapterKitModalComponent,
    ArchiveModalComponent,
    RestoreModalComponent,
    RequeueFastqModalComponent,
    EditInfoModalComponent,
    LaneCommentsModalComponent,
    LibraryPrepKitModalComponent,
    ChangeAnalysesDeliveryStatusModalComponent,
    ChangeAnalysesQCStatusModalComponent,
    CancelRequeueModalComponent,
    AlertModalComponent,
    DynamicAlertModalComponent,
    RequeueLibraryModalComponent,
    RequeueBiosampleModalComponent,
    PrepTabLibraryPrepKitModalComponent,
    RequeueAnalysisModalComponent,
    DeleteKitModalComponent,
    BiosampleWorkflowUploadModalComponent,
    CommentsModalComponent,
    BiosampleUnlockModalComponent,
    ChangeFastqQcModalComponent,
    ChooserModalComponent,
    CreateResourceModalComponent,
    CancelAnalysisModalComponent,
    AppLauncherModalComponent,
    BsAppLauncherComponent, 
    ConfirmationModalComponent,
    EmptyTrashModalComponent,
    MergeSamplesModalComponent,
    ChangeSampleDataModalComponent
  ]
})
export class SharedModule { }

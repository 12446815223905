import { Injectable } from '@angular/core';
import { IBioSampleLibraryYield } from '@app/core/model/bioSamples/bioSample';
import { IPrepRequest } from '@app/core/model/prepRequest';
import { ILabWorkflow } from '@app/core/model/labWorkflow';
import { DateTimeUtilities } from '@app/core/utilities/date-time.utilities';

@Injectable({
  providedIn: 'root'
})
export class LabWorkflowService {

  constructor() { }

 public getSortedActiveLabWorkflows( yields: IBioSampleLibraryYield[], prepRequests: IPrepRequest[] ): ILabWorkflow[] {
    if (!prepRequests) {
        prepRequests = [];
    }
    return this.getSortedLabWorkflows(yields, prepRequests)
        .filter((w) => w.prepRequest != null && w.prepRequest.Status !== 'Canceled' && w.prepRequest.Status !== 'Cancelled');
 }

 public getSortedLabWorkflows( yields: IBioSampleLibraryYield[], prepRequests: IPrepRequest[] ): ILabWorkflow[] {
    let labWorkflows: ILabWorkflow[] = [];
    if (!prepRequests) {
        prepRequests = [];
    }
    if (!yields) {
        yields = [];
    }
    const prepRequestDictionary = this.getActivePrepRequestsDictionary(prepRequests);

    yields.forEach((y) => {
        const w: ILabWorkflow = {
            prepKitYield: y,
            prepRequest: null,
            missingYield: 0
        };

        if (prepRequestDictionary[y.LibraryPrep.Id]) {
            w.prepRequest = prepRequestDictionary[y.LibraryPrep.Id];
            w.missingYield = w.prepRequest.RequiredYield - w.prepKitYield.TotalExpectedYield;
        }

        labWorkflows.push(w);
    });

    labWorkflows = labWorkflows.sort((a, b) => {

        // sort by preprequest DateModified, then by LibraryPrepKit name

        if (a.prepRequest == null && b.prepRequest == null) {
            if (a.prepKitYield.LibraryPrep.Name < b.prepKitYield.LibraryPrep.Name) {
                return -1;
            } else if (a.prepKitYield.LibraryPrep.Name === b.prepKitYield.LibraryPrep.Name) {
                return 0;
            } else {
                return 1;
            }
        }

        if (a.prepRequest != null && b.prepRequest == null) {
            return -1;
        }

        if (a.prepRequest == null && b.prepRequest != null) {
            return 1;
        }

        if (a.prepRequest != null && b.prepRequest != null) {
            return DateTimeUtilities.compareByDate(a, b, (input) => input.prepRequest.DateModified);
        }

        return 0;
    });

    return labWorkflows;
 }

 public getActivePrepRequestsDictionary(prepRequests: IPrepRequest[]): { [key: string]: IPrepRequest } {
    const result: { [key: string]: IPrepRequest } = {};

    // construct dictionary where key is the LibraryPrep Id
    if (!prepRequests) {
        prepRequests = [];
    }

    prepRequests.forEach((pr) => {
        const id = pr.LibraryPrep.Id;
        if (result[id]) {
            return;
        }
        result[id] = pr;
    });

    return result;
 }
}

import { Component, ViewEncapsulation } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { BasespaceService, V2FilesList } from '@bssh/ng-sdk';
import { CustomColDef } from '@bssh/comp-lib/lib/components/data-table/custom-colDef';
import { get } from 'lodash';
import { SubSink } from 'subsink';
import { delay, retryWhen } from 'rxjs/operators';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { BytesPipe } from '@app/core/utilities/pipes/bytes.pipe';

const defaultGetFilesParams: BasespaceService.GetV2DatasetsIdFilesParams = {
  id: null,
  limit: 100,
  sortby: 'Name',
  sortdir: 'Asc',

  // required params in object type but not req. here
  turbomode: null,
  filehrefcontentresolution: null,
  excludebamcoveragefolder: null,
  excludeemptyfiles: null,
  excludesystemfolder: null,
  excludevcfindexfolder: null,
  excludeextensions: null
};

@Component({
  selector: 'app-fastq-dataset-name-renderer',
  templateUrl: './fastq-dataset-name-renderer.component.html',
  styleUrls: ['./fastq-dataset-name-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FastqDatasetNameRendererComponent implements AgRendererComponent {
  public params = null;
  public value = null; // value that will be displayed in cell
  public rowData = [];
  public gridOptions = null;
  public run = null;
  protected subs: SubSink = new SubSink();

  constructor(private basespaceApi: BasespaceService, private bytePipe: BytesPipe) { }

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: any): void {
    if (!params || !params.data) {
      return;
    }

    this.params = params;
    this.value = this.params.data.Name ? this.params.data.Name : this.params.data.Id;
    this.run = this.params.data.Run ? this.params.data.Run : null;
    this.initializeDatatable();

    defaultGetFilesParams.id = this.params.data.Id; // setting the resource id for which files info is req.
    this.subs.sink = this.basespaceApi.GetV2DatasetsIdFiles(defaultGetFilesParams).pipe(
      retryWhen(genericRetryWhen()),
      delay(observableEmitDelay)
    )
      .subscribe({
        next: (data: V2FilesList) => {
          this.rowData = data.Items;
        }
      });
  }

  private initializeDatatable() {
    const columnDefs: CustomColDef[] = [
      {
        headerName: 'FastQ Filename',
        field: 'Name',
        suppressSizeToFit: false,
        minWidth: 150,
        cellClass: 'value-cell-style'
      },
      {
        headerName: 'Size',
        valueGetter: (params) => {
          if (params && params.data) {
            const totalSize = get(params.data, 'Size', 0);
            return this.bytePipe.transform(totalSize, 2);
          }

          return null;
        },
        headerClass: 'ag-header-cell-numeric',
        cellClass: 'ag-cell-numeric value-cell-style',
        type: 'numericColumn',
        suppressSizeToFit: false,
        minWidth: 150
      }
    ];

    this.gridOptions = {
      defaultColDef: {
        headerCheckboxSelection: false,
        checkboxSelection: false,
        sortable: false
      },
      columnDefs,
      headerHeight: 20,
      rowHeight: 72
    };
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BsSpinnerService {
  public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading: Observable<boolean> = this.loadingSubject.asObservable();

  constructor() { }


}

<div #InitiateBiosampleUploadDiv>
    <app-sass-helper #sassHelper></app-sass-helper>
    <ngx-smart-modal #InitiateBiosampleUploadModal [identifier]="modalType" [title]="ngxSmartModalTitle" [closable]="true" [dismissible]="false"
    [escapable]="false" showCloseButton="true" [disableConfirmButton]="disableConfirmButton"
        [closeButtonText]="closeButtonText" [confirmButtonText]="confirmButtonText">

        <section #apiDataWrapperLoader class="wrapper" 
        [ngClass]="{ active: (isLoadingApiData$ | async) }"
        *ngIf="(isLoadingApiData$ | async)" data-cy="progress-overlay-wrapper">
        </section>

        <lib-progress #apiDataSpinnerLoader [id]="apiDataSpinnerLoader" class="spinner" [loader]="false" [fixed]="true"
                [spinner]="true" [spinnerPosition]="'center'" [progressBarContainer]="'modal'" (started)="onStarted()" (completed)="onCompleted()"
                data-cy="biosample-workflow-modal-overlay-spinner">
        </lib-progress>

        <lib-progress class="biosample-upload-loader" id="biosample-upload-loading" #progressBar [loader]="true"
            [spinner]="false" [spinnerPosition]="'center'" (completed)="onProgressBarCompleted($event)">
        </lib-progress>

        <div *ngIf="!errorMsg && !uploadResultData">
            <hr class="popover-line">
            <lib-file-picker [numberOfFilesAllowed]="noOfFilesAllowed" [validExtensionsPattern]="validExtensionsPattern"
                (filesSelected)="onFilesSelected($event)">
            </lib-file-picker>
            <hr class="popover-line-top">
        </div>

        <ng-container *ngIf="!(isLoadingApiData$ | async) && !errorMsg && uploadResultData">
            <div class="banner-notifications-container" bannerNotificationsContainer>
            </div>
            <div class="validated-csv-container" *ngIf="uploadResultData">
                <app-validated-csv [data]="uploadResultData"></app-validated-csv>
            </div>
        </ng-container>

        <div class="download-template" *ngIf="!uploadResultData">
            <div class="download-template__item">
                <a target="_top" class="download-template__item__link" href='{{csvFormatTemplate}}'>Download this template</a> to correctly set up a
                biosample workflow document (documents must be in CSV format).
            </div>
            <div class="download-template__item">
                <lib-popover class="lib-popover__header" 
                [popoverLabel]="'Additional upload guidance'"
                popoverSize= "auto"
                [adaptivePosition]="adaptivePosition"
                [popoverTitle]="'UPLOAD GUIDANCE'">
                    <p class="file-format">
                        <span class="file-format__header">CSV Format</span>
                        <br />
                        {{csvFormatMessage}}
                    </p>
                    <p class="file-format">
                        <span class="file-format__header">Biosample Workflows</span>
                        <br />
                        {{biosampleWorkflowText}}. <a href='{{biosampleWorkflowUrl}}' target="_blank">Learn More</a>
                    </p>
                    <p class="file-format">
                        <span class="file-format__header">Analysis Workflows</span>
                        <br />
                        {{analysisWorkflowText}} <a href='{{analysisWorkflowUrl}}' target="_blank">Learn More</a>
                    </p>
                    <p class="file-format">
                        Certain apps require the <a href='{{fastqReqUrl}}' target="_blank">strict Illumina FASTQ
                            standard</a> in order to be consumed.
                    </p>
                </lib-popover>
            </div>
        </div>

        <div *ngIf="errorMsg">
            <lib-error-alert-message [errorMsg]="errorMsg">
            </lib-error-alert-message>
        </div>
    </ngx-smart-modal>
</div>
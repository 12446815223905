<ngx-smart-modal
  #getLink
  identifier="getLink"
  [closable]="true"
  closeButtonText="Close"
  [confirmButtonText]="confirmButtonText"
  [disableConfirmButton]="disableConfirmButton"
  [title]="modalTitle"
>
  <app-get-link
    [shareUrl]="shareUrl"
    [title]="title"
    [canDisplayActivationText]="errorMsg ? false : true"
  ></app-get-link>
  
  <lib-error-alert-message
    [alertMsg]="alertMessage"
    [errorMsg]="errorMsg"
  ></lib-error-alert-message>

</ngx-smart-modal>
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IRunData, NgxSmartModalService } from '@bssh/comp-lib';
import { delay, filter, map, take, tap } from 'rxjs/operators';
import { BaseModalComponent } from './../base-modal/base-modal.component';
import { ModalTexts } from './../modal-texts';
import { IUnlockBiosamplesModalInput } from './../model/action-modal';
import { IBioSample } from '@app/core/model/bioSamples/bioSample';

@Component({
  selector: 'app-biosample-unlock-modal',
  templateUrl: './biosample-unlock-modal.component.html',
  styleUrls: ['./biosample-unlock-modal.component.scss']
})
export class BiosampleUnlockModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  public modalType = 'BiosampleUnlockModalComponent';
  public confirmButtonText = ModalTexts.BIO_SAMPLE_UNLOCK.CONFIRM_BUTTON_TEXT;

  public modalTitle = ModalTexts.BIO_SAMPLE_UNLOCK.MODAL_TITLE;
  public biosampleItems: IBiosampleItem[];
  public alertMessage = `
  <div>
      <p>By selecting Continue, you have reviewed all FASTQ datasets to ensure accurate data aggregation.
          <b>Using a biosample from mixed sources may produce inaccurate results.</b>
      </p>
  </div>
  `;

  constructor(
    public ngxSmartModalService: NgxSmartModalService
  ) {
    super(ngxSmartModalService);
    this.subs.sink = this.confirm.subscribe(() => {
      this.closable = false;
      this.disableConfirmButton = true;
      this.isLoadingApiData$.next(true);

      this.showCloseButton = false;
      this.showConfirmButton = false;
    });
  }

  ngOnInit() {
    super.ngOnInit();

    // Listen to get biosample items to display to user
    this.subs.sink = this.data.pipe(
      filter((input: IUnlockBiosamplesModalInput) => !!input.biosampleItems),
      take(1)
    ).subscribe((input: IUnlockBiosamplesModalInput) => {
      this.biosampleItems = input.biosampleItems;
    });

    // Listen to unlock biosample progress
    this.subs.sink = this.data.pipe(
      filter((input: IUnlockBiosamplesModalInput) => !isNaN(input.unlockBiosamplesProgress)),
      map((input: IUnlockBiosamplesModalInput) => input.unlockBiosamplesProgress),
      tap((unlockBiosamplesProgress: number) => {
        if(this.apiDataSpinnerLoader) {
          this.apiDataSpinnerLoader.set(unlockBiosamplesProgress * 100);
        }
      }),
    ).subscribe();
  }

  canCloseOnConfirm(): boolean {
    return false;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  protected constructError(errors: any): string[] {
    return errors.map((biosampleFailureItem: { errorMessage: string; data: IBioSample }) => {
      return `${biosampleFailureItem.data.BioSampleName}: ${biosampleFailureItem.errorMessage}`;
    });
  }

}

export interface IBiosampleItem extends IRunData {
  id: string;
}
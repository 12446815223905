import { BiosamplesSortFields } from '@app/biosamples/datasource/biosamples.datasource';
import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { ISearchResourceConfig } from '@app/core/services/resource-dictionary/search-resource-dictionary-types';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { DataTableUtilities } from '@app/core/utilities/data-table-utilities';
import { CustomColDef } from '@bssh/comp-lib/lib/components/data-table/custom-colDef';
import { OverrideColumnDefinition, OverrideColumnParameters, SearchGridOptionsFormatter } from './search-grid-options-formatter';
import { DatePipe } from '@angular/common';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';

export enum BiosampleSearchColumnFields {
    Name = 'BioSampleName',
    DefaultProjectName = 'DefaultProjectName',
    Updated = 'Updated',
    Owner = 'Owner',
    LabStatus = 'LabStatus',
    Status = 'Status'
}

export interface BiosampleOverrideColumnDefinition extends OverrideColumnDefinition<BiosampleSearchColumnFields> {}

export class BiosampleSearchGridOptionsFormatter extends SearchGridOptionsFormatter<BiosampleSearchColumnFields> {
    private biosampleResourceConfig: ISearchResourceConfig;

    constructor(public resourceDictionaryService: SearchResourceDictionaryService, public bsshDatePipe: BsshDatePipe) {
        super();
        this.biosampleResourceConfig = this.resourceDictionaryService.getResourceConfigByType(SearchResourceType.Biosample);
    }

    protected getOverrideColumnParameters(): OverrideColumnParameters<BiosampleSearchColumnFields> {
        const inclusiveColumns: BiosampleSearchColumnFields[] = [
            BiosampleSearchColumnFields.Name,
            BiosampleSearchColumnFields.DefaultProjectName,
            BiosampleSearchColumnFields.Owner,
            BiosampleSearchColumnFields.Updated
        ];

        const overrideColumnDefinitions: OverrideColumnDefinition<BiosampleSearchColumnFields>[] = [
            {
              colId: BiosampleSearchColumnFields.Name,
              checkboxSelection: false
            },
            {
              colId: BiosampleSearchColumnFields.DefaultProjectName,
              filterActive: false
            }
        ];

        return {
            inclusiveColumns,
            overrideColumnDefinitions
        };
    }

    protected getDefaultColumnDefinitions(): CustomColDef[] {
        const biosampleConfig = this.biosampleResourceConfig;
        return [
            {
                headerName: 'Name',
                field: 'BioSample.BioSampleName',
                cellRendererSelector: (params) => {
                  if(params.data) {
                    return DataTableUtilities.getLinkRenderer(
                      params.data.BioSample.BioSampleName,
                      biosampleConfig.uiHrefAccessor(params.data)
                    );
                  } else {
                    return DataTableUtilities.getSpanRenderer();
                  }
                },
                flex: 3.5,
                filterActive: false,
                sortable: false,
                colId: BiosampleSearchColumnFields.Name,
              },
              {
                headerName: 'Project',
                field: 'BioSample.DefaultProject.Name',
                cellRendererSelector: (params) => {
                  if(params.data && params.data.BioSample && params.data.BioSample.DefaultProject) {
                    const project = params.data.BioSample.DefaultProject;
                    return DataTableUtilities.getLinkRenderer(
                        project.Name,
                        `/projects/${project.Id}`
                    );
                  } else {
                    return DataTableUtilities.getSpanRenderer();
                  }
                },
                colId: BiosamplesSortFields.DefaultProjectName,
                sortable: false,
                flex: 3
              },
              {
                headerName: 'Updated',
                valueGetter: (params) => {
                  if (!params.data) { return; }
                  return this.bsshDatePipe.transform(params.data.BioSample.DateModified);
                },
                flex: 1.5,
                filterActive: false,
                colId: BiosampleSearchColumnFields.Updated,
                resizable: false,
                sortable: false,
                cellRenderer: 'span',
              },
              {
                headerName: 'Owner',
                field: 'BioSample.UserOwnedBy.Name',
                flex: 2,
                colId: BiosampleSearchColumnFields.Owner,
                sortable: false,
                cellRenderer: 'span',
              },
              {
                headerName: 'Lab Status',
                field: 'BioSample.LabStatus',
                suppressSizeToFit: true,
                cellRenderer: 'span',
                maxWidth: 160,
                minWidth: 80,
                filterActive: false,
                colId: BiosampleSearchColumnFields.LabStatus,
                sortable: false,
                resizable: false
              },
              {
                headerName: 'Biosample Status',
                field: 'BioSample.Status',
                suppressSizeToFit: true,
                cellRenderer: 'span',
                maxWidth: 180,
                minWidth: 80,
                cellStyle: (params) => {
                  if (params.value === 'Canceled' || params.value === 'QcFailed') {
                      return { fontWeight: 'bold' };
                  } else {
                      return null;
                  }
                },
                filterActive: false,
                colId: BiosampleSearchColumnFields.Status,
                sortable: false,
                resizable: false
              }
        ];
    }
}
import { IApplication } from '../applications/applications';


export interface IUserAgreement {
    Agreement?: IAgreement;
    Status?: string;
    DateSigned?: string;
}

export interface IAgreement {
    Id?: number;
    Href?: string;
    Title?: string;
    Category?: string;
    ExpiredOn?: string;
    Application?: IApplication;
}

export enum AgreementCategory {
    USER = 'USER',
    DEVELOPER = 'DEVELOPER',
    APP_EULA = 'APP_EULA'
    // add more as necessary.
}

export enum AgreementStatus {
    PENDING = 'pending',
    SIGNED = 'signed'
    // add more as necessary.
}

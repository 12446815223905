/**
 * Contains the search resource types as expected by the BSSH Search API.
 *
 * This enum may be slightly different from `ResourceType` enum, as the search api expect different resource type strings
 */
export class SearchResourceType {

    public static Project = 'project';
    public static Run = 'run';
    public static App = 'app';
    public static Biosample = 'biosample';
    public static Sample = 'sample';
    public static File = 'datasetfile';
    public static Dataset = 'dataset';
    public static AppResult = 'appresult';
    public static AppSession = 'appsession';
    // Add more as necessary.
}

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService, ProgressComponent } from '@bssh/comp-lib';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { IFileUploadModalInput, IFileUploadProgressModalOutput } from '../model/action-modal';

@Component({
  selector: 'app-file-upload-progress-modal',
  templateUrl: './file-upload-progress-modal.component.html',
  styleUrls: ['./file-upload-progress-modal.component.scss']
})
export class FileUploadProgressModalComponent extends BaseModalComponent implements OnInit, AfterViewInit, OnDestroy {
  modalType = 'fileUploadProgressModal';
  fileName: string;
  totalSize: string = '0B';
  uploadedSize: string = '0B';
  disableCancel: boolean = false;
  statusText: string;

  private totalSizeInBytes: number = 0;
  
  @ViewChild('fileUploadProgressBar', { static: false }) progressBar: ProgressComponent;
  
  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    public elementRef: ElementRef
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    this.subs.sink = this.data.subscribe((modalInput: IFileUploadModalInput) => {
      // Initial input
      if (!this.fileName) {
        this.fileName = modalInput.fileName;
        this.totalSizeInBytes = modalInput.totalSizeInBytes;
        this.totalSize = this.convertFileSize(this.totalSizeInBytes);
        this.statusText = `Uploading ${this.fileName}`;
      }
      // Progress update
      const uploadedSizeInBytes = modalInput.uploadedSizeInBytes;
      this.uploadedSize = this.convertFileSize(uploadedSizeInBytes);
      if (this.progressBar) {
        const uploadedPercentage = uploadedSizeInBytes / this.totalSizeInBytes * 100
        this.setProgress(uploadedPercentage);
        if (uploadedSizeInBytes >= this.totalSizeInBytes) {
          this.statusText = 'Completing upload session, please wait...';
          this.disableCancel = true;
          // add back primary button class for disabled button styling
          this.elementRef.nativeElement.querySelector('.btn').className = 'btn btn--primary';
        }
      }
    });
  }
  
  ngAfterViewInit() {
    super.ngAfterViewInit();
    // remove primary button styling
    this.elementRef.nativeElement.querySelector('.btn--primary').className = 'btn';
  }

  setProgress(uploadedPercentage: number) {
    this.progressBar.set(uploadedPercentage);
  }

  onModalOpen() {
    this.setProgress(0); // need to set progress after modal opens for the bar to appear
  }

  accept(modal: NgxSmartModalComponent) {
    const modalOutput: IFileUploadProgressModalOutput = { isCancel: true };
    this.confirm.emit(modalOutput);
    this.confirm.complete();
    modal.close();
  }

  get progressText() {
    return `${this.uploadedSize} of ${this.totalSize}`;
  }

  private convertFileSize(sizeInBytes: number) {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes}B`;
    }
    const sizeInKb = sizeInBytes / 1024;
    if (sizeInKb < 1024) {
      return `${+sizeInKb.toFixed(2)}KB`;
    }
    const sizeInMb = sizeInKb / 1024;
    if (sizeInMb < 1024) {
      return `${+sizeInMb.toFixed(2)}MB`;
    }
    const sizeInGb = sizeInMb / 1024;
    if (sizeInGb < 1024) {
      return `${+sizeInGb.toFixed(2)}GB`;
    }
    const sizeInTb = sizeInGb / 1024;
    return `${+sizeInTb.toFixed(2)}TB`;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
  
}

import { Injectable } from '@angular/core';
import { BasespaceService, V2UserNotificationCompactList, V2UserNotificationCompact } from '@bssh/ng-sdk';
import { shareReplay, retryWhen, finalize, delay, map, filter, catchError } from 'rxjs/operators';
import _ from 'lodash';
import { IObservableStore, BaseObservableStore } from '../../infrastructure/base-observable.store';
import { IUserNotification, IUserNotificationInfo, IUserNotificationList } from '@app/core/model/user-notifications/user-notifications';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { IUserAlertNotificationState } from './user-alert-notifications.state';

// Interface
export interface IUserAlertNotificationStore extends IObservableStore<IUserAlertNotificationState> {
  loadUserAlertNotificationList(category: string, offset: number, limit: number,
    sortby: string, sortdir: string, forceLoad: boolean, attentionRequired: boolean): void;
  deleteUserNotification(notificationId: string): void;
}

@Injectable({
  providedIn: 'root'
})
export class UserAlertNotificationStore extends BaseObservableStore<IUserAlertNotificationState> implements IUserAlertNotificationStore {

  // pass in the state slices we are interested in...
  constructor(private basespaceApi: BasespaceService) {
    super(['alertNotifications']);
  }

  /* Calls BSSH API to load a list of user notifications and cache in the notification store as 'userNotificationList'
  */
  public loadUserAlertNotificationList(
    category: string, offset: number, limit: number, sortby: string,
    sortdir: string, forceLoad: boolean = false) {
    const currentState = this.getState();
    if (currentState && currentState.alertNotifications && !forceLoad) {
      this.dispatchCurrentState(currentState);
    } else {
      this.loadingSubject.next(true);
      const requestParams: any = { 'category': category, 'attentionrequired': 0, 'offset': offset, 'limit': limit, 'sortby': sortby, 'sortdir': sortdir };
      const userNotificationList$ = this.basespaceApi.GetV2UsersCurrentNotifications(requestParams);
      // Subscribe to retrieve
      this.subs.sink = userNotificationList$.pipe(
        // Do not make an additional API call if any additional subscribers have subscribed later on
        shareReplay(1),
        // Retry in case of HTTP errors
        retryWhen(genericRetryWhen()),
        // To avoid a Flash of content, maintain a delay
        delay(observableEmitDelay),
        // Stop loading in finalize.
        finalize(() => this.loadingSubject.next(false))
      ).subscribe({
        next: (apiResponse) => {
          const notifications: IUserNotification[] = apiResponse.Items.map((item: V2UserNotificationCompact) => {
            const notification = item as IUserNotification;
            const notificationInfo = item.NotificationInfo as IUserNotificationInfo;
            notification.NotificationInfo = notificationInfo;
            return notification;
          });
          this.setState({ alertNotifications: {
            limit: limit,
            sortby : sortby,
            sortdir : sortdir,
            userNotifications : notifications }, 
            alertNotificationStateError: null 
          }, StoreActions.LoadNotificationDetails);
        },
        error: error => {
          this.handleError(error, () => ({ ...this.getState(), alertNotificationStateError: error }));
          this.loadingSubject.next(false);
        }
      });
    }
  }

  deleteUserNotification(notificationId: string) {
    const currentState = this.getState();
    if (currentState && currentState.alertNotifications) {
      this.subs.sink = this.basespaceApi.DeleteV2NotificationsId(notificationId).pipe(
        // Retry in case of HTTP errors
        retryWhen(genericRetryWhen()),
        // To avoid a Flash of content, maintain a delay
        delay(observableEmitDelay),
      ).subscribe({
        next: (apiResponse: any) => {
          // filter out the dismissed notification
          if (currentState.alertNotifications) {
            currentState.alertNotifications.userNotifications = currentState.alertNotifications.userNotifications.filter((
              {  Id }) => Id !== notificationId);
          }
          this.setState({ alertNotifications: currentState.alertNotifications, alertNotificationStateError: null}, StoreActions.DeleteNotification);
        },
        error: error => {
          this.handleError(error, () => ({ ...this.getState(), alertNotificationStateError: error }));
          this.loadingSubject.next(false);
        }
      });
    }
  }
}

// The action-names for this store, for logging/tracking.
export enum StoreActions {
  LoadNotificationDetails = 'LOAD_ALERT_NOTIFICATION_LIST',
  DeleteNotification = 'DELETE_ALERT_NOTIFICATION'
}


import environment from '@environments/environment';

export interface Scripts {
    name: string;
    src?: string;
    loadAsync: boolean;
    type: string;
    innerText?: string;
}

export class ScriptConstants {

    static pendoAnalyticsScript : string = `(function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
            v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
                o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
        // Call this whenever information about your visitors becomes available
        // Please use Strings, Numbers, or Bools for value types.
        pendo.initialize({
            visitor: {
                id:            @visitorId,   // Required if user is logged in
                // email:        // Optional
                // role:         // Optional
                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
                actingId:      @visitorActingId
            },
            account: {
                // id:           'ACCOUNT-UNIQUE-ID' // Highly recommended
                // name:         // Optional
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional
                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            }
        });
    })('${environment.pendoAnalyticsApiKey}');`
}

 export const ScriptStore: Scripts[] = [
     { name: 'walkmeProd', src: 'https://cdn.walkme.com/users/5b32612733d9430e8009968749f9fa73/walkme_5b32612733d9430e8009968749f9fa73_https.js', loadAsync: true,  type: "walkme" },
     { name: 'walkmeTest', src: 'https://cdn.walkme.com/users/5b32612733d9430e8009968749f9fa73/test/walkme_5b32612733d9430e8009968749f9fa73_https.js', loadAsync: true, type: "walkme"  },
     { name: 'gtmscript', loadAsync: true, type: "googleTagManager", innerText: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${environment.googleTagManagerId}');` },
     { name: 'gtmnoscript', loadAsync: true, type: "googleTagManager", src: `https://www.googletagmanager.com/ns.html?id=${environment.googleTagManagerId}` },
     { name: 'pendoAnalyticsScript', loadAsync: true, type: "pendoAnalyticsScript", innerText: ScriptConstants.pendoAnalyticsScript}
];

import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NgxSmartModalService, NgxSmartModalComponent, IFileListTypes, IRunData } from '@bssh/comp-lib';
import { IMergeSamplesModalInput, IMergeSamplesModalOutput, IModalInput } from '../model/action-modal';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-merge-samples-modal',
  templateUrl: './merge-samples-modal.component.html',
  styleUrls: ['./merge-samples-modal.component.scss']
})
export class MergeSamplesModalComponent extends BaseModalComponent implements OnInit {
  public modalType = 'mergeSamples';
  public modalTitle = 'Combine samples';
  public alertMessages: string[];
  public sampleNameControl: FormControl = new FormControl('');

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subs.sink = this.data.subscribe((input: IMergeSamplesModalInput) => {
      this.disableConfirmButton = true; // initially action will be disabled, will get enabled only after one of the options is selected
      if(input.errorMessages && input.errorMessages.length > 0) {
        this.error.next(input.errorMessages);
      } else if(input.alertMessages) {
        this.alertMessages = input.alertMessages;
      }
    });

    this.subs.sink = this.sampleNameControl.valueChanges.subscribe((value: string) => {
      this.disableConfirmButton = value && value.length > 0 ? false : true;
      const data: IMergeSamplesModalOutput = {
        newSampleName: value
      };
      this.modal.setData(data, true);
    });
  }

  constructError(error) {
    return error;
  }

}

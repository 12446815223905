export interface IAlertModalText {
    title: string;
    closeButtonText?: string;
    confirmButtonText?: string;
    content: string;
    closable?: boolean;
}

export type AlertModalType = 'LEAVE_RUN_SETUP_PAGE' | 'LEAVE_MICROARRAY_ANALYSIS_SETUP_PAGE'
    | 'GO_BACK_FROM_MICROARRAY_ANALYSIS_CONFIGURATION'
    | 'SAMPLE_DATA_INDEX_COLUMNS_TO_BE_CLEARED'
    | 'CHANGE_SAMPLE_SOURCE' | 'REPLACE_SAMPLE_DATA'
    | 'REPLACE_CONFIG_DATA' | 'DISCARD_CHANGES' | 'CANCEL_IMPORT_REFERENCE_FILE'
    | 'DELETE_REFERENCE_FILE' | 'ADD_DUPLICATE_REFERENCE_FILE' | 'CANCEL_FILE_IMPORT'
    | 'UPDATE_READ_LENGTHS'| 'CONFIRM_CANCEL_ACT' | 'CUSTOM' | 'CONFIRM_DELETE_ACT'
    | 'ICA_SETTING_REMINDER' | 'MISSING_ICA_SUBSCRIPTION_WARNING';

export const alertModalTexts: Readonly<{[key in AlertModalType]: IAlertModalText}> = Object.freeze({
    LEAVE_RUN_SETUP_PAGE: {
        title: 'Are you sure you want to leave?',
        closeButtonText: 'No, continue Run Setup',
        confirmButtonText: 'Yes, cancel Run Setup',
        content: '<p>If you navigate away from this page, your progress will be lost.</p>'
    },

    LEAVE_MICROARRAY_ANALYSIS_SETUP_PAGE: {
        title: 'Are you sure you want to leave?',
        closeButtonText: 'No, continue Analysis Setup',
        confirmButtonText: 'Yes, cancel Analysis Setup',
        content: '<p>If you navigate away from this page, your progress will be lost.</p>'
    },

    REPLACE_SAMPLE_DATA: {
        title: 'Change Sample Data?',
        closeButtonText: 'Cancel',
        confirmButtonText: 'Proceed Anyway',
        content: `
<p>
    You are modifying a previously defined instrument setting. <br/>
    This might replace the current values on your Sample Data table.
</p>`
    },

    SAMPLE_DATA_INDEX_COLUMNS_TO_BE_CLEARED: {
      title: 'Update Override Cycles?',
      closeButtonText: 'Cancel',
      confirmButtonText: 'Proceed Anyway',
      content: `
<p>
    Index1/2 values will be cleared if the new override cycles is applied.<br/>
    Do you want to continue?
</p>`
  },

    CHANGE_SAMPLE_SOURCE: {
        title: 'Change Sample Source',
        closeButtonText: 'CANCEL',
        confirmButtonText: 'CHANGE',
        content: `
<p>
    Are you sure you want to change the source? This will reset the sample selection
    from the current source.
<p>`
    },

    REPLACE_CONFIG_DATA: {
        title: 'Change Configuration Data?',
        closeButtonText: 'Cancel',
        confirmButtonText: 'Proceed Anyway',
        content: `
<p>
    You are modifying a previously defined run setting. <br/>
    This might replace the current values on your config settings.
</p>`
    },

    DISCARD_CHANGES: {
        title: 'Leave And Discard Changes?',
        closeButtonText: 'No, stay in this page',
        confirmButtonText: 'Proceed Anyway',
        content: `
<p>
You are leaving without saving changes. <br/>
This will cancel the changes you made on current page.
</p>`
    },

    GO_BACK_FROM_MICROARRAY_ANALYSIS_CONFIGURATION: {
        title: 'Are you sure you want to go back?',
        closeButtonText: 'No, continue Sample Selection',
        confirmButtonText: 'Yes, go back to Configuration',
        content: `<p>If you go back, your Sample Selection progress will be lost.<p>`
    },

    CANCEL_FILE_IMPORT: {
        title: 'Cancel Upload?',
        closeButtonText: 'CANCEL UPLOAD',
        confirmButtonText: 'FINISH UPLOAD',
        content: `
        <p>
            If you navigate away from this page before the upload is complete, the session
            will be cancelled and no files will be uploaded.
        <p>`
    },

    CUSTOM: {
        // Implement this in the input
    } as IAlertModalText,

    CANCEL_IMPORT_REFERENCE_FILE: {
        title: 'Leave and Cancel Import?',
        closeButtonText: 'No, Stay in this page',
        confirmButtonText: 'Proceed Anyway',
        content: `If you navigate away from this page, your reference file will not be saved.`
    },
    DELETE_REFERENCE_FILE: {
        title: 'Delete reference file?',
        closeButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        content: `<p>This permanently deletes the reference file and it cannot be restored.<p>`
    },
    ADD_DUPLICATE_REFERENCE_FILE: {
        title: 'Replace file?',
        closeButtonText: 'Cancel',
        confirmButtonText: 'Yes, replace',
        content: `<p>There is already a file with the same name. Do you want to replace the original file? <p>`
    },
    UPDATE_READ_LENGTHS: {
        title: 'Update read lengths?',
        closeButtonText: 'Cancel',
        confirmButtonText: 'Proceed anyway',
        content: `
<p>
This will cause the respective Index information on your Sample table to be deleted.
</p>`
    },
    CONFIRM_CANCEL_ACT:{
      title: 'Are you sure you want to leave?',
      closeButtonText:'Cancel',
      confirmButtonText:'Proceed anyway',
      content:` <p>
      If you navigate away from this page, your progress will be lost.
      </p>`
    },
    CONFIRM_DELETE_ACT: {
        title: 'Delete analysis configuration template?',
        closeButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        content: `<p>This permanently deletes the analysis configuration template and it cannot be restored.<p>`
    },
    ICA_SETTING_REMINDER: {
        title: 'Reminder',
        confirmButtonText: 'Continue',
        closable: false,
        content: `<p>The planned run includes application(s) that require data to be available in ICA. Please make sure the workgroup setting to send data to ICA is turned on before sequencing run takes place.<p>`
    },
    MISSING_ICA_SUBSCRIPTION_WARNING: {
        title: 'Missing Subscription',
        confirmButtonText: 'Continue',
        closable: false,
        content: `<p>
            The planned run includes application(s) that require a subscription to Illumina Connected Analytics.
            View subscription options <a class="btn--link" target="_blank" href="https://www.illumina.com/products/by-type/informatics-products/connected-analytics.html">here</a> or contact Illumina Support for more information.
        </p>`
    }
});

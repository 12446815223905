import { Injectable } from '@angular/core';
import { ICurrentUserState } from './current-user.state';
import { BaseObservableStore, IObservableStore } from '@app/core/store/infrastructure/base-observable.store';
import { BasespaceService, V2PostUsersCurrentRequest, V2UserSubscription } from '@bssh/ng-sdk';
import { shareReplay, retryWhen, finalize, delay, filter, map } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { genericRetryWhen } from '@app/core/rxjsutils/rxjs-utilities';
import { IUser } from '@app/core/model/user';
import { HttpClient } from '@angular/common/http';
import { BsUserAgreementsService } from '@app/core/services/bs-api/user-agreements/bs-user-agreements.service';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import { DynamicScriptLoaderService } from '@app/core/utilities/script/dynamic-script-loader.service';
import { Constants } from '@app/core/utilities/constants';
import { isNullOrUndefined } from 'util';
import { ConsoleLogger } from '@app/core/utilities/consolelogger';
import { HttpUtilityService } from '@app/core/services/http-utility/http-utility.service';
import environment from '@environments/environment';
import { ScriptStore } from '@app/core/utilities/script/script.store';
import { get } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import _ from 'lodash';
import { CurrentUserWorkgroupsStore } from '../current-user-workgroups/current-user-workgroups.store';


// Interface for the store.
export interface ICurrentUserStore extends IObservableStore<ICurrentUserState> {
  loadCurrentUser(
    includeSubscriptionInfo: boolean,
    refreshState: boolean,
    forceNotifyContextChanged: boolean,
    checkForLastActingUserIdCookie: boolean): void;
  changeUserContext(id: string): void;
}

@Injectable({
  providedIn: 'root'
})

export class CurrentUserStore extends BaseObservableStore<ICurrentUserState> implements ICurrentUserStore {

  private tcNotSignedMessage = 'Please login to BaseSpace and sign the user agreement(s) before using the API.';
  private userContextChangedSubject = new Subject<boolean>();
  private currentUserId: string;
  /**
   * A short hand way for subscribers to know if the user context has changed.
   * does not emit upon initial user data load(technically not a context switch)
   */
  contextChanged$ = this.userContextChangedSubject.asObservable().pipe(
    filter(userContextChanged => userContextChanged)
  );

  // To do: See what common code in the stores can be moved to base

  constructor(
    private basespaceApi: BasespaceService,
    private httpClient: HttpClient,
    private bsUserAgreementsService: BsUserAgreementsService,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private httpUtilityService: HttpUtilityService,
    private cookieService: CookieService,
    private workgroupsStore: CurrentUserWorkgroupsStore) {
    super(['currentUser', 'currentUserId', 'currentUserCodeFeatures', 'currentUserStateError']);
  }

  /**
   * Calls BSSH API to load user in the user store cache
   *
   * @param includeSubscriptionInfo Whether to make an additional api call to load subscription info
   * @param refreshState Whether to discard the current state and refresh it with the api response
   * @param forceNotifyContextChanged Whether to notify subscribers of a user context change
   * @param checkForLastActingUserIdCookie Whether to check for Last Acting User Id cookie, and switch context if applicable
   */
  loadCurrentUser(
    includeSubscriptionInfo: boolean = true,
    refreshState: boolean = false,
    forceNotifyContextChanged: boolean = false,
    checkForLastActingUserIdCookie: boolean = true): void {

    this.loadingSubject.next(true);
    const currentState = this.getState();
    // Do not load if the state is already populated.
    if (!(currentState ? currentState.currentUser : null) || refreshState) {

      // Get the current user
      const userDetails$ = this.getCurrentUser();

      // Get the current user subscription
      const userSubscription$ = this.getCurrentUserSubscription(includeSubscriptionInfo);

      // Combine from three observables to provide one single observable to which we can subscribe.
      this.subs.sink = combineLatest([userDetails$, userSubscription$]).pipe(
        // Do not make an additional API call if any additional subscribers have subscribed later on
        shareReplay(1),
        // Retry in case of HTTP errors
        retryWhen(genericRetryWhen()),
        // To avoid a Flash of content, maintain a delay
        delay(100),

      ).subscribe({
        next: ([v2user, subscription]) => {
          this.tryRedirectUserPerDomain(v2user.DomainName, v2user.IsPublicDomainUser);

          // If possible, Switch to a workgroup that the user was last active on(using the 'LastActingUserId' cookie.)
          if (checkForLastActingUserIdCookie && this.trySetContextToLastActiveWorkgroup(v2user.Id)) {
            return;
          }


          const user: IUser = v2user;
          // If the subscription was not requested, this will be null
          user.Subscription = subscription;

          this.setState({
            currentUser: user, currentUserId: user.Id, currentUserCodeFeatures: v2user.Features,
            currentUserStateError: null
          },
            CurrentUserStoreActions.LoadUser);

          // No need to notify subscribers upon first load
          // as the respective initial data in most components/stores is loaded independent of the user store.
          if ((!StringUtilities.isBlank(this.currentUserId) && this.currentUserId !== user.Id) || forceNotifyContextChanged) {
            this.userContextChangedSubject.next(true);
          }
          this.currentUserId = user.Id;

          this.addPendoAnalytics();
          this.loadWalkmeScripts();
          // note: google tag mgr is now added via dependency on 3rd party lib

          this.loadingSubject.next(false);
        },
        error: error => {
          if (error.status === 409 && error.error.ResponseStatus.Message === this.tcNotSignedMessage) {
            // This is the case where the User has not yet signed Main website T & C Agreement.
            // We will need to redirect to User Agreement page for them to sign, and come back.
            this.bsUserAgreementsService.redirectToTCAgreementsPage();
          }
          this.handleError(error, () => ({ ...currentState, currentUserStateError: error }));
          this.loadingSubject.next(false);
        }
      });
    } else {
      // If a subsequent call is made, just dispatch the current state
      // This will lead to a 'stateChanged' obs emit
      this.dispatchCurrentState(currentState);
    }
  }

  private addPendoAnalytics() {
    const currentState = this.getState();

    if (currentState != null && currentState.currentUserCodeFeatures != null) {
      if (this.isPendoAnalyticsEnabled(currentState.currentUserCodeFeatures)) {

        var actingUser = currentState.currentUser;
        const visitorId = (actingUser && actingUser.LoggedInUser != null && actingUser.LoggedInUser.Id != null) ? actingUser.LoggedInUser.Id : '';
        const visitorActingId = (actingUser != null && actingUser.Id != null) ? actingUser.Id : '';

        let pendoAnalyticsScript = ScriptStore.find(s => s.name == 'pendoAnalyticsScript');
        pendoAnalyticsScript.innerText = pendoAnalyticsScript.innerText.replace('@visitorId', visitorId);
        pendoAnalyticsScript.innerText = pendoAnalyticsScript.innerText.replace('@visitorActingId', visitorActingId);

        this.dynamicScriptLoader.scripts[pendoAnalyticsScript.name].innerText = pendoAnalyticsScript.innerText;

        this.dynamicScriptLoader.load('pendoAnalyticsScript').
          catch(error => ConsoleLogger.logError(error));
      }
    }
  }

  private loadWalkmeScripts() {
    const currentState = this.getState();
    const currentUserCodeFeatures: string[] = currentState.currentUserCodeFeatures;
    /*
    * We are not removing the loaded script based on user context switch
    * and WalkMeEnabled CodeFeature after loging
    */
    if (this.isWalkMeEnabled(currentUserCodeFeatures)) {
      const walkmeScriptName = this.isWalkMeTestMode(currentUserCodeFeatures) ?
        Constants.WalkMeScriptNames[1] : Constants.WalkMeScriptNames[0];
      this.dynamicScriptLoader.load(walkmeScriptName).then(data => { }).
        catch(error => ConsoleLogger.logError(error));
    }
  }

  private googleTagManagerIsEnabled(currentUserCodeFeatures: string[]): boolean {
    if (isNullOrUndefined(currentUserCodeFeatures)) {
      return false;
    }
    return currentUserCodeFeatures.includes('GoogleTagManager');
  }

  private isWalkMeEnabled(currentUserCodeFeatures: string[]): boolean {
    if (isNullOrUndefined(currentUserCodeFeatures)) {
      return false;
    }
    return currentUserCodeFeatures.includes('WalkMeEnabled');
  }

  private isWalkMeTestMode(currentUserCodeFeatures: string[]): boolean {
    if (isNullOrUndefined(currentUserCodeFeatures)) {
      return false;
    }
    return currentUserCodeFeatures.includes('WalkMeTestMode');
  }

  private isPendoAnalyticsEnabled(currentUserCodeFeatures: string[]): boolean {
    if (isNullOrUndefined(currentUserCodeFeatures)) {
      return false;
    }
    return currentUserCodeFeatures.includes('PendoAnalytics');
  }

  /**
   * Changes the current user context with the passed in user id, and reloads the state
   * @param id The User Id
   */
  changeUserContext(id: string): void {

    const currentState = this.getState();
    // Do not switch context if the passed in user is same as the current state
    if (get(currentState, 'currentUser.Id') !== id) {
      this.setCurrentUserContext(id, currentState);
    } else {
      this.dispatchCurrentState(currentState);
    }
  }

  /**
   * Switches the current user context to the supplied user(identified by id)
   * @param id id of the user to switch to
   * @param currentState The current state.
   * @param forceNotifyContextChanged Whether to notify subscribers of a user context change
   */
  private setCurrentUserContext(id: string, currentState: ICurrentUserState = this.getState(), forceNotifyContextChanged: boolean = false) {
    this.loadingSubject.next(true);
    this.subs.sink = this.basespaceApi.PostV2UsersCurrent({ Id: id } as V2PostUsersCurrentRequest).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(100),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe({
      next: (apiResponse) => {
        this.tryRedirectUserPerDomain(apiResponse.DomainName, apiResponse.IsPublicDomainUser);
        this.loadCurrentUser(true, true, forceNotifyContextChanged, false);
      },
      error: error => {
        this.handleError(error, () => ({ ...currentState, currentUserStateError: error }));
      }
    });
  }

  /**
   * Gets the currently logged in user details from api
   */
  private getCurrentUser() {
    return this.basespaceApi.GetV2UsersCurrent(this.getUserCurrentRequest());
  }
  /**
   * Gets the subscription for currently logged in user from api
   */
  private getCurrentUserSubscription(includeSubscriptionInfo: boolean): Observable<V2UserSubscription> {
    return includeSubscriptionInfo ? this.basespaceApi.GetV2UsersCurrentSubscription(this.getCurrentUserSubscriptionRequest()) : of(null);
  }

  /**
   * Builds the request to call BSSH Current user api.
   */
  private getUserCurrentRequest() {
    return {
      include: ['roles'],
      options: null
    };
  }

  /**
   * Gets the request object for getting the current user subscription from bssh api
   */
  private getCurrentUserSubscriptionRequest() {
    return {
      refreshcache: false,
    } as BasespaceService.GetV2UsersCurrentSubscriptionParams;
  }

  private tryRedirectUserPerDomain(domainName: string, isPublicDomainUser: boolean) {

    // Usually siteurl is pointed to the domain on which the site is deployed.
    // currently the local environment is configured differently i.e. the 'siteUrl' setting points to one of the test environments
    // This is due to the presence of old site pages
    if (this.httpUtilityService.isLocalEnvironment()) {
      return;
    }

    // redirect if user domain is not the same as current user domain
    const domainFromUrl = this.httpUtilityService.getDomainFromCurrentRequestUrl();
    const publicDomain = this.httpUtilityService.getDomainFromUrl(environment.siteUrl);
    // If user is public, but the url is enterprise, redirect to public
    const pathAndQuery = this.httpUtilityService.getPathAndQueryFromCurrentRequestUrl();
    if (isPublicDomainUser && domainFromUrl !== publicDomain) {
      const redirectUrl = `${environment.siteUrl}${environment.siteUrl.endsWith('/') ? '' : '/'}${pathAndQuery}`;
      this.httpUtilityService.redirectToUrl(redirectUrl);
    } else if (!isPublicDomainUser && domainFromUrl !== domainName) {
      // Token was issued to enterprise domain, but trying to access on public domain.
      // redirect to enterprise.
      this.httpUtilityService.redirectToDomain(domainName, pathAndQuery);
    }
  }

  /**
   * Checks if last active user workgroup for the user can be derived from cookie, and switches context to the workgroup
   * @param currentActingUserId The currently active user id
   */
  private trySetContextToLastActiveWorkgroup(currentActingUserId: string): boolean {
    const lastActingUserIdCookieValue = this.cookieService.get(Constants.CookieKeys.LastActingUserIdKey);
    const isLastActingUserIdCookieValid = !_.isEmpty(lastActingUserIdCookieValue) && lastActingUserIdCookieValue !== currentActingUserId;
    if (isLastActingUserIdCookieValid) {
      this.subs.sink = this.workgroupsStore.stateChanged.
        pipe(filter(state => state != null && state.currentUserWorkgroups != null &&
          !_.isEmpty(this.cookieService.get(Constants.CookieKeys.LastActingUserIdKey))),
          map(state => state.currentUserWorkgroups.Items)).subscribe({
            next: workGroups => {
              if (workGroups.some(wg => wg.Id === lastActingUserIdCookieValue)) {
                // We only switch if the cookie value was valid, and the user is a member of the workgroup in the cookie.
                this.setCurrentUserContext(lastActingUserIdCookieValue, this.getState(), true);
              } else {
                // If not, remove the cookie so that subsequent requests do not have to check again.
                this.cookieService.delete(Constants.CookieKeys.LastActingUserIdKey, '/', environment.psTokenDomain);
                // let the user load as usual.
                this.loadCurrentUser(true, true, false, false);
              }
            }
          });
    }
    return isLastActingUserIdCookieValid;
  }

}

// The actions enum for the Current User Store
export enum CurrentUserStoreActions {
  LoadUser = 'LOAD_USER'
}

import { Injectable } from '@angular/core';
import { IUser } from '../../model/user';
import { HttpClient } from '@angular/common/http';
import { ICollaborator, ICollaboratorListResponse, IShareUrlResponse } from '../../model/collaborator';
import { delay, retryWhen } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { genericRetryWhen, observableEmitDelay } from '../../rxjsutils/rxjs-utilities';
import { ResourceType } from '../../model/resource-type';
import { IGenericResponse } from '../../bsshapi/api-wrappers';
import { UserRestrictionService } from '../user/user-restriction.service';
import { BsApiEndPoints } from '../bs-api/endpoints';
import { LegacyBsApiEndpointsService } from '../legacy-bs-api/legacy-bs-api-endpoints.service';

export interface IShareService {
  getCollaborators(resourceType: ResourceType, resourceId: string): Observable<ICollaboratorListResponse>;
  sendInvitations(resourceType: ResourceType, resourceId: string, collaborators: ICollaborator[]): Observable<IGenericResponse>;
  canShare(owner: IUser, userId: string): boolean;
  getLink(resourceType: ResourceType, resourceId: string): Observable<IShareUrlResponse>;
  activateLink(resourceType: ResourceType, resourceId: string): Observable<IShareUrlResponse>;
  deactivateLink(resourceType: ResourceType, resourceId: string): Observable<IShareUrlResponse>;
}

@Injectable({
  providedIn: 'root'
})
export class ShareService implements IShareService {
  // tslint:disable-next-line: max-line-length
  constructor(private httpClient: HttpClient, private userRestrictionService: UserRestrictionService, private legacyBsApiEndpointsService: LegacyBsApiEndpointsService) {
  }

  /**
   * Fetches a list of collaborators for the resource.
   * @param resourceType The type of the resource for which the collaborators needs to be fetched.
   * @param resourceId The id of the resource for which the collaborators need to be fetched.
   */
  public getCollaborators(resourceType: ResourceType, resourceId: string): Observable<ICollaboratorListResponse> {
    const requestUrl = this.legacyBsApiEndpointsService.getInvitationsUrl(resourceType, resourceId);
    return this.httpClient.get<ICollaboratorListResponse>(requestUrl).pipe(
            // Retry in case of HTTP errors
            retryWhen(genericRetryWhen()),
            // To avoid a Flash of content, maintain a delay
            delay(observableEmitDelay),
    );
  }

  /**
   * Sends the invitations to the collaborators received.
   * @param resourceType The type of the resource.
   * @param resourceId The id of the resource.
   * @param collaborators The list of collaboartors to whom the invitatiosn are to be sent.
   */
  public sendInvitations(resourceType: ResourceType, resourceId: string, collaborators: ICollaborator[]): Observable<IGenericResponse> {
    const requestUrl = this.legacyBsApiEndpointsService.getInvitationsUrl(resourceType, resourceId);

    return this.httpClient.post<IGenericResponse>(requestUrl, collaborators).pipe(
          // Retry in case of HTTP errors
          retryWhen(genericRetryWhen()),
          // To avoid a Flash of content, maintain a delay
          delay(observableEmitDelay),
    );
  }

  /**
   * Checks if the share action can be performed or not.
   * @param owner The owner of the resource.
   * @param userId The id of the logged in user.
   * @returns true if the logged in user is the owner of the resource and is not restricted.
   */
  public canShare(owner: IUser, userId: string): boolean {
      return !!(owner && userId && owner.Id === userId && !this.userRestrictionService.userHasStorageRestrictions());
  }

  /**
   * Sends an api call which gets the the shareUrl for the resource.
   * @param resourceType The type of the resource.
   * @param resourceId The id of the resource.
   */
  public getLink(resourceType: ResourceType, resourceId: string): Observable<IShareUrlResponse> {
    const requestUrl = this.legacyBsApiEndpointsService.getShareByLinkUrl(resourceType, resourceId);
    return this.httpClient.get<IShareUrlResponse>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  /**
   * Activates the link for the resource and sends back the shareUrl.
   * @param resourceType The type of the resource.
   * @param resourceId The id of the resource.
   */
  public activateLink(resourceType: ResourceType, resourceId: string): Observable<IShareUrlResponse> {
    const requestUrl = this.legacyBsApiEndpointsService.getShareByLinkUrl(resourceType, resourceId);
    return this.httpClient.post<IShareUrlResponse>(requestUrl, {}).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  /**
   * Deactivates the share link for the resource.
   * @param resourceType The type of the resource.
   * @param resourceId The id of the resource.
   */
  public deactivateLink(resourceType: ResourceType, resourceId: string): Observable<IShareUrlResponse> {
    const requestUrl = this.legacyBsApiEndpointsService.getShareByLinkUrl(resourceType, resourceId);
    return this.httpClient.delete<IShareUrlResponse>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

}

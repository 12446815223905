import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { SearchQueryService } from '@app/core/services/search/resource-search/search-query.service';
import { FileSearchColumnFields } from '../gridOptions/file-search-grid-options-formatter';
import { ISearchDataSourceOptions, SearchDataSource } from './search.datasource';

export class FileSearchDataSource extends SearchDataSource {
    constructor(
        searchQueryService: SearchQueryService,
        resourceDictionaryService: SearchResourceDictionaryService,
        dataSourceOptions: ISearchDataSourceOptions
    ) {
        const appResourceDictionary = resourceDictionaryService.getResourceConfigByType(SearchResourceType.File);
        super(searchQueryService, appResourceDictionary, dataSourceOptions);
    }

    get rawTextFilterColumnName(): string {
        return FileSearchColumnFields.Name;
    }
}

import { Injectable } from '@angular/core';
import { IApplicationMetadata } from '@app/core/model/meta/application-metadata';
import { IApiResponseWrapper } from '@app/core/model/v2-api/v2-api-wrappers';
import { BasespaceService } from '@bssh/ng-sdk';
import { BaseObservableStore } from '../infrastructure/base-observable.store';
import { IResourceStore } from '../resource/resource.store';
import { IMetadataState } from './metadata.state';

export interface IMetadataStore {
    loadApplicationMetadata(forceLoad: boolean): void;
}

@Injectable({
    providedIn: 'root'
})

export class MetadataStore extends BaseObservableStore<IMetadataState> implements IMetadataStore {

    constructor(
        private basespaceApi: BasespaceService) {
        super(['applicationMetadata', 'metadataStateError']);
    }

    /**
     * Loads applicaiton metadata into the store
     * @param forceLoad Whether to discard current application metadata in the store and load from api
     */
    loadApplicationMetadata(forceLoad: boolean = false): void {
        const currentState = this.getState();
        if (currentState.applicationMetadata && !forceLoad) {
            this.dispatchCurrentState(currentState);
            return;
        }
        // Load data from API
        const fetchData$ = this.basespaceApi.GetV2Applicationmetadata();

        // Next callback
        const nextCallback = (apiResponse: IApiResponseWrapper<IApplicationMetadata>) => {
            this.setState({ applicationMetadata: apiResponse.Response, metadataStateError: null }, MetadataStoreActions.LoadAppMetadata);
        };
        // Error callback
        const errorCallBack = error => this.handleError(error, () => ({ ...this.getState(), metadataStateError: error }));
        // load slice in the state.
        // For now passing `loadingSubject`. When there are multiple metadata lists, need to split the loading observables
        this.loadStateSlice(this.loadingSubject, fetchData$, nextCallback, errorCallBack);
    }
}

/**
 * Load actions enum for MetadataStore
 */
export enum MetadataStoreActions {
    LoadAppMetadata = 'LOAD_APP_METADATA'
}

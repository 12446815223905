import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ILabWorkflow } from '@app/core/model/labWorkflow';
import { BasePairUtils } from '@app/core/utilities/base-pair.utilities';
import { isNullOrUndefined } from 'util';
import { ModalTexts } from '../../modal-texts';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormFieldsComponent } from '@bssh/comp-lib';
import { Constants } from '@app/core/utilities/constants';

@Component({
  selector: 'app-lab-requeue',
  templateUrl: './lab-requeue.component.html',
  styleUrls: ['./lab-requeue.component.scss']
})
export class LabRequeueComponent implements OnInit, AfterViewInit {
  private MAX_RAW_BP = Constants.Yields.MaxYieldRawBp;

  @Input() public informationListToDisplay = [];
  @Input() public selectedLabWorkflow: ILabWorkflow = null;
  @Input() public errorMsg: string | string[] = null;
  @Input() public labWorkflows: ILabWorkflow[] = null;

  libPrepKit = [];
  selectedLabWorkflowPrepRequestId: string = null;
  alertMsg: string = null;
  abbreviation = null;
  requestedAdditionalYield: number = null;
  currentBasePairUnit = null;
  isValidateOnInputChange = true;
  yieldInputMax: number = this.MAX_RAW_BP;
  yieldInputMin = 1;

  public formGroup: FormGroup;
  @Output() confirm: EventEmitter<ILabRequeueComponentOutput> = new EventEmitter();

  @ViewChild('form', { static: false })
  public form: FormFieldsComponent;

  constructor(private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.group({
      requestedYield: [0, [
        Validators.min(this.yieldInputMin),
        Validators.max(this.yieldInputMax),
        Validators.required]]
    });

  }

  ngOnInit() {
    this.selectedLabWorkflowPrepRequestId = isNullOrUndefined(this.selectedLabWorkflow) ?
      null : this.selectedLabWorkflow.prepRequest.Id;
    this.initProperties();
  }

  ngAfterViewInit() {
    this.triggerInputRequestedAdditionalYieldChange();
    this.sendConfirmationToParentComponent()
  }

  private getOutput(): ILabRequeueComponentOutput {
    const divisor = !isNullOrUndefined(this.currentBasePairUnit) ? this.currentBasePairUnit.divisor : 0;
    return ({
      RequestedAdditionalYield: Math.round(this.requestedAdditionalYield * divisor),
      PrepRequestId: this.selectedLabWorkflowPrepRequestId,
      errorMsg: this.errorMsg,
      isInputValid: !this.formGroup.invalid,
      yieldInputMax: this.yieldInputMax,
      yieldInputMin: this.yieldInputMin
    });
  }

  private initProperties() {
    if (isNullOrUndefined(this.errorMsg)) {
      this.updateRequestedAdditionalYieldToMatchLabWorkflow();

      if (!isNullOrUndefined(this.labWorkflows)) {
        this.labWorkflows.forEach(labWorkflow => {
          this.libPrepKit.push({ value: labWorkflow.prepRequest.Id, name: labWorkflow.prepRequest.LibraryPrep.Name });
        });
      }
    }
  }

  onPrepKitChange(e) {
    this.selectedLabWorkflowPrepRequestId = e.option.value;

    this.updateRequestedAdditionalYieldToMatchLabWorkflow();
    this.errorMsg = null;

    if (!isNullOrUndefined(this.requestedAdditionalYield)) {
      this.alertMsg = ModalTexts.REQUEUE_BIOSAMPLE.UPDATE_YIELD_MESSAGE;
    }

    this.triggerInputRequestedAdditionalYieldChange();
    this.sendConfirmationToParentComponent();
  }

  onYieldChange(e) {
    this.requestedAdditionalYield = e.target.value;
    this.alertMsg = null;
    this.errorMsg = null;

    this.sendConfirmationToParentComponent();
  }

  public getErrorMessage() {
    const requestedYield = this.formGroup.controls.requestedYield;

    if (requestedYield.errors && requestedYield.errors.max) {
      return "A yield cannot exceed 999.99 Gbp. Check your entry and try again.";
    }
    else if (requestedYield.errors && (requestedYield.errors.min || requestedYield.errors.required)) {
      return "A yield must be at least 1 bp. Check your entry and try again.";
    }
    return "";
  }

  /**
   * Updates requested additional yield to match the missing yield of the newly selected workflow
   */
  private updateRequestedAdditionalYieldToMatchLabWorkflow() {

    const missingYield = isNullOrUndefined(this.selectedLabWorkflow) ? 0 : this.selectedLabWorkflow.missingYield;
    const bpu = BasePairUtils.getBasePairUnits(missingYield);
    this.currentBasePairUnit = bpu.basePairUnit;
    if (parseInt(bpu.result) <= 0) {
      this.requestedAdditionalYield = null;
    } else if (bpu.basePairUnit !== BasePairUtils.BASE) {
      // add 0.01 to the fractional value to ensure the additional requested yield exceeds missing yield
      this.requestedAdditionalYield = BasePairUtils.toFixed(parseFloat(bpu.result) + 0.01, 2);
    } else {
      this.requestedAdditionalYield = parseInt(bpu.result);
    }

    this.abbreviation = this.currentBasePairUnit.abbreviation;

    // update yield input max and min to match the current units
    this.yieldInputMax = Constants.Yields.MaxYieldRawBp / bpu.basePairUnit.divisor;
    this.yieldInputMin = Constants.Yields.MinYieldRawBp / bpu.basePairUnit.divisor;

    // reset the form control to match new units.
    // tslint:disable: no-string-literal
    this.formGroup.controls['requestedYield'].setValidators(
      [
        Validators.min(this.yieldInputMin),
        Validators.max(this.yieldInputMax),
        Validators.required
      ]);
    this.formGroup.controls['requestedYield'].updateValueAndValidity();

  }

  private triggerInputRequestedAdditionalYieldChange() {
    setTimeout(() => {
      if (this.form && this.form.InputComponent) {
        const event = new Event('change');

        Object.defineProperty(event, 'target',
          { writable: false, value: this.form.InputComponent.elementRef.nativeElement });

        this.form.InputComponent.elementRef.nativeElement.dispatchEvent(event);
      }
    }, 500);
  }

  private sendConfirmationToParentComponent() {
    // to make validation call to form fields component
    setTimeout(() => {
      this.confirm.emit(this.getOutput());
    }, 500);
  }

}

export interface ILabRequeueComponentOutput {
  RequestedAdditionalYield: number;
  PrepRequestId: string;
  errorMsg: string | string[];
  isInputValid: boolean;
  yieldInputMax: number;
  yieldInputMin: number;
}

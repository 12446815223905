<div #TrashModalDiv>
    <ngx-smart-modal #TrashModal
                     [identifier]="modalType"
                     [title]="headers.modalHeader"
                     closable="true"
                     showCloseButton="true"
                     closeButtonText="Close"
                     [disableConfirmButton]="disableConfirmButton"
                     [confirmButtonText]="confirmButtonText">

        <section #apiDataWrapperLoader
                 class="wrapper"
                 [ngClass]="{ active: (isLoadingApiData$ | async) }"
                 *ngIf="(isLoadingApiData$ | async)"
                 data-cy="progress-overlay-wrapper">
        </section>

        <lib-progress #apiDataSpinnerLoader
                      [id]="apiDataSpinnerLoader"
                      class="'spinner'"
                      [loader]="false"
                      [fixed]="true"
                      [spinner]="true"
                      [spinnerPosition]="'center'"
                      [progressBarContainer]="'modal'"
                      (started)="onStarted()"
                      (completed)="onCompleted()"
                      data-cy="trash-modal-progress-overlay-spinner">
        </lib-progress>

        <lib-download-data [runName]='headers.resourceTitleHeader'
                           [runSize]='headers.resourcePropertyHeader'
                           [runData]='itemsToTrash'
                           [runError]="errorMsg"
                           [runAlert]="trashAlertMessage"
                           [fileListHeader]="headers.deleteOptionHeader"
                           [fileListTypes]="deleteOptionTypes"
                           (outputDataChange)="dataChanged($event)">
        </lib-download-data>

        <div class="msgs msgs--info"
             *ngIf="(infoMessage===true)&&(errorMsg===null)">
            <div class="alert_message__box">
                <span class="alert_message__box-text"
                      [innerHTML]="msg"></span>
            </div>
        </div>

    </ngx-smart-modal>
</div>
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ObservableStore } from '@codewithdan/observable-store';
import { ReduxDevToolsExtension } from '@codewithdan/observable-store-extensions';
import { LicenseManager } from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import environment from './environments/environment';

// ag-grid
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-066240}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Illumina_(USA,CA,San_Diego,92123)}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{BaseSpace_Sequence_Hub}_only_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{BaseSpace_Sequence_Hub}_need_to_be_licensed___{BaseSpace_Sequence_Hub}_has_been_granted_a_Deployment_License_Add-on_for_{14}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_September_2025}____[v3]_[01]_MTc1NzcxODAwMDAwMA==8f4e3422131262ecb076605b3000ff56");

if (environment.production) {
  enableProdMode();
}
// enable state history tracking for local only.
ObservableStore.globalSettings = {
  isProduction: environment.production,
  trackStateHistory: !environment.production,
  logStateChanges: false
};

if (!ObservableStore.globalSettings.isProduction) {
  ObservableStore.addExtension(new ReduxDevToolsExtension());
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { Injectable } from '@angular/core';
import { BaseObservableStore, IObservableStore } from '@app/core/store/infrastructure/base-observable.store';
import { ICurrentUserGlobalAppsState } from './current-user-global-apps.state';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import environment from '@environments/environment';
import { IDomainApps, IUserGlobalApp } from '@app/core/model/userproduct';
import { map, shareReplay, retryWhen, finalize, delay } from 'rxjs/operators';
import * as _ from 'lodash';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { isNullOrUndefined } from 'util';
import { AuthorizationService } from '@app/core/services/authorization/authorization.service';


// Interface for the store.
export interface ICurrentUserProductStore extends IObservableStore<ICurrentUserGlobalAppsState> {
  loadCurrentUserGlobalApps(refreshState: boolean, isPublicDomain?: boolean): void;
}


@Injectable({
  providedIn: 'root'
})
export class CurrentUserGlobalAppsStore extends BaseObservableStore<ICurrentUserGlobalAppsState> implements ICurrentUserProductStore {

  private platformHost: string;
  private serviceUrl: string;
  private platformDomain: string;
  // To do: See what common code in the stores can be moved to base

  constructor(private httpClient: HttpClient, private authorizationService: AuthorizationService) {
    // Call Super Constructor with the properties which we want to save in store.
    super(['userGlobalApps', 'userGlobalAppsError']);
    this.platformHost = environment.platformUrl;
  }
  /**
   * Loads the global ilmn apps that the currently logged in user has access to into the store.
   * @param refreshState Indicates whether to discard state and load it afresh from the api
   * @param isPublicDomain Whether the user is in public domain
   */
  loadCurrentUserGlobalApps(refreshState: boolean = false, isPublicDomain: boolean = true): void {
    this.loadingSubject.next(true);
    const currentState = this.getState();

    // If the current logged in user is in public domain, take the configured public domain name
    // Else get the domain name by decoding the pstoken.
    const platformDomainName = isNullOrUndefined(isPublicDomain) || isPublicDomain ?  environment.platformDomain
     : this.authorizationService.getDomainNameForCurrentLoggedInUser();
    this.serviceUrl = this.platformHost.replace('https://', 'https://' + platformDomainName + '.');

    // Do not load if the state is already populated.
    if (!(currentState ? currentState.userGlobalApps : null) || refreshState) {
      const requestPath = 'do/globalAdmin/domainApp/search/';
      const requestUrl = `${this.serviceUrl}/${requestPath}`;

      this.subs.sink = this.httpClient.get<IDomainApps>(requestUrl, this.getDomainAppsRequestOptions(platformDomainName)).pipe(

        // app filtering logic
        map(appSearchResults => {

          if (appSearchResults != null && appSearchResults.list != null) {

            // Filter out disabled apps.
            const enabledApps = appSearchResults.list.filter(app => {
              // Filter out apps that aren't provisioned
              const isProvisionable = app.globalAppTO.allowed_to_provision === 1;
              // Filter out apps that don't have basespace or seqhub (China's SeqHub ID is "cnn1-seqhub") in their client ID. This
              // removes any links to Sequence Hub from the app switcher, including instances in other regions.
              const clientId = app.globalAppTO.client_id;
              const looksLikeSequenceHub = _.isString(clientId) &&
                (clientId.indexOf('basespace') > -1 || clientId.indexOf('seqhub') > -1);
              // Filter out apps for which we can't generate a decent hyperlink
              const href = this.getAppHref(appSearchResults.isPublicDomain, app.globalAppTO.publicUrl, app.globalAppTO.r_url,
                app.globalAppTO.url_pattern, this.platformDomain);
              return isProvisionable && !looksLikeSequenceHub && _.isString(href) && href.length > 0;
            });

            // Map to IUserProduct
            return enabledApps.map(app => {
              const userProduct: IUserGlobalApp = {
                id: app.globalAppTO.appid,
                class: app.globalAppTO.appColor,
                href: this.getAppHref(appSearchResults.isPublicDomain, app.globalAppTO.publicUrl, app.globalAppTO.r_url,
                  app.globalAppTO.url_pattern, this.platformDomain),
                title: app.globalAppTO.name,
                isActive: () => false
              };
              return userProduct;
            });
          }
          // Return null when platform returns no apps for the user
          return null;
        }),
        // Do not make an additional API call if any additional subscribers have subscribed later on
        shareReplay(1),
        // Retry in case of HTTP errors
        retryWhen(genericRetryWhen()),
        // To avoid a Flash of content, maintain a delay
        delay(observableEmitDelay)
      ).subscribe({
        next: (apps) => {
          this.setState({ userGlobalApps: apps, userGlobalAppsError: null }, CurrentUserProductStoreActions.LoadUserProducts);
          this.loadingSubject.next(false);
        },
        error: error => {
          this.handleError(error, () => ({ ...this.getState(), userProductsError: error }));
          this.loadingSubject.next(false);
        }
      });
    } else {
      // If a subsequent call is made, just dispatch the current state
      // This will lead to a 'stateChanged' obs emit
      this.dispatchCurrentState(currentState);
    }
  }

  /**
   * Builds Request for calling Platform Domain app search api
   */
  // tslint:disable-next-line: ban-types
  private getDomainAppsRequestOptions(domainName: string): Object {
    const headerArray = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const requestParams = { domain: domainName };
    const reqParams = new HttpParams({ fromObject: requestParams });

    return {
      headers: headerArray,
      withCredentials: true,
      params: reqParams
    };
  }


  // We have to build the href for enterprise customers, platform supplies the parts but does not do the work
  // Example - isPublic:false, publicUrl:"https://variantinterpreter.vi.testing-domain.illumina.com/home",
  // returnUrl:"https://customer.vi.testing-domain.illumina.com/home", urlPattern:"customer", domain:"enterprise1"
  // Resulting href should be "https://enterprise1.vi.testing-domain.illumina.com/home"
  private getAppHref(isPublic: boolean, publicUrl: string, returnUrl: string, urlPattern: string, domain: string): string {
    if (isPublic) {
      return publicUrl;
    }
    return returnUrl.replace(urlPattern, domain);
  }

}


// The actions enum for the Current User Store
export enum CurrentUserProductStoreActions {
  LoadUserProducts = 'LOAD_USER_PRODUCTS'
}

import { Injectable } from '@angular/core';
import { SearchQueryService } from '@app/core/services/search/resource-search/search-query.service';
import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { ProjectSearchDataSource } from './project-search.datasource';
import { ISearchDataSourceOptions, ISearchTableDataSource } from './search.datasource';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { BiosampleSearchDataSource } from './biosample-search.datasource';
import { SampleSearchDataSource } from './sample-search.datasource';
import { RunSearchDataSource } from './run-search.datasource';
import { AppSearchDataSource } from './app-search.datasource';
import { FileSearchDataSource } from './file-search.datasource';

export interface ISearchGridOptionsFactoryService {
  getSearchDatasourceForResource(
    resourceType: SearchResourceType,
    datasourceOptions?: ISearchDataSourceOptions
  ): ISearchTableDataSource;
}

@Injectable({
  providedIn: 'root'
})
export class SearchDatasourceFactoryService implements ISearchGridOptionsFactoryService {

  constructor(
    private searchQueryService: SearchQueryService,
    private resourceDictionaryService: SearchResourceDictionaryService
  ) { }

  /**
   * Gets the search data source for the specific resource type
   * @param resourceType The Search resource type
   * @param datasourceOptions Data source options
   */
  getSearchDatasourceForResource(
    resourceType: SearchResourceType,
    datasourceOptions?: ISearchDataSourceOptions
    ): ISearchTableDataSource {

    switch (resourceType) {
      case SearchResourceType.Project:
        return new ProjectSearchDataSource(this.searchQueryService, this.resourceDictionaryService, datasourceOptions);
      case SearchResourceType.Run:
        return new RunSearchDataSource(this.searchQueryService, this.resourceDictionaryService, datasourceOptions);
      case SearchResourceType.App:
        return new AppSearchDataSource(this.searchQueryService, this.resourceDictionaryService, datasourceOptions);
      case SearchResourceType.Biosample:
        return new BiosampleSearchDataSource(this.searchQueryService, this.resourceDictionaryService, datasourceOptions);
      case SearchResourceType.Sample:
        return new SampleSearchDataSource(this.searchQueryService, this.resourceDictionaryService, datasourceOptions);
      case SearchResourceType.File:
        return new FileSearchDataSource(this.searchQueryService, this.resourceDictionaryService, datasourceOptions);
      default:
        throw new Error(`Datasource not supported for resource ${resourceType}`);
    }
  }
}

<ngx-smart-modal
  #mergeSamples
  identifier="mergeSamples"
  [closable]="true"
  closeButtonText="cancel"
  confirmButtonText="combine"
  [disableConfirmButton]="disableConfirmButton"
  [title]="modalTitle"
>
  <lib-error-alert-message
    [alertMsg]="alertMessages"
    [errorMsg]="errorMsg"
  ></lib-error-alert-message>

  <div class="merge-samples__message">Are you sure you want to combine these samples?</div>

  <lib-form-field 
    label="New Sample Name">
    <input lib-input
            type="text"
            [formControl]="sampleNameControl"/>
  </lib-form-field>

</ngx-smart-modal>
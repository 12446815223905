import { Component } from '@angular/core';
import { NgxSmartModalService, StatusChangeResource } from '@bssh/comp-lib';
import { AnalysisQCStatus, NON_SUCCESS_ANALYSIS_QC_STATUSES, ALL_POSSIBLE_ANALYSIS_QC_STATUSES } from '../../../../core/model/appSessions/analysis-status';
import { IAppSession } from '../../../../core/model/appSessions/appSession';
import { ModalTexts } from '../../modal-texts';
import { StatusToDisplayName } from '../../../../core/utilities/pipes/status-to-display-name.pipe';
import { ChangeStatusModalComponent } from '../change-status-modal.component';

@Component({
  selector: 'app-change-analyses-qc-status-modal',
  templateUrl: './change-analyses-qc-status-modal.html',
})
export class ChangeAnalysesQCStatusModalComponent extends ChangeStatusModalComponent<IAppSession, AnalysisQCStatus> {

  statusTitle = 'QC Status';
  analysisIdentifierTitle = 'ANALYSES';
  modalType = 'InitiateChangeAnalysesQCStatusModal';
  ngxSmartModalTitle: string = ModalTexts.CHANGE_STATUS.MODAL_TITLE.QC.ANALYSIS;
  allPossibleStatuses = ALL_POSSIBLE_ANALYSIS_QC_STATUSES;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public statusToDisplayName: StatusToDisplayName) {
    super(ngxSmartModalService, statusToDisplayName);
    this.setReverMapping();
  }


  protected getStatusChangeResources(resources: IAppSession[]): StatusChangeResource[] {
    const analysesForChange: StatusChangeResource[] = [];

    for (const analysis of resources) {
      const qcStatus = analysis.QcStatus;

      const statusChangeResource: StatusChangeResource = {
        resourceName: analysis.Name,
        currentStatus: this.statusToDisplayName.transform(qcStatus),
      };

      if (NON_SUCCESS_ANALYSIS_QC_STATUSES.includes(AnalysisQCStatus[qcStatus])) {
        statusChangeResource.statusClass = 'status-change__result__value--bold';
      }

      analysesForChange.push(statusChangeResource);
    }

    return analysesForChange;
  }

  private setReverMapping() {
    Object.keys(AnalysisQCStatus).forEach((status: AnalysisQCStatus) => {
        const value: string = AnalysisQCStatus[status];
        this.reverseMap.set(value, status);
    });
  }

}

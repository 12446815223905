<ngx-smart-modal #RequeueRunErrors class="run-errors-modal" identifier="RequeueRunErrors" [closable]="true"
  [escapable]="false" [dismissible]="true" [showCloseButton]="showCloseButton" closeButtonText="Cancel" [title]='title'
  [showConfirmButton]="showConfirmButton" confirmButtonText="Proceed" [disableConfirmButton]="disableConfirmButton">

  <h4 class="mb-3" style="line-height:2.5rem;" [innerHTML]="subTitle"></h4>

  <overlay-scrollbars class="run-errors-modal_content">
    <lib-error-alert-message [alertMsg]="warnings" [errorMsg]="errors">
    </lib-error-alert-message>
  </overlay-scrollbars>

  <div class="run-errors-modal_skip-validation" *ngIf="showSkipValidationCheckBox">
    <lib-radio-checkbox (change)="onSkipValidationCheckboxChange()" data-cy="ignore-validation-checkbox">
      <span style="font-weight:500;">Ignore the validation {{errorTypeText}}</span>
    </lib-radio-checkbox>
    <label style="font-size:smaller;">
      The above validation {{errorTypeText}} will be ignored, and the selected sample sheet will be directly submitted
      (no validation) for analysis requeue.
    </label>
  </div>

</ngx-smart-modal>
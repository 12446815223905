import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { SearchQueryService } from '@app/core/services/search/resource-search/search-query.service';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import environment from '@environments/environment';
import { IServerSideGetRowsRequest } from 'ag-grid-community';
import { SampleSearchColumnFields } from '../gridOptions/sample-search-grid-options-formatter';
import { ISearchDataSourceOptions, SearchDataSource } from './search.datasource';

export class SampleSearchDataSource extends SearchDataSource {
    constructor(
        searchQueryService: SearchQueryService,
        resourceDictionaryService: SearchResourceDictionaryService,
        dataSourceOptions: ISearchDataSourceOptions
    ) {
        const sampleResourceDictionary = resourceDictionaryService.getResourceConfigByType(SearchResourceType.Sample);
        super(searchQueryService, sampleResourceDictionary, dataSourceOptions);
        this.origin = `${StringUtilities.trimTrailingSlash(environment.apiUrl)}/v1pre3`;
    }

    get rawTextFilterColumnName(): string {
        return SampleSearchColumnFields.Name;
    }

    getRowsRequest(params: any, rowsRequest: IServerSideGetRowsRequest, response: any) {
        // hack to get v1pre responses working with v2 counts for search result totals
        this.response = { Paging: { TotalCount: response.Response.TotalCount } } as any;

        return {
            ...rowsRequest,
            rowData: response.Response.Items,
            startRow: response.Response.Offset,
            lastRow: this.pagination ? response.Response.TotalCount : response.Response.Items.length
        };
    }
}

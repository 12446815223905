import { Injectable } from '@angular/core';
import { TrashItemMessageTypes } from '@app/core/store/trash-items/trash-items.store';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private subject: Subject<Message> = new Subject();

  constructor() { }

  sendMessage(message: Message) {
    this.subject.next(message);
  }

  clearMessages() {
    this.subject.next();
  }

  getMessages(messageTypes?: MessageType | MessageType[]): Observable<Message> {
    if(!messageTypes) {
      // listener to all messages
      return this.subject.asObservable();
    }  else {
      messageTypes = Array.isArray(messageTypes) ? messageTypes : [messageTypes];
      return this.subject.pipe(
        filter((message: Message) => {
          return (messageTypes as MessageType[]).findIndex((messageType: MessageType) => messageType === message.type) !== -1;
        })
      );
    }
  }

}

type MessageType = TrashItemMessageTypes;

interface Message {
  type: MessageType;
  data?: any;
}
import { IndexAdapterKit } from '@stratus/gss-ng-sdk';
import _ from 'lodash';
import { CustomOption } from '../store/cloud-run-prep/run-setup.state';

export class GSSKitsSortUtilities {

    /**
     * Logic to sort Index Adapter Kits
     * @param kits Index Adapter Kits array
     */
    public static sortIAKs(kits: IndexAdapterKit[]): any[] {
        return kits.sort((kitA: IndexAdapterKit, kitB: IndexAdapterKit): number => {
            const a: string = kitA.displayName || kitA.name;
            const b: string = kitB.displayName || kitB.name;
            return GSSKitsSortUtilities.compareIAKSetName(a, b);
        });
    }

    /**
     * Get the sorted list of Index Adapter Kits within custom options
     * @param iakOptions index adapter kits in list of Custom Options
     */
    public static getSortedIAKsFromOption(iakOptions: CustomOption[]): IndexAdapterKit[] {
        // extract iak from custom options
        const indexAdapterKits = iakOptions.map(option => option.originalData);
        return this.sortIAKs(indexAdapterKits);
    }

    /**
     * Logic to sort other kit array
     * @param kits Other array e.g. Library Prep Kits
     */
    public static sortKits(kits: any[]): any[] {
        return _.sortBy(kits, kit => (kit.displayName || kit.name).toUpperCase());
    }

    /**
     * Logic to sort custom option array
     * @param kits Custom option array to be sorted
     */
    public static sortCustomOptions(kits: CustomOption[]): CustomOption[] {
        return _.sortBy(kits, kit => (kit.text).toUpperCase());
    }

    /**
     * Sort index adapter kits by name and length
     *  - Set A B C D should appear before Set A, Set B.. etc
     */
    private static compareIAKSetName(kitNameA: string, kitNameB: string, caseSensitive?: boolean): number {
        const a = caseSensitive ? kitNameA : kitNameA.toUpperCase();
        const b = caseSensitive ? kitNameB : kitNameB.toUpperCase();
        const minLength = Math.min(a.length, b.length);
        let diff = 0;
        for (let i = 0; i < minLength && diff === 0; i++) {
            diff = this.customCharCodeAt(a, i) - this.customCharCodeAt(b, i);
        }
        return diff === 0 ? a.length - b.length : diff;
    }

    /**
     * since '(' is smaller than alphabet (http://www.asciitable.com/), we need to increase the char code to archive the result.
     */
    private static customCharCodeAt(s: string, index: number): number {
        const charCode = s.charCodeAt(index);
        return charCode < 48 && charCode > 32 ? charCode + 100 : charCode;
    }
  }

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  IV2EntityForImportWrapper, IV2EntityImportStatus, IV2ManifestImportResponse,
  IV2ManifestImportTemplate
} from '../../core/bsshapi/api-wrappers';
import { IValidatedCsvResult, V2ImportStatus, IValidatedCsvRow } from './validated-csv.interface';

@Component({
  selector: 'app-validated-csv',
  templateUrl: './validated-csv.component.html',
  styleUrls: ['./validated-csv.component.scss']
})
export class ValidatedCsvComponent implements OnChanges {

  @Input() data: IV2ManifestImportTemplate = null;

  rows: Array<IValidatedCsvRow> = null;
  headerRow: string[] = null;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data !== null && changes.data.currentValue !== null) {
      const result = this.createRows(changes.data.currentValue);
      this.rows = result.rows;
      this.headerRow = result.headerRow;
    }
  }

  private createRows(rawData: IV2ManifestImportResponse): IValidatedCsvResult {
    const result: IValidatedCsvResult = { headerRow: null, rows: null };
    if (rawData !== null) {
      const lines = rawData.ParsedLines || [];
      let longestRow = [];
      if (lines.length) {
        longestRow = lines.reduce((prev: string[], curr: string[]) => prev.length > curr.length ? prev : curr);
      }

      const manifestStatuses = rawData.ManifestStatuses || {} as IV2EntityForImportWrapper;
      let statuses = manifestStatuses.Statuses || [];
      if (rawData.BioSamples) {
        const sampleStatuses = this.selectMany(rawData.BioSamples.map(s => s.Statuses), a => a);
        statuses = statuses.concat(sampleStatuses);
      }
      if (rawData.Subjects) {
        const subjectStatuses = this.selectMany(rawData.Subjects.map(s => s.Statuses), a => a);
        statuses = statuses.concat(subjectStatuses);
      }
      let maximumErrorLine = 0;
      if (statuses.length) {
        maximumErrorLine = statuses.reduce((prev: number, curr: IV2EntityImportStatus) => Math.max(prev, curr.Line), 0);
      }

      // we have data and validationResults
      // the goal is to glue them together in a viewModel object called rows
      result.rows = [];
      const maxLength = Math.max(lines.length, maximumErrorLine);
      for (let rowIndex = 0; rowIndex < maxLength; rowIndex++) {
        const dataRow = rowIndex < lines.length ? lines[rowIndex] : [];
        for (let colIndex = dataRow.length; colIndex < longestRow.length; colIndex++) {
          dataRow.push('');
        }
        const rowObject = {
          data: dataRow,
          validationErrors: this.getValidationsByLine(statuses, rowIndex + 1, V2ImportStatus.Error),
          validationWarnings: this.getValidationsByLine(statuses, rowIndex + 1, V2ImportStatus.Warning)
        };
        result.rows.push(rowObject);
      }
      // change characters to uppercase
      result.headerRow = longestRow.map((e: string, i: number) => String.fromCharCode(i + 65));
    }
    return result;
  }

  private selectMany(array, selector) {
    if (array.length === 0) {
      return [];
    }
    return array.map(selector).reduce((a, b) => a.concat(b));
  }

  private getValidationsByLine(statuses: IV2EntityImportStatus[], lineNumber: number, status: string): IV2EntityImportStatus[] {
    return statuses.filter((r: IV2EntityImportStatus) => r.Line === lineNumber && r.Type === status);
  }

}

import { Injectable } from '@angular/core';
import { CurrentUserStore } from '../../../user/store/current-user/current-user.store';
import { UserSubscriptionService } from './user-subscription.service';
import { IRestriction } from '../../model/user/restriction';
import { IUser } from '../../model/user';
import { CodeFeaturesService } from './code-features.service';
import { filter } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { IApplication } from '../../model/applications/applications';
import { BytesFilter } from '../../utilities/filters/bytes.filter';

export interface IUserRestrictionService {
  isUserRestrictedFromApp(app: IApplication): IRestriction;
  getUserStorageRestriction(): IRestriction;
  userHasStorageRestrictions(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserRestrictionService extends BaseService implements IUserRestrictionService {
  public static readonly STORAGE_QUOTA_RESTRICTION = 'App launch is restricted due to exceeding your storage quota.';
  public static readonly BASIC_USER_PAID_APP_RESTRICTION = 'Basic subscriptions are limited to free apps.';
  public static readonly FREE_TRIAL_USER_INSUFFICIENT_CREDITS_RESTRICTION = 'Free trial user is restricted due to insufficient iCredits.';

  private currentUser: IUser;

  constructor(private currentUserStore: CurrentUserStore, private userSubscriptionService: UserSubscriptionService,
              private codeFeatureService: CodeFeaturesService) {
                super();
                this.init();
  }

  private init() {
    this.subs.sink = this.currentUserStore.stateChanged.pipe(filter(state => state != null && state.currentUser != null))
      .subscribe({
        next: state => {
          this.currentUser = state.currentUser;
        }
      });
    }

  isUserRestrictedFromApp(app: IApplication): IRestriction {
    const restriction: IRestriction = {
      isRestricted: false,
      message: ''
    };

    if (this.userHasStorageRestrictions()) {
      restriction.isRestricted = true;
      restriction.message = UserRestrictionService.STORAGE_QUOTA_RESTRICTION;
      return restriction;
    }

    if (this.userSubscriptionService.isBasicUser() && !app.IsFree) {
      restriction.isRestricted = true;
      restriction.message = UserRestrictionService.BASIC_USER_PAID_APP_RESTRICTION;
      return restriction;
    }

    if (this.userSubscriptionService.isFreeTrialUser() && this.codeFeatureService.hasWallet
      && this.currentUser.Subscription.Wallet.ICreditBalance <= 0 && !app.IsFree) {
      // Zuora or other wallet is active, so we can restrict based on their icredit balance
      restriction.isRestricted = true;
      restriction.message = UserRestrictionService.FREE_TRIAL_USER_INSUFFICIENT_CREDITS_RESTRICTION;
      return restriction;
    }

    return restriction;
  }

  // TODO: Use 'UserNotificationStore' when its in place
  getUserStorageRestriction(): IRestriction {
    const restriction: IRestriction = {
      isRestricted: false,
      message: ''
    };

    if (!this.userSubscriptionService.isBasicUser()) {
      return restriction;
    }

    const formattedQuota = this.currentUser.StorageStatistics ? BytesFilter(this.currentUser.StorageStatistics.Quota) : '';
    if (this.userHasStorageRestrictions()) {
      restriction.message = 'Your account has been restricted because it has exceeded the ' + formattedQuota + ' storage limit.';
      restriction.isRestricted = true;
    } else if (this.currentUser && this.currentUser.StorageStatistics &&
      this.currentUser.StorageStatistics.TotalOwnedSize > this.currentUser.StorageStatistics.Quota * 0.9) {
      restriction.message = 'Your account is close to exceeding the ' + formattedQuota +
        ' storage limit.  This may lead to restrictions on your account.';
    }

    return restriction;
  }

  userHasStorageRestrictions(): boolean {
    if (!this.userSubscriptionService.isBasicUser()) {
      return false;
    }

    return this.currentUser && this.currentUser.StorageStatistics &&
          (this.currentUser.StorageStatistics.TotalOwnedSize > this.currentUser.StorageStatistics.Quota);
  }
}

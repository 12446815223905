import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { isNullOrUndefined } from 'util';
import { ResourceType } from '../../../core/model/resource-type';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';
import { EditInfoModalInput, EditInfoModalOutput } from '../model/action-modal';
import {
  EditInfoComponentOutput, EditInfoEditableField, EditInfoInputFieldType, ResourceEditableField,
  ResourceFieldToValueMap
} from '../model/edit-info-editable-field';

@Component({
  selector: 'app-edit-info-modal',
  templateUrl: './edit-info-modal.component.html',
  styleUrls: ['./edit-info-modal.component.scss']
})
export class EditInfoModalComponent extends BaseModalComponent implements AfterViewInit, OnInit {

  resourceType: ResourceType = null;
  fieldsToEdit: EditInfoEditableField[] = [];

  modalType = 'InitiateEditInfoModal';
  ngxSmartModalTitle: string = '';
  confirmButtonText: string = ModalTexts.EDIT_INFO.CONFIRM_BUTTON_TEXT;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setUpInputSubscriber();
  }

  constructError(error: any): string {
    if (error.error && error.error.ErrorMessage) {
      return error.error.ErrorMessage;
    }

    if (error.error &&
       error.error.Notifications &&
       error.error.Notifications[0].Item) {
      return error.error.Notifications[0].Item;
    }

    return ModalTexts.EDIT_INFO.ERROR;
  }

  // event has EditInfoComponentOuput
  dataChanged($event: EditInfoComponentOutput) {
    const componentOutput: EditInfoComponentOutput = $event;
    if (componentOutput) {
      this.disableConfirmButton = this.isAnyMandatoryFieldEmpty(componentOutput.newValueMap);
      const data: EditInfoModalOutput = {
        newValueMap: componentOutput.newValueMap,
      };
      this.modal.setData(data, true);
    }
  }

  private isAnyMandatoryFieldEmpty(newValueMap: ResourceFieldToValueMap) {
    for (const editableField of this.fieldsToEdit) {
      if (editableField.isOptional === false &&
        (newValueMap[editableField.name].length === 0)) {
        return true;
      }
    }
    return false;
  }

  private setUpInputSubscriber() {
    this.subs.sink = this.data.subscribe(res => {
      if (res) {
        const response: EditInfoModalInput = res as EditInfoModalInput;
        this.resourceType = response.resourceType;
        this.ngxSmartModalTitle = this.getModalTitle();
        this.fieldsToEdit = this.getFieldsToEdit(response.currentValueMap);
      }
    });
  }

  private getModalTitle(): string {
    if (!this.resourceType) {
      return '';
    }

    switch (this.resourceType) {
      case ResourceType.RUN:
        return 'Edit Run';
      case ResourceType.ANALYSIS:
        return 'Edit Analysis';
      case ResourceType.BIO_SAMPLE:
        return 'Edit Biosample';
      case ResourceType.PROJECT:
        return 'Edit Project';
      default:
        return null;
    }
  }

  private getFieldsToEdit(currentValueMap?: ResourceFieldToValueMap): EditInfoEditableField[] {
    if (!this.resourceType) {
      return [];
    }
    switch (this.resourceType) {
      case ResourceType.RUN:
      case ResourceType.ANALYSIS:
      case ResourceType.BIO_SAMPLE:
        return this.getFieldToEdit(currentValueMap);
      case ResourceType.PROJECT:
        return this.getProjectFieldsToEdit(currentValueMap);
      case ResourceType.PLANNED_RUN:
        return [];
      default:
        return [];
    }
  }

  private getFieldToEdit(currentValueMap?: ResourceFieldToValueMap): EditInfoEditableField[] {
    const fields: EditInfoEditableField[] = [
      {
        name: ResourceEditableField.NAME,
        type: EditInfoInputFieldType.INPUT,
        placeholder: ResourceEditableField.NAME.toString(),
        maxLength: 100,
        maxLengthWarningMessage: ModalTexts.EDIT_INFO.MAX_LENGTH_WARNING_MESSAGE(100, this.resourceType),
        isOptional: false,
        mandatoryFieldErrorMessage: ModalTexts.EDIT_INFO.MANDATORY_FIELD_ERROR_MESSAGE(this.resourceType),
        currentValue: currentValueMap ? currentValueMap[ResourceEditableField.NAME] : '',
      }
    ];
    return fields;
  }

  /* not used anywhere till now, as system only supports run edit. */
  private getProjectFieldsToEdit(currentValueMap?: Partial<Record<ResourceEditableField, string>>): EditInfoEditableField[] {
    const fields: EditInfoEditableField[] = [
      {
        name: ResourceEditableField.NAME,
        type: EditInfoInputFieldType.INPUT,
        placeholder: ResourceEditableField.NAME.toString(),
        maxLength: 255,
        maxLengthWarningMessage: ModalTexts.EDIT_INFO.MAX_LENGTH_WARNING_MESSAGE(255, this.resourceType),
        isOptional: false,
        mandatoryFieldErrorMessage: ModalTexts.EDIT_INFO.MANDATORY_FIELD_ERROR_MESSAGE(this.resourceType),
        currentValue: currentValueMap ? currentValueMap[ResourceEditableField.NAME] : '',
      },
      {
        name: ResourceEditableField.DESCRIPTION,
        type: EditInfoInputFieldType.TEXTAREA,
        placeholder: ResourceEditableField.DESCRIPTION.toString(),
        maxLength: 500,
        maxLengthWarningMessage: ModalTexts.EDIT_INFO.MAX_LENGTH_DESCRIPTION_WARNING_MESSAGE,
        isOptional: true,
        currentValue: currentValueMap ? currentValueMap[ResourceEditableField.DESCRIPTION] : '',
      }
    ];
    return fields;
  }
}

<ngx-smart-modal #changeSampleDataModal class="change-sample-data-modal" identifier="changeSampleDataModal"
    [title]="'Change Sample Data?'" confirmButtonText="Proceed" closeButtonText="Cancel">
    <p>
        Would you like to replace the existing samples in the sample table or add to existing samples?
    </p>
    <br />
    <lib-radio-checkbox (change)="changeToReplaceData(true)" [checked]="true" type="radio" name='actionType'>
        Replace existing samples
    </lib-radio-checkbox>
    <lib-radio-checkbox (change)="changeToReplaceData(false)" type="radio" name='actionType'>
        Add to existing samples
    </lib-radio-checkbox>
</ngx-smart-modal>
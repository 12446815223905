import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { ISearchResourceConfig } from '@app/core/services/resource-dictionary/search-resource-dictionary-types';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { AppInfoRendererComponent } from '../app-info-renderer/app-info-renderer.component';
import { ProjectSearchColumnFields } from './project-search-grid-options-formatter';
import { OverrideColumnDefinition, SearchGridOptionsFormatter, OverrideColumnParameters } from './search-grid-options-formatter';

export enum AppSearchColumnFields {
    Name = 'Name',
    Categories = 'Category',
    Author = 'CompanyName'
}

export interface AppOverrideColumnDefinition extends OverrideColumnDefinition<AppSearchColumnFields> {}

export class AppSearchGridOptionsFormatter extends SearchGridOptionsFormatter<AppSearchColumnFields> {
    private appResourceConfig: ISearchResourceConfig;

    constructor(
        private resourceDictionaryService: SearchResourceDictionaryService,
    ) {
        super();
        this.appResourceConfig = this.resourceDictionaryService.getResourceConfigByType(SearchResourceType.App);
    }

    protected getOverrideColumnParameters(): OverrideColumnParameters<AppSearchColumnFields> {
        const inclusiveColumns: AppSearchColumnFields[] = [
            AppSearchColumnFields.Name,
            AppSearchColumnFields.Categories,
            AppSearchColumnFields.Author
          ];

        const overrideColumnDefinitions: OverrideColumnDefinition<AppSearchColumnFields>[] = [
            {
                colId: AppSearchColumnFields.Name,
                headerName: 'Name',
                checkboxSelection: false
            }
        ];

        return {
            inclusiveColumns,
            overrideColumnDefinitions
        };
    }

    protected getDefaultColumnDefinitions() {
        const appConfig = this.appResourceConfig;
        const defaultColumnDefinitions = [
            {
                headerName: 'Name',
                field: 'Application.Name',
                colId: AppSearchColumnFields.Name,
                cellRendererFramework: AppInfoRendererComponent,
                sortable: false,
                flex: 4.5
            },
            {
                headerName: 'Category',
                cellRenderer: 'span',
                field: 'Application.Category',
                colId: AppSearchColumnFields.Categories,
                sortable: false,
                flex: 4
            },
            {
                headerName: 'Author',
                cellRenderer: 'span',
                field: 'Application.CompanyName',
                colId: AppSearchColumnFields.Author,
                sortable: false,
                flex: 1.5
            },
        ];
        return defaultColumnDefinitions;
    }
}

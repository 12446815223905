import environment from '@environments/environment';

export class ConsoleLogger {

    static logMessage(message: any, ...optionalParams: any[]) {
        if (!environment.production) {
            console.log(message, optionalParams != null && optionalParams.length > 0 ? optionalParams : '');
        }

    }

    static logError(message: any, ...optionalParams: any[]) {
        console.error(message, optionalParams);
    }

    static logWarning(message: any, ...optionalParams: any[]) {
        if (!environment.production) {
            console.warn(message, optionalParams != null && optionalParams.length > 0 ? optionalParams : '');
        }

    }
}

import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ModalService } from '../../modals/services/modal.service';
import { ICommentsModalInput } from '../../modals/model/action-modal';
import { CommentsModalComponent } from '../../modals/comments-modal/comments-modal.component';
import { ResourceType } from '../../../core/model/resource-type';

@Component({
  selector: 'app-comments-trigger-cell-renderer',
  templateUrl: './comments-trigger-cell-renderer.component.html',
  styleUrls: ['./comments-trigger-cell-renderer.component.scss']
})
export class CommentsTriggerCellRendererComponent implements AgRendererComponent {
  private resourceId = null;
  private resourceType = null;
  public params = null;

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: any): void {
    if (!params || !params.value || !params.value.resourceId || !params.value.resourceType) {
      return;
    }

    this.params = params;
    this.resourceId = this.params.value.resourceId;
    this.resourceType = this.params.value.resourceType;
  }

  constructor(private modalService: ModalService) { }

  showCommentsModal($event: Event) {
    this.modalService.openModal({ id: this.resourceId, resourceType: this.resourceType } as ICommentsModalInput, CommentsModalComponent);
    $event.stopPropagation();
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authorization/authorization.service';
import environment from '@environments/environment';
import { StringUtilities } from '../../core/utilities/string-utilities';

@Injectable()
export class IpsHeaderInterceptor implements HttpInterceptor {
    constructor(private authorizationService: AuthorizationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.startsWith(`${StringUtilities.trimTrailingSlash(environment.ipsApiUrl)}/v1/performance`)) {
            let headers = request.headers;

            // Temprary solution to allow access to ips/user/instruments.
            // TODO: Remove when IPS does not have to redirect call to basespace.
            const psToken = this.authorizationService.getPsToken();
            if (psToken) {
                headers = headers.set('psToken', psToken);
            }
            const clonedRequest = request.clone({ headers, withCredentials: false });
            return next.handle(clonedRequest);
        } else {
            return next.handle(request);
        }
    }
}

<div id='FilePreviewDiv'>
    <ngx-smart-modal #FilePreviewModal
        [identifier]="modalType"
        [title]="modalTitle"
        [closable]="closable"
        [showCloseButton]="showCloseButton" 
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButton"
        [confirmButtonText]="confirmButtonText">

        <lib-file-preview
        [apiBaseUrl]="apiBaseUrl"
        [requestedFile]="file"
        [isPreviewableTextFile]="isPreviewableTextFile"
        (data)="dataChanged($event)"
        >
        </lib-file-preview>

    </ngx-smart-modal>
</div>

import { isNullOrUndefined } from 'util';
import moment from 'moment';
import 'moment-duration-format';
import { StringUtilities } from './string-utilities';
import _ from 'lodash';

export class DateTimeUtilities {

    /**
     * Gets the difference between two dates in milli seconds.
     * @param startDate The start Date
     * @param endDate The End date
     */
    static getDifferenceInMs(startDate: string | Date, endDate: string | Date): number {
        const startDateParse = moment(startDate);
        const endDateParse = moment(endDate);
        if (startDateParse.isValid() && endDateParse.isValid()) {
            const duration = moment.duration(moment(endDate).diff(moment(startDate)));
            return duration.asMilliseconds();
        }
        return null;
    }

    /**
     * converts milliseconds to a duration in hh:mm:ss format
     * @param milliSeconds The total milliseconds
     */
    static getMilliSecondsAsDuration(milliSeconds: number): ITimeDuration {
        if (!StringUtilities.isBlank(milliSeconds)) {
            const durationInhhmmss = moment.duration(milliSeconds, 'ms').format('hh|mm|ss', {trim: false, useGrouping: false});
            const durationComponents = _.split(durationInhhmmss, '|');
            return {
                hours: durationComponents[0],
                minutes: durationComponents[1],
                seconds: durationComponents[2],
            };
        }
        return null;
    }

    static compareByDate<T>(a: T, b: T, dateGetter: (input: T) => string): number {
        const dateStringA = dateGetter(a);
        const dateStringB = dateGetter(b);
    
        const dateA = new Date(dateStringA);
        const dateB = new Date(dateStringB);
    
        if (dateA > dateB) {
            return -1;
        } else if (dateStringA === dateStringB) {
            return 0;
        } else {
            return 1;
        }
     }
}

export interface ITimeDuration {
    hours: string;
    minutes: string;
    seconds: string;
}

import { filter} from 'rxjs/operators';
import { NavigationEnd, Router, Route, ActivatedRoute, Navigation } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})

export class RoutingStateService extends BaseService {
  public currentNavigation: Navigation;
  public previousNavigation: Navigation;
  private history = [];

  public subs = new SubSink();

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  public loadRouting() {
    this.subs.sink = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        const currentNavigation = this.router.getCurrentNavigation();
        if (currentNavigation) {
          this.previousNavigation = this.currentNavigation;
          this.currentNavigation = currentNavigation;
        }
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/dashboard';
  }
}

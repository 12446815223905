import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';
import { NgxSmartModalComponent } from '@bssh/comp-lib';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { IModalOutput, IModalInput, ModalStatus } from '../model/action-modal';

export interface IModalEvents {
  confirm: Observable<IModalOutput>;
  new?: Observable<IModalOutput>;
}

export interface IModalService {
  openModal<T>(inputData: IModalInput, component: Type<T>): IModalEvents;
  handleError(error: string);
}

@Injectable({
  providedIn: 'root'
})
export class ModalService implements IModalService {

  // The modal that is currently being displayed
  wrapperComponentRef: ComponentRef<any> = null;
  
  /**
   * The modal that is 'queued' as another modal is still present in DOM.
   * This modal exists in memory but is not in the DOM yet.
   * It will only replace wrapperComponentRef and appear in DOM when the present modal has finished closing.
   * This is to prevent race condition issues such as the present modal not being cleaned up fully
   * or the new modal not showing up.
   */
  queuedWrapperComponentRef: ComponentRef<any> = null;

  private _modalStatusChanges: BehaviorSubject<ModalStatus> = new BehaviorSubject(ModalStatus.CLOSED);
  modalStatusChanges: Observable<ModalStatus> = this._modalStatusChanges.asObservable();

  private _modalRemovedSubject = new Subject<boolean>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) { }

  public openModal<T>(inputData: IModalInput, component: Type<T>): IModalEvents {
    if (this.wrapperComponentRef && this.queuedWrapperComponentRef) {
      throw new Error('Failed to open modal. Too many modals queued.');
    }
    
    const componentRef = this.createComponent(component) as ComponentRef<any>;

    this.attachOrQueueComponent(componentRef);

    // provide input data to modal
    componentRef.instance.data.next(inputData);
    
    // return the output observable from the modal
    return {
      confirm: componentRef.instance.confirm,
      new: componentRef.instance.newAction
    };
  }

  private handleClose() {
    // should use closeModalFinished here instead of closeModal to avoid 'object unsubscribed' console error
    this.wrapperComponentRef.instance.closeModalFinished.subscribe(_ => {
      this.removeDialogComponentFromBody();
    });
  }

  public setData(inputData: IModalInput) {
    if (this.wrapperComponentRef) {
      this.wrapperComponentRef.instance.data.next(inputData);
    }
  }

  private setError(error: string) {
    if (this.wrapperComponentRef) {
      this.wrapperComponentRef.instance.error.next(error);
    }
  }

  /**
   * handleError() is responsible for handling the errors and cloosing the modal as well.
   * If error isn't received, the modal is supposed to close if canCloseOnConfirm returns true.
   * @param error The runstateError from the run store.
   */
  public handleError(error: string) {
    if (!this.wrapperComponentRef) {
      return;
    }

    if (error) {
      this.setError(error);
    } else {
      const modal: NgxSmartModalComponent = this.wrapperComponentRef.instance.modal;
      if (!isNullOrUndefined(modal) && this.wrapperComponentRef.instance.canCloseOnConfirm()) {
        modal.close();
        this._modalStatusChanges.next(ModalStatus.CLOSED);
      }
    }
  }

  /**
   * closeModal() is responsible only for cloosing the modal.
   * Use this when the modal needs to be closed explicitly wihtout checking any conditions.
   */
  public closeModal() {
    const modal: NgxSmartModalComponent = this.wrapperComponentRef.instance.modal;
    if (!isNullOrUndefined(modal)) {
      modal.close();
      this._modalStatusChanges.next(ModalStatus.CLOSED);
    }
  }

  private createComponent<T>(component: Type<T>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = componentFactory.create(this.injector);
    return componentRef;
  }

  private attachOrQueueComponent<T>(componentRef: ComponentRef<T>) {
    if (this.wrapperComponentRef) {
      // Queue the modal as another modal is still present in DOM
      this.queuedWrapperComponentRef = componentRef

      // Wait for present modal to finish closing before attaching queued modal to DOM
      this._modalRemovedSubject.pipe(first()).subscribe(_ => {
        this.attachComponent(componentRef);
        this.queuedWrapperComponentRef = null;
      });
      return this.queuedWrapperComponentRef;

    } else {
      this.attachComponent(componentRef);
      return this.wrapperComponentRef;
    }
  }
  
  private attachComponent<T>(componentRef: ComponentRef<T>) {
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.wrapperComponentRef = componentRef;

    this.handleClose();

    this._modalStatusChanges.next(ModalStatus.OPENED);
  }

  private removeDialogComponentFromBody() {
    if (this.wrapperComponentRef) {
      this.appRef.detachView(this.wrapperComponentRef.hostView);
      this.wrapperComponentRef.destroy();
      this.wrapperComponentRef = null;
      this._modalRemovedSubject.next()
    }

    return;
  }

}

<ngx-smart-modal #LaunchAnalysisProgressBarModal 
    [identifier]="modalType"
    [title]="modalTitle"
    [closable]="false"
    [showCloseButton]="false"
    [showConfirmButton]="false"
    [escapable]="false"
    [dismissible]="false">
        <hr class="popover-line">
        <p class="description">
            This may take a few minutes. Do not close the browser until launching is complete.
        </p>
        <lib-progress
            class="launch-analysis-loading-bar"
            id="launch-analysis-loading-bar"
            #progressBar
            [loader]="true"
            [spinner]="false"
            [spinnerPosition]="'center'"
            [thick]="true"
            (completed)="onCompleted()"
        >
        </lib-progress>
        <span class="spacer"></span>
</ngx-smart-modal>
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BannerNotificationsContainerDirective, BannerNotificationsService, IndividualConfig } from '@bssh/comp-lib';
import { UserGlobalNotificationsStore } from '@app/core/store/usernotifications/global/user-global-notifications.store';
import { UserNotificationStream, IUserNotificationInfo, SubscriptionNotificationType } from '@app/core/model/user-notifications/user-notifications';
import { CurrentUserStore } from '@app/user/store/current-user/current-user.store';
import { SubSink } from 'subsink';
import { filter, tap, delay } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import { ActiveToast } from '@bssh/comp-lib/lib/components/banner-notifications/toastr/toastr.service';
import { Constants } from '@app/core/utilities/constants';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import marked from 'marked';
import * as DOMPurify from 'dompurify';

@Component({
  selector: 'app-bs-global-banners',
  templateUrl: './bs-global-banners.component.html',
  styleUrls: ['./bs-global-banners.component.scss']
})
export class BsGlobalBannersComponent implements OnInit, OnDestroy {
  @ViewChild(BannerNotificationsContainerDirective, { static: true }) bannerNotificationsContainer: BannerNotificationsContainerDirective;
  private subs = new SubSink();
  private notificationsLoadingSubject = new BehaviorSubject<boolean>(true);
  public notificationsDataLoading$ = this.notificationsLoadingSubject.asObservable();
  public notificationsCount$: Observable<number>;

  constructor(private bannerNotificationService: BannerNotificationsService,
              private userGlobalNotificationsStore: UserGlobalNotificationsStore,
              private currentUserStore: CurrentUserStore,
              private router: Router) { }

  ngOnInit() {
    this.bannerNotificationService.toasts = [];
    this.bannerNotificationService.clear();
    this.bannerNotificationService.overlayContainer = this.bannerNotificationsContainer;
    this.loadGlobalNotifications();
    this.userGlobalNotificationsStore.stateChanged.pipe(
      filter(state => !isNullOrUndefined(state)),
      filter(state => {
        return !isNullOrUndefined(state.cmsNotifications) &&
          !isNullOrUndefined(state.dataDeletionNotifications) &&
          !isNullOrUndefined(state.subscriptionNotifications) &&
          !isNullOrUndefined(state.storageNotifications) &&
          !isNullOrUndefined(state.restrictedActivityNotifications);
      }),
    ).subscribe({
      next: (state) => {
        this.notificationsCount$ = of(state.dataDeletionNotifications.length +
          state.cmsNotifications.length +
          state.subscriptionNotifications.length +
          state.storageNotifications.length + state.restrictedActivityNotifications.length);
        // Notifications need to be displayed in order
        // Banner notifications are rendered with latest added using .info() api on top.
        // So add in the reverse order of actual needed order.
        // 5. Render Storage notifications
        this.renderGlobalNotifications(state.storageNotifications);
        // 4. Render Subscription Notifications
        this.renderGlobalNotifications(state.subscriptionNotifications);
        // 3. Render CMS notifications
        this.renderCmsGlobalNotifications(state.cmsNotifications);
        // 2. Render Restricted activity notifications
        this.renderGlobalNotifications(state.restrictedActivityNotifications);
        // 1. Render Data Deletion Notifications
        this.renderGlobalNotifications(state.dataDeletionNotifications);
        this.notificationsLoadingSubject.next(false);
      }
    });
    // Subscribe to Current User Store's context changed observable and
    // reload notifications list when the user context changes
    this.subs.sink = this.currentUserStore.contextChanged$.subscribe({
      next: () => {
        this.bannerNotificationService.clear();
        this.notificationsLoadingSubject.next(true);
        this.loadGlobalNotifications(true);
      }
    });
  }


  /**
   * Loads notifications in the store
   * @param forceLoad Whether to force load
   */
  private loadGlobalNotifications(forceLoad: boolean = false) {

    this.userGlobalNotificationsStore.loadGlobalNotifications(UserNotificationStream.Subscription, null, null, forceLoad);
    this.userGlobalNotificationsStore.loadGlobalNotifications(UserNotificationStream.Storage, null, null, forceLoad);
    this.userGlobalNotificationsStore.loadGlobalNotifications(UserNotificationStream.DataDeletion, null, null, forceLoad);
    this.userGlobalNotificationsStore.loadGlobalNotifications(UserNotificationStream.RestrictedActivity, null, null, forceLoad);
    // To do: specify limit & offset per media. For now, specifying 6(as the old site)
    this.userGlobalNotificationsStore.loadGlobalNotifications(UserNotificationStream.Cms, 0, 6, forceLoad);
  }

  /**
   * Renders CMS global notifications (CMS notifications have special structure)
   * @param cmsNotifications Cms Notifications
   */
  private renderCmsGlobalNotifications(cmsNotifications: IUserNotificationInfo[]): void {
    if (cmsNotifications.length > 0) {

      // cmsNotifications is order by ModifiedOn in DESC order
      // need to reverse the array in order to show the latest on top
      cmsNotifications.reverse().forEach(notification => {
        // Markdown content type
        if (notification.Type === 'Markdown') {
          let message = marked(notification.Description);
          message = message.replace('<a href', '<a class="banner-notification__link" target="_blank" rel="noopener" href');
          message = DOMPurify.sanitize(message, { ADD_ATTR: ['target'] });
          message = `${this.formatNotificationPrefix(notification.NotificationSeverity)}${message}`;
          this.bannerNotificationService.info(message, null, this.getBannerConfig(notification.IsDismissable));
          return;
        }

        // JSON content type
        const description = StringUtilities.IsJsonString(notification.Description) ? JSON.parse(notification.Description) as any :
          notification.Description;
        if (!isNullOrUndefined(description)) {
          const content = isNullOrUndefined(description.content) ? description : description.content;
          let message = "";

          if (description.button) {
            // optional: button/link that links to an another page or an external site

            const linkTarget = isNullOrUndefined(description.button.target) ?  "_blank" : description.button.target;
            // tslint:disable-next-line: max-line-length
            const link = `<a class="banner-notification__link" target="${linkTarget}" href="${description.button.href}">${description.button.label}</a>`;
            message = `${this.formatNotificationPrefix(notification.NotificationSeverity)}${content}${link}`;

          } else {
            // no button/link to display
            message = `${this.formatNotificationPrefix(notification.NotificationSeverity)}${content}`;
          }

          this.bannerNotificationService.info(message, null, this.getBannerConfig(notification.IsDismissable));
        }
      });
    }
  }
  /**
   * Handles rendering notifications other than CMS
   * @param notifications Global Notifications
   */
  private renderGlobalNotifications(notifications: IUserNotificationInfo[]): void {
    if (notifications.length > 0) {
      notifications.forEach(notification => {
        // Show the free trial notification only on the dashboard.
        if (!this.router.url.endsWith('dashboard') && notification.Type === SubscriptionNotificationType.FreeTrial) {
          return;
        }
        
        let linkHTML = '';
        if (!StringUtilities.isBlank(notification.HrefContent)) {
          // tslint:disable-next-line: max-line-length
          const linkText = notification.Stream === UserNotificationStream.Subscription.toString() && notification.Type !== SubscriptionNotificationType.FreeTrial ?
            Constants.NotificationLinkText.RenewNow : Constants.NotificationLinkText.LearnMore;
          linkHTML = `<a class="banner-notification__link" target="_blank" href="${notification.HrefContent}">${linkText}</a>`;
        }
        // Server returns three severities, 'Info', 'Warning', 'Restriction'
        // For now, if the severity is not 'Info', the message severity is a 'Warning'
        // Todo: If PO decides this should be worded or styled differently, change the banner notification service method call
        // & formatNotificationPrefix() method
        const message = `${this.formatNotificationPrefix(notification.NotificationSeverity)}${notification.Description}${linkHTML}`;
        const notificationHandle = this.bannerNotificationService.info(message, null, this.getBannerConfig(notification.IsDismissable));
        this.subscribeToNotificationClosedEvent(notificationHandle, notification);
      });
    }

  }

  private getBannerConfig(showCloseButton: boolean): Partial<IndividualConfig> {
    return {
      closeButton: showCloseButton,
      enableHtml: true
    };
  }

  private formatNotificationPrefix(notificationSeverity: string): string {
    return notificationSeverity ?
      `<span class="toast-title">${notificationSeverity === 'Info' ? '' : 'Warning:'}</span> ` : '';
  }

  private subscribeToNotificationClosedEvent(notificationHandle: ActiveToast<any>, notification: IUserNotificationInfo) {
    if (!notification.IsDismissable) {
      return;
    }
    // subscribe to the notification handle's 'manualClosed' observable
    notificationHandle.toastRef.manualClosed().subscribe({
      next: (data) => {
        // call to store to dismiss and set state
        // There is no need to dispatch state and re-render the notifications as the dismissed notification is already removed from DOM
        this.userGlobalNotificationsStore.dismissNotification(notification, false);
      }
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}

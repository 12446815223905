import { Pipe, PipeTransform, Injectable } from '@angular/core';
import {BasePairUnitResult, BasePairUtils} from '@app/core/utilities/base-pair.utilities';
import { isNullOrUndefined } from 'util';

@Pipe({name: 'basepairs'})
@Injectable({
  providedIn: 'root'
})
export class BasePairsPipe implements PipeTransform {

  /**
   * Converts a yield value in base pairs to a formatted value in Kbp, Gbp etc, based on the conversion divisor
   * @param numBasePairs Base pairs in bp
   * @param precision precision
   * @param defaultValue default in case the formatted value is null or zero
   * @returns formatted value
   */
  transform(numBasePairs: number, precision: number = 2, defaultValue: string = '--'): string {
    if ( numBasePairs != null && numBasePairs >= 0 ) {
      const basePairUnits: BasePairUnitResult = BasePairUtils.getBasePairUnits(numBasePairs, precision);
      if (basePairUnits) {
          return `${basePairUnits.result} ${basePairUnits.basePairUnit.abbreviation}`;
      }
    }
    return defaultValue;
  }
}

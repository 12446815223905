import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lane, V1pre3TrashItem, V2InstrumentCompactList, V2CommentList, V2LabRequeueCompact, V2FilesList, IAppSessionCompact, AppSessionCompact, V1pre3FileCompact } from '@bssh/ng-sdk';
import { Observable } from 'rxjs/internal/Observable';
import { delay, map, retryWhen, shareReplay } from 'rxjs/operators';
import { ResourceType } from '../../model/resource-type';
import { IApiResponseWrapper, IResourceCapabilities, IRequeueResponse, IListResponseWrapper, ISubDirectoryApiResponseWrapper, IApiListResponseWrapper, IRequeueableResponse } from '../../model/v2-api/v2-api-wrappers';
import { genericRetryWhen, observableEmitDelay } from '../../rxjsutils/rxjs-utilities';
import { BaseService } from '../base.service';
import { BsApiEndPoints } from './endpoints';
import { isNullOrUndefined } from 'util';
import { DeleteOption } from '../../model/delete-options';
import { LaneStatus } from '../../model/runs/lane-status';
import { ISequencingStats } from '@app/core/model/runs/seq-stats';
import { ILaneLibraryMapping } from '@app/core/model/runs/lane-library-mapping';
import { ILegacySampleAppSessionsResponseWrapper } from '@app/core/model/runs/sample-app-session';
import { IPlannedRun } from '@app/runs/run-details/tabs/run-details-settings/run-details-settings.component';
import { IProjectCapabilities, ProjectCapabilityType } from '@app/core/store/projects/projects.store';
import { ICollaboratorListResponse } from '../../model/collaborator';
import { IAppSession } from '@app/core/model/appSessions/appSession';
import { ISample } from '@app/core/model/samples/sample';
import { LegacyBsApiEndpointsService } from '../legacy-bs-api/legacy-bs-api-endpoints.service';
import { DemoDataContentGroup } from '@app/core/store/demo-data/demo-data.store';
import { IDatasetType } from '@app/core/model/datasets/dataset-type';

// Interface for the BsApiService.
export interface IBsApiService {
  getCapabilities(resourceType: ResourceType, resourceId: string): Observable<IResourceCapabilities>;
  regenerateFastq(resourceId: string): Observable<IRequeueResponse>;
  nextSeqRequeue(resourceId: string): Observable<IRequeueResponse>;
  trashResource(resourceType: ResourceType, resourceId: string,
    deleteOption?: DeleteOption): Observable<IApiResponseWrapper<V1pre3TrashItem>>;
  trashResourceWithV2(resourceType: ResourceType, resourceId: string,
    deleteOption?: DeleteOption): Observable<V1pre3TrashItem>;
  changeLaneQc(laneId: string, laneStatus: LaneStatus, comments: string): Observable<Lane>;
  getRunSequencingStats(runId: string): Observable<ISequencingStats>;
  getInstrumentList(limit: number, sortBy: 'Id' | 'Name', sortDir?: string): Observable<V2InstrumentCompactList>;
  trashAppSession(appsessionId: string): Observable<IApiResponseWrapper<V1pre3TrashItem>>;
  getCmsContentList(contentGroupName: string, offset: number, limit: number,
    sortBy: 'Name' | 'ContentType' | 'DateModified' | 'Revision' | 'Status', sortDir?: 'Asc' | 'Desc'): Observable<IApiResponseWrapper<any>>;
  setUserV2BiosampleRegistry(userId: string, V2BioSampleRegistryEnabled: boolean): Observable<null>;
  getBiosamples(runId: string, limit: number, offset: number, sortBy: string, sortDir: string):
    Observable<IListResponseWrapper<ILaneLibraryMapping>>;
  getSamples(resourceType: ResourceType, id: string, limit: number, offset: number, sortBy: string, sortDir: string):
    Observable<ILegacySampleAppSessionsResponseWrapper>;
  getAppsessionProperty(analyses: IAppSession,
    propertyName: string,
    propertyItemLimit: number): Observable<any>;
  requeueSampleSheet(runId: string, sampleSheet: string): Observable<any>;
  getIcaDataDownloadUrl(dataUrn: string): Observable<any>;
}

@Injectable({
  providedIn: 'root'
})
/**
 * This class holds the direct HTTP calls for various BSSH APIs which are not available in swagger sdk.
 */
export class BsApiService extends BaseService implements IBsApiService {

  constructor(
    private httpClient: HttpClient,
    private legacyBsApiEndpointsService: LegacyBsApiEndpointsService
  ) {
    super();
  }

  /**
   * Makes an API call to fetch capabilities for a given resource type and resource id.
   */
  public getCapabilities(resourceType: ResourceType, resourceId: string): Observable<IResourceCapabilities> {
    const requestUrl = BsApiEndPoints.getCapabilitiesUrl(resourceType, resourceId);
    return this.httpClient.get<IResourceCapabilities>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay)
    );
  }

  public regenerateFastq(resourceId: string): Observable<IRequeueResponse> {
    const requestUrl = BsApiEndPoints.getRegenerateFastqUrl(resourceId);
    return this.httpClient.post<IRequeueResponse>(requestUrl, {});
  }

  public nextSeqRequeue(resourceId: string): Observable<IRequeueResponse> {
    const requestUrl = BsApiEndPoints.getNextSeqRequeueUrl(resourceId);
    return this.httpClient.post<IRequeueResponse>(requestUrl, {});
  }

  public changeLaneQc(laneId: string, laneStatus: LaneStatus, comments: string): Observable<Lane> {
    if (isNullOrUndefined(laneId) || isNullOrUndefined(laneStatus)) {
      return;
    }

    const requestUrl = BsApiEndPoints.getChangeLaneUrl(laneId);
    const postData = {
      Status: laneStatus.toString(),
      ...!isNullOrUndefined(comments) && { Comment: comments }
    };

    return this.httpClient.post<Lane>(requestUrl, postData);
  }

  public trashResource(resourceType: ResourceType, resourceId: string, deleteOption?: DeleteOption):
    Observable<IApiResponseWrapper<V1pre3TrashItem>> {

    const requestUrl = BsApiEndPoints.getTrashResouceUrl(resourceType, resourceId, deleteOption);
    return this.httpClient.delete<any>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay));
  }

  public trashResourceWithV2(resourceType: ResourceType, resourceId: string, deleteOption?: DeleteOption):
    Observable<V1pre3TrashItem> {

    const requestUrl = BsApiEndPoints.getTrashResouceUrlWithV2(resourceType, resourceId, deleteOption);
    return this.httpClient.delete<any>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay));
  }

  public trashAppSession(appsessionId: string, ByPassTrash: boolean = false): Observable<IApiResponseWrapper<V1pre3TrashItem>> {
    const requestUrl = BsApiEndPoints.getTrashAppSessionUrl(appsessionId, ByPassTrash);
    return this.httpClient.delete<any>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay));
  }

  public trashSamples(sampleIds: string[]): Observable<IApiResponseWrapper<V1pre3TrashItem[]>> {
    const payload = {
      Ids: sampleIds,
      ResourceType: ResourceType.SAMPLE
    };

    return this.httpClient.post<any>(
      BsApiEndPoints.BULK_DELETE_URL,
      payload
    ).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay));
  }

  /**
   * Makes an API call to featch sequencing stats associated with the given run id.
   * @param runId V1Pre3Id of the run
   */
  getRunSequencingStats(runId: string): Observable<ISequencingStats> {
    const requestUrl = BsApiEndPoints.getRunSequencingStatsUrl(runId);
    return this.httpClient.get<ISequencingStats>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  /**
   * Fetches a list of instruments associated with the runs-list, without workgroup and activeruns filter.
   * The swagger generated sdk api (GetV2InstrumentsResponse) requires both workgroup and activeruns params,
   * which will filter out some of the options returned by the sdk api.
   * @param limit max number of instruments returned by the api
   * @param sortBy sort by instrument Id or Name
   * @param sortDir defaults to 'asc'
   */
  getInstrumentList(limit: number, sortBy: 'Id' | 'Name', sortDir = 'asc'): Observable<V2InstrumentCompactList> {
    const requestUrl = BsApiEndPoints.getInstrumentListUrl();
    const params = {
      limit: String(limit),
      sortBy,
      sortDir
    };
    return this.httpClient.get<V2InstrumentCompactList>(requestUrl, { params }).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getBiosamples(runId: string, limit: number = 100, offset: number = 0, sortBy: string = 'LaneNumber', sortDir: string = 'asc'):
    Observable<IListResponseWrapper<ILaneLibraryMapping>> {
    const requestUrl = BsApiEndPoints.getRunsBiosamplesUrl(runId);
    const params = {
      limit: String(limit),
      offset: String(offset),
      sortBy,
      sortDir
    };
    return this.httpClient.get<IListResponseWrapper<ILaneLibraryMapping>>(requestUrl, { params }).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getSample(id: string): Observable<IApiResponseWrapper<ISample>> {
    const requestUrl = BsApiEndPoints.getSamplesApiUrl(id);
    return this.httpClient.get<IApiResponseWrapper<ISample>>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getSamples(
    resourceType: ResourceType,
    id: string,
    limit: number = 25,
    offset: number = 0,
    sortBy: string = 'sampleId',
    sortDir: string = 'desc'
  ): Observable<ILegacySampleAppSessionsResponseWrapper> {
    let requestUrl;
    switch (resourceType) {
      case ResourceType.RUN:
        requestUrl = this.legacyBsApiEndpointsService.getRunSamplesUrl(id);
        break;
      case ResourceType.PROJECT:
        requestUrl = BsApiEndPoints.getProjectSamplesUrl(id);
        break;
    }

    const params = {
      limit: String(limit),
      offset: String(offset),
      sortBy,
      sortDir
    };
    return this.httpClient.get<ILegacySampleAppSessionsResponseWrapper>(requestUrl, { params }).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  /**
   * fetches data to display on run Settings spotlight main section
   *
   */
  getRunSettings(url: string): Observable<IApiResponseWrapper<IPlannedRun>> {
    return this.httpClient.get<IApiResponseWrapper<any>>(url).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getLaneComment(url: string): Observable<V2CommentList> {
    return this.httpClient.get<V2CommentList>(url).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  /**
   * fetches data to display Library Pools on run Settings page
   *
   */
  getRunSettingsLibraryPool(url: string): Observable<IApiResponseWrapper<any>> {
    return this.httpClient.get<IApiResponseWrapper<any>>(url).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getProjectCapabilities(id: string, projectCapabilityType: ProjectCapabilityType = ProjectCapabilityType.TRASH): Observable<IApiResponseWrapper<IProjectCapabilities>> {

    let requestUrl = BsApiEndPoints.getProjectCapabilitiesUrl(id);
    if (projectCapabilityType != null) {
      requestUrl = `${requestUrl}?projectCapabilityType=${projectCapabilityType.toString()}`;
    }

    return this.httpClient.get<IApiResponseWrapper<IProjectCapabilities>>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  createAppSession(params: any): Observable<IAppSession> {
    const requestUrl = BsApiEndPoints.ANALYSES_URL;
    return this.httpClient.post<any>(requestUrl, params);
  }

  updateAppSession(params: IAppSession): Observable<IAppSession> {
    const requestUrl = BsApiEndPoints.getAppSessionUrl(params.Id);
    return this.httpClient.post<any>(requestUrl, params);
  }

  getAppSession(appSessionId): Observable<IAppSession> {
    const requestUrl = BsApiEndPoints.getAppSessionUrl(appSessionId);
    return this.httpClient.get<any>(requestUrl).pipe(
      map((response: any) => response.Response)
    );
  }

  /**
   * fetches a list of cms content entries
   * @param contentGroupName name of the content group
   */
  getCmsContentList(contentGroupName: CmsGroupName, offset: number, limit: number,
    sortBy: 'Name' | 'ContentType' | 'DateModified' | 'Revision' | 'Status' = 'DateModified',
    sortDir: 'Asc' | 'Desc' = 'Desc'): Observable<IApiResponseWrapper<any>> {
    const params = {
      limit: String(limit),
      offset: String(offset),
      sortBy: sortBy,
      sortDir: sortDir
    };
    const requestUrl = BsApiEndPoints.getCmsContentsUrl(contentGroupName);
    return this.httpClient.get<IApiResponseWrapper<any>>(requestUrl, { params }).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }
  /**
   * Makes an api call to update the biosample registry setting for the user.
   * @param userId current user Id
   * @param V2BioSampleRegistryEnabled corresponds to settings tab app-version switcher 'New' mode
   */
  setUserV2BiosampleRegistry(userId: string, V2BioSampleRegistryEnabled: boolean): Observable<null> {
    const requestUrl = BsApiEndPoints.postV2UsersIdV2biosampleregistryApiUrl(userId);
    const params = {
      V2BioSampleRegistryEnabled,
    };
    return this.httpClient.post<null>(requestUrl, params).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  /**
  * Makes an api call to update the Analysis Configuration Template setting for the user
  * @param userId Current User ID
  * @param V2AnalysisConfigTemplateEnabled corresponds to settings tab Advanced LIMS Run Planning switcher 'On'
  * @returns
  */
  setAnalysisConfigurationTemplateToggle(userId: string, V2AnalysisConfigTemplateEnabled: boolean): Observable<null> {
    const requestUrl = BsApiEndPoints.postV2UsersIdAnalysisConfigTemplateRequestApiUrl(userId);
    const params = {
      V2AnalysisConfigTemplateEnabled
    };
    return this.httpClient.post<null>(requestUrl, params).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getFromResourceUrl(url): Observable<any> {

    return this.httpClient.get<any>(url).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getPlotOptions(runId: string, chartType: string): Observable<IApiResponseWrapper<any>> {
    const requestUrl = BsApiEndPoints.getPlotOptionsUrl(runId, chartType);

    return this.httpClient.get<IApiResponseWrapper<any>>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getPlotData(runId: string, requestParams: any, chartType: string): Observable<IApiResponseWrapper<any>> {
    const requestUrl = BsApiEndPoints.getPlotDataUrl(runId, chartType);
    const params = requestParams;

    return this.httpClient.get<IApiResponseWrapper<any>>(requestUrl, { params }).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  cancelLabRequeue(url): Observable<V2LabRequeueCompact> {
    return this.httpClient.post<V2LabRequeueCompact>(url, { Status: 'Canceled' });
  }

  public getAppsessionProperty(analyses: IAppSession,
    propertyName: string,
    propertyItemLimit: number): Observable<any> {

    return this.getAppsessionPropertyById(analyses.Id, propertyName, propertyItemLimit);
  }

  public getAppsessionPropertyById(appSessionId: string,
    propertyName: string,
    propertyItemLimit: number): Observable<any> {

    const requestUrl = BsApiEndPoints.getAppsessionPropertyApiUrl(appSessionId, propertyName);
    const params = {
      propertyItemLimit: String(propertyItemLimit),
    };

    return this.httpClient.get<any>(requestUrl, { params }).pipe(
      // Do not make an additional API call if any additional subscribers have subscribed later on
      shareReplay(1),
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  public getRunRequeueable(runId: string): Observable<IRequeueableResponse> {
    const requestUrl = BsApiEndPoints.getRunRequeueableUrl(runId);
    return this.httpClient.get<IRequeueableResponse>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  public validateSampleSheet(runId: string, sampleSheet: string) {
    const requestUrl = BsApiEndPoints.getValidateSamplesheetUrl(runId);
    const requestParams = { runId, sampleSheet };
    return this.httpClient.post<any>(requestUrl, requestParams).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  public requeueSampleSheet(runId: string, sampleSheet: string): Observable<any> {
    const requestUrl = BsApiEndPoints.getRequeueSamplesUrl(runId);
    const requestParams = {
      runId, sampleSheet
    };
    return this.httpClient.post<any>(requestUrl, requestParams).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  public getIcaDataDownloadUrl(dataUrn: string): Observable<any> {
    const requestUrl = BsApiEndPoints.getIcaDataDownloadUrl();
    const requestParams = {
      urn: dataUrn
    };
    return this.httpClient.post<any>(requestUrl, requestParams).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getAppsessionAppResults(appSessionId: string, requestParams: any): Observable<ISubDirectoryApiResponseWrapper<any>> {
    const requestUrl = BsApiEndPoints.getAppsessionAppResultsApiUrl(appSessionId);
    const params = requestParams

    return this.httpClient.get<ISubDirectoryApiResponseWrapper<any>>(requestUrl, { params }).pipe(
      // Do not make an additional API call if any additional subscribers have subscribed later on
      shareReplay(1),
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getAppSessionReports(appSessionId: string): Observable<IApiResponseWrapper<any>> {
    const requestUrl = BsApiEndPoints.getAppSessionReport(appSessionId);
    return this.httpClient.get<IApiResponseWrapper<any>>(requestUrl).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getDataSets(appSessionId: string, requestParams: any): Observable<V2FilesList> {
    const requestUrl = BsApiEndPoints.getDataSetApiUrl();
    const params = requestParams

    return this.httpClient.get<V2FilesList>(requestUrl, { params }).pipe(
      // Do not make an additional API call if any additional subscribers have subscribed later on
      shareReplay(1),
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getDatasetType(datasetTypeId: string): Observable<IDatasetType> {
    const requestUrl = BsApiEndPoints.getDatasetType(datasetTypeId);

    return this.httpClient.get<IDatasetType>(requestUrl).pipe(
      // Do not make an additional API call if any additional subscribers have subscribed later on
      shareReplay(1),
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getFileSets(appSessionId: string, fileSetId: string, requestParams: any): Observable<IApiListResponseWrapper<V1pre3FileCompact>> {
    const requestUrl = BsApiEndPoints.getFileSetApiUrl(appSessionId, fileSetId);

    return this.httpClient.get<IApiListResponseWrapper<V1pre3FileCompact>>(requestUrl, { params: requestParams }).pipe(
      // Do not make an additional API call if any additional subscribers have subscribed later on
      shareReplay(1),
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getFileContent(fileId: string, redirect?: string): Observable<any> {
    const requestUrl = BsApiEndPoints.getFileContentUrl(fileId, redirect);

    return this.httpClient.get(requestUrl, { responseType: 'text' }).pipe(
      // Do not make an additional API call if any additional subscribers have subscribed later on
      shareReplay(1),
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

  getAppResultById(appResultId: number, requestParams: any): Observable<ISubDirectoryApiResponseWrapper<any>> {
    const requestUrl = BsApiEndPoints.getAppResultApiUrl(appResultId);
    const params = requestParams

    return this.httpClient.get<ISubDirectoryApiResponseWrapper<any>>(requestUrl, { params }).pipe(
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(observableEmitDelay),
    );
  }

}




export interface IWebserverApiWarning {
  Message: string;
  ErrorCode: number;
}

export interface IWebserverApiError {
  ErrorCode: number;
  ParameterName: string;
  ErrorMessage: string;
}

export interface ISampleMergeResponse {
  warnings: IWebserverApiWarning[];
  errors: IWebserverApiError[];
  action: string;
  suggestedName?: string;
}

export enum ApplicationDataContentGroup {
  ApplicationPromoContent = 'applications-promo-content'
}

export type CmsGroupName = ApplicationDataContentGroup | DemoDataContentGroup;
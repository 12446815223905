import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { isNullOrUndefined } from 'util';
import IResource from '../../../core/model/resource';
import { ResourceType } from '../../../core/model/resource-type';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';
import { ArchiveOrRestoreActionModalInput } from '../model/action-modal';
import { IArchiveOrRestoreResource } from '../model/archive-or-restore-resource';
import { IErrorItem } from '../../../core/bsshapi/api-wrappers';
import { BytesPipe } from '../../../core/utilities/pipes/bytes.pipe';

@Component({
  selector: 'app-restore-modal',
  templateUrl: './restore-modal.component.html',
  styleUrls: ['./restore-modal.component.scss']
})
export class RestoreModalComponent extends BaseModalComponent implements AfterViewInit, OnInit {

  resourceType: ResourceType = null;
  resourceList: IArchiveOrRestoreResource[] = [];
  resourceIdentifierTitle: string = null;
  resourceSizeTitle: string = null;
  actionMsg: string = ModalTexts.RESTORE.ACTION_MESSAGE;
  alertMsg: string = '';
  totalSizeOfResources: string = null;

  modalType = 'InitiateRestoreModal';
  ngxSmartModalTitle: string = ModalTexts.RESTORE.MODAL_TITLE;
  confirmButtonText: string = ModalTexts.RESTORE.CONFIRM_BUTTON_TEXT;


  constructor(public ngxSmartModalService: NgxSmartModalService, public _bytePipe: BytesPipe) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setUpInputSubscriber();
  }

  constructError(error: any): string | string [] {

    this.actionMsg = null; // not needed after api error
    if (error.data && Array.isArray(error.data)) {
      this.resourceList = this.resourceList.filter(resource => {
        return this.isContainer(error.data, resource.Id);
      });
      this.resourceList = this.setHasIssues(this.resourceList);
      this.totalSizeOfResources = this.getTotalSizeOfResources();
    }

    if (!isNullOrUndefined(error.errorMessage)) {
      return error.errorMessage;
    }

    if (isNullOrUndefined(error.errorCode)) {
      return ModalTexts.RESTORE.ERROR;
    }

    return '';
  }

  // TODO: identical code in ArchiveModalComponent - refactor later
  private getTotalSizeOfResources() {
    let totalSize = 0;
    this.resourceList.forEach(resource => {
      totalSize += resource.TotalSize;
    });
    return this._bytePipe.transform(totalSize, 2);
  }

  private setHasIssues(resources: IArchiveOrRestoreResource[]) {
    resources.forEach(resource => {
      resource.hasIssues = true;
    });
    return resources;
  }

  private isContainer(resources: IResource[], resourceId: string) {
    return resources.some(res => res.Id === resourceId);
  }

  private setUpInputSubscriber() {
    this.subs.sink = this.data.subscribe(res => {
      if (res) {
        const response: ArchiveOrRestoreActionModalInput = res as ArchiveOrRestoreActionModalInput;
        this.resourceType = response.resourceType;
        this.resourceList = response.resourceList;
        if (this.resourceList) {
          this.setVisibiltyForConfirmButton();
        }
        this.resourceIdentifierTitle = response.resourceIdentifierTitle;
        this.resourceSizeTitle = response.resourceSizeTitle;
        if (response.actionMsg) {
          this.actionMsg = response.actionMsg;
        }
        this.alertMsg = response.alertMsg;
        this.totalSizeOfResources = response.totalSizeOfResources;
      }
    });
  }

  private setVisibiltyForConfirmButton() {
    this.disableConfirmButton = !this.resourceList.some(res => !res.hasIssues);
  }

}

import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';
import { BannerNotificationsContainerDirective, BannerNotificationsService, IFilePickerComponentOutput, NgxSmartModalService, ProgressComponent } from '@bssh/comp-lib';
import { IV2ManifestImportResponse } from '../../../core/bsshapi/api-wrappers';
import { IBiosampleUploadModalInput, IBiosampleUploadModalOutput } from '../model/action-modal';
import { Constants } from '../../../core/utilities/constants';
import { SassHelperComponent } from '@app/core/utilities/sass-helper/sass-helper.component';

@Component({
  selector: 'app-biosample-workflow-upload-modal',
  templateUrl: './biosample-workflow-upload-modal.component.html',
  styleUrls: ['./biosample-workflow-upload-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BiosampleWorkflowUploadModalComponent extends BaseModalComponent implements OnInit {

  alertMsg: string = null; // set to proper msg if selected lanes are not appropriate for status change
  confirmButtonText: string = 'Continue';

  noOfFilesAllowed: number = 1;
  validExtensionsPattern: RegExp = /(\.txt|\.csv)$/i;

  modalType = 'InitiateBiosampleUploadModal';
  ngxSmartModalTitle: string;
  analysisWorkflowText: string;
  biosampleWorkflowText: string;
  validationFailedError: string = null;
  validationSuccessMsg: string = null;
  csvFormatMessage: string = null;

  csvFile: File = null;
  uploadResultData: IV2ManifestImportResponse = null; // validation result

  modalClass: string = 'modal-dialog-centered'; // ngx-smart-modal class

  analysisWorkflowUrl: string;
  biosampleWorkflowUrl: string;
  fastqReqUrl: string;
  csvFormatTemplate: string;
  public adaptivePosition = false;

  public activeWrapper = false;

  @ViewChild(BannerNotificationsContainerDirective, { static: false }) bannerNotificationsContainer: BannerNotificationsContainerDirective;
  @ViewChild('progressBar', { static: false }) public progressBar: ProgressComponent;
  @ViewChild('sassHelper', { static: true }) public sassHelper: SassHelperComponent;

  constructor(public ngxSmartModalService: NgxSmartModalService, private banner: BannerNotificationsService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setUpInputSubscriber();
    this.initModalProperties();
  }

  // method to receive data after file validation has happened on backend
  private setUpInputSubscriber() {
    this.subs.sink = this.data.subscribe((response: IBiosampleUploadModalInput) => {
      if (response) {
        this.uploadResultData = response.manifestUploadResponse;

        // increase the size of modal to display validator csv table
        this.modalClass = 'modal-dialog-centered-large';
        this.modal.addCustomClass(this.modalClass);

        if (this.uploadResultData.TotalErrors > 0) {
          this.disableConfirmButton = true;
          this.validationFailedError = this.constructValidationError(this.uploadResultData);
          // invalidate data -- user has to upload a new file by opening the modal again
          this.csvFile = null;
          this.modal.setData(null, true);
        } else {
          const output: IBiosampleUploadModalOutput = this.modal.getData();
          // Data has been already validated once. Update for final submission
          output.preview = false;
          this.modal.setData(output);
          this.validationSuccessMsg = this.constructValidationSuccessMsg();
          this.disableConfirmButton = false;
        }
        this.setValidationSuccessOrFailureBannerMsg();
        this.isLoadingApiData$.next(false);
      }
    });
  }

  private setValidationSuccessOrFailureBannerMsg() {
    if (this.isLoadingApiData$.getValue() === false && !this.errorMsg && this.uploadResultData) {
      // had to use setTimeout as it takes some time for this.bannerNotificationsContainer to be valid
      setTimeout(() => {
        this.banner.toasts = [];
        this.banner.clear();
        this.banner.overlayContainer = this.bannerNotificationsContainer;

        if ((this.uploadResultData.TotalErrors > 0) && !this.csvFile) {
          // set error
          this.banner.error(this.validationFailedError, null,
            { closeButton: false, positionClass: 'full-width-banner', enableHtml: true });
        }
        if ((this.uploadResultData.TotalErrors === 0) && this.csvFile) {
          // set success
          this.banner.success(this.validationSuccessMsg, null,
            { closeButton: true, positionClass: 'full-width-banner', enableHtml: true });
        }
      });
    }
  }

  onFilesSelected(filePickerOutput: IFilePickerComponentOutput) {
    if (filePickerOutput.errors && filePickerOutput.errors.length >  0) {
      return;
    }
    this.progressBar.start();
    setTimeout(() => {
      this.csvFile = filePickerOutput.selectedFiles[0];
      const output: IBiosampleUploadModalOutput = {
        preview: true,
        biosampleCsvFile: filePickerOutput.selectedFiles[0]
      };
      this.modal.setData(output);
      this.progressBar.complete();
    }, 1000);
  }

  onProgressBarCompleted($event: any)
  {
    // submit with preview true first time , after confirmation submit with preview false
      this.confirm.emit(this.modal.getData());
      this.isLoadingApiData$.next(true);
  }

  private constructValidationError(uploadResultData: IV2ManifestImportResponse): string {
    const totalErrors = uploadResultData.TotalErrors.toString();
    const totalWarnings = uploadResultData.TotalWarnings.toString();
    return ModalTexts.UPLOAD_BIOSAMPLE_WORKFLOW.VALIDATION_ERROR(totalErrors, totalWarnings);
  }

  private constructValidationSuccessMsg(): string {
    return ModalTexts.UPLOAD_BIOSAMPLE_WORKFLOW.VALIDATION_SUCCESS_MSG;
  }

  private initModalProperties() {
    // confirm button is diabled till validation
    this.disableConfirmButton = true;
    this.csvFormatMessage = ModalTexts.UPLOAD_BIOSAMPLE_WORKFLOW.CSV_FORMAT_TEXT;
    this.ngxSmartModalTitle = ModalTexts.UPLOAD_BIOSAMPLE_WORKFLOW.MODAL_TITLE;
    this.analysisWorkflowText = ModalTexts.UPLOAD_BIOSAMPLE_WORKFLOW.ANALYSIS_WORKFLOW_TEXT;
    this.biosampleWorkflowText = ModalTexts.UPLOAD_BIOSAMPLE_WORKFLOW.BIOSAMPLE_WORKFLOW_TEXT;

    this.analysisWorkflowUrl = Constants.analysisWorkflowUrl;
    this.biosampleWorkflowUrl = Constants.biosampleWorkflowUrl;
    this.fastqReqUrl = Constants.fastqReqUrl;
    const cdnPath= this.sassHelper.readProperty('cdn-path').replace(/['"]+/g, '').trim();
    this.csvFormatTemplate = cdnPath + Constants.csvFormatTemplate;
  }
}

<div #InitiateCreateResourceDiv>
    <ngx-smart-modal #InitiateCreateResourceModal
        [identifier]="modalType"
        [title]="ngxSmartModalTitle"
        closable="true"
        showCloseButton="true" 
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButton" 
        [confirmButtonText]="confirmButtonText">

        <app-edit-info
            [editableFields]="fields"
            (confirm)="dataChanged($event)"
            [errorMsg]="errorMsg?.toString()">
        </app-edit-info>

    </ngx-smart-modal>
</div>

import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { SearchQueryService } from '@app/core/services/search/resource-search/search-query.service';
import { RunSearchColumnFields } from '../gridOptions/run-search-grid-options-formatter';
import { ISearchDataSourceOptions, SearchDataSource } from './search.datasource';

export class RunSearchDataSource extends SearchDataSource {
    constructor(
        searchQueryService: SearchQueryService,
        resourceDictionaryService: SearchResourceDictionaryService,
        dataSourceOptions: ISearchDataSourceOptions
    ) {
        const runResourceDictionary = resourceDictionaryService.getResourceConfigByType(SearchResourceType.Run);
        super(searchQueryService, runResourceDictionary, dataSourceOptions);
    }

    get rawTextFilterColumnName(): string {
        return RunSearchColumnFields.Name;
    }
}
import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  Input,
  Output,
  QueryList,
  ViewChild,
  EventEmitter,
  ViewEncapsulation,
  Injector,
  OnDestroy
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { ComboBoxDropdownService } from "./dropdown/dropdown.service";
import { ComboBoxDropdownComponent } from "./dropdown/dropdown.component";
import { ComboBoxOptionComponent } from "./option/option.component";
import { Observable, of } from 'rxjs';
import { scrollItemIntoView, KEY_ENTER, KEY_UP, KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_ESCAPE, KEY_SPACE, ENTER_KEY, KEY_TAB } from '@app/cloud-run-prep/constants';
import { ComboBoxFilterPipe } from "./comboBoxFilterPipe.pipe";
import { SubSink } from "subsink";
import { isEmpty } from "lodash";

const CLASS_MAP: any = {
  ComboBox: ""
};

@Component({
  selector: "combobox",
  templateUrl: "./combobox.component.html",
  styleUrls: ["./combobox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxComponent),
      multi: true
    },
    ComboBoxDropdownService
  ],
  encapsulation: ViewEncapsulation.None
})
export class ComboBoxComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {

  constructor(
    private dropdownService: ComboBoxDropdownService,
    public elementRef: ElementRef,
    private injector: Injector
  ) {
    this.dropdownService.register(this);
    this.inputRef = elementRef;
    const type = this.inputRef.nativeElement.type;
    this.inputRef.nativeElement.classList.add(CLASS_MAP[type]);
  }
  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  public onBlur: EventEmitter<any> = new EventEmitter();

  @Output()
  public change: EventEmitter<any> = new EventEmitter();

  @Input()
  public searchMode = false;

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public selected: any;

  @Input()
  public required = false;

  @Input()
  public disabled = false;

  @Input()
  public contentClass: string = "";

  @Input()
  public customizedDisplayText: string = "";

  @Input()
  public enableDeleteBtn = false;

  @Input()
  public isLoading = false;

  /**
   * Add function to check before proceeding change
   * Return value to indicate if should proceed or not
   */
  @Input()
  public onBeforeChange?: ($event) => Observable<boolean>;

  @Input()
  public onAfterChange?: () =>  void;

  @ViewChild("input", { static: true })
  public input: ElementRef;

  @ViewChild(ComboBoxDropdownComponent, { static: true })
  public dropdown: ComboBoxDropdownComponent;

  @ContentChildren(ComboBoxOptionComponent,  {descendants: true})
  public options: QueryList<ComboBoxOptionComponent>;

  public selectedOption: ComboBoxOptionComponent;

  public defaultContentClass = "input ComboBox__content";

  private currentActiveIndex = -1;

  private subs = new SubSink();

  private _value: string;

  public displayText: string;

  public searchText: string;

  public inputRef: ElementRef;

  public control: NgControl;

  public onChangeFn = (_: any) => { };

  public onTouchedFn = () => { };

  public get inputValue() {
    const displayingText = this.customizedDisplayText? this.customizedDisplayText: this.displayText;
    return (this.dropdown.showing && this.searchMode)?
      (this.searchText === null ? displayingText : this.searchText) :
      displayingText;
  }

  public set inputValue(val) {
    if (this.dropdown.showing && this.searchMode) {
      this.searchText = val;
    }
  }

  public get config() {
    return {
      maxHeight: '24rem',
      height: 'auto',
      hasBackdrop: !this.enableDeleteBtn
    }
  }

  ngOnInit(): void {
    this.contentClass =
      this.contentClass === ""
        ? this.defaultContentClass
        : `${this.defaultContentClass} ${this.contentClass}`;
    this.control = this.injector.get(NgControl);
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      if (!this.isLoading) {
        this.selectedOption = this.options
          .toArray()
          .find(option => option.value === this.selected);
        if (!isEmpty(this.selected) && !this.selectedOption) {
          this.displayText = this.selected = this._value = "";
          this.onChange();
        } else {
          this.displayText = this.selectedOption ? this.selectedOption.label : "";
        }
      }
   });
    // When options are changed and the old value doesn't exits in the new option list
    // Clear the value
    this.subs.sink = this.options.changes.subscribe((newList) => {
      setTimeout(() => {
        if (this.selected) {
          const selectedOption = newList.find(option => option.value === this.selected);
          if(this.searchMode && this.dropdown.showing) {
            // Reset the style after filtering
            newList.forEach(item => item.active && !item.selected && item.setInactiveStyles());
            // In active search mode
            if (selectedOption) {
              this.selectedOption = selectedOption;
              this.currentActiveIndex = this.getNavigableOptions().indexOf(selectedOption);
              this.scrollToOption(false, selectedOption)
            } else {
              this.currentActiveIndex = -1;
              this.scrollToOption(true);
            }
          } else {
            if (!selectedOption) {
              this.displayText = this.selected = this._value = "";
            } else {
              // When the valid formValue arrives before the option list, update display text
              this.selectedOption = selectedOption;
              this.displayText = this.selectedOption.label;
            }
            this.onChange();
          }
        }

        // in cdk overlay, it will get content height to check if it fits in current view
        // when options are dynamic e.g. options loaded after calling dropdown.show()
        // height was returned 0 hence in view detection is not working
        if(this.dropdown.showing) {
          this.dropdown.updatePosition();
        }
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public toggleDropdown() {
    if(this.searchMode) {
      this.input.nativeElement.readOnly = false;
      if (!this.dropdown.showing) {
        this.searchText = null;
        this.input.nativeElement.select();
      }
    }

    if(this.dropdown.showing) {
      this.dropdown.hide();
      return;
    }

    this.dropdown.show();

    setTimeout(() => {
      // use setTimeOut to adjust position after it shows
      this.dropdown.updatePosition();
    }, 0);
    if (!this.options.length) {
      return;
    }

    // Clear highlighted item when showing
    if(this.currentActiveIndex > -1) {
      this.options.forEach(item => item.active && item.setInactiveStyles());
      this.currentActiveIndex = -1;
    }

    // Shouldn't highlight if nothing is selected, or if the selected option is no longer inside the list
    if (this.selectedOption) {
      let index = this.getNavigableOptions().findIndex(x => x.value === this.selectedOption.value);
      if (index != -1) {
        this.setActive(index);
        this.currentActiveIndex = index;
        setTimeout(() => this.scrollToOption());
      }
    }
  }

  public hideDropdown() {
    this.onTouchedFn();
    this.dropdown.hide();
    if (this.searchMode) {
      this.searchText = null;
    }
    this.input.nativeElement.readOnly = true;
  }

  public onDropMenuIconClick(event: UIEvent) {
    event.stopPropagation();

    setTimeout(() => {
      this.input.nativeElement.focus();
      this.input.nativeElement.click();
    }, 10);
  }

  public onDeleteIconClick(event: UIEvent) {
    const decision$ = this.onBeforeChange
      ? this.onBeforeChange({ newValue: null, oldValue: this.selectedOption })
      : of(true);

    decision$.subscribe(proceed => {
      if (proceed) {
        if (this.currentActiveIndex) {
          this.setInActive(this.currentActiveIndex);
        }
        if (this.searchMode) {
          this.searchText = null;
        }
        this.onTouchedFn();
        this.writeValue(null);
        this.onChange();
      }
    });
  }

  public onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === KEY_TAB) {
      // specific handling for tab
      if (this.dropdown.showing) {
        this.hideDropdown();
      }
      return;
    }
    if (
      this.searchMode ||
      [
        KEY_SPACE,
        KEY_ENTER,
        KEY_UP,
        KEY_DOWN,
        KEY_LEFT,
        KEY_RIGHT,
        KEY_ESCAPE
      ].includes(event.keyCode)
    ) {
      if (!this.dropdown.showing) {
        this.toggleDropdown();
        return;
      }

      if (!this.options.length) {
        if (this.searchMode && [KEY_ENTER, KEY_ESCAPE].includes(event.keyCode)) {
          this.hideDropdown();
        } else if (!this.searchMode) {
          event.preventDefault();
        }
        return;
      }
    }

    if (event.keyCode === KEY_ESCAPE) {
      this.dropdown.showing && this.hideDropdown();
    } else if (
      event.keyCode === KEY_DOWN ||
      (!this.searchMode && event.keyCode === KEY_RIGHT)
    ) {
      this.moveNext();
      event.preventDefault();
    } else if (
      event.keyCode === KEY_UP ||
      (!this.searchMode && event.keyCode === KEY_LEFT)
    ) {
      this.movePrevious();
      event.preventDefault();
    } else if (event.keyCode === KEY_ENTER || (!this.searchMode && event.keyCode === KEY_SPACE)) {
      this.selectOption(this.getActiveOption());
      event.preventDefault();
    }
  }

  public selectOption(option: ComboBoxOptionComponent) {
    this.hideDropdown();
    // Check if any function needs to be executed before change
    if (this.onBeforeChange) {
      this.onBeforeChange({ newValue: option, oldValue: this.selectedOption }).subscribe((result: boolean = false) => {
        if (result) {
          this.updateSelection(option);
        }
      });
    } else {
      this.updateSelection(option);
    }
  }

  private updateSelection (option: ComboBoxOptionComponent) {
    if (!option) {
      this.hideDropdown();
      return;
    }
    this.selected = option.value;
    this.selectedOption = option;
    this.displayText = this.selectedOption ? this.selectedOption.label : "";
    this.hideDropdown();
    this.onChange();
    // Check if any function needs to be executed after change
    if(this.onAfterChange) {
      this.onAfterChange();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: any): void {
    if (value) {
      this.selected = value;
      this.selectedOption = this.options ? this.options.toArray().find(option => option.value === this.selected) : null;
      this.displayText = this.selectedOption ? this.selectedOption.label : "";
    } else {
      this.selected = this.displayText = "";
      this.selectedOption = null;
    }
  }

  public onTouched($event) {
    this.onTouchedFn();
    this.onBlur.emit($event);
  }

  public onChange() {
    this.onChangeFn(this.selected);
    this.change.emit({ option: this.selectedOption });
  }

  /**
   * Search for the first child div in the host component
   */
  findFirstDivChild(): HTMLDivElement {
    const parent: HTMLElement = this.elementRef.nativeElement;
    return parent.querySelector('div');
  }

  private moveNext() {
    const options = this.getNavigableOptions();
    this.setInActive(this.currentActiveIndex);
    this.currentActiveIndex++;

    let scrollToTop = false;
    if (this.currentActiveIndex >= options.length) {
      this.currentActiveIndex = 0;
      scrollToTop = true;
    }

    this.setActive(this.currentActiveIndex);
    this.scrollToOption(scrollToTop);
  }

  private movePrevious() {
    const options = this.getNavigableOptions();
    this.setInActive(this.currentActiveIndex);
    this.currentActiveIndex--;

    const optionContainer = this.dropdown.getOverlayRef()
    .hostElement.querySelector(".os-viewport");

    let scrollToTop = false;

    if(this.currentActiveIndex < 0 && optionContainer.scrollTop > 0) {
      // If item index is the first item, and the container is not scrolled to top
      // Should stay focus to selected item, and scroll the container to the top
      this.currentActiveIndex = 0;
      scrollToTop = true;
    } else if (this.currentActiveIndex < 0) {
      this.currentActiveIndex = options.length - 1;
    }

    this.setActive(this.currentActiveIndex);
    this.scrollToOption(scrollToTop);

  }

  private scrollToOption(scrollToTop: boolean = false, option: any = null) {
    const activeOption = option || this.getNavigableOptions()[this.currentActiveIndex];
    if (activeOption && activeOption.el && activeOption.el.nativeElement) {
      let target = activeOption.el.nativeElement;
      scrollItemIntoView(target);
    }

    if (scrollToTop) {
      // Should show option header when the first item is highlighted
      const optionContainer = this.dropdown.getOverlayRef()
        .hostElement.querySelector(".os-viewport");
      optionContainer.scrollTop = 0;
    }
  }

  private setInActive(index: number) {
    if (index === -1) {
      return;
    }

    const option = this.getNavigableOptions()[index];
    if(option){
      option.setInactiveStyles();
    }
  }

  private setActive(index: number | number) {
    const option = this.getNavigableOptions()[index];
    option && option.setActiveStyles();
  }

  private getActiveOption(): ComboBoxOptionComponent {
    const options = this.getNavigableOptions();
    if(options.length === 1) {
      // If only one items available
      return options[0];
    }
    // After filtering, currentActiveIndex may be incorrect
    return options[this.currentActiveIndex];
  }

  private getNavigableOptions(): Array<ComboBoxOptionComponent> {
    const choosableOptions = this.options.filter(x => !x.isHeader && !x.isCustomLink);
    return choosableOptions;
  }
}


import { IApplicationCompact } from "./application";
import { IUser } from "../user";
import IResource from '../resource';
import { IComputeStatistics } from "../compute-statistics";

export interface IAppSession extends IResource {
    Application?: IApplicationCompact;
    Children?: IAppSession[];
    UserCreatedBy?: IUser;
    Status?: string;
    ExecutionStatus?: string;
    QcStatus?: string;
    StatusSummary?: string;
    Purpose?: string;
    DateStarted?: string;
    DateCompleted?: string;
    DeliveryStatus?: string;
    ContainsComments?: boolean;
    AppSessionParent?: IAppSession;
    AppSessionRoot?: IAppSession;
    HrefComments?: string;
    HrefLogs?: string;
    HrefIcaAnalysis?: string;
    ComputeStatistics?: IComputeStatistics;
    RunningDuration?: number;
}

// API execution statuses. If you want display friendly names then refer to:
// enum AnalysisStatus
export enum AppSessionExecutionStatus {
    
        Undefined, 
        Aborted, 
        Running,
        NeedsAttention,
        Complete,
        TimedOut, 
        AwaitingAuthorization, 
        PendingPayment, 
        PaymentComplete, 
        PaymentAborted,
        Aborting,
        PendingExecution,
        Initializing,
        Pending,
        Canceled,
    
}

export type SortBy = "Name" | "Id" | "AppId" | "ExecutionStatus" | "ModifiedOn" | "DateCreated" | "AppName" | "TotalSize" | "QcStatus" | "DeliveryStatus"; 
export type SortDir = "Desc" | "Asc"
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { IUserAgreement, AgreementCategory, AgreementStatus } from '@app/core/model/user-agreements/user-agreements';
import { BaseService } from '../../base.service';
import { BasespaceService } from '@bssh/ng-sdk';
import { shareReplay, retryWhen, map, catchError } from 'rxjs/operators';
import { genericRetryWhen } from '@app/core/rxjsutils/rxjs-utilities';
import { BsMetaDataService } from '../meta/bs-meta-data.service';
import { HttpUtilityService } from '../../http-utility/http-utility.service';


export interface IBsUserAgreementsService {
  getPendingAgreements(category: AgreementCategory): Observable<IUserAgreement[]>;
  checkForSignedUserTCAgreement(): Observable<boolean>;
  redirectToTCAgreementsPage(): void;
}


/**
 * A service to interact with BSSH Agreements API
 */
@Injectable({
  providedIn: 'root'
})
export class BsUserAgreementsService extends BaseService implements IBsUserAgreementsService {

  private tcAgreementsCategoryTitle = 'main website terms and conditions agreement';

  private readonly hasSignedTcAgreementSubject = new ReplaySubject<boolean>(1);

  /**
   * An Observable which indicates whether the user is authenticated
   */
  public hasSignedTcAgreement$ = this.hasSignedTcAgreementSubject.asObservable();

  private hasSignedTcAgreement = false;

  constructor(private basespaceApi: BasespaceService,
              private bsMetaDataService: BsMetaDataService,
              private httpUtilityService: HttpUtilityService) {
    super();
  }

  /**
   * gets the pending user agreements for a given Category
   * @param category The Category
   */
  getPendingAgreements(category: AgreementCategory): Observable<IUserAgreement[]> {
    const pendingAgreementsRequest = this.getAgreementsApiRequest(AgreementStatus.PENDING, category);
    return this.basespaceApi.GetV2Useragreements(pendingAgreementsRequest).pipe(
      shareReplay(1),
      retryWhen(genericRetryWhen()),
      map(apiResponse => {
        return apiResponse.Items;
      })
    );
  }

  /**
   * Checks if the user has signed the main website Terms and conditions(TC) agreement.
   */
  checkForSignedUserTCAgreement(): Observable<boolean> {
    return this.getPendingAgreements(AgreementCategory.USER).pipe(
      map(pendingAgreements => {
        if (pendingAgreements != null && pendingAgreements.length > 0) {
          const hasSigned = pendingAgreements.some(agreement => agreement.Status.toLowerCase() === AgreementStatus.SIGNED &&
            agreement.Agreement.Title.toLowerCase() === this.tcAgreementsCategoryTitle);
          this.hasSignedTcAgreementSubject.next(hasSigned);
          return hasSigned;
        }
        return true;
      }),
      catchError((error) => {
        // Probably better to just return true instead of defaulting to a re-direct.
        return of(true);
      })

    );
  }

  /**
   * Redirects to TC agreements page.
   */
  redirectToTCAgreementsPage() {
    // To do remove after proxy set up is complete
    // since a Nginx proxy is not fully in place, we are using two domains(one for new website, another for old)
    // Once all the proxying is set up, getting the main website url from meta data api will not be necessary
    // It should be as simple as getting the base url from the current request url(using document api).
    this.bsMetaDataService.getBasespaceAppMetadata().subscribe({
      next: (metaData) => {
        const userTCAgreementUrl = this.getTCAgreementAcceptUrl(metaData.Response.HrefOAuthAuthorizeDialog);
        this.httpUtilityService.redirectToUrl(userTCAgreementUrl);
      }
    }
    );
  }

  setUserTCSignedStatus(): void {
    if (!this.hasSignedTcAgreement) {
      this.hasSignedTcAgreement = true;
      this.hasSignedTcAgreementSubject.next(this.hasSignedTcAgreement);
    }
  }

  /**
   *
   * @param status The agreement status
   * @param agreementCategory The agreement category
   * @param appId (optional) The application Id.
   */
  public getAgreementsApiRequest(status: AgreementStatus, agreementCategory?: AgreementCategory, appId?: string) {
    return {
      include: [status.toString()],
      category: agreementCategory ? agreementCategory.toString() : null,
      applicationid: appId ? appId : null
    };
  }


  private getTCAgreementAcceptUrl(baseWebsiteUrl: string): string {
    const baseWebSiteUrl = this.httpUtilityService.getBaseUrl(baseWebsiteUrl);
    return `${baseWebSiteUrl}/agreements/accept?returnUrl=${this.httpUtilityService.getCurrentRequestUrl()}`;
  }

}

<div #TransferModalDiv data-cy="transfer-modal">
    <ngx-smart-modal #TransferModal
        [identifier]="modalType"
        [title]="modalTitle"
        [closeButtonText]="closeButtonText"
        [confirmButtonText]="confirmButtonText"
        [disableConfirmButton]="disableConfirmButton">

        <!-- to handle ivy tpyecheck issue, passing errorMsg.toString() -->
        <lib-share-data
            [projectTitle]="resourceName"
            [inputLabel]="inputLabel"
            [inputPlaceholder]="inputPlaceholder"
            [optionalMessageLabel]="optionalMessageLabel"
            [optionalMessagePlaceholder]="optionalMessagePlaceholder"
            [allowAddUser]="false"
            [alertMsg]="alertMessage"
            [errorMsg]="errorMsg?.toString()"
            (outputDataChange)="dataChanged($event)">
        </lib-share-data>

    </ngx-smart-modal>
</div>
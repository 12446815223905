import { Highlightable } from '@angular/cdk/a11y';
import { Component, HostBinding, HostListener, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { ComboBoxDropdownService } from '../dropdown/dropdown.service';
import { ComboBoxComponent } from '../combobox.component';
import { isNil } from 'lodash';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'combobox-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class ComboBoxOptionComponent implements Highlightable {

  @Input()
  public label: string;

  @Input()
  public value: string;

  @Input()
  public isHeader = false;

  @Input()
  public isCustomLink = false;

  @Output()
  public customLinkSelect: EventEmitter<UIEvent> = new EventEmitter();

  @HostBinding('class.header')
  public get isOptionHeaderOption(): boolean {
    return this.isHeader;
  }

  @HostBinding('class.selected')
  public get selected(): boolean {
    return this.select.selectedOption === this;
  }

  @HostBinding('class.active')
  public active = false;

  private select: ComboBoxComponent;

  constructor(private dropdownService: ComboBoxDropdownService, public el: ElementRef) {
    this.select = this.dropdownService.getComboBox();
  }

  public getLabel(): string {
    return this.label;
  }

  public setActiveStyles(): void {
    this.active = true;
  }

  public setInactiveStyles(): void {
    this.active = false;
  }

  @HostListener('click', ['$event'])
  public onClick(event: UIEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.isHeader && !isNil(this.value)) {
      this.select.selectOption(this);
    }

    if (this.customLinkSelect) {
      this.select.hideDropdown();
      this.customLinkSelect.emit(event);
    }

  }
}

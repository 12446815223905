import {Injectable, OnDestroy} from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import environment from '@environments/environment';
import {CurrentUserStore} from '@app/user/store/current-user/current-user.store';
import {SubSink} from 'subsink';
import {filter, finalize, switchMap, take, map} from 'rxjs/operators';
import moment from 'moment';
import {StringUtilities} from '../utilities/string-utilities';
import {get} from 'lodash';
import {CookieService} from 'ngx-cookie-service';
import {Constants} from '@app/core/utilities/constants';
import {IUser} from '@models/user';
import { InstrumentRunSetupTokenService } from '../services/stratus-api/instrument-run-setup-token/instrument-run-setup-token.service';
import { InstrumentRunSetupTokenRequest } from '../model/instrument-run-setup-token/instrument-run-setup-token-request';
import { InstrumentRunSetupTokenResponse } from '../model/instrument-run-setup-token/instrument-run-setup-token-response';
import { CurrentUserWorkgroupsStore } from '@app/user/store/current-user-workgroups/current-user-workgroups.store';

@Injectable()
export class GssHeaderInterceptor implements HttpInterceptor, OnDestroy {
  private URL_IAP_API: string = StringUtilities.trimTrailingSlash(environment.iapApiUrl);
  private URL_IPS_API: string = StringUtilities.trimTrailingSlash(environment.ipsApiUrl);
  private URL_STRATUS_PREFIX = `${this.URL_IAP_API}/v1/`;
  private URL_STRATUS_V2_PREFIX = `${this.URL_IAP_API}/v2/`;
  private URL_STRATUS_TOKEN = `${this.URL_IAP_API}/v1/instrumentRunSetup/tokens`;
  private URL_STRATUS_IPS = `${this.URL_IPS_API}/v1/performance`;
  private URL_INSTURMENT_TYPES = `${this.URL_IAP_API}/v1/sequencing/instrumentTypes`;

  private JWT_GSS = 'jwt.gss';
  private HTTP_HEADER_AUTH = 'Authorization';

  // Used to determine if request url is S3 presigned url
  private S3_presignedURL_regex = environment.preSignedUrlregex ? new RegExp(environment.preSignedUrlregex) : null;

  private subs = new SubSink();
  private currentUser: IUser;

  private isRefreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private instrumentRunSetupTokenService: InstrumentRunSetupTokenService,
    private userStore: CurrentUserStore,
    private userWorkgroupStore: CurrentUserWorkgroupsStore,
    private cookieService: CookieService
  ) {
    this.subscribeToUserContext();
  }

  /**
   * Keep track of currentUser stateChanges
   */
  subscribeToUserContext(): void {
    this.subs.sink = this.userStore.stateWithPropertyChanges.pipe(
      filter(stateWithPropertyChanges => get(stateWithPropertyChanges, 'stateChanges.currentUserId'))
    ).subscribe({
      next: (stateWithPropertyChanges) => {
        this.currentUser = stateWithPropertyChanges.stateChanges.currentUser;
        // Previous jwt is no longer valid on user context-switch
        this.purgeTokenCache();
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.purgeTokenCache();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // For CORS requests, when 'Access-Control-Allow-Origin' response header is '*' (allow all domains), as is the case with Stratus API,
    // the browsers do not allow credentials flag to be set. So, the credentials flag has to be false only for stratus apis
    // For BSSH APIs, we need credentials flag to be set, as we authenticate by cookies there
    // See https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials &
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials for more information
    // Check if the api is a stratus api.
    if (request.urlWithParams.includes(this.URL_IAP_API) || this.isS3PresignedUrl(request)) {
      request = request.clone({ withCredentials: false });
    }

    if (this.isStratusRequest(request) && !request.url.startsWith(this.URL_INSTURMENT_TYPES)) {
      const jwt = this.getTokenFromCache();
      if (this.isTokenExpired(jwt)) {
        if (this.isRefreshTokenInProgress) {
          return this.retryWhenRefreshTokenCompleted(next, request);
        } else {
          return this.refreshToken(next, request);
        }
      } else {
        request = this.addAuthenticationToken(request, jwt);
      }
    }

    return next.handle(request);
  }

  private refreshToken(next: HttpHandler, request: HttpRequest<any>) {
    this.isRefreshTokenInProgress = true;

    // Make subsequent API calls wait until the new token has been retrieved
    this.refreshTokenSubject.next(null);

    return this.userStore.stateWithPropertyChanges.pipe(
      filter(stateWithPropertyChanges => get(stateWithPropertyChanges, 'stateChanges.currentUserId')),
      switchMap(stateWithPropertyChanges => {
        this.currentUser = stateWithPropertyChanges.stateChanges.currentUser;
        return this.requestNewToken().pipe(
          switchMap(token => {
            this.refreshTokenSubject.next(token);
            return next.handle(this.addAuthenticationToken(request, token));
          }),
          finalize(() => this.isRefreshTokenInProgress = false)
        );
      }
    ));
  }

  private retryWhenRefreshTokenCompleted(next: HttpHandler, request: HttpRequest<any>) {
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addAuthenticationToken(request, token)))
    );
  }

  private requestNewToken(): Observable<any> {
    const psToken: string = this.getPsTokenFromCookies();
    // Workgroup jwt token flow: get workgroupId -> call token endpoint with workgroupId
    if (get(this.currentUser, 'IsWorkgroup')) {
      return this.userWorkgroupStore.getCurrentUserWorkgroup(this.currentUser.Id).pipe(
        // retrieve externalProvidedId for jwt token endpoint workgroup parameter
        switchMap(workgroup => of(workgroup.ExternalProviderId)),
        switchMap(workgroupId => this.getAccessTokenByPsToken(psToken, workgroupId)),
        map(resp => {
          this.saveTokenInCache(resp.token);
          return resp.token;
        })
      );
    }

    // Personal jwt token flow: extract uid from current user response -> retrieve personal token with uid param
    const uId = this.currentUser.PlatformId;
    return this.getAccessTokenByPsToken(psToken, null, uId).pipe(
      map(resp => {
        this.saveTokenInCache(resp.token);
        return resp.token;
      })
    );
  }

  private getAccessTokenByPsToken(psToken: string, workGroupId?: string, uId ?: string): Observable<InstrumentRunSetupTokenResponse> {
    this.instrumentRunSetupTokenService.defaultHeaders = this.instrumentRunSetupTokenService.defaultHeaders
      .set('Authorization', `Bearer ${psToken}`);

    const request: InstrumentRunSetupTokenRequest = {};
    if (uId) {
      request.mem = [`uid:${uId}`];
    } else if (workGroupId) {
      request.cwid = workGroupId;
      request.mem = [`wid:${workGroupId}`];
    }
    return this.instrumentRunSetupTokenService.createInstrumentRunSetupToken(request);
  }

 private addAuthenticationToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(this.HTTP_HEADER_AUTH, `Bearer ${token}`)
    });
  }

  private isTokenExpired(jwt: string): boolean {
    if (!jwt) {
      return true;
    }

    const decodedJwt: string = StringUtilities.fromBase64String(jwt.split('.')[1]);
    const expiryTime: number = StringUtilities.IsJsonString(decodedJwt) ? JSON.parse(decodedJwt).exp : 1;

    return (moment().isAfter(expiryTime * 1000));
  }

  private isStratusRequest(request: HttpRequest<any>) {
    // Stratus GSS request starts with v1/sequencing
    // Stratus GMS requests start with v1|v2/infinium
    // GDS request doesn't e.g. https://integration.stratus.illumina.com/v1/files?
    const isstratus =  (request.url.startsWith(this.URL_STRATUS_PREFIX)
                        || request.url.startsWith(this.URL_STRATUS_V2_PREFIX))
                      && !request.url.startsWith(this.URL_STRATUS_TOKEN)
                      && !request.url.startsWith(this.URL_STRATUS_IPS);
    return isstratus;
  }

  private isS3PresignedUrl(request: HttpRequest<any>) {
    if (!this.S3_presignedURL_regex) {
      // If regex is not set in configuration, return false for the checking
      return false;
    }
    // Check if it's calling Stratus S3 presigned URL for resource
    const isPresignedURL = request.method === 'GET' && this.S3_presignedURL_regex.test(request.url);
    return isPresignedURL;
  }

  private getPsTokenFromCookies(): string {
    return this.cookieService.get(Constants.Auth.authpsToken);
  }

  private getTokenFromCache(): string {
    return sessionStorage.getItem(`${this.JWT_GSS}`);
  }

  private saveTokenInCache(jwt: string): void {
    sessionStorage.setItem(`${this.JWT_GSS}`, jwt);
  }

  private purgeTokenCache(): void {
    sessionStorage.removeItem(`${this.JWT_GSS}`);
  }
}
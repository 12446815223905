<div class="dashed-border">
    <table class="bs-validated-csv" *ngIf="rows && rows.length">
        <tr>
            <th></th>
            <th *ngFor="let header of headerRow">{{header}}</th>
        </tr>
        <ng-container *ngFor="let row of rows; let i = index">
            <tr>
                <th>
                    {{i + 1}}
                    <span *ngIf="row.validationErrors.length > 0"
                            class="badge validation-error-badge">{{row.validationErrors.length}}</span>
                    <span *ngIf="row.validationWarnings.length > 0"
                        class="badge validation-warning-badge">{{row.validationWarnings.length}}</span>
                </th>
                <td *ngFor="let col of row.data">{{col}}</td>
            </tr>
            <tr *ngFor="let val of row.validationErrors">
                <td [attr.colspan]="row.data.length+1" class="validation-error-message"><span
                        role="alert">{{val.StatusMessage}}</span></td>
            </tr>
            <tr *ngFor="let val of row.validationWarnings">
                <td [attr.colspan]="row.data.length+1" class="validation-warning-message"><span
                        role="alert">{{val.StatusMessage}}</span></td>
            </tr>
        </ng-container>
    </table>
</div>

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ICollaborator } from '@app/core/model/collaborator';
import { ResourceType } from '@app/core/model/resource-type';
import { IShareModalInput, IShareModalOutput } from '@app/shared/modals/model/action-modal';
import { IOption, IUserListItem, NgxSmartModalService, ShareOutputData } from '@bssh/comp-lib';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent extends BaseModalComponent implements AfterViewInit, OnInit {
  modalTitle = ModalTexts.SHARE.MODAL_TITLE;
  modalType = 'SendInvitationModal';
  confirmButtonText = ModalTexts.SHARE.CONFIRM_BUTTON_TEXT;

  // share component parameters
  inputLabel = ModalTexts.SHARE.INVITE_COLLABORATOR;
  inputPlaceholder = ModalTexts.EMAIL;
  userListTitle = ModalTexts.SHARE.COLLABORATORS;
  optionalMessageLabel = ModalTexts.OPTIONAL_MESSAGE_LABEL;
  optionalMessagePlaceholder = ModalTexts.OPTIONAL_MESSAGE_PLACEHOLDER;
  associateResourceMessage = ModalTexts.ASSOCIATE_RESOURCE_MESSAGE;

  projectTitle = '';
  alertMessage = '';
  permissionOptions: IOption[];
  resourceType: ResourceType;
  includeAssociatedResources: boolean = true;
  disableConfirmButtonSubject: BehaviorSubject<boolean>;

  userList: IUserListItem[] = [];
  private existingCollaborators: ICollaborator[];

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    // Disabling the confirm button by default, it will be enabled when user makes any changes.
    this.disableConfirmButtonSubject = new BehaviorSubject(true);
    this.subs.sink = this.disableConfirmButtonSubject.subscribe((value: boolean) => {
      this.disableConfirmButton = value;
    });

    // subscribe to change in input data
    this.subs.sink = this.data.subscribe((input: IShareModalInput) => {
      if (isNullOrUndefined(input)) {
        return;
      }

      this.projectTitle = input.resourceName;
      this.existingCollaborators = input.collaborators;
      this.userList = this.collaboratorsToUsers(input.collaborators);
      this.resourceType = input.resourceType;

      this.modalTitle = 'Share this ' + this.resourceType.toString();
      if(this.resourceType === ResourceType.PROJECTS) {
        // Remove 's' in projects because only 1 project can be shared at a time
        this.modalTitle = this.modalTitle.slice(0, -1);

        this.includeAssociatedResources = false;
      }

      if (input.alertMsg != null) {
        this.alertMessage = input.alertMsg;
      }

      this.permissionOptions = this.getShareOptions();

      this.error.next(input.errorMsg);
    });
  }

  dataChanged($event) {
    // avoid calling the downstream function while user is still typing
    if ($event.isInputValid === true || isNullOrUndefined($event.isInputValid)){
      const _data: IShareModalOutput = {
        collaborators: this.userToCollaborators($event.userList),
        includeAssociatedResources: $event.includeAssociatedResources
      };

      // user added and removed, nothing to save
      if (_data.collaborators.length === 0 &&
        this.existingCollaborators.length === 0) {
        this.disableConfirmButtonSubject.next(true);
      } else {
        this.disableConfirmButtonSubject.next(false);
      }
      this.modal.setData(_data, true);
    }
  }

  constructError(error: any): string {
    // TODO handle below errors along with email validation
    // email entered is not a valid email address
    // emial entered already has a pending invite
    if (isNullOrUndefined(error.error) || isNullOrUndefined(error.error.message)) {
      return ModalTexts.SHARE.SHARE_ERROR;
    }

    return error.error.message;
  }

  /**
   * Maps collaborators to users
   * @param collaborators list of existing collaborator for the resource
   */
  collaboratorsToUsers(collaborators: ICollaborator[]) {
    if (isNullOrUndefined(collaborators) || collaborators.length === 0) {
      return [];
    }

    const users: IUserListItem[] = [];
    collaborators.forEach(collaborator => {
      const user: IUserListItem = collaborator;
      user.status = collaborator.grantId === 0 ? 'Pending' : '';
      user.permission = this.getUserPermission(collaborator);

      users.push(user);
    });

    return users;
  }

  /**
   * Returns possible share options.
   */
  private getShareOptions(): IOption[] {
    const options: IOption[] = [this.getOption(ModalTexts.SHARE.READ_ONLY)];

    // Run can be shared only with read permissions.
    switch(this.resourceType) {
      case ResourceType.RUN:
      case ResourceType.RUNS:
        return options;
    }

    options.push(this.getOption(ModalTexts.SHARE.WRITE));
    return options;
  }

  private getUserPermission(collaborator: ICollaborator): string {
    if (collaborator.permission === 'READ' || collaborator.permission === 'ROLE_READER') {
      return ModalTexts.SHARE.READ_ONLY;
    }

    return ModalTexts.SHARE.READ_WRITE;
  }

  private getCollaboratorPermission(permission: string): string {
    if (permission === ModalTexts.SHARE.READ_ONLY) {
      return 'ROLE_READER';
    }

    return 'ROLE_WRITER';
  }

  private getOption(key: string): IOption {
    return { name: key, value: key };
  }

  /**
   * Maps users to collaborators
   * @param users list of new and old users
   */
  private userToCollaborators(users: IUserListItem[]): ICollaborator[] {
    if (isNullOrUndefined(users) || users.length === 0) {
      return [];
    }

    const collaborators: ICollaborator[] = [];
    users.forEach(user => {
      const collaborator: ICollaborator = _.cloneDeep(user);
      collaborator.isNew = user.status === 'New';
      collaborator.permission = this.getCollaboratorPermission(user.permission);
      collaborators.push(collaborator);
    });

    return collaborators;
  }

}

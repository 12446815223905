import { Component, OnInit } from '@angular/core';
import { IAllowedStatus, NgxSmartModalService, StatusChangeComponentOutput, StatusChangeResource } from '@bssh/comp-lib';
import { isNullOrUndefined } from 'util';
import { ILane } from '../../../../core/model/runs/lane';
import { ALL_POSSIBLE_LANE_STATUSES, ReverseLaneStatus, LaneStatus } from '@app/core/model/runs/lane-status';
import { BaseModalComponent } from '../../base-modal/base-modal.component';
import { ModalTexts } from '../../modal-texts';
import { Lane } from '@bssh/ng-sdk';
import { StatusToDisplayName } from '@app/core/utilities/pipes/status-to-display-name.pipe';
import { ChangeStatusModalComponent } from '../change-status-modal.component';

@Component({
  selector: 'app-change-lane-qc-modal',
  templateUrl: './change-lane-qc-modal.component.html',
  styleUrls: ['./change-lane-qc-modal.component.scss']
})
export class ChangeLaneQcModalComponent extends ChangeStatusModalComponent<ILane, LaneStatus> {

  lanes: StatusChangeResource[] = [];
  laneIdentifierTitle: string = 'LANE';
  statusTitle: string = 'QC STATUS';
  modalType = 'InitiateChangeLaneQcModal';
  ngxSmartModalTitle: string = ModalTexts.CHANGE_LANE_STATUS.MODAL_TITLE.QC.LANE;
  allPossibleStatuses = ALL_POSSIBLE_LANE_STATUSES;

  constructor(public ngxSmartModalService: NgxSmartModalService,
              public statusToDisplayName: StatusToDisplayName) {
    super(ngxSmartModalService, statusToDisplayName);
    this.setReverMapping();
  }

  protected getStatusChangeResources(lanes: ILane[]): StatusChangeResource[] {
    const lanesForChange: StatusChangeResource[] = [];
    if (!lanes) {
      return lanesForChange;
    }
    for (const lane of lanes) {
      const laneForChange: StatusChangeResource = {
        resourceName: lane.LaneNumber.toString(),
        currentStatus: lane.Status,
        displayStatus: this.statusToDisplayName.transform(lane.Status),
        statusClass: lane.Status === LaneStatus.QcFailed ? 'status-change__result__value--bold' : null
      };

      lanesForChange.push(laneForChange);
    }

    return lanesForChange;
  }

  private setReverMapping() {
    Object.keys(LaneStatus).forEach((status: LaneStatus) => {
        const value: string = LaneStatus[status];
        this.reverseMap.set(value, status);
    });
  }
}

import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { PlatformClient } from '../../platform-service/platform-client';
import environment from '@environments/environment';
import { AuthorizationService } from '../../authorization/authorization.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionTrackerService {

    private authorizationToken: string;

    constructor(
        private httpClient: HttpClient,
        private authorizationService: AuthorizationService,
        private platformClient: PlatformClient) {
       this.authorizationToken = this.authorizationService.getPsToken();
    }

    public isIdle(): Observable<any> {
        return this.platformClient
                    .isIdleUsingGET(this.authorizationToken)
                    .pipe(
                        catchError(error => {
                            return throwError(error);
                        })
                    ) as Observable<any>;
    }

    public updateSessionTracker(lastActiveBefore: number): Observable<any> {
        const url = `${environment.platformUrl}/SessionTracker/`;
        let params = new HttpParams();
        params = params.append('clientId', environment.clientId);
        params = params.append('lastAccessSync', lastActiveBefore.toString());

        const headers =  this.getHeader(this.authorizationToken);

        return this.httpClient
                    .put(url, null, { headers, params, withCredentials: true, observe: 'response'})
                    .pipe(
                        catchError(error => {
                            return throwError(error);
                        })
                    ) as Observable<any>;

    }

    private getHeader(token: string): HttpHeaders {
        return new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + token
        });
    }
}

import { Injectable } from '@angular/core';
import { PlatformApiEndPoints } from '../endpoints';
import { ResourceBaseService } from '../../resource-base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import environment from '../../../../../environments/environment';
import { HttpUtilityService } from '../../http-utility/http-utility.service';
import { Observable, of, throwError } from 'rxjs';
import { IPlatformSession } from '../../../model/platform-service/platform-service-models';
import { map, catchError } from 'rxjs/operators';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import { isNullOrUndefined } from 'util';

export interface IPlatformSessionService {
  validateSessionToken(psToken: string): Observable<IPlatformSession>;
  invalidateSessionToken(psToken: string): Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class PlatformSessionService extends ResourceBaseService<IPlatformSession> implements IPlatformSessionService {

  constructor(httpClient: HttpClient,
              private httpUtilityService: HttpUtilityService) {
    super(httpClient, PlatformApiEndPoints.session);
  }

  /**
   * Validates whether the Pstoken is valid
   * @param psToken The Ps Token
   */
  validateSessionToken(psToken: string): Observable<IPlatformSession> {
    return this.post('ValidateToken/', null, this.getHttpOptionsWithPsToken(psToken));
  }

  /**
   * Invalidates the session token (psToken)
   * @param psToken Platform session token
   */
  invalidateSessionToken(psToken: string) {
    return this.delete('InvalidateToken/', this.getHttpOptionsWithPsToken(psToken)).pipe(
      map(response => true),
      catchError(response => throwError(response))
    );
  }

  /**
   * Gets the Http options to be passed in the request
   * @param psToken Ps token
   */
  // tslint:disable-next-line: ban-types
  private getHttpOptionsWithPsToken(psToken: string): Object {
    const domain = this.httpUtilityService.getDomainFromPsToken(psToken);
    return {
      headers: new HttpHeaders({
        clientId: environment.clientId,
        access_token: psToken || "undefined",
        domain: !isNullOrUndefined(domain) ? domain : this.httpUtilityService.getDomainFromCurrentRequestUrl()
      })
    };
  }


}

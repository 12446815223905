import { HttpClient, HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { StringUtilities } from '../utilities/string-utilities';

/**
 * A generic Resource service which can be used by derived services to make REST API Calls.
 */
export class ResourceBaseService<T> extends BaseService {
    // Add to the parmeters/service as necessary
    constructor(
        private httpClientBase: HttpClient,
        protected apiUrl: string) {
        super();
    }

    // tslint:disable-next-line: ban-types
    protected post(resourcePath: string, item: T, options: Object = {headers: {}}) {
        const url = resourcePath != null ? `${this.apiUrl}${resourcePath}` : this.apiUrl;
        return this.httpClientBase
            .post<T>(url, StringUtilities.isBlank(item) ? null : JSON.stringify(item), options).pipe(
                catchError(error => {
                    return throwError(error);
                })
            ) as Observable<T>;
    }

    protected put(id: string, item: T): Observable<T> {
        return this.httpClientBase
            .put<T>(`${this.apiUrl}/${id}`,
                JSON.stringify(item));
    }

    protected get(id: number): Observable<T> {
        return this.httpClientBase
            .get<T>(`${this.apiUrl}/${id ? id : ''}`);
    }

    protected list(queryOptions: { [key: string]: string }): Observable<T[]> {
        const queryString = Object
            .keys(queryOptions)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryOptions[key])}`)
            .join('&');
        return this.httpClientBase
            .get<T[]>(`${this.apiUrl}?${queryString}`);
    }

    /**
     * Makes a DELETE request with the given request options.
     * @param resourcePath The Resource path to delete
     * @param options The standard Http request options
     */
    // tslint:disable-next-line: ban-types
    protected delete(resourcePath: string, options?: Object) {
        if(options)
            return this.httpClientBase.delete(`${this.apiUrl}${resourcePath}`, options);
        else
            return this.httpClientBase.delete(`${this.apiUrl}${resourcePath}`);
    }
}




import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Constants } from '../../utilities/constants';
import environment from '@environments/environment';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { StringUtilities } from '@app/core/utilities/string-utilities';

export interface ISkinnyBsshService {
  isRouteEnabled(route: string): Observable<boolean>;
  isSkinnyBssh(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SkinnyBsshService extends BaseService implements ISkinnyBsshService {

  //When matching, casing differences are ignored.
  private SkinnyBssh_UI_Blacklist_regex = !(StringUtilities.isBlank(environment.SkinnyBsshA8UIGuardBlacklist)) ? new RegExp(environment.SkinnyBsshA8UIGuardBlacklist, 'i') : null;

  // Right now, this service make decisions based on route/page environment variables etc.
  constructor(private router: Router) {
    super();
  }

  isSkinnyBssh(): boolean {
    return (this.SkinnyBssh_UI_Blacklist_regex != null);
  }

  isRouteEnabled(route: string): Observable<boolean> {
    if (this.SkinnyBssh_UI_Blacklist_regex != null) {
      return of(this.SkinnyBssh_UI_Blacklist_regex.test(route));
    }
    // If regex is not set in configuration, return false for the checking
    return of(false);
  }

  navigateToPageNotFound() {
    const newRoute = Constants.NOT_FOUND_ROUTE;
    this.router.navigateByUrl(newRoute, { skipLocationChange: true });
  }
}
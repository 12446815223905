import { Component, OnInit, OnDestroy, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { ICancelRequeueModalInput } from '../model/action-modal';
import { V2LabRequeueCompact } from '@bssh/ng-sdk';
import { ErrorMessages } from '@app/core/utilities/error-messages';
import { BioSampleLabRequeues } from '@app/core/store/biosamples/biosamples.store';
import { map } from 'rxjs/operators';
import { BsApiService } from '@app/core/services/bs-api/bs-api-service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-cancel-requeue-modal',
  templateUrl: './cancel-requeue-modal.component.html',
  styleUrls: ['./cancel-requeue-modal.component.scss']
})
export class CancelRequeueModalComponent extends BaseModalComponent implements OnInit, OnDestroy, AfterViewInit {

  ngxSmartModalTitle = 'Cancel Requeue';
  requeue: V2LabRequeueCompact;
  requeueName: string;
  alertMsg: string = null;
  headerKey: string = null;
  headerValue: string = null;

  confirmButtonText = 'YES';

  constructor(public ngxSmartModalService: NgxSmartModalService, private bsApiService: BsApiService) {
    super(ngxSmartModalService);
    this.modalType = 'InitiateCancelRequeueModal';
  }

  ngOnInit() {
    super.ngOnInit();
    this.setUpInputSubscriber();
  }


  private setUpInputSubscriber() {
    this.subs.sink = this.data.subscribe(res => {
      if (res) {
        const response: ICancelRequeueModalInput = res as ICancelRequeueModalInput;
        this.requeue = response.requeue;
        this.requeueName = response.requeueType;
        if (this.requeue.Status === 'Canceled') {
          this.error.next(ErrorMessages.REQUEUE_STATUS_NOT_PENDING);
        }

        switch (this.requeueName) {
          case BioSampleLabRequeues.NewLibrary:
            this.headerKey = 'Biosample Name';
            this.headerValue = this.requeue.BioSample.BioSampleName;
            break;
          case BioSampleLabRequeues.ExistingLibrary:
            this.headerKey = 'Library Name';
            this.headerValue = '--';
            this.fetchHeader(this.requeue.HrefSampleLibrary).subscribe((data) => {
              this.headerValue = data.Name;
            });
            break;
          case BioSampleLabRequeues.ExistingPool:
            this.headerKey = 'Pool Name';
            this.headerValue = '--';
            this.fetchHeader(this.requeue.HrefLibraryPool).subscribe((data) => {
              this.headerValue = data.UserPoolId;
            });
            break;
          default:
            return null;
        }
      }
    });
  }

  private fetchHeader(href: string): Observable<any> {

    return this.bsApiService.getFromResourceUrl(href).pipe(
      // two types of href are there. one with v1pre3 comes wrapped in IV1ApiResponse
      // v2 gives data which is not wrapped
      map (data => {
        if ( data.Response ) {
          return data.Response;
        } else {
          return data;
        }
      }));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.modal.setData(this.requeue, true);
  }

  protected constructError(error: any): string {
    if (error && error.error && error.error.ErrorMessage) {
      return error.error.ErrorMessage;
    }
    return 'An unexpected error occured while cancelling requeue.';
  }

}

/*
 * Instrument Run Setup Tokens
 * This endpoint belongs to IMS, but is not exposed to the public on swagger.
 * This class is created by referring to:
 * https://git.illumina.com/SEES/Stratus/blob/master/Services/InstrumentManagementService/docs/sdd/ims-sdd.md#instrument-run-setup-token-exchange-apis
 */

/* tslint:disable max-line-length */

import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpEvent } from '@angular/common/http';
import { InstrumentRunSetupTokenRequest } from '@app/core/model/instrument-run-setup-token/instrument-run-setup-token-request';
import { InstrumentRunSetupTokenResponse } from '@app/core/model/instrument-run-setup-token/instrument-run-setup-token-response';
import { Observable } from 'rxjs';
import { GssApiModule } from '@stratus/gss-ng-sdk';
import { Configuration } from '@app/core/model/instrument-run-setup-token/configuration';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import environment from '@environments/environment';

@Injectable({
  providedIn: GssApiModule
})
export class InstrumentRunSetupTokenService {

  protected basePath = StringUtilities.trimTrailingSlash(environment.iapApiUrl);
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();


  constructor(
    protected httpClient: HttpClient,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }

  /**
   * This endpoint provides the ability for the user to pass in their credentials to generate a jwt token or an Illumina session token.
   * Additionally, a user can pass in a session token to exchange for a jwt token.
   * @summary Creates a JWT token for GSS Instrument Run Setup.
   * @param apiKey ApiKey as authorization for JWT token generation. This property is optional.
   * @param cwid Set the current workgroup on the token. Used for aligning resources to a workgroup. This property is optional.
   * @param acl Defines the access control list to be applied to the JWT. This property is optional.
   * @param mem Defines the membership list to be applied to the JWT. This property is optional.
   * @param scopes Scopes can be passed in during token generation to limit the token to particular scopes. This property is optional.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createInstrumentRunSetupToken(body?: InstrumentRunSetupTokenRequest, observe?: 'body', reportProgress?: boolean): Observable<InstrumentRunSetupTokenResponse>;
  public createInstrumentRunSetupToken(body?: InstrumentRunSetupTokenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InstrumentRunSetupTokenResponse>>;
  public createInstrumentRunSetupToken(body?: InstrumentRunSetupTokenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InstrumentRunSetupTokenResponse>>;
  public createInstrumentRunSetupToken(body?: InstrumentRunSetupTokenRequest, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const endpointPath = this.basePath + '/v1/instrumentRunSetup/tokens';

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', 'application/json');

    return this.httpClient.post<InstrumentRunSetupTokenResponse>(endpointPath,
      body,
      {
        headers,
        observe,
        reportProgress
      }
    );
  }
}

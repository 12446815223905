import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ResourceType } from '@app/core/model/resource-type';
import { ISearchResultItem } from '@app/core/model/search/search-result-item';
import { IChooserServerSideDataParams, NgxSmartModalService } from '@bssh/comp-lib';
import { GridOptions } from 'ag-grid-community';
import _ from 'lodash';
import { SearchDatasourceFactoryService } from '../../../search/datasource/search-datasource-factory.service';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';
import { IChooserModalInput, IChooserModalOutput } from '../model/action-modal';
import { ChooserGridOptionsFactoryService } from './gridOptions/chooser-grid-options-factory.service';

@Component({
  selector: 'app-chooser-modal',
  templateUrl: './chooser-modal.component.html',
  styleUrls: ['./chooser-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChooserModalComponent extends BaseModalComponent implements OnInit {

  public chooserTitle = '';
  public chooserTitleTooltip = '';
  public chooserSearchPlaceHolderText = '';
  public hasMatcher = false;
  public debounceTimeInMs = 300;
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public isResourceCreatable = false;
  public confirmButtonText = ModalTexts.CHOOSER.CONFIRM_BUTTON_TEXT;
  public newButtonText = ModalTexts.CHOOSER.NEW_BUTTON_TEXT;
  public chooserServerSideParams: IChooserServerSideDataParams;
  public gridOptions: GridOptions;
  public modalType = 'ChooserModal';
  public resourceType: ResourceType;


  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private searchDatasourceFactory: SearchDatasourceFactoryService,
    private chooserGridOptionsFactory: ChooserGridOptionsFactoryService
  ) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.disableConfirmButton = true;

    this.subs.sink = this.data.subscribe((data: IChooserModalInput) => {
      if (data) {
        this.chooserTitle = data.title;
        this.chooserTitleTooltip = data.titleTooltip;
        this.chooserSearchPlaceHolderText = data.searchPlaceHolderText;
        this.rowSelection = data.rowSelection;
        this.hasMatcher = data.hasMatcher;
        this.debounceTimeInMs = _.get(data, 'debounceTimeForRawTextQuery', this.debounceTimeInMs);
        this.isResourceCreatable = data.isResourceCreatable;
        this.resourceType = data.resourceType as ResourceType;
        // tslint:disable-next-line: max-line-length
        const searchDataSource = this.searchDatasourceFactory.getSearchDatasourceForResource(this.resourceType, data.searchDataSourceOptions);
        this.chooserServerSideParams = {
          serverSideRequestOptions: searchDataSource.requestOptions,
          rawTextFilterProcessing: searchDataSource.rawTextFilterProcessing
        };
        const searchGridOptions = this.chooserGridOptionsFactory.getGridOptionsFormatterForResource(this.resourceType);
        this.gridOptions = searchGridOptions.getGridOptions({});
      }
    });
  }

  chooserItemsSelected(items: ISearchResultItem[]) {
    this.disableConfirmButton = !(items && items.length > 0);
    const data: IChooserModalOutput<ISearchResultItem> = {
      selectedItems: items
    };
    this.modal.setData(data, true);
  }
}


import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppInitializationService } from '@app/core/utilities/app-initialization.service';

// documentation of APP_INITIALIZER says that initialization will be paused while all promises inside 'useFactory' are resolved
// if any promises reject, app will not start
// https://www.tektutorialshub.com/angular/angular-how-to-use-app-initializer/
// https://dzone.com/articles/how-to-use-the-app-initializer-token-to-hook-into

// prototype of moving the init process and other dependencies into an AppInitializationService
export function init(appInitializationService: AppInitializationService) {
    return () => {
        appInitializationService.init();
    }
}

@NgModule({
    providers: [{
        provide: APP_INITIALIZER,
        useFactory: init,
        deps: [AppInitializationService],
        multi: true
    }],
})
export class AppInitializationModule { } 
/*
 * Stratus api service
 * This class holds the direct HTTP calls for various stratus APIs which are not available in swagger sdk.
 */

/* tslint:disable max-line-length */

import { Injectable } from '@angular/core';
import { StringUtilities } from '@app/core/utilities/string-utilities';
import environment from '@environments/environment';
import { HttpHeaders, HttpClient, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NoContentResult } from '@stratus/gss-ng-sdk';

@Injectable({
  providedIn: 'root'
})
export class StratusApiService {
  protected basePath = StringUtilities.trimTrailingSlash(environment.iapApiUrl);
  public defaultHeaders = new HttpHeaders();

  constructor(
    protected httpClient: HttpClient,
  ) {}

  /**
   * For a given sequencing run ID, delete the sequencing run.
   * @summary Delete sequencing run.
   * @param runId GSS sequencing run id.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public disableSequencingRun(runId?: string, observe?: 'body', reportProgress?: boolean): Observable<NoContentResult>;
  public disableSequencingRun(runId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<NoContentResult>>;
  public disableSequencingRun(runId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<NoContentResult>>;
  public disableSequencingRun(runId?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const endpointPath = this.basePath + '/v1/sequencing/runs/{runId}'
            .replace('{' + 'runId' + '}', encodeURIComponent(String(runId)));

    // verify required parameter 'runId' is not null or undefined
    if (runId === null || runId === undefined) {
        throw new Error('Required parameter runId was null or undefined when calling disableSequencingRun.');
    }

    let headers = this.defaultHeaders;
    headers = headers.set('Accept', 'application/json');

    return this.httpClient.delete<NoContentResult>(endpointPath,
      {
        headers,
        observe,
        reportProgress
      }
    );
  }
}



<div #InitiateChangeLaneQcDiv>
    <ngx-smart-modal #InitiateChangeLaneQcModal
        [identifier]="modalType"
        [title]="ngxSmartModalTitle"
        closable="true"
        showCloseButton="true" 
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButton" 
        [confirmButtonText]="confirmButtonText">

        <lib-progress #apiDataSpinnerLoader 
            [id]="apiDataSpinnerLoader" 
            class="'spinner'" 
            [loader]="false" 
            [fixed]="true"
            [spinner]="true" 
            [spinnerPosition]="'center'" 
            [progressBarContainer]="'modal'" 
            (started)="onStarted()" 
            (completed)="onCompleted()"
            data-cy="change-lane-qc-status-progress-overlay-spinner"
        >
        </lib-progress>

        <lib-status-change
            [statusChangeResourceList]="resources"
            [allowedStatuses]="allowedStatuses"
            [resourceIdentifierTitle]="laneIdentifierTitle"
            [statusTitle]="statusTitle"
            [alertMsg]="alertMsg"
            [errorMsg]="errorMsg != null ? errorMsg.toString(): null"
            [commentMaxLength]="commentMaxLength"
            [commentFieldIdentifier]="commentFieldName"
            (confirm)="dataChanged($event)">
        </lib-status-change>

    </ngx-smart-modal>
</div>
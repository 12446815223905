<div #RequeueBiosampleDiv>
    <ngx-smart-modal #RequeueBiosample
        [identifier]="modalType"
        [title]="modalTitle"
        [closeButtonText]="closeButtonText"
        [disableConfirmButton]="disableConfirmButton" 
        [confirmButtonText]="confirmButtonText">
        <app-lab-requeue
            [informationListToDisplay]="informationListToDisplay"
            [selectedLabWorkflow]="selectedLabWorkflow"
            [labWorkflows]="labWorkflows"
            [errorMsg]="errorMsg"
            (confirm)="dataChanged($event)"
        >
        </app-lab-requeue>
    </ngx-smart-modal>
</div>
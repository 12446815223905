import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { IApiHttpError, IBsOutageStatus, OutageType } from '../model/v2-api/http-error';
import environment from '@environments/environment';
import { StringUtilities } from '../utilities/string-utilities';
import { Constants } from '../utilities/constants';


@Injectable()
export class BsOutageInterceptor implements HttpInterceptor, OnDestroy {

    private subs = new SubSink();
    private outageStatusFileName = 'outage-status.json';

    constructor(
        private router: Router,
        private httpClient: HttpClient) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => event, (err: any) => {
                // only interested in BSSH api.
                if (_.startsWith(req.url, environment.apiUrl) && err instanceof HttpErrorResponse) {
                    // The browser first makes a pre-flight request to ascertain CORS on the api
                    // Even though the api is down with a 503 error response,
                    // the pre-flight request fails with unknown error code(0) because it does not detect CORS headers in the response
                    // So need to include status = 0 as well.
                    if (err.status === 503 || err.status === 0 ) {
                        const outageStatusUrl = `${StringUtilities.trimTrailingSlash(environment.outageUrl)}/${this.outageStatusFileName}`;
                        setTimeout(() => {
                            this.subs.sink = this.httpClient.get<IBsOutageStatus>(outageStatusUrl).subscribe({
                                next: (response: IBsOutageStatus) => {
                                    this.navigateToOutagePage(response);
                                },
                                error: (er) => {
                                    // We will want to redirect to outage page
                                    // Log to console(should rarely if ever happen)
                                    console.log('Error downloading outage status json from Outage domain CF', er);
                                    this.navigateToOutagePage();
                                }
                            });
                        }, 250);
                    }
                }
            })
        );
    }

    private navigateToOutagePage(outageStatus?: IBsOutageStatus): void {
        if (outageStatus == null) {
            outageStatus = {
                type: OutageType.Unplanned
            };
        }
        const apiError: IApiHttpError = {
            httpErrorStatus: 503,
            data: outageStatus
        };
        // Navigate to the outage page silently, without changing the url in the address bar.
        // The route state will have info that the page can use to display content
        this.router.navigateByUrl(Constants.HTTP_ERROR_ROUTE, {
            skipLocationChange: true,
            state: {
                ApiHttpError: apiError
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}

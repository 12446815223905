import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { Constants } from '../constants';
import { DatePipe } from '@angular/common';
import { ConsoleLogger } from '../consolelogger';
import _ from 'lodash';


/**
 * This pipe will faciliate token replacement in strings.
 * For now it is a simple pipe with a single replacement support.
 * TO DO: We can add dependencies on other services such as Routing, Current User store etc to support a variety of tokens
 * E.g: Input String : "Welcome {currentUser.Name}" Replaced string: "Welcome John Doe"(using currrent user store)
 */
@Pipe({
  name: 'tokenReplacer'
})
@Injectable({
  providedIn: 'root'
})
export class TokenReplacerPipe implements PipeTransform {

  // Tokens are wrapped in curly braces.
  private tokenRegexPattern: RegExp = new RegExp('\{([^\}]+)\}', 'g');

  constructor(private datePipe: DatePipe) {
  }

  /**
   * Replaces known tokens in a given string with actual values
   * @param stringWithTokens The string with tokens that need to be replaced
   */
  transform(stringWithTokens: string): string {
    const matches = stringWithTokens.match(this.tokenRegexPattern);
    if (matches != null && matches.length > 0) {
      matches.forEach(match => {
        match = _.trimStart(match, '{');
        match = _.trimEnd(match, '}');
        stringWithTokens = this.replaceTokens(stringWithTokens, match);
      });
    }
    return stringWithTokens;
  }

  /**
   * Replaces tokens in a given string
   * @param stringWithTokens The string with tokens
   * @param token The token (without enclosing curly braces)
   */
  private replaceTokens(stringWithTokens: string, token: string): string {
    if (!isNullOrUndefined(token)) {
      let tokenType: string;
      // For multi option tokens.
      const tokenComponents = token.split('|');
      if (tokenComponents.length >= 1) {
        tokenType = tokenComponents[0];
      } else {
        // We should establish a token format for straight forward tokens
        // E.g: {currentUser.Id}, in that case, token type will be 'currentUser'
        // For now, defaulting to token(not used as of now)
        tokenType = token;
      }
      switch (tokenType) {
        case Constants.MultiOptionTokenNames.Date:
          return this.replaceMultiOptionDateToken(stringWithTokens, token);
        // Add more cases as necessary.
        default:
          return stringWithTokens;
      }
    }
    return stringWithTokens;
  }

  private replaceMultiOptionDateToken(stringWithTokens: string, token: string): string {
    try {
      const tokenComponents = token.split('|');
      // Multi option date token, right now, expected to be of the format {<TYPE>|<Format>|<Value>}
      if (tokenComponents.length === 3) {
        // Format date with the supplied format.
        const day = new Date(tokenComponents[2]).getDate();
        let dateTobeReplaced = this.datePipe.transform(tokenComponents[2], tokenComponents[1]);
        if (tokenComponents[1] === Constants.DateFormats.MonthDayWithSuffix) {
          dateTobeReplaced = dateTobeReplaced + this.GetDaySuffix(day);
        }
        return stringWithTokens.replace(`{${token}}`, dateTobeReplaced);
      }
    } catch (error) {
      // Invalid dates, and other errors. do nothing. Log on console.
      ConsoleLogger.logError('error in replaceMultiOptionDateToken', error);
    }
    return stringWithTokens;
  }


  private GetDaySuffix(day: number): string {
    switch (day) {
      case 1:
      case 21:
      case 31:
        return 'st';
      case 2:
      case 22:
        return 'nd';
      case 3:
      case 23:
        return 'rd';
      default:
        return 'th';
    }
  }

}


import { Scripts } from './script/script.store';

export class Constants {
    static basespaceDMNotResourceOwnerErrorCode = 'BASESPACE.DATAMANAGEMENT.NOT_RESOURCE_OWNER';
    static basespaceDMTrashUnshareProjectChildNotAllowed = 'BASESPACE.DATAMANAGEMENT.TRASH_UNSHARE_PROJECT_CHILD_NOT_ALLOWED';
    static insufficientResourcePermissions = 'BASESPACE.COMMON.INSUFFICIENT_RESOURCE_PERMISSIONS';
    static inactiveSubscriptionErrorCode = 'BASESPACE.WORKGROUPS.MISSING_OR_INACTIVE_SUBSCRIPTION';
    static authenticationFailure = 'BASESPACE.COMMON.AUTHENTICATION_FAILURE';
    static trashDefaultProjectNotAllowed = 'BASESPACE.PROJECT.DELETE_DEFAULT_PROJECT_NOT_ALLOWED';
    static applicationNotVisibleToUser = 'BASESPACE.COMMON.APPLICATION_NOT_USER_VISIBLE';

    /**
     * file format template path constants.
     */

    static analysisWorkflowUrl = 'https://help.basespace.illumina.com/Source/Informatics/BS/AnalysisWorkflows_swBS.htm?Highlight=analysis%20workflow';
    static biosampleWorkflowUrl = 'https://help.basespace.illumina.com/Source/Informatics/BS/UploadBiosampleWorkflows_swBS.htm?Highlight=biosample%20workflow';
    static fastqReqUrl = 'https://help.basespace.illumina.com/Source/Informatics/BS/UploadFastqReq_swBS.htm?Highlight=fastq';
    static csvFormatTemplate = 'assets/resources/Import_BiologicalSamples_Template-v2.csv';

    /**
     * Authorization constants.
     */
    static Auth = {
        authpsToken: 'psToken',
        authClientId: 'clientId'
    };

    static WalkMeScriptNames: Array<string> = ['walkmeProd', 'walkmeTest'];
    /**
     * Spotlight - user notification invite token constant.
     */
    static InviteToken = 'inviteToken';

    static ACCESS_FORBIDDEN_ROUTE = 'access-forbidden';

    static NOT_FOUND_ROUTE = 'error/http404';

    static RESOURCE_DELETED = 'resource-deleted';

    static SWITCH_WORKGROUP_ROUTE = 'switchworkgroup';

    static HTTP_ERROR_ROUTE = 'httperror';

    /**
     * Platform Session Track default configuration
     * These configuration are overridden by the  platform cookie most of the time,
     */
    static PlatformSessionTrack = {
        lastAccessSync: 'lastAccessSync',
        idleTimeoutInSeconds: 'idleTimeoutInSeconds',
        idleTimeoutIntervalCheckInSeconds: 'idleTimeoutIntervalCheckInSeconds',
        defaultIdleTimeOutInSeconds: 1800, // 30 mints
        defaultKeepAliveIntervalInSeconds: 60, // 1 min
        defaultIdleTimeOutWaitTimeInSeconds: 60, // 1 min
    };

    /**
     * Idle service Session settings
     */
    static SessionIdleSettings = {
        enabled: true,
        sessionIdleInterruptSources: 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll'
    };

    /**
     * Maps the 'SubscriptionRegion' to 4 character region code
     * The region code is used in BSSH/VI Urls in region specific apps
     */
    static SubscriptionRegionMapper: { [key: string]: string } = {
        US: 'use1',
        CA: 'cac1',
        AU: 'aps2',
        EU: 'euc1',
        EUW2: 'euw2',
        CN: 'cnn1'
    };

    static RegionalBsshHostMatcher = '(.*?)\.sh\.basespace\.illumina\.com';
    static PageTitleSuffix = 'BaseSpace Sequence Hub';
    static RelatedUserWithAccessParam = 'RelatedUserWithAccess';
    static RelatedUserIdWithAccessField = 'RelatedUserIdWithAccess';
    static ResourceTypeParam = 'ResourceType';
    static LocalEnvironmentName = 'local';
    static ApiHttpErrorField = 'ApiHttpError';
    static OutageStatusField = 'OutageStatus';

    static MultiOptionTokenNames = {
        Date: 'DATE'
    };

    static DateFormats = {
        MonthDayWithSuffix: 'MMMM d',
        StandardDate: 'yyyy-MM-dd'
    };

    static NotificationLinkText = {
        RenewNow: 'Renew Now',
        LearnMore: 'Learn More'
    };

    static ServerSortFields = {
        ModifiedOn: 'modifiedon',
        Score: 'score',
        Status: 'Status',
        Name: 'Name',
        TotalSize: 'TotalSize',
        UserOwnedBy: 'UserOwnedBy',
        DateCreated: 'DateCreated',
        DateModified: 'DateModified',
        VersionNumber: 'VersionNumber'
    };

    static ServerSortDirection = {
        Asc: 'asc',
        Desc: 'desc'
    };

    static ServerPagingParams = {
        Limit: 'limit',
        Offset: 'offset'
    };

    static ServerSortParams = {
        SortBy: 'sortby',
        SortDir: 'sortdir'
    };

    static PagingStyles = {
        Strict: 'strict',
        Default: 'default',
    };

    static V2SampleSheet = {
        Identifier: 'FileFormatVersion,2'
    };

    static CookieKeys = {
        LastActingUserIdKey: 'LastActingUserId'
    };

    static Yields = {
        MaxYieldRawBp: 999999999999,
        MinYieldRawBp: 1
    };

    static UNINDEXED_READS_PROJECT_NAME = 'Unindexed Reads';

    static SAMPLE_SHEET_FILENAME = 'SampleSheet.csv';
}

import { AfterViewInit, Component, OnInit } from "@angular/core";
import { NgxSmartModalService } from "@bssh/comp-lib";
import { BaseModalComponent } from "../base-modal/base-modal.component";
import { ModalTexts } from "../modal-texts";
import { ICancelAnalysisModalInput } from "../model/action-modal";

@Component({
  selector: 'app-cancel-analyses-modal',
  templateUrl: './cancel-analysis-modal.component.html',
  styleUrls: ['./cancel-analysis-modal.component.scss']
})

export class CancelAnalysisModalComponent  extends BaseModalComponent implements OnInit, AfterViewInit {
  public resourceName: string = null;
  public modalType = 'CancelAnalysisModal';
  public confirmButtonText = ModalTexts.CANCEL_ANALYSIS.CONFIRM_BUTTON_TEXT;
  public modalTitle = ModalTexts.CANCEL_ANALYSIS.MODAL_TITLE;
  public cancelAnalysisMessage = ModalTexts.CANCEL_ANALYSIS.CANCEL_ANALYSIS_MESSAGE;
  
  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.subs.sink = this.data.subscribe((input: ICancelAnalysisModalInput) => {
      if (!input) {
        return;
      }
      this.resourceName = input.resourceName;
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();  
  }

  protected constructError(error: any): string {
    if (error.error.ErrorMessage != null) {
      return error.error.ErrorMessage;
    }

    if (error.ErrorCode == null) {
      return ModalTexts.CANCEL_ANALYSIS.ERROR;
    }

    return '';
  }
}

import { Injectable } from '@angular/core';
import { IUserSettingsState } from './user-settings.state';
import { BaseObservableStore, IObservableStore } from '../infrastructure/base-observable.store';
import { BasespaceService, V2PostUserSettingsRequest, V2UserSetting } from '@bssh/ng-sdk';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { retryWhen, delay, shareReplay, filter } from 'rxjs/operators';
import { genericRetryWhen } from '../../rxjsutils/rxjs-utilities';
import { UserSessionType } from '@app/core/model/user/session-type';

export interface IUserSettingsStore extends IObservableStore<IUserSettingsState> {
  loadUserSettings(userSessionType: UserSessionType, forceLoad: boolean): void;
  setUserSettings(userSessionType: UserSessionType, settings: V2UserSetting[]): void;
  isLoggedInUserSettingsLoaded(): Observable<boolean>;
}

export const MAX_USER_SETTINGS_LIMIT = 1000;

@Injectable({
  providedIn: 'root'
})
export class UserSettingsStore extends BaseObservableStore<IUserSettingsState> implements IUserSettingsStore {

  private loggedInUserSettingsChangedSubject = new BehaviorSubject<boolean>(false);
  loggedInUserSettingsChanged$ = this.loggedInUserSettingsChangedSubject.asObservable();

  private actingUserSettingsChangedSubject = new BehaviorSubject<boolean>(false);
  actingInUserSettingsChanged$ = this.actingUserSettingsChangedSubject.asObservable();

  constructor(private basespaceApi: BasespaceService) {
    super(['actingUserSettingsList', 'loggedInUserSettingsList', 'userSettingsStateError']);
  }

  loadUserSettings(userSessionType: UserSessionType, forceLoad: boolean = false): void {

    const currentState = this.getState();

    // Check if we need to fetch user settings from the server
    if (currentState &&
      (userSessionType === UserSessionType.LoggedInUser &&
        currentState.loggedInUserSettingsList !== undefined && currentState.loggedInUserSettingsList !== null) ||
      (userSessionType === UserSessionType.ActingUser
        && currentState.actingUserSettingsList !== undefined && currentState.actingUserSettingsList !== null && !forceLoad)) {

        // Dispatch current state for any subscribers
        if (userSessionType === UserSessionType.LoggedInUser) {
          this.loggedInUserSettingsChangedSubject.next(true);
        } else {
          this.actingUserSettingsChangedSubject.next(true);
        }
        this.dispatchCurrentState(currentState);
    } else {
      this.fetchUserSettings(userSessionType, currentState);
    }
  }

  setUserSettings(userSessionType: UserSessionType, settings: V2UserSetting[]): void {
    const payload: V2PostUserSettingsRequest = { UserSettings: settings };
    this.basespaceApi.PostV2UsersIdSettings({id: userSessionType, payload})
    .subscribe(result => {
      // Update the user settings list in the cache
      let state = null;
      let subject = null;
      let action = null;
      if (userSessionType === UserSessionType.ActingUser) {
        state = { actingUserSettingsList: result.UserSettings };
        subject = this.actingUserSettingsChangedSubject;
        action = UserSettingsStoreActions.UpdateActingUserSettings;
      } else {
        state = { loggedInUserSettingsList: result.UserSettings };
        subject = this.loggedInUserSettingsChangedSubject;
        action = UserSettingsStoreActions.UpdateLoggedInUserSettings;
      }
      this.setState(state, action);
      subject.next(true);
    });
  }

  /**
   * Fetch the list of user settings from the server
   * @param requestParams type of BasespaceService.GetV2UsersIdSettingsParams
   */
  private fetchUserSettings(userSessionType: UserSessionType, currentState: IUserSettingsState): void {
    this.loadingSubject.next(true);

    const userSettingsResponse$ = this.basespaceApi.GetV2UsersIdSettings({id: userSessionType, limit: MAX_USER_SETTINGS_LIMIT});

    this.subs.sink = userSettingsResponse$.pipe(
      // Do not make an additional API call if any additional subscribers have subscribed later on
      shareReplay(1),
      // Retry in case of HTTP errors
      retryWhen(genericRetryWhen()),
      // To avoid a Flash of content, maintain a delay
      delay(500),

    ).subscribe({
      next: (result) => {
        let state = null;
        let subject = null;
        let action = null;
        if (userSessionType === UserSessionType.ActingUser) {
          state = { actingUserSettingsList: result.Items };
          subject = this.actingUserSettingsChangedSubject;
          action = UserSettingsStoreActions.LoadActingUserSettingsList;
        } else {
          state = { loggedInUserSettingsList: result.Items };
          subject = this.loggedInUserSettingsChangedSubject;
          action = UserSettingsStoreActions.LoadLoggedInUserSettingsList;
        }
        state.userSettingsStateError = null;
        this.setState(state, action);
        subject.next(true);

        this.loadingSubject.next(false);
      },
      error: error => {
        this.handleError(error, () => ({ ...currentState, userSettingsStateError: error }));
        this.loadingSubject.next(false);
      }
    });
  }

  isLoggedInUserSettingsLoaded(): Observable<boolean> {
    this.loadUserSettings(UserSessionType.LoggedInUser);
    return this.loggedInUserSettingsChanged$.pipe(filter(s => s === true));
  }
}

// The action-names for this store, for logging/tracking.
export enum UserSettingsStoreActions {
    LoadActingUserSettingsList = 'LOAD_ACTING_USERSETTINGS_LIST',
    UpdateActingUserSettings = 'UPDATE_ACTING_USERSETTINGS_LIST',
    LoadLoggedInUserSettingsList = 'LOAD_LOGGEDIN_USERSETTINGS_LIST',
    UpdateLoggedInUserSettings = 'UPDATE_LOGGEDIN_USERSETTINGS_LIST'
  }

<ngx-smart-modal
  #importRunErrors
  class="run-errors-modal"
  identifier="importRunErrors"
  [closable]="true"
  [escapable]="false"
  [dismissible]="true"
  [showCloseButton]="showCloseButton"
  [showConfirmButton]="showConfirmButton"
  confirmButtonText="Proceed"
  [title]='title'>

  <h4 class="mb-3" style="line-height:2.5rem;" [innerHTML]="subTitle"></h4>

  <lib-error-alert-message
    [alertMsg]="warnings"
    [errorMsg]="errors">
  </lib-error-alert-message>

</ngx-smart-modal>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITrash, TrashItemUtilities } from '@app/core/model/trash';
import { genericRetryWhen, observableEmitDelay } from '@app/core/rxjsutils/rxjs-utilities';
import { BsApiEndPoints } from '@app/core/services/bs-api/endpoints';
import { MessageService } from '@app/core/services/message/message.service';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { ConsoleLogger } from '@app/core/utilities/consolelogger';
import { ToastrMessages } from '@app/core/utilities/toastr-messages';
import { ToastrService } from '@bssh/comp-lib';
import { BasespaceService } from '@bssh/ng-sdk';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, delay, finalize, retryWhen, tap, debounceTime } from 'rxjs/operators';
import { AbstractResourceStore } from '../resource/abstract.resource.store';
import { IResourceStore } from '../resource/resource.store';
import { ITrashItemsState } from './trash-items.state';
export interface ITrashItemsStore extends IResourceStore<ITrashItemsState> {
    updateSelectedTrashItems(updatedSelections: ITrash[]): void;
}

export enum TrashItemStoreActions {
    UpdateSelectedTrashItems = 'UPDATE_SELECTED_TRASH_ITEMS',
    UpdateTrashItemsCount = 'UPDATE_TRASH_ITEMS_COUNT'
}

export enum TrashItemMessageTypes {
    UpdateTrashItemsCount = 'UPDATE_TRASH_ITEMS_COUNT'
}

@Injectable({
    providedIn: 'root'
})
export class TrashItemsStore extends AbstractResourceStore<ITrashItemsState> implements ITrashItemsStore {

    private refreshSubject = new BehaviorSubject<boolean>(false);
    trashItemsRefreshed$ = this.refreshSubject.asObservable();

    constructor(
        private basespaceService: BasespaceService,
        private toastrService: ToastrService,
        private searchResourceDictionaryService: SearchResourceDictionaryService,
        private http: HttpClient,
        private messageService: MessageService
    ) {
        super(
            [
                'selectedTrashItems',
                'trashItemsCount'
            ]
        );

        // initialize items in trash count
        this.updateTrashItemsCount();

        this.subs.sink = this.messageService.getMessages(TrashItemMessageTypes.UpdateTrashItemsCount)
        .pipe(debounceTime(observableEmitDelay))
        .subscribe(() => this.updateTrashItemsCount());
    }


    updateSelectedTrashItems(updatedSelections: ITrash[]): void {
        this.setState({ selectedTrashItems: updatedSelections }, TrashItemStoreActions.UpdateSelectedTrashItems);
    }

    updateTrashItemsCount(): void {
        this.http.get(`${BsApiEndPoints.TRASH_ITEMS_URL}?limit=0`).pipe(
            tap((response) => {
                const totalCount = response && response['Paging'] ? response['Paging'].TotalCount : null;
                this.setState({ trashItemsCount: totalCount }, TrashItemStoreActions.UpdateSelectedTrashItems);
            })
        ).subscribe();
    }

    forceRefreshStore() {
        this.refreshSubject.next(true);
    }

    getActiveList(): ITrash[] {
        const { selectedTrashItems } = this.getState();
        if(!selectedTrashItems) {
            return null;
        } else {
            return selectedTrashItems;
        }
    }

    canRestore(): boolean {
        const selectedTrashItems = this.getActiveList();
        return !!(selectedTrashItems && selectedTrashItems.length > 0);
    }

    restoreTrashItem(trashItem: ITrash): Observable<any> {
        return this.basespaceService.PostV2TrashIdRestorefromtrash({ id: trashItem.Id }).pipe(
            retryWhen(genericRetryWhen()),
            delay(observableEmitDelay),
            tap(() => {
                const itemName = trashItem.Name;
                const resourceName = TrashItemUtilities.getTrashItemResourceName(trashItem, this.searchResourceDictionaryService);
                this.toastrService.success(ToastrMessages.TRASH_ITEM_RESTORE.SUCCESS(itemName, resourceName));
            }),
            catchError((error) => {
                ConsoleLogger.logError(error);
                this.toastrService.error(error.error.ErrorMessage);
                return of(null);
            })
        );
    }

    restore(): void {
        const selectedTrashItems = this.getActiveList();
        this.loadingSubject.next(true);
        forkJoin(
            selectedTrashItems.map((trashItem: ITrash) => this.restoreTrashItem(trashItem))
        ).pipe(
            finalize(() => this.loadingSubject.next(false))
        ).subscribe({
            next: () => {
                this.forceRefreshStore();
            }
        });
    }

    canEmpty(): boolean {
        const selectedTrashItems = this.getActiveList();
        return !selectedTrashItems || selectedTrashItems.length === 0;
    }

    empty(): void {
        this.loadingSubject.next(true);
        this.basespaceService.DeleteV2Trash().pipe(
            retryWhen(genericRetryWhen()),
            delay(observableEmitDelay),
            finalize(() => {
                this.loadingSubject.next(false);
            })
        ).subscribe({
            next: () => {
                this.forceRefreshStore();
            },
            error: (error) => {
                if (this.isTrashEventErrorCode(error.error.ErrorCode)) {
                    this.toastrService.error(error.error.ErrorMessage, 'Unable To Empty Trash');
                } else {
                    this.toastrService.error('Unable To Empty Trash');
                }
            }
        });
    }

    private isTrashEventErrorCode(errorCode: string) {
        return (
            errorCode === 'BASESPACE.DATAMANAGEMENT.TRASH_ALREADY_BEING_EMPTIED' ||
            errorCode === 'BASESPACE.DATAMANAGEMENT.TRASH_EMPTY_OTHER_EVENT_IN_PROGRESS'
        );
    }

}
export interface EditInfoEditableField {
    name: ResourceEditableField; // Name of the field
    type: EditInfoInputFieldType;
    placeholder: string; // Placeholder
    disabled?: boolean; // If you need to display a value to the user but don't want it editable (e.g. default project for biosample creation)
    maxLength?: number; // Max length allowed. If no max limit, leave empty
    maxLengthWarningMessage?: string; // warning message to be displayed when length exceeds Maxlength
    isOptional: boolean; // whether it is optional
    mandatoryFieldErrorMessage?: string; // error message when mandatory field is left empty
    currentValue?: string; // TODO: will the value always be string ?
}

export interface EditInfoComponentOutput {
    newValueMap: ResourceFieldToValueMap;
}

export enum EditInfoInputFieldType {
    // Currently edit component supports these two types of html elements.
    // Add more ( like radio button, checkbox) if needed.
    INPUT = 'Input', // for smaller inputs
    TEXTAREA = 'TextArea' // for large inputs
}

export enum ResourceEditableField {
    // TODO: add more more fields here if other fields need to edited
    NAME = 'Name',
    DESCRIPTION = 'Description',
    DEFAULT_PROJECT = 'Default Project',
    CONTAINER = 'Container',
    POSITION = 'Position'
}

// map of editable field (like name, description) to its value
export type ResourceFieldToValueMap = Partial<Record<ResourceEditableField, string>>;

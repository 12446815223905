import { Injectable } from '@angular/core';
import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { BsshDatePipe } from '@app/core/utilities/pipes/bssh-date.pipe';
import { BytesPipe } from '@app/core/utilities/pipes/bytes.pipe';
import { SearchColumnFields, SearchGridOptionsFormatter } from '@app/search/gridOptions/search-grid-options-formatter';
import { BiosampleChooserGridOptionsFormatter } from './biosample-chooser-grid-options-formatter';
import { ProjectChooserGridOptionsFormatter } from './project-chooser-grid-options-formatter';
import { SampleChooserGridOptionsFormatter } from './sample-chooser-grid-options.formatter';

export interface IChooserGridOptionsFactoryService {
  getGridOptionsFormatterForResource(resourceType: SearchResourceType): SearchGridOptionsFormatter<SearchColumnFields>;
}

@Injectable({
  providedIn: 'root'
})
export class ChooserGridOptionsFactoryService implements IChooserGridOptionsFactoryService {

  constructor(
    private resourceDictionaryService: SearchResourceDictionaryService,
    private bytePipe: BytesPipe,
    private bsshDatePipe: BsshDatePipe
  ) { }


  getGridOptionsFormatterForResource(resourceType: SearchResourceType): SearchGridOptionsFormatter<SearchColumnFields> {
    switch (resourceType) {
      case SearchResourceType.Project:
        return new ProjectChooserGridOptionsFormatter(this.resourceDictionaryService, this.bytePipe, this.bsshDatePipe);
      case SearchResourceType.Biosample:
        return new BiosampleChooserGridOptionsFormatter(this.resourceDictionaryService, this.bsshDatePipe);
      case SearchResourceType.Sample:
        return new SampleChooserGridOptionsFormatter(this.resourceDictionaryService, this.bsshDatePipe);
      default:
        throw Error(`Chooser Grid Options for ${resourceType} not supported.`);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, StatusChangeResource, StatusChangeComponentOutput } from '@bssh/comp-lib';
import { ModalTexts } from '../modal-texts';
import { isNullOrUndefined } from 'util';
import { IAppSession } from '../../../core/model/appSessions/appSession';
import { ReverseAnalysisDeliveryStatus, ALL_POSSIBLE_ANALYSIS_DELIVERY_STATUSES,
   AnalysisDeliveryStatus, 
   NON_SUCCESS_ANALYSIS_DELIVERY_STATUSES} from '../../../core/model/appSessions/analysis-status';
import { StatusToDisplayName } from '../../../core/utilities/pipes/status-to-display-name.pipe';
import { ChangeStatusModalComponent } from '../change-qc-status/change-status-modal.component';

@Component({
  selector: 'app-change-analyses-delivery-status-modal',
  templateUrl: './change-analyses-delivery-status-modal.component.html',
  styleUrls: ['./change-analyses-delivery-status-modal.component.scss']
})
export class ChangeAnalysesDeliveryStatusModalComponent extends ChangeStatusModalComponent<IAppSession, AnalysisDeliveryStatus> {

  // analyses: StatusChangeResource[] = [];
  analysisIdentifierTitle: string = 'ANALYSES';
  statusTitle: string = 'DELIVERY';
  alertMsg: string = null; // set to proper msg if selected analysess are not appropriate for status change
  allPossibleStatuses = ALL_POSSIBLE_ANALYSIS_DELIVERY_STATUSES;

  modalType = 'InitiateChangeAnalysesDeliveryStatusModal';
  ngxSmartModalTitle: string = ModalTexts.CHANGE_STATUS.MODAL_TITLE.DELIEVRY.ANALYSIS;

  constructor(public ngxSmartModalService: NgxSmartModalService, public statusToDisplayName: StatusToDisplayName) {
    super(ngxSmartModalService, statusToDisplayName);
    this.setAnalysisDeliveryStatusReverseMap();
  }

  protected getStatusChangeResources(resources: IAppSession[]): StatusChangeResource[] {
    const analysesForChange: StatusChangeResource[] = [];
    if (isNullOrUndefined(resources)) {
      return analysesForChange;
    }

    for (const analysis of resources) {
      const analysisStatus = analysis.DeliveryStatus;
      const analysisForChange: StatusChangeResource = {
        resourceName: analysis.Name.toString(),
        currentStatus: this.statusToDisplayName.transform(analysisStatus)
      };

      if (NON_SUCCESS_ANALYSIS_DELIVERY_STATUSES.includes(analysisStatus)) {
        analysisForChange.statusClass = 'status-change__result__value--bold';
      }

      analysesForChange.push(analysisForChange);
    }

    return analysesForChange;
  }

  private setAnalysisDeliveryStatusReverseMap() {
    this.reverseMap = ReverseAnalysisDeliveryStatus;
  }

}

import { IV2EntityImportStatus } from '../../core/bsshapi/api-wrappers';

export enum V2ImportStatus {
    Error = 'Error',
    Warning = 'Warning'
}

export interface IValidatedCsvRow {
    data: string[];
    validationErrors: Array<IV2EntityImportStatus>;
    validationWarnings: Array<IV2EntityImportStatus>;
}

export interface IValidatedCsvResult {
    headerRow: string[];
    rows: Array<IValidatedCsvRow>;
}

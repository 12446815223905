import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { Router, NavigationEnd, NavigationStart, NavigationError, RoutesRecognized } from '@angular/router';
import { CodeFeaturesService } from '../user/code-features.service';
import { CustomNGXLoggerService, NgxLoggerLevel, NGXLogger } from 'ngx-logger';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggerService extends BaseService {
  private logger: NGXLogger;
  constructor(private router: Router, private codeFeaturesService: CodeFeaturesService, private customLogger: CustomNGXLoggerService) {
    super();

    // To get instance of NGXLogger only for this service with its own configs
    // can specify the 'serverLoggingUrl' config later to send the logs to server
    this.logger = this.customLogger.create({
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: false
    });

    this.init();
  }

  init() {
    this.info(this.router.url);
    this.subs.sink = this.router.events
      .subscribe({
        next: (event) => {
          if (event instanceof RoutesRecognized && this.codeFeaturesService.webRouteLoggingIsEnabled) {
            this.info(event.urlAfterRedirects);
          }

          if (event instanceof NavigationError && this.codeFeaturesService.webRouteLoggingIsEnabled) {
            this.error(event.error); // In case navigation is unsuccessful
          }

        },
        error: (error: Error) => {
          if (this.codeFeaturesService.webRouteLoggingIsEnabled) {
            this.error(error.message);
          }
        }
      });
  }

  debug(message: any, ...additional: any[]) {
    if (additional.length === 0) {
      this.logger.debug(message);
      return;
    }

    this.logger.debug(message, additional);
  }

  info(message: any, ...additional: any[]) {
    if (additional.length === 0) {
      this.logger.info(message);
      return;
    }

    this.logger.info(message, additional);
  }

  log(message: any, ...additional: any[]) {
    if (additional.length === 0) {
      this.logger.log(message);
      return;
    }

    this.logger.log(message, additional);
  }

  warn(message: any, ...additional: any[]) {
    if (additional.length === 0) {
      this.logger.warn(message);
      return;
    }

    this.logger.warn(message, additional);
  }

  error(message: any, ...additional: any[]) {
    if (additional.length === 0) {
      this.logger.error(message);
      return;
    }

    this.logger.error(message, additional);
  }
}

import { ILinkCellRendererComponentOptions } from '@bssh/comp-lib/lib/components/data-table/link/link-cell-renderer.component';

export class DataTableUtilities {

    public static getLinkRenderer(value: any, href?: string, routerLinkParams?: any[], linkTarget?: string)  {

        const linkComponentParams: ILinkCellRendererComponentOptions = {
          value: value,
          href: href,
          routerLinkParams: routerLinkParams,
          target: linkTarget
        }
        return ({component: 'link', params: linkComponentParams});
      }
    
    public static getSpanRenderer(value?: any) {
        if (value) {
          return ({component: 'tooltip', params: { value }});
        }
    
        return ({component: 'span', params: { value: '---' }});
    }
}

<ngx-smart-modal #chooserModal 
    [identifier]="modalType"
    [closable]="closable"
    customClass="chooser-modal modal-dialog-centered" 
    [closeButtonText]="closeButtonText" 
    [confirmButtonText]="confirmButtonText"
    [newButtonText]="newButtonText" 
    data-cy="chooser-modal"
    [disableConfirmButton]="disableConfirmButton"
    newButtonText="New"
    [showNewButton]="isResourceCreatable"
    >
        <lib-chooser #chooserSingleSelect 
        [title]="chooserTitle"
        [searchPlaceHolderText]="chooserSearchPlaceHolderText"
        [titleToolTip]="chooserTitleTooltip" 
        [rowSelection]="rowSelection" 
        [gridOptions]="gridOptions"
        [chooserServerSideDataParams]="chooserServerSideParams" 
        [hasMatcher]="hasMatcher"
        (itemsSelected)="chooserItemsSelected($event)" 
        [debounceTime]="debounceTimeInMs.toString()"></lib-chooser>
</ngx-smart-modal>

import {Injectable} from '@angular/core';
import {AnalysisDefinitionGroupedOption, CustomOption, GroupedOption} from '@app/core/store/cloud-run-prep/run-setup.state';
import { isEmpty } from 'lodash';
import {ISampleDataRow} from '@app/run-planning/interface';
import {bool} from '@root/node_modules/aws-sdk/clients/signer';

export type GroupedOptionType = 'Kits' | 'Genomes' | 'Reference Files';
export const GROUPED_OPTIONS_CUSTOM_GROUP_INDEX = 0;
export const GROUPED_OPTIONS_STANDARD_GROUP_INDEX = 1;

@Injectable({
  providedIn: 'root'
})
export class RunSetupHelperService {
  constructor() { }

  public isIlluminaKit(kit: any): boolean {
    return kit.isIllumina || (kit.organization || '').toUpperCase() === 'ILLUMINA' || kit.id === 'Unspecified';
  }
  
  public mapToCustomOption(item: any) : CustomOption {
    if (!item) {
      return null;
    }
    return {
      id: item.id,
      text: item.displayName || item.name,
      value: item.id,
      originalData: item
    };
  }

  /**
   * Group a list of CustomOptions into Custom and Standard (Illumina) groups for combobox dropdown display.
   * @param options list of CustomOptions to group by 'Custom' & 'Standard'
   * @param groupLabel optionType to label the custom/standard groups. Defaults to 'kits'.
   */
  groupByCustomAndStandardOptions(options: CustomOption[], groupLabel: GroupedOptionType = 'Kits'): GroupedOption[] {
    const customOptions = options.filter(x => !this.isIlluminaKit(x.originalData));
    const standardOptions = options.filter(x => this.isIlluminaKit(x.originalData));
    return [
      // update GROUPED_OPTIONS_X_GROUP_INDEX when the order is changed
      { groupName: `Custom ${groupLabel}`, options: customOptions },
      { groupName: `Standard ${groupLabel}`, options: standardOptions }
    ];
  }

  /***
   * Based on 'fields', set missing properties in 'values' with empty string
   */
  setMissingValuesToEmpty(values: any, fields: any[]) {
    if (isEmpty(fields)) {
      return;
    }

    values = values || {};
    for (const field of fields) {
      if (!(field.id in values)) {
        values[field.id] = '';
      }
    }
  }

  /**
   * Checks if the sample data table has user data
   * @param rows sample data table rows
   */
  isSampleDataTableEmpty(rows: ISampleDataRow[]): boolean {
    return isEmpty(rows)
      || !rows.find(row => Object.keys(row).some(field => (field !== 'index') && !isEmpty(row[field])));
  }

  /**
   * Given a analysisDefinition grouped option, determine if it is a bclConvert type, which is then used to cache the AVDs
   * used to determine the implicit bcl convert
   * @param analysisDefinitionGroupedOption dropdown option from application (AVDs grouped under AnalysisDef)
   */
  isGroupedOptionBclConvertAnalysisDefinition(analysisDefinitionGroupedOption: AnalysisDefinitionGroupedOption): boolean {
    const bclConvertAnalysisDefinitionNameRegex = /BCLConvert/i;
    let isBclConvertGroupedOption = bclConvertAnalysisDefinitionNameRegex.test(analysisDefinitionGroupedOption.name);
    // BASE-72726 the default analysis definition, FASTQGeneration will be updated to BCLConvert soon
    // (if the preferred bclConvert default is not found, fallback to the old BCLConvertName FASTQGeneration)
    if (!isBclConvertGroupedOption) {
      const fallbackBclConvertAnalysisDefinitionNameRegex = [/(fastq)/i, /(generate|generation)/i];
      isBclConvertGroupedOption = fallbackBclConvertAnalysisDefinitionNameRegex.every(r => r.test(analysisDefinitionGroupedOption.name));
    }
    return isBclConvertGroupedOption;
  }
}

import { Injectable } from '@angular/core';
import { CanLoad, Route} from '@angular/router';
import { Observable, of } from 'rxjs';
import { SkinnyBsshService } from '../services/skinny-bssh/skinny-bssh.service';
import { map, catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SkinnyBsshGuard implements CanLoad {
  
  constructor(private SkinnyBsshService: SkinnyBsshService) {
  }

    /**
   * @param route route snap shot
   */
  canLoad(route: Route): Observable<boolean> {
    const requestPath = route.path;
    return this.checkIfEnabledToLoad(requestPath);
  }

  /**
   * Checks if the requested route can be loaded. Redirects to 404 page if not
   */
  private checkIfEnabledToLoad(requestPath? : string): Observable<boolean> {
    if(requestPath) {
    return this.SkinnyBsshService.isRouteEnabled(requestPath).pipe(
      take(1),
      map((isRouteDisabled) => {
        if (isRouteDisabled) {
          // Redirect to 404
          this.SkinnyBsshService.navigateToPageNotFound();
        } else {
          return true;
        }
      }),
      catchError(error => {
        return of(false);
      })
    );
    }
  else {
    this.SkinnyBsshService.navigateToPageNotFound();
    }
  }
}

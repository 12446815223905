<ngx-smart-modal
  #EmptyTrashModal
  [identifier]="modalType"
  [closable]="true"
  closeButtonText="cancel"
  confirmButtonText="empty trash"
  
  [title]="modalTitle"
>
  <div class="empty-trash-modal__size-container">
    <div class="empty-trash-modal">Total size</div>
    <span class="empty-trash-modal__dots"></span>
    <div class="empty-trash-modal">{{totalSize | bytes:2}}</div>
  </div>

  <lib-error-alert-message
      [alertMsg]="alertMessages"
      [errorMsg]="errorMsg"
    ></lib-error-alert-message>

</ngx-smart-modal>
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { StringHumanizer } from '../../store/usernotifications/string-humanizer';
/**
 * Converts Resource Status values to Display Values
 * e.g  'QcFailed' to 'QC Failed',
 *      'PendingAnalysis' to 'Pending Analysis'
 *      'LaneQcFailed' to 'Lane QC Failed'
 */
@Pipe({
        name: 'statusToDisplayName'
})
@Injectable({
        providedIn: 'root'
})
export class StatusToDisplayName implements PipeTransform {
        constructor(private stringHumanizer: StringHumanizer) {
        }

        transform(input: string): string {
                let result = this.stringHumanizer.humanizeString(input);
                return result.toLowerCase() === 'undefined' ? '--' : result.replace('Qc', 'QC');
        }

}

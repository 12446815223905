import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { ITransferModalInput, ITransferModalOutput } from '../model/action-modal';
import { ModalTexts } from '../modal-texts';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ResourceType } from '@app/core/model/resource-type';

@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.scss'],
  exportAs: 'appTransferModal',
})
export class TransferModalComponent extends BaseModalComponent implements AfterViewInit, OnInit {
  resourceName: string;
  resourceType: ResourceType;
  public alertMessage = '';
  public confirmButtonText = ModalTexts.TRANSFER_OWNERSHIP.CONFIRM_BUTTON_TEXT;
  public modalTitle = ModalTexts.TRANSFER_OWNERSHIP.MODAL_TITLE;
  public inputLabel = ModalTexts.TRANSFER_OWNERSHIP.INPUT_LABEL;
  public inputPlaceholder = ModalTexts.EMAIL;
  public optionalMessageLabel = ModalTexts.OPTIONAL_MESSAGE_LABEL;
  public optionalMessagePlaceholder = ModalTexts.OPTIONAL_MESSAGE_PLACEHOLDER;
  public canTransfer = true;
  public modalType = 'TransferModal';

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.disableConfirmButton = true;

    // subscribe to change in input data
    this.subs.sink = this.data.subscribe((response: ITransferModalInput) => {
      if (response) {
        this.resourceName = response.resourceName;
        this.resourceType = response.resourceType;

        // check if there is any error input to modal
        if (response.errorMsg) {
          this.error.next(response.errorMsg);
          this.canTransfer = false;
          return;
        }

        // else display alert message
        this.alertMessage = response.alertMsg;

      }
    });
   }

  dataChanged($event) {
    // not setting the modal data, if transfer cannot be done
    // confirm button remains hidden
    if (!this.canTransfer) {
      return;
    }

    // else set the incoming input values to modal data
    const _data: ITransferModalOutput = {
      email: $event.inputValue,
      optionalMessage: $event.optionalMessage
    };

    this.modal.setData(_data, true);

    // Enable the confirm button only when first email input is received
    this.disableConfirmButton = !($event.inputValue && $event.isInputValid);
  }

  protected constructError(error: any): string {
    if(error.name === "TimeoutError") {
      let singularResourceType;

        switch(this.resourceType) {
          case ResourceType.PROJECT:
          case ResourceType.PROJECTS:
            singularResourceType = ResourceType.PROJECT;
            break;
          case ResourceType.RUN:
          case ResourceType.RUNS:
            singularResourceType = ResourceType.RUN;
            break;
        }

        // This is handling special case for when size of resource is too large for transfer and API times out
        return ModalTexts.TRANSFER_OWNERSHIP.SEND.RESOURCE_SIZE_ERROR(singularResourceType);
    } else {
      return error.error.ResponseStatus.Message;
    }
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from '@bssh/comp-lib';
import { isNullOrUndefined } from 'util';
import { ResourceType } from '../../../core/model/resource-type';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { ModalTexts } from '../modal-texts';
import { CreateResourceModalInput, CreateResourceModalOutput } from '../model/action-modal';
import {
  EditInfoComponentOutput,
  EditInfoEditableField, EditInfoInputFieldType, ResourceEditableField,
  ResourceFieldToValueMap,
} from '../model/edit-info-editable-field';
import { filter } from 'rxjs/operators';
import { IProject } from '@app/core/model/projects/project';

@Component({
  selector: 'app-create-resource-modal',
  templateUrl: './create-resource-modal.component.html',
  styleUrls: ['./create-resource-modal.component.scss']
})
export class CreateResourceModalComponent extends BaseModalComponent implements AfterViewInit, OnInit {

  resourceType: ResourceType = null;
  fields: EditInfoEditableField[] = [];

  modalType = 'CreateResourceModal';
  ngxSmartModalTitle: string = '';
  confirmButtonText: string = ModalTexts.CREATE_RESOURCE.CONFIRM_BUTTON_TEXT;

  // For storing default project when creating a biosample
  defaultProject: IProject;

  constructor(public ngxSmartModalService: NgxSmartModalService) {
    super(ngxSmartModalService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.setUpInputSubscriber();
  }

  constructError(error: any): string {
    if (isNullOrUndefined(error.errorCode)) {
      return ModalTexts.CREATE_RESOURCE.ERROR;
    }
    return '';
  }

  dataChanged($event: EditInfoComponentOutput) {
    const componentOutput: EditInfoComponentOutput = $event;
    if (componentOutput) {
      this.disableConfirmButton = this.isAnyMandatoryFieldEmpty(componentOutput.newValueMap);
      const data: CreateResourceModalOutput = {
        fieldValueMap: componentOutput.newValueMap,
      };

      switch(this.resourceType) {
        case ResourceType.BIO_SAMPLE:
        case ResourceType.BIO_SAMPLES:
          data.defaultProject = this.defaultProject;
          break;
      }

      this.modal.setData(data, true);
    }
  }

  private isAnyMandatoryFieldEmpty(newValueMap: ResourceFieldToValueMap) {
    for (const editableField of this.fields) {
      if (editableField.isOptional === false &&
        (newValueMap[editableField.name].length === 0)) {
        return true;
      }
    }
    return false;
  }

  private setUpInputSubscriber() {
    this.subs.sink = this.data
    .pipe(
      filter((response) => !!response)
    ).subscribe(res => {
        const response: CreateResourceModalInput = res as CreateResourceModalInput;
        this.resourceType = response.resourceType;

        this.initModalProperties(response);
      });
  }

  private initModalProperties(input: CreateResourceModalInput) {
    switch (this.resourceType) {
      case ResourceType.PROJECT:
      case ResourceType.PROJECTS:
        this.initCreateNewProject();
        break;
      case ResourceType.BIO_SAMPLE:
      case ResourceType.BIO_SAMPLES:
          this.initCreateNewBiosample(input);
          break;
      default:
        break;
    }
  }

  private initCreateNewProject(): void {
    this.ngxSmartModalTitle = 'New Project';
    this.fields = this.getProjectFieldsToEdit();
  }

  private initCreateNewBiosample(input: CreateResourceModalInput): void {
    this.ngxSmartModalTitle = 'New Biosample';
    this.defaultProject = input.defaultProject;
    this.fields = this.getBiosampleFieldsToEdit();
  }

  private getProjectFieldsToEdit(currentValueMap?: Record<ResourceEditableField, string>): EditInfoEditableField[] {
    const fields: EditInfoEditableField[] = [
      {
        name: ResourceEditableField.NAME,
        type: EditInfoInputFieldType.INPUT,
        placeholder: ResourceEditableField.NAME.toString(),
        maxLength: 255,
        maxLengthWarningMessage: ModalTexts.EDIT_INFO.MAX_LENGTH_WARNING_MESSAGE(255, this.resourceType),
        isOptional: false,
        mandatoryFieldErrorMessage: ModalTexts.EDIT_INFO.MANDATORY_FIELD_ERROR_MESSAGE(this.resourceType),
        currentValue: currentValueMap ? currentValueMap[ResourceEditableField.NAME] : '',
      },
      {
        name: ResourceEditableField.DESCRIPTION,
        type: EditInfoInputFieldType.TEXTAREA,
        placeholder: ResourceEditableField.DESCRIPTION.toString(),
        isOptional: true,
        currentValue: currentValueMap ? currentValueMap[ResourceEditableField.DESCRIPTION] : '',
      }
    ];
    return fields;
  }

  private getBiosampleFieldsToEdit(currentValueMap?: Record<ResourceEditableField, string>): EditInfoEditableField[] {
    const fields: EditInfoEditableField[] = [
      {
        name: ResourceEditableField.NAME,
        type: EditInfoInputFieldType.INPUT,
        placeholder: ResourceEditableField.NAME.toString(),
        maxLength: 100,
        maxLengthWarningMessage: ModalTexts.EDIT_INFO.MAX_LENGTH_WARNING_MESSAGE(100, this.resourceType),
        isOptional: false,
        mandatoryFieldErrorMessage: ModalTexts.EDIT_INFO.MANDATORY_FIELD_ERROR_MESSAGE(this.resourceType),
        currentValue: currentValueMap ? currentValueMap[ResourceEditableField.NAME] : '',
      },
      {
        name: ResourceEditableField.DEFAULT_PROJECT,
        type: EditInfoInputFieldType.INPUT,
        disabled: true,
        placeholder: ResourceEditableField.DEFAULT_PROJECT.toString(),
        isOptional: false,
        currentValue: this.defaultProject.Name
      },
      {
        name: ResourceEditableField.CONTAINER,
        type: EditInfoInputFieldType.INPUT,
        placeholder: ResourceEditableField.CONTAINER.toString(),
        isOptional: true,
        currentValue: currentValueMap ? currentValueMap[ResourceEditableField.CONTAINER] : '',
      },
      {
        name: ResourceEditableField.POSITION,
        type: EditInfoInputFieldType.INPUT,
        placeholder: ResourceEditableField.POSITION.toString(),
        maxLength: 20,
        maxLengthWarningMessage: ModalTexts.EDIT_INFO.MAX_LENGTH_WARNING_MESSAGE(20, this.resourceType),
        isOptional: true,
        currentValue: currentValueMap ? currentValueMap[ResourceEditableField.POSITION] : '',
      }
    ];
    return fields;
  }
}

<ng-container *ngIf="params && params.data">
    <ng-template #customTrigger>
        <div class="dataset-name-tooltip__custom-trigger" >{{ value }}</div>
    </ng-template>
    <lib-popover
        popoverSize="extra-large"
        [popoverType]="customTrigger"
        popoverPlacement="right"
        [popoverAsyncContent]="popover">

        <ng-template #popover>
            <div class="dataset-name-tooltip">
                <div class="dataset-name-tooltip__metadata">
                    <div class="dataset-name-tooltip__metadata__block">
                        <h4 class="dataset-name-tooltip__metadata__title">DATASET DETAILS</h4>
                        <dl *ngIf="params.data && params.data.OriginDataset" class="dataset-name-tooltip__metadata-list">
                            <dt class="dataset-name-tooltip__metadata-list__name">Original Dataset</dt>
                            <dd class="dataset-name-tooltip__metadata-list__value">
                                {{ params.data.OriginDataset.Name }}   
                            </dd>

                            <dt class="dataset-name-tooltip__metadata-list__name">Date Copied</dt>
                            <dd class="dataset-name-tooltip__metadata-list__value">
                                {{ params.data.DateCreated | isoDateTime: 'LLL' }} 
                            </dd>
                        </dl>
                        <dl class="dataset-name-tooltip__metadata-list">
                            
                            <dt class="dataset-name-tooltip__metadata-list__name">Run</dt>
                            <dd class="dataset-name-tooltip__metadata-list__value">
                                <span *ngIf="!run || !run.ExperimentName">--</span>
                                <span *ngIf="run && run.ExperimentName">
                                    <a *ngIf="run.HrefBaseSpaceUI" [href]="run.HrefBaseSpaceUI">{{ run.ExperimentName }}</a>
                                    <span *ngIf="!run.HrefBaseSpaceUI">{{ run.ExperimentName }}</span>
                                </span>
                            </dd>

                            <dt class="dataset-name-tooltip__metadata-list__name">Flow Cell ID</dt>
                            <dd class="dataset-name-tooltip__metadata-list__value">{{ run && run.FlowcellBarcode ? run.FlowcellBarcode : '--' }}</dd>
                        </dl>
                    </div>
                </div>
                <div class="dataset-name-tooltip__table">
                    <h4 class="dataset-name-tooltip__table__title">DATASET FILE DETAILS</h4>
                    <lib-data-table [gridOptions]="gridOptions" [rowData]="rowData" enableColumnResizing="disable"></lib-data-table>
                </div>

            </div>
        </ng-template>
    </lib-popover>
</ng-container>
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSmartModalComponent, NgxSmartModalService } from '@bssh/comp-lib';
import { get } from 'lodash';
import { BaseModalComponent } from '../base-modal/base-modal.component';
import { IRequeueAnalysisModalInput, IRequeueAnalysisModalOutput } from '../model/action-modal';

export enum RequeueType {
  FromCurrentRun = 1,
  FromSampleSheet = 2
}

@Component({
  selector: 'app-requeue-analysis-modal',
  templateUrl: './requeue-analysis-modal.component.html',
  styleUrls: ['./requeue-analysis-modal.component.scss'],
})
export class RequeueAnalysisModalComponent extends BaseModalComponent implements OnInit, AfterViewInit {
  

  @ViewChild('sampleSheetInput', { static: false })
  public sampleSheetInput: ElementRef<any>;

  public modalType = 'requeueAnalysisModal';
  private requeueTypeText = {
    [RequeueType.FromCurrentRun]: 'Use Sample Sheet from this run',
    [RequeueType.FromSampleSheet]: 'Use a new Sample Sheet'
  }
  public defaultRequeueTypes: RequeueType[] = [RequeueType.FromCurrentRun, RequeueType.FromSampleSheet];
  public requeueTypeOptions;  
  public availableRequeueTypes: RequeueType[];
  public sampleSheetName;
  
  public requeueFormGroup: FormGroup = this.formBuilder.group({
    requeueType: new FormControl('', [Validators.required]),
    sampleSheet: new FormControl('', [Validators.required])
  });
  get requeueType() { return this.requeueFormGroup ? this.requeueFormGroup.get('requeueType') : null; }
  get sampleSheet() { return this.requeueFormGroup ? this.requeueFormGroup.get('sampleSheet') : null; }
  
  public get showSampleSheetControl() {
    return get(this.requeueType, 'value') === RequeueType.FromSampleSheet;
  }
  public get canRequeueFromCurrentRun() {
    return this.availableRequeueTypes.includes(RequeueType.FromCurrentRun);
  }


  constructor(
    ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder) { 
    super(ngxSmartModalService);
  }

  ngOnInit() {
    this.subscribeToFormChanges();
    // subscribe to change in input data
    this.subs.sink = this.data.subscribe((input: IRequeueAnalysisModalInput) => {
      if (input) {
        this.availableRequeueTypes = input.requeueTypes;
        this.requeueType.patchValue(input.requeueTypes.length > 0 ? input.requeueTypes[0] : null);
        this.requeueTypeOptions = this.defaultRequeueTypes.map(x => {
          const checked = x === input.requeueTypes[0];
          const disabled = !input.requeueTypes.includes(x);
          return {
            value: x, text: this.requeueTypeText[x], disabled, checked
          };
        });
      }
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  /**
   * Upon submission, process the form and submit request to GSS to create LPK and handle return result
   */
   accept(modal: NgxSmartModalComponent) {
    const content = this.requeueType.value === RequeueType.FromCurrentRun ? '' : this.sampleSheet.value;
     const output: IRequeueAnalysisModalOutput = { content };
    this.confirm.emit(output);
    modal.close();
  }

  onRequeueTypeChange(value) {
    this.requeueType.patchValue(value);
  }

  subscribeToFormChanges() {    
    // Upon change in requeue type, need to update the form group controls 
    this.requeueType.valueChanges.subscribe(() => {
      if(!this.showSampleSheetControl && this.sampleSheet) {
        this.requeueFormGroup.removeControl('sampleSheet')
      } else if(this.showSampleSheetControl && !this.sampleSheet) {
        this.sampleSheetName = '';
        this.requeueFormGroup.addControl('sampleSheet', new FormControl('', [Validators.required]));
      }
    });
  }

  handleSampleSheet(event: any) {
    if (!event.target || event.target.files.length == 0) {
      if(!this.sampleSheet.value) {
        this.sampleSheetName = '';
      }
      return;
    }
    const file = event.target.files[0];
    this.sampleSheetName = file.name;
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onloadend = (ev) => {
      this.sampleSheet.patchValue(fileReader.result.toString());
    };
  }
}

import { SearchResourceType } from '@app/core/model/search/search-resource-type';
import { SearchResourceDictionaryService } from '@app/core/services/resource-dictionary/search-resource-dictionary.service';
import { SearchQueryService } from '@app/core/services/search/resource-search/search-query.service';
import { BiosampleSearchColumnFields } from '../gridOptions/biosample-search-grid-options-formatter';
import { ISearchDataSourceOptions, SearchDataSource } from './search.datasource';

export class BiosampleSearchDataSource extends SearchDataSource {
    constructor(
        searchQueryService: SearchQueryService,
        resourceDictionaryService: SearchResourceDictionaryService,
        dataSourceOptions: ISearchDataSourceOptions
    ) {
        const biosampleResourceDictionary = resourceDictionaryService.getResourceConfigByType(SearchResourceType.Biosample);
        super(searchQueryService, biosampleResourceDictionary, dataSourceOptions);
    }

    get rawTextFilterColumnName(): string {
        return BiosampleSearchColumnFields.Name;
    }
}

<lib-universal-navbar
	(workGroupOpen)="log($event)"
	(searchOpen)="searchAction($event)"
	[searchWidgetConfig]="searchWidgetParameters"
	(onSelectSearchAction)="onSelectAction($event)"
	(notificationsOpen)="notificationsAction($event)"
	(helpOpen)="helpAction($event)"
	(trashOpen)="trashAction($event)"
	(workGroupSelected)="switchUserContext($event)"
	(applicationsOpen)="log($event)"
	(signOutClicked)="logOut($event)"
	[logoLinkNewWindow]="logoLinkNewWindow"
	[titleLinkNewWindow]="titleLinkNewWindow"
	[applicationLinkBottom]="navBar.applicationLinkBottom"
	[profileLinks]="navBar.profileLinks"
	[links]="navBar.links"
	[transparent]="transparent"
	[showNotifications]="true"
	(notificationDeleted)="deleteUserAlertNotification($event)"
	[trashBadge]="trashBadge"
	></lib-universal-navbar>

<!-- Todo : move this error div to a separate component and open the modal dynamically. 
	For now, it is in the template-->
<div #subscriptionErrorModalDiv>
	<ngx-smart-modal #subscriptionErrorModal identifier="errorModal" title="An Active Sequence Hub Subscription is Required"
		[showCloseButton]="true" closeButtonText="Close" [showConfirmButton]="false" [escapable]="true"
		[dismissible]="true">
		<app-subscription-error-modal-body></app-subscription-error-modal-body>
	</ngx-smart-modal>
</div>

<!-- used for walkme only, always hidden: this should only be added to the DOM when the archive code feature is enabled. -->
<input type="hidden" value="archive-enabled" *ngIf="archiveEnabled" id="bs-toolbar-file-archive"/>

<!-- used for walkme only, always hidden: this should only be added to the DOM when the run zip code feature is enabled. -->
<input type="hidden" value="runzipping-enabled" *ngIf="runZippingEnabled" id="bs-toolbar-run-zip"/>

<!-- used for walkme only, always hidden: this should only be added to the DOM when user has Ica Subscription. -->
<input type="hidden" value="ica-subscription-active" *ngIf="userHasActiveIcaSubscription"  id="ica-subscription-active"/>
import { Injectable } from '@angular/core';
import { IsoDateTime } from '@app/core/utilities/pipes/iso-date-time.pipe';
import { StringHumanizer } from '../string-humanizer';
import { ICurrentAlertNotificationState, IAlertCard } from '@bssh/comp-lib';
import { UserAlertNotificationStore } from './user-alert-notifications.store';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IUserNotification } from '@app/core/model/user-notifications/user-notifications';

@Injectable({
    providedIn: 'root'
})
export class UserAlertNotificationService {

    constructor(
        public userAlertNotificationStore: UserAlertNotificationStore,
        private isoDateTime: IsoDateTime,
        private stringHumanizer: StringHumanizer) { }

    public getUserAlertNotificationStoreState(): Observable<ICurrentAlertNotificationState> {

        const alertNotifications =  {
            title: '',
            alertCards: []
        };
        return this.userAlertNotificationStore.stateChanged.pipe(
            map(state => {
                if (state != null && state.alertNotifications != null) {
                    alertNotifications.alertCards = state.alertNotifications.userNotifications.map(item => {
                        return this.mapInviteNotification(item);
                    });
                    return { currentUserAlertNotifications:  alertNotifications};
                }
            }), catchError(error => {
                return of({ currentUserAlertNotifications: alertNotifications });
            })
        );
    }

    public deleteUserAlertNotification(alertCard: IAlertCard) {
        this.userAlertNotificationStore.deleteUserNotification(alertCard.id);
    }

    public loadUserAlertNotificationList(
        category: string, offset: number, limit: number, sortby: string,
        sortdir: string, forceLoad: boolean = false): void {
        this.userAlertNotificationStore.loadUserAlertNotificationList(category, offset, limit, sortby, sortdir, forceLoad);
    }

    private mapInviteNotification(data: IUserNotification): IAlertCard {
        return {
            type: 'general',
            title: data.NotificationInfo ? this.stringHumanizer.humanizeString(data.NotificationInfo.TitleHtml) : '',
            id: data.Id,
            date: this.isoDateTime.transform(data.DateModified, 'LLL'),
            name: data.NotificationInfo ? `${ data.NotificationInfo.InviteType }-${ data.NotificationInfo.Name }` : '',
            description: data.NotificationInfo ? data.NotificationInfo.Description : '',
            dismissible:  data.NotificationInfo ? data.NotificationInfo.IsDismissable : true,
            route: data.NotificationInfo ? data.NotificationInfo.HrefContent : ''
        };
    }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { EMPTY, Observable, of } from "rxjs";
import { BetaPreviewService } from "../beta-preview.service";
import { ReloadPageService } from "../reload-page.service";

@Injectable({
  providedIn: 'root'
})
export class BetaPreviewResolverService implements Resolve<string | null> {
  
  constructor(private reloadPageService: ReloadPageService,
    private betaPreviewService: BetaPreviewService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Observable<string> | null {

    if (this.betaPreviewService.isBetaPreviewEnabled()) {
      return of(null);
    }

    this.reloadPageService.reloadPage(routerState.url);
    return EMPTY;
  }
}